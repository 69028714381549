import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import FormButton from "../element/component/FormButton";
import HealthForm from "../element/component/HealthForm";

class corporate_membership_program extends Component {
  componentDidMount() {
    document.title = "Corporate Membership Program | Bridge Health";
  }

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      phone: "",
      company: "",
      team: "",
      errors: {
        username: "",
        email: "",
        phone: "",
        company: "",
        team: "",
      },
      submitted: false,
    };
  }

  closePopup = () => {
    this.setState({ submitted: false });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const errors = { ...this.state.errors };

    // Clear the error for the changed field
    errors[name] = "";

    this.setState({
      [name]: value,
      errors,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { username, company, email, phone } = this.state;
    const errors = {};

    // Validate username
    if (username.trim() === "") {
      errors.username = "Name is required";
    }

    // Validate Company
    if (company.trim() === "") {
      errors.company = "Company name is required";
    }

    // Validate email
    if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      errors.email = "Invalid email format";
    }

    // Validate phone
    if (!phone.match(/^\d{10}$/)) {
      errors.phone = "Phone number must be 10 digits";
    }

    // Update the state with the errors
    this.setState({ errors });

    // If there are no errors, submit the form
    if (Object.keys(errors).length === 0) {
      // Perform the form submission logic here
      // e.g., call an API endpoint, update the database, etc.

      // Show the "Thank you" message
      this.setState({ submitted: true });

      // Reset the form
      this.setState({
        username: "",
        email: "",
        phone: "",
        company: "",
        team: "",
        errors: {
          username: "",
          company: "",
          email: "",
          phone: "",
          team: "",
        },
      });

      // Show the "Thank you" message
      this.setState({ submitted: true });

      // Call the sendEmail function
      await this.sendEmail();
    }
  };

  sendEmail = async (e) => {
    // e.preventDefault();

    const { email, username, phone, team, company } = this.state;

    const res = await fetch("https://mailbridge.bridgehealth.in/employe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        username,
        phone,
        team,
        company,
      }),
    });

    const data = await res.json();
    if (data.status === 401 || !data) {
      console.log("error");
    } else {
      this.setState({
        show: true,
        email: "",
        username: "",
        phone: "",
        company: "",
        team: "",
      });
      console.log("Email sent");
    }
  };

  render() {
    const { submitted, errors, company, team } = this.state;

    return (
      <>
        <Header />
        <Helmet>
          <title>
            Employee Health | Corporate Health & Wellness | Bridge health
          </title>
          <meta
            name="Corporate Membership Program"
            content="Discover our comprehensive corporate membership program designed to promote employee health & wellness. Bridge Health offers tailored solutions for corporate healthcare needs."
          />
        </Helmet>
        <section className="team-section padding">
          {/* About Section Two */}
          <div className="container-fulid">
            <div className="row align-items-center corporatepaddinground">
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      srcSet={require("../../assets/images/shape/back.webp")}
                      alt=""
                      className="imgb2p"
                      rel="preload"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-box">
                  <div className="textleft">
                    <h3 className="textleft" style={{ fontSize: 40 + "px" }}>
                      <span className="headeingcolorblack">
                        Redefining the Meaning of{" "}
                      </span>
                      <br></br>
                      <span className="headeingcolorblue">
                        Employee-Centric Care
                      </span>
                    </h3>
                    <h4 className="textleft marginTop40">
                      Array of benefits for a healthier <br></br>and happier
                      workforce
                    </h4>
                   <FormButton ButtonName={"get a callback"}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6 margin10rem">
                <div className="sec-title">
                  <h2 className="sec-heading">
                    <span>
                      {" "}
                      We help you figure out <br></br>
                    </span>
                    <div>
                      <span className="headeingcolorblue">Where You Are </span>
                    </div>
                    on your
                    <div>
                      <span className="headeingcolorblue">
                        {" "}
                        Wellbeing Journey{" "}
                      </span>
                    </div>
                  </h2>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      srcSet={require("../../assets/images/shape/b2b12.webp")}
                      alt=""
                      style={{ marginRight: 2 + "rem" }}
                      rel="preload"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="auto-container margin150">
          <div className="sec-title text-align-center">
            <h2>
              <span className="headeingcolorblack">THE CORPORATE </span>
              <span className="headeingcolorblue"> WELLNESS </span>
              <span className="headeingcolorblack">MAP</span>
            </h2>
            <h4 className="marginTop40 text-align-center">
              Where are you on the well being journey?
            </h4>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="image-wrapper">
            <div className="image-one">
              <img
                srcSet={require("../../assets/images/shape/slider1.webp")}
                alt=""
                className="imgwidth"
                rel="preload"
              />
            </div>
          </div>
        </div>


        <div className="container-fulid">
          <div className="container margintop100">
            <div className="row align-items-center">
              <div className="image-wrapper">
                <div className="image-one">
                  <video
                    muted="muted"
                    loop="loop"
                    className="videofull"
                    playsInline
                    autoPlay
                    kind="corporate membeship"
                  >
                    <source
                      srcSet={require("../../assets/images/logo.mp4")}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="container-fluid b2bback">
          <div className="auto-container ">
            <div className="sec-title text-center textleft marginleft20forpersonal1">
              <h3 className="fonts">
                {" "}
                <span className="headeingcolorblue">BRIDGE HEALTH </span>
                makes it possible for{" "}
                <span className="headeingcolorblue"> Employers </span>to{" "}
                <br></br>keep a check on their{" "}
                <span className="headeingcolorblue">
                  {" "}
                  Employees’ Health Metrics{" "}
                </span>
              </h3>
            </div>
            <div className="row align-items-center">
              <div className="image-wrapper">
                <div className="image-one">
                  <video
                    muted="muted"
                    loop="loop"
                    className="videofull"
                    playsInline
                    autoPlay
                    kind="corporate Membership"
                  >
                    <source
                      src={require("../../assets/images/output.mp4")}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Contact Section --> */}
        <section>
          <section>
            <div className="auto-container">
              <div className="sec-title text-center">
                <h2>
                  <span className="headeingcolorblue">Boost </span>
                  <span className="headeingcolorblack">
                    Your Employees Health
                  </span>
                  <br></br>
                </h2>
              </div>
            </div>
          </section>
          <div className="auto-container" style={{ marginBottom: 4 + "rem" }}>
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-form-area">
                  {/* <!-- Contact Form--> */}
                  <div className="contact-form">
                    <p className="text-center">
                      Reach out to us and we'll help you in setting up the{" "}
                      <br></br>best of{" "}
                      <span className="headeingcolorblue">
                        Preventive Healthcare
                      </span>{" "}
                      Services for your employee.
                    </p>
                   <HealthForm/>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      srcSet={require("../../assets/images/shape/b2b1.webp")}
                      alt=""
                      rel="preload"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default corporate_membership_program;
