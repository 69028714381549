import React from 'react';

const MemberCard = ({ imgSrc, alt }) => {
  return (
    <div className="member-card">
      <img src={imgSrc} alt={alt} className="member-photo" />
    </div>
  );
}

export default MemberCard;
