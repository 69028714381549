import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import FormButton from "../element/component/FormButton";

class hypertension extends Component {
  componentDidMount() {
    document.title = "Hypertension | Bridge Health";
  }

  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Hypertension, Hypertensive Treatment In Bangalore - Bridge Health
          </title>
          <meta
            name="description"
            content="Bridge health offers specialized care for hypertension in Bangalore. Find expert doctors, personalized treatments for hypertension. Book online for hypertension treatment."
          />
        </Helmet>
        <section className="team-section padding doctoeback image-mobile-terms">
          <section className="container-fluid">
            <div className="row align-items-center auto-container reverseFlex">
              <div className="col-lg-6">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50">
                      <span className="headeingcolorblack">
                        Adios Hypertension,
                      </span>
                      <br></br>
                      <span className="headeingcolorblue"> Hello Life </span>
                    </h2>
                    <h3 className="textleft marginTop40">
                      Beat hypertension with <br></br> our experts
                    </h3>
                  </div>
                  <div className="btn-box btn5 pt-4">
                      <FormButton />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image-wrapper imgright">
                  <div className="image-one">
                    <img
                      srcset={require("../../assets/images/shape/tension1.webp")}
                      alt=""
                      className="imgwidth"
                      rel="preload"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className="contact-section heath-margin">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/tension2.webp")}
                  alt=""
                  className="widthimg"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div className="contact-form-area">
                  <div className="sec-title squrebox">
                    <p className="font22">
                      Hypertension, or high blood pressure, is a common and
                      often asymptomatic condition that affects millions of
                      people worldwide. If left unmanaged, hypertension can lead
                      to serious health problems such as heart disease, stroke,
                      kidney failure, and vision loss. It is a major risk factor
                      for cardiovascular disease, which is the leading cause of
                      death globally.
                    </p>
                  </div>
                  <div className="">
                  <FormButton />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="row align-items-center reverseFlex">
              <div className="col-lg-6">
                <div className="sec-title squrebox">
                  <p className="font22">
                    Fortunately, hypertension can be effectively managed with
                    the right care, including lifestyle changes and medication
                    when necessary. By prioritizing hypertension management,
                    individuals can significantly reduce their risk of
                    developing serious health problems and improve their overall
                    health and well-being
                  </p>
                </div>
                <div className="btn-box text-center btn5">
                    <FormButton />
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/tension3.webp")}
                  alt=""
                  className="widthimg imgFloatRight"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid bgcolor marginTop50">
          <div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/tension4.webp")}
                  alt=""
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div className="martop">
                  <div className="sec-title">
                    <p className="font22">
                      At Bridge Health, we understand how important it is to
                      manage hypertension to prevent serious health problems.
                      That's why our team of healthcare professionals works with
                      each individual to create a personalized management plan
                      that includes lifestyle modifications, like diet and
                      exercise, as well as medication when needed. Using the
                      latest technology and tools, we closely monitor blood
                      pressure readings and provide ongoing support to help
                      individuals achieve their goals.
                    </p>
                  </div>
                  <div className="btn-box text-center btn5">
                  <FormButton />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-section">
          <div className="auto-container">
            <div className="row align-items-center reverseFlex">
              <div className="col-lg-6">
                <div className="sec-title squrebox">
                  <p className="font22">
                    Our hypertension management program is designed to empower
                    individuals to take control of their health and reduce their
                    risk of developing serious health problems. With Bridge
                    Health, individuals can feel confident that they have the
                    resources and support they need to effectively manage their
                    hypertension and improve their overall health and well-being
                  </p>
                </div>
                <div className="btn-box text-center btn5">
                    <FormButton />
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/tension5.webp")}
                  alt=""
                  className="widthimg imgFloatRight marginTop40reverse"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <img
            srcset={require("../../assets/images/shape/tension6.webp")}
            alt=""
            className="labwidth"
            rel="preload"
          />
        </section>

        <section className="contact-section">
          <div className="auto-container heath-margin">
            <h2 className="header1 text-center">
              <span className="headeingcolorblack"> The</span>
              <span className="headeingcolorblue"> Bridge Health</span>
              <span className="headeingcolorblack"> Advantage </span>
            </h2>

            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="contact-form-area">
                  <ul className="listicon">
                    <li>
                      Develop a personalized management plan that includes
                      lifestyle modifications and medication when necessary
                    </li>

                    <li>
                      Provide ongoing support to help individuals achieve their
                      hypertension management goals
                    </li>
                    <li>
                      Encourage regular follow-up appointments to track progress
                      and make any necessary adjustments to the management plan
                    </li>
                    <li>
                      Collaborate with other healthcare providers to provide
                      comprehensive care to patients with hypertension
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="contact-form-area">
                  <ul className="listicon">
                    <li>
                      Monitor blood pressure readings using the latest
                      technology and tools
                    </li>
                    <li>
                      Refer patients to specialists when needed, such as
                      dietitians, exercise specialists, or cardiologists
                    </li>
                    <li>
                      Educate patients on the importance of managing
                      hypertension and the potential health risks associated
                      with uncontrolled hypertension
                    </li>
                    <li>
                      Empower patients to take an active role in their
                      hypertension management and encourage them to ask
                      questions and share concerns
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2 className="header1">
                <span className="headeingcolorblack"> Rise Above </span>
                <span className="headeingcolorblue"> Hypertension! </span>
              </h2>
            </div>
            <div className="d-flex justify-content-center pb-5">
                <FormButton />
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default hypertension;
