import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import ReachToUsForm from "../element/ReachToUsForm";
import FormButton from "../element/component/FormButton";

class diabetes extends Component {
  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Effective Diabetes Care & Prevention in Bangalore - Bridge Health
          </title>
          <meta
            name="description"
            content="Bridge health offers best diabetes care services in Bangalore. Highly experienced diabetologist /endocrinologist offer best diabetic care treatments near you."
          />
        </Helmet>
        <section className="team-section padding image-mobile-terms">
          {/* About Section Two */}
          <section className="container-fluid">
            <div className="row align-items-center auto-container reverseFlex">
              <div className="col-lg-6">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50">
                      <span className="headeingcolorblack">Diabetes?</span>
                      <br></br>
                      <span className="headeingcolorblue">
                        {" "}
                        More like{" "}
                        <span className="diabeates">
                          Dia<span className="beat">beat</span>es
                        </span>{" "}
                      </span>
                    </h2>
                    <h3 className="textleft marginTop40">
                      Manage, control, and beat <br></br>diabeates with our
                      experts
                    </h3>
                  </div>
                  <FormButton />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="imgright">
                  <img
                    srcset={require("../../assets/images/shape/dia1.webp")}
                    alt=""
                    className="imgwidth"
                    rel="preload"
                  />
                </div>
              </div>
            </div>
          </section>
        </section>
        <section className="contact-section heath-margin">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/dia2.webp")}
                  alt=""
                  className="widthimg"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div className="contact-form-area">
                  <div className="sec-title squrebox">
                    <p className="font22">
                      Diabetes is a chronic condition that affects millions of
                      people worldwide. If left untreated or improperly managed,
                      it can lead to a range of complications, including nerve
                      damage, blindness, kidney disease, and heart disease.
                      Therefore, proper diabetes care is essential for
                      maintaining good health and preventing these
                      complications.
                    </p>
                  </div>

                  <FormButton />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="row reverseFlex">
              <div className="col-lg-6">
                <div className="contact-form-area">
                  <div className="sec-title squrebox">
                    <p className="font22">
                      Effective diabetes management involves a lot of steps such
                      as monitoring blood sugar levels, taking medication as
                      prescribed, following a healthy diet, engaging in regular
                      exercise, and getting regular check-ups with a healthcare
                      professional. At Bridge Health, we take an active role in
                      helping you manage diabetes to help you significantly
                      improve your quality of life and reduce your risk of
                      long-term complications.
                    </p>
                  </div>
                  <FormButton />
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/dia3.webp")}
                  alt=""
                  className="widthimg"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="contact-section">
          <div className="auto-container">
            <h2 className="header1 text-center">
              <span className="headeingcolorblack"> The</span>
              <span className="headeingcolorblue"> Bridge Health</span>
              <span className="headeingcolorblack"> Advantage </span>
            </h2>
          </div>
          <div className="auto-container heath-margin">
            <div className="row">
              <div className="col-lg-6">
                <ul className="listicon">
                  <li>Blood sugar monitoring and management</li>
                  <li>Exercise recommendations and guidance</li>
                  <li>
                    Support for managing the emotional and psychological aspects
                    of living with diabetes
                  </li>
                  <li>
                    Referral to diabetes support groups or resources for
                    additional support
                  </li>

                  <li>
                    Regular check-ups and screenings for complications of
                    diabetes, such as eye and kidney damage
                  </li>
                </ul>
              </div>

              <div className="col-lg-6">
                <ul className="listicon">
                  <li>Nutritional counselling and meal planning</li>
                  <li>Diabetes education and self-management training</li>
                  <li>
                    Wound care and management for diabetic ulcers or foot wounds
                  </li>

                  <li>
                    Medication management, such as insulin injections or oral
                    medications
                  </li>
                  <li>
                    Treatment and management of related conditions, such as high
                    blood pressure or high cholesterol
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2 className="header1">
                <span className="headeingcolorblack">
                  {" "}
                  Don't sugarcoat it.{" "}
                </span>
                <span className="headeingcolorblue"> Manage diabetes! </span>
              </h2>
            </div>
            <div className="d-flex justify-content-center pb-4" >
              <FormButton />
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default diabetes;
