import React, { useState, useRef } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DoctorDataCopy from "../element/doctors-Data-copy";
import { Helmet } from "react-helmet";

const Affiliate = () => {
  const sliderRef = useRef(null);

  const [showMore, setShowMore] = useState(false);
  const initialCardsToShow = 8;

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const previousSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
  };

  const data = [
    {
      id: 1,
      name: "Dr. Ashok Kumar P",
      description: "Interventional Cardiologist",
      experience: "Experience: 14 Years",
      location: "Location: Vasanthnagar",
      imageSrc: "image 25.png",
    },
    {
      id: 2,
      name: "Dr. Kiran S Murthy",
      description: "Physiotherapist",
      experience: "Experience: 20 Years",
      location: "Location: Banshankari",
      imageSrc: "image 117.png",
    },
    {
      id: 3,
      name: "Dr.Murali Mohan S, MS, DNB",
      description: "Consultant Neuro and Spine Surgeon",
      experience: "Experience: 12 Years",
      location: "Location: Jayanagar",
      imageSrc: "image 118.png",
    },
    {
      id: 4,
      name: "Dr. Pavan Rasalkar",
      description: "Interventional Cardiologist",
      experience: "Experience: 12 Years",
      location: "Location: Padmanabhanagar",
      imageSrc: "image 119.png",
    },
    {
      id: 5,
      name: "Dr. Pavan Rasalkar",
      description: "Interventional Cardiologist",
      experience: "Experience: 12 Years",
      location: "Location: Padmanabhanagar",
      imageSrc: "image 120.png",
    },
    {
      id: 6,
      name: "Dr. Pavan Rasalkar",
      description: "Interventional Cardiologist",
      experience: "Experience: 12 Years",
      location: "Location: Padmanabhanagar",
      imageSrc: "image 121.png",
    },
    {
      id: 7,
      name: "Dr. Pavan Rasalkar",
      description: "Interventional Cardiologist",
      experience: "Experience: 12 Years",
      location: "Location: Padmanabhanagar",
      imageSrc: "image 122.png",
    },
    {
      id: 8,
      name: "Dr. Pavan Rasalkar",
      description: "Interventional Cardiologist",
      experience: "Experience: 12 Years",
      location: "Location: Padmanabhanagar",
      imageSrc: "image 123.png",
    },
    {
      id: 9,
      name: "Dr. Pavan Rasalkar",
      description: "Interventional Cardiologist",
      experience: "Experience: 12 Years",
      location: "Location: Padmanabhanagar",
      imageSrc: "aff1.png",
    },
    {
      id: 10,
      name: "Dr. Verma",
      description: "Interventional Cardiologist",
      experience: "Experience: 12 Years",
      location: "Location: Padmanabhanagar",
      imageSrc: "aff1.png",
    },
  ];
  const hospitalNames = [
    "Anand Diagnostic Laboratory",
    "Neuberg Diagnostics",
    "Ebisu Diagnostics Center",
    "SRL Diagnostics",
    "Forstis Hospitals",
    "RxDx",
    "Partha",
    "Maple Grove General Hospital",
    "Golden State Medical Center",
    "Pineview Healthcare",
  ];

  return (
    <>
      <Header />
      <Helmet>
        <title>
          Personalized Care Continuum -Medical & Digital Health Services |Bridge
          Health
        </title>
        <meta
          name="description"
          content="Bridge Health is one stop health solution for all preventive & holistic healthcare with personalized annual health plans. & There is a plan to fit the specific health needs of everybody - your spouse, parents, kids & YOU. Know more about Bridge Health, JP Nagar 2nd phase Bangalore."
        />
      </Helmet>
      <section className="team-section padding">
        {/* About Section Two */}
        <section className="container-fluid">
          <div className="row align-items-center auto-container reverseFlex">
            <div className="col-lg-6">
              <div className="desk-top">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft wrap-font1 mt-5">
                      <span className="headeingcolorblue wrap-shin2">
                        Affiliate Partnerships{" "}
                      </span>{" "}
                      <br></br>
                    </h2>
                    <h3 className="textleft font44">
                      <b>Our partnerships make us better!</b>
                    </h3>
                  </div>
                </div>
              </div>
              {/* Mobile Code */}
              <div className="mob-mobile">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50 uni-affi">
                      <span className="headeingcolorblack">Affiliate </span>{" "}
                      <br></br>
                      <span className="headeingcolorblue"> Partnerships</span>
                    </h2>
                    <h3 className="uni-covid">
                      Our partnerships make us better!
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="imgright">
                <img
                  srcSet={require("../../assets/images/shape/affiliateTop.webp")}
                  alt="affiliate"
                  className="imgwidth wrap2-shi-img1 mobilehide"
                  rel="preload"
                />
                <img
                  srcSet={require("../../assets/images/shape/mob-affi.webp")}
                  alt="affiliate"
                  className="imgwidth wrap2-shi-img1 desktophide"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>
      </section>

      {/* Desktop code  */}
      <section className="desk-afflicate">
        <div className="container-blog">
          <p className="wrap-aff-pag">
            Bridge Health, A caring companion in your health journey. We've
            teamed up with an extensive network of esteemed medical partners,
            including expert doctors, leading pharmaceutical providers,
            cutting-edge labs, state-of-the-art diagnostic facilities, top-tier
            hospitals, and responsive emergency services.  Our commitment to
            your well-being goes beyond words; it's reflected in our
            collaborative approach with these partners, ensuring you receive
            nothing but the best care throughout your health journey.{" "}
          </p>
        </div>
      </section>

      {/* Mobile code  */}
      <section className="mob-afflicate">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <p className="wrap-aff2-pa1">
                Bridge Health, A caring companion in your health journey. We've
                teamed up with an extensive network of esteemed medical
                partners, including expert doctors, leading pharmaceutical
                providers, cutting-edge labs, state-of-the-art diagnostic
                facilities, top-tier hospitals, and responsive emergency
                services.  Our commitment to your well-being goes beyond words;
                it's reflected in our collaborative approach with these
                partners, ensuring you receive nothing but the best care
                throughout your health journey.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Desktop code  */}
      <section className="desk-afflicate">
        <div className="container-blog">
          <h2 className="aff-titlw">Our Team of Doctors</h2>
          <section>
            <div className="row">
              <div className="col-xl-12">
                <Slider
                  ref={sliderRef}
                  {...settings}
                  className="theme_carousel"
                >
                  {data.map(
                    (item, index) =>
                      index % 2 === 0 && (
                        <div className="row" key={item.id}>
                          <DoctorDataCopy
                            imageSrc={item.imageSrc}
                            name={item.name}
                            description={item.description}
                            experience={item.experience}
                            location={item.location}
                          />
                          {data[index + 1] && (
                            <DoctorDataCopy
                              imageSrc={data[index + 1].imageSrc}
                              name={data[index + 1].name}
                              description={data[index + 1].description}
                              experience={data[index + 1].experience}
                              location={data[index + 1].location}
                            />
                          )}
                        </div>
                      )
                  )}
                </Slider>
              </div>
            </div>
          </section>
        </div>
        <div className="aff-slider-buttons">
          <div className="aff-space"></div>
          <div className="btn-box btn-aff card-aff-btn">
            <ContactForm
              buttonText="BOOK AN APPOINTMENT"
              popupPosition="right"
              className="btn-style-one"
            />
          </div>
          <div>
            <button className="affiliate-prev" onClick={previousSlide}></button>
            <button className="affiliate-next" onClick={nextSlide}></button>
          </div>
        </div>
      </section>

      {/* Mobile code  */}
      <section className="mob-afflicate">
        <div className="container">
          <h2 className="aff-titlw-wrap">Our Team of Doctors</h2>

          <div className="row">
            {data
              .slice(0, showMore ? data.length : initialCardsToShow)
              .map((doctor) => (
                <div className="col-sm-6" key={doctor.id}>
                  <div>
                    <DoctorDataCopy
                      imageSrc={doctor.imageSrc}
                      name={doctor.name}
                      description={doctor.description}
                      experience={doctor.experience}
                      location={doctor.location}
                    />
                  </div>
                </div>
              ))}
          </div>

          {data.length > initialCardsToShow && (
            <div className="btn-box text-center">
              <button className="commonBtnforAll" onClick={toggleShowMore}>
                {showMore ? "SEE LESS" : "SEE MORE"}
              </button>
            </div>
          )}
        </div>
      </section>
      {/* Desktop code  */}
      <section className="desk-affiliate">
        <h2 className="aff-partners">Bridgehealth Partners</h2>
        <div className="container-fluid">
          <div>
            <div className="row affliatePagebackground align-items-center">
              <div className="col-lg-3 hospital-scroll">
                {hospitalNames.map((name, index) => (
                  <div key={index} className="hospital-name-container">
                    <span className="round-badge">{name.charAt(0)}</span>
                    <span className="hospital-name">{name}</span>
                  </div>
                ))}
              </div>
              <div className="col-lg-9">
                <img
                  srcSet={require("../../assets/images/shape/Map Desktop.webp")}
                  alt="affiliate"
                  className="widthimg imgFloatRight borderRadius"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Desktop code  */}
      <section section className="desk-afflicate aff-bottom">
        <div className="container-blog">
          <div className="row">
            <div className="col-lg-2">
              <div className="aff-partner-image">
                <img
                  srcSet={require("./../../assets/images/background/anandaff.webp")}
                  alt="aff"
                  className="wrap-img-partners"
                  rel="preload"
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="aff-partner-image">
                <img
                  srcSet={require("./../../assets/images/background/Fortis-Healthcare1.webp")}
                  alt="aff"
                  className="wrap-img-partners"
                  rel="preload"
                />
              </div>
            </div>

            <div className="col-lg-2">
              <div className="aff-partner-image">
                <img
                  srcSet={require("./../../assets/images/background/Kauvery_Hospital_logo1.webp")}
                  alt="aff"
                  className="wrap-img-partners1"
                  rel="preload"
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="aff-partner-image">
                <img
                  srcSet={require("./../../assets/images/background/Fortis-Healthcare2.webp")}
                  alt="aff"
                  className="wrap-img-partners"
                  rel="preload"
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="aff-partner-image">
                <img
                  srcSet={require("./../../assets/images/background/primaaff.webp")}
                  alt="aff"
                  className="wrap-img-partners"
                  rel="preload"
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="aff-partner-image">
                <img
                  srcSet={require("./../../assets/images/background/neubergaff.webp")}
                  alt="aff"
                  className="wrap-img-partners"
                  rel="preload"
                />
              </div>
            </div>
          </div>
          <div className="btn-box btn-aff">
            <ContactForm
              buttonText="BOOK AN APPOINTMENT"
              popupPosition="right"
              className="btn-style-one"
            />
          </div>
        </div>
      </section>

      {/* Mobile code  */}
      <section section className="mob-afflicate mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-3">
              <div className="aff-partner-image">
                <img
                  srcSet={require("./../../assets/images/shape/mob-affi9.webp")}
                  alt="aff"
                  className="wrap-img-aff"
                  rel="preload"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="aff-partner-image">
                <img
                  srcSet={require("./../../assets/images/shape/mob-affi10.webp")}
                  alt="aff"
                  className="wrap-img-aff"
                  rel="preload"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="aff-partner-image">
                <img
                  srcSet={require("./../../assets/images/shape/mob-affi11.webp")}
                  alt="aff"
                  className="wrap-img-aff"
                  rel="preload"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="aff-partner-image">
                <img
                  srcSet={require("./../../assets/images/shape/mob-affi12.webp")}
                  alt="aff"
                  className="wrap-img-aff"
                  rel="preload"
                />
              </div>
            </div>
          </div>
          <div className="btn-box btn-aff mt-5">
            <ContactForm
              buttonText="BOOK AN APPOINTMENT"
              popupPosition="right"
              className="btn-style-one"
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Affiliate;
