import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Helmet } from "react-helmet";
import LeadershipTeam from "../../components/LeadershipTeam ";
class Leadership extends Component {
  componentDidMount() {
    document.title = "Leadership | Bridge Health";
  }

  constructor(props) {
    super(props);
    this.state = {
      showFullContent1: true,
      showFullContent2: true,
      showFullContent3: true,
    };
  }

  toggleContent1 = () => {
    this.setState((prevState) => ({
      showFullContent1: !prevState.showFullContent1,
    }));
  };

  toggleContent2 = () => {
    this.setState((prevState) => ({
      showFullContent2: !prevState.showFullContent2,
    }));
  };
  toggleContent3 = () => {
    this.setState((prevState) => ({
      showFullContent3: !prevState.showFullContent3,
    }));
  };

  render() {
    const { showFullContent1, showFullContent2, showFullContent3 } = this.state;

    return (
      <>
        <Header />
        <Helmet>
          <title>Board of Directors: Inspiring Leaders at Bridge Health</title>
          <meta
            name="description"
            content="Meet the accomplished & experienced members of Bridge health's Board of Directors who provide strategic guidance and leadership to drive the success of our organization.          "
          />
        </Helmet>

        {/*
    <!-- Mobile --> */}

        <div className="d-none">
          <section className="about-section-two marginTop0">
            <div className="container">
              <div className="auto-container">
                <div className="sec-title text-center mt-5">
                  <h2>
                    {" "}
                    <span className="headeingcolorblack"> BOARD OF</span>{" "}
                    <span className="headeingcolorblue">DIRECTORS</span>
                  </h2>
                </div>
              </div>
              <div className="container-fulid">
                <div className="auto-container">
                  <div className="row align-items-center">
                    <div className="col-lg-2">
                      <div className="displayflex ">
                        <span>
                          <img
                            srcset={require("../../assets/images/shape/team-mob1.webp")}
                            alt=""
                            className="zoom"
                            rel="preload"
                          />
                        </span>

                        <span className="teamtext">
                          <b>Kris Gopalakrishnan</b> <br></br> CHAIRMAN &
                          CO-FOUNDER
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-10">
                      <div className="author-info">
                        <div className="wrapper-box text">
                          <div className="text">
                            <i>
                              Senapathy “Kris” Gopalakrishnan served as the Vice
                              Chairman of Infosys from 2011 to 2014 and the Chief
                              Executive Officer and Managing Director of Infosys
                              from 2007 to 2011. Kris is one of the co-founders of
                              Infosys. He also was selected to Thinkers 50, an
                              elite list of global business thinkers, in 2009.
                            </i>
                            {showFullContent1 ? (
                              <h6 className="leader-btn">
                                <i onClick={this.toggleContent1}>SHOW MORE</i>
                              </h6>
                            ) : (
                              <div className="text">
                                <i>
                                  He was elected president of India's apex
                                  industry chamber Confederation of Indian
                                  Industry (CII) for 2013-14, and served as one of
                                  the co-chairs of the World Economic Forum in
                                  Davos in January 2014. He serves on the Board of
                                  Governors of Okinawa Institute for Science and
                                  Technology (OIST) and is the Chairman, Board of
                                  Governors of IIIT, Bangalore. He is the Chairman
                                  of the Vision Group on Information Technology of
                                  Karnataka Government and the Chairman of CII
                                  Start up Council. In January 2011, the
                                  Government of India awarded Kris the Padma
                                  Bhushan, the country’s third-highest civilian
                                  honor. Kris holds Master’s degrees in physics
                                  and computer science from the Indian Institute
                                  of Technology, Madras. Kris is a Fellow of
                                  Indian National Academy of Engineers (INAE) and
                                  an Honorary Fellow of Institution of Electronics
                                  and Telecommunication Engineers (IETE) of India.
                                </i>
                                <h6 className="leader-btn">
                                  <i onClick={this.toggleContent1}>SHOW LESS</i>
                                </h6>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fulid">
                <div className="auto-container">
                  <div className="row align-items-center">
                    <div className="col-lg-2">
                      <div className="displayflex ">
                        <span>
                          <img
                            srcset={require("../../assets/images/shape/kc_ganesh.png")}
                            alt=""
                            className="zoom"
                            rel="preload"
                          />
                        </span>

                        <span className="teamtext">
                          <b>KC Ganesh</b> <br></br> Director
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-10">
                      <div className="author-info">
                        <div className="wrapper-box text">
                          <div className="text">
                            <i>
                              KC leads Prayog Advisors, where he manages the investment portfolio, real estate holdings, and philanthropic initiatives of the Gopalakrishnan family. With a career spanning over 25 years, KC brings extensive experience in corporate strategy, finance, and consulting. He has held significant roles in several prestigious organizations, including Cognizant, Infosys, Reliance, and KPMG.
                            </i>
                            {showFullContent1 ? (
                              <h6 className="leader-btn">
                                <i onClick={this.toggleContent1}>SHOW MORE</i>
                              </h6>
                            ) : (
                              <div className="text">
                                <i>
                                  Throughout his career, KC has been instrumental in driving growth and innovation, leveraging his expertise to develop and implement strategic initiatives that have yielded substantial results. His comprehensive understanding of the financial landscape and adeptness in navigating complex business environments have made him a trusted advisor and leader in his field.
                                </i>
                                <h6 className="leader-btn">
                                  <i onClick={this.toggleContent1}>SHOW LESS</i>
                                </h6>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fulid">
                <div className="auto-container">
                  <div className="row align-items-center">
                    <div className="col-lg-2">
                      <div className="displayflex ">
                        <span>
                          <img
                            srcset={require("../../assets/images/shape/sheeba_D'Mello.png")}
                            alt=""
                            className="zoom"
                            rel="preload"
                          />
                        </span>

                        <span className="teamtext">
                          <b>Sheeba D'Mello</b> <br></br> Director
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-10">
                      <div className="author-info">
                        <div className="wrapper-box text">
                          <div className="text">
                            <i>
                              Sheeba leads investments in the unlisted space at the Pratithi Family Office, where she plays a pivotal role in identifying and nurturing high-potential opportunities. With over 20 years of experience in financial services, Sheeba has spent the last decade deeply immersed in the Indian venture capital and private equity industry. Her extensive background spans a broad spectrum of investment activities, from accelerators and seed-stage funding to growth-stage investments and LP investing in funds.
                            </i>
                            {showFullContent1 ? (
                              <h6 className="leader-btn">
                                <i onClick={this.toggleContent1}>SHOW MORE</i>
                              </h6>
                            ) : (
                              <div className="text">
                                <i>
                                  Prior to her venture investing career, Sheeba worked with global leaders in credit research, retirement funds, and financial research. During this time, she was instrumental in establishing new functions and setting best practices, showcasing her ability to drive innovation and efficiency within organizations. Sheeba's passion lies in supporting visionary entrepreneurs who are dedicated to solving significant challenges.
                                </i>
                                <h6 className="leader-btn">
                                  <i onClick={this.toggleContent1}>SHOW LESS</i>
                                </h6>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {/* <div className="mt-5 text-center">
              <FacebookShareButton
                url="https://bridgehealth.in"
                hashtag="#code"
                quote="please share this post"
              >
                <FacebookIcon></FacebookIcon>
              </FacebookShareButton>
            </div> */}

            {/* <div className="container-fulid">
              <div className="auto-container marginTop50">
                <div className="row align-items-center">
                  <div className="col-lg-2">
                    <div className="displayflex marginleftteam">
                      <span className="teamtext">
                        <b>Swami D Swaminathan</b> <br></br> Director &
                        Co-Founder
                      </span>
                      <span>
                        {" "}
                        <img
                          srcset={require("../../assets/images/shape/directo.webp")}
                          alt=""
                          rel="preload"
                          className="zoom"
                          style={{ borderRadius: "50%" }}
                        />{" "}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="author-info">
                      <div className="wrapper-box text">
                        <div className="text1">
                          <i>
                            Swaminathan Dandapani in his earlier role as
                            Executive Chairman of Manipal Health Enterprises,
                            provided direction and leadership to the Healthcare
                            business expanding the footprint of Manipal
                            Hospitals in new geographies both within & outside
                            India.
                          </i>
                          {showFullContent2 ? (
                            <h6 className="leader-btn">
                              <i onClick={this.toggleContent2}>SHOW MORE</i>
                            </h6>
                          ) : (
                            <div className="text1">
                              <i>
                                He also was the Chairman of Manipal Integrated
                                Services the integrated facility management
                                company in the Manipal group, Swami was also the
                                initiative lead for the “Bridging the Health
                                DivideLeveraging Digital India” sponsored by
                                NASSCOM ( Association of Software & Services
                                Companies in India) and NATHEALTH ( Association
                                of Healthcare Services & Products providers in
                                India) . Prior to joining Manipal Group, Swami
                                was the Managing Director & Chief Executive
                                Officer of Infosys BPO (a fully owned subsidiary
                                of Infosys Ltd). Swami led the firm building a
                                25000 + team worldwide, of over 65+ different
                                nationalities in their workforce, with global
                                operations in 6 centers in India and over 12
                                centers outside India in Americas, Europe,
                                China, LATAM and APAC. Swami, a Chartered
                                Accountant has over 40 years of experience in
                                General Management, Finance & Accounting, Sales
                                & Marketing, International Trading and Human
                                Resources Management. He has performed multiple
                                roles in Sales, Marketing and Operations,
                                leading global teams in the Automobiles,
                                Consumer Durables, Technology, Manufacturing and
                                Services industries. He has also been associated
                                with infrastructure projects in India and
                                overseas. Swami has successfully concluded &
                                executed several M&A projects across US, Europe,
                                APAC, Middle East & India in multiple business
                                verticals.
                              </i>
                              <h6 className="leader-btn">
                                <i onClick={this.toggleContent2}>SHOW LESS</i>
                              </h6>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </section>

          {/*
        <!-- Gallery Section --> */}
          {/* <section className="gallery-section teamleader">
            <div className="auto-container">
              <div className="auto-container">
                <div className="sec-title textalign martextleft40">
                  <h2>
                    {" "}
                    <span className="headeingcolorblue"> LEADERSHIP</span>
                    <span className="headeingcolorblack marginleftspan">
                      {" "}
                      TEAM
                    </span>
                  </h2>
                </div>
              </div>
              <div className="displayflex">
                <div className="teamflex team-block-one lead1">
                  <div className="column">
                    <div className="leadership">
                      <img
                        srcset={require("../../assets/images/gallery/karan.jpg")}
                        alt=""
                        className="avtar"
                        rel="preload"
                      />
                      <div className="overlay">
                        <div className="texttitle">Karan Verma</div>
                        <div className="designation1">CEO</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="teamflex team-block-one lead1">
                  <div className="column">
                    <div className="leadership">
                      <img
                        srcset={require("../../assets/images/gallery/bipul.jpg")}
                        alt=""
                        className="avtar"
                        rel="preload"
                      />
                      <div className="overlay">
                        <div className="texttitle">Bipul Jha</div>
                        <div className="designation1">Head of Operations</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="teamflex team-block-one lead2">
                  <div className="column">
                    <div className="leadership">
                      <img
                        srcset={require("../../assets/images/gallery/dhanya.JPG")}
                        alt=""
                        className="avtar"
                        rel="preload"
                      />
                      <div className="overlay">
                        <div className="texttitle">
                          Dr. Dhanya R
                        </div>
                        <div className="designation1">
                          Clinical Services
                        </div>
                        <div className="linkdinicon"></div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="displayflex marginteam"></div>
              <div className="displayflex ">
                <div className="teamflex team-block-one lead1">
                  <div className="column">
                    <div className="leadership">
                      <img
                        srcset={require("../../assets/images/gallery/jahan_Copy.jpg")}
                        alt=""
                        className="avtar"
                        rel="preload"
                      />
                      <div className="overlay">
                        <div className="texttitle">Jahan Aara</div>
                        <div className="designation1">
                          CFO
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="teamflex team-block-one lead1">
                  <div className="column">
                    <div className="leadership">
                      <img
                        srcset={require("../../assets/images/gallery/sunil.jpg")}
                        alt=""
                        className="avtar"
                        rel="preload"
                      />
                      <div className="overlay">
                        <div className="texttitle">Dr. Sunil Bura</div>
                        <div className="designation1">
                          Partnerships & Corp. Ops.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="teamflex team-block-one lead1">
                  <div className="column">
                    <div className="leadership">
                      <img
                        srcset={require("../../assets/images/gallery/tanvi.JPG")}
                        alt=""
                        className="avtar"
                        rel="preload"
                      />
                      <div className="overlay">
                        <div className="texttitle">Tanvi K</div>
                        <div className="designation1">
                          Product & Marketing
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="teamflex team-block-one lead2">
                  <div className="column">
                    <div className="leadership div-heading">
                      <img
                        srcset={require("../../assets/images/gallery/jayeeta.JPG")}
                        alt=""
                        className="avtar"
                        rel="preload"
                      />
                      <div className="overlay">
                        <div className="texttitle">Jayeeta Swar</div>
                        <div className="designation1">
                          IT/Technology
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="teamflex team-block-one lead2">
                  <div className="column">
                    <div className="leadership div-heading">
                      <img
                        srcset={require("../../assets/images/gallery/ravi.JPG")}
                        alt=""
                        className="avtar"
                        rel="preload"
                      />
                      <div className="overlay">
                        <div className="texttitle">Ravi Shankar</div>
                        <div className="designation1">Elder Care</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="teamflex team-block-one lead2">
                  <div className="column">
                    <div className="leadership div-heading">
                      <img
                        srcset={require("../../assets/images/gallery/sunu.JPG")}
                        alt=""
                        className="avtar"
                        rel="preload"
                      />
                      <div className="overlay">
                        <div className="texttitle">Sunu Matthew</div>
                        <div className="designation1">Human Resources</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </div>
        {/*
    <!-- desktop --> */}
        <section className="about-section-two abt-ar-s2-a2 marginTop0">
          <div className="auto-container d-flex justify-content-around mt-5">
            <div className="sec-title text-center">
              <h2>
                {" "}
                <span className="headeingcolorblack"> BOARD OF</span>{" "}
                <span className="headeingcolorblue">DIRECTORS</span>
              </h2>
            </div>
            {/* <div className=" text-center">
                <FacebookShareButton
                  url="https://bridgehealth.in"
                  hashtag="#code"
                  quote="please share this post"
                >
                  <FacebookIcon></FacebookIcon>
                </FacebookShareButton>
              </div> */}
          </div>
          <div className="container-fulid" style={{ backgroundSize: "16%" }}>
            <div className="auto-container">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <div className="image-wrapper ">
                    <div className="image-one">
                      <img
                        srcset={require("../../assets/images/shape/director.webp")}
                        alt=""
                        className="zoom-mobile-leadership-chirman"
                        rel="preload"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="author-info">
                    <div className="wrapper-box text">
                      <h2 className="leader-heading">Kris Gopalakrishnan</h2>
                      <div className="designation leader-heading">
                        Chairman & Co-Founder
                      </div>
                      <hr></hr>
                      <div className="text">
                        <i>
                          Senapathy “Kris” Gopalakrishnan served as the Vice
                          Chairman of Infosys from 2011 to 2014 and the Chief
                          Executive Officer and Managing Director of Infosys
                          from 2007 to 2011. Kris is one of the co-founders of
                          Infosys. He also was selected to Thinkers 50, an
                          elite list of global business thinkers, in 2009.
                        </i>
                        {showFullContent1 ? (
                          <h6 className="leader-btn">
                            <i onClick={this.toggleContent1}>SHOW MORE</i>
                          </h6>
                        ) : (
                          <div className="text">
                            <i>
                              He was elected president of India's apex
                              industry chamber Confederation of Indian
                              Industry (CII) for 2013-14, and served as one of
                              the co-chairs of the World Economic Forum in
                              Davos in January 2014. He serves on the Board of
                              Governors of Okinawa Institute for Science and
                              Technology (OIST) and is the Chairman, Board of
                              Governors of IIIT, Bangalore. He is the Chairman
                              of the Vision Group on Information Technology of
                              Karnataka Government and the Chairman of CII
                              Start up Council. In January 2011, the
                              Government of India awarded Kris the Padma
                              Bhushan, the country’s third-highest civilian
                              honor. Kris holds Master’s degrees in physics
                              and computer science from the Indian Institute
                              of Technology, Madras. Kris is a Fellow of
                              Indian National Academy of Engineers (INAE) and
                              an Honorary Fellow of Institution of Electronics
                              and Telecommunication Engineers (IETE) of India.
                            </i>
                            <h6 className="leader-btn">
                              <i onClick={this.toggleContent1}>SHOW LESS</i>
                            </h6>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fulid" style={{ backgroundSize: "16%" }}>
            <div className="auto-container">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <div className="image-wrapper ">
                    <div className="image-one">
                      <img
                        srcset={require("../../assets/images/shape/kc_ganesh.png")}
                        alt=""
                        className="zoom-mobile-leadership-chirman"
                        rel="preload"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="author-info">
                    <div className="wrapper-box text">
                      <h2 className="leader-heading">KC Ganesh</h2>
                      <div className="designation leader-heading">
                        Director
                      </div>
                      <hr></hr>
                      <div className="text">
                        <i>
                          KC leads Prayog Advisors, where he manages the investment portfolio, real estate holdings, and philanthropic initiatives of the Gopalakrishnan family. With a career spanning over 25 years, KC brings extensive experience in corporate strategy, finance, and consulting. He has held significant roles in several prestigious organizations, including Cognizant, Infosys, Reliance, and KPMG.
                        </i>
                        {showFullContent2 ? (
                          <h6 className="leader-btn">
                            <i onClick={this.toggleContent2}>SHOW MORE</i>
                          </h6>
                        ) : (
                          <div className="text">
                            <i>
                              Throughout his career, KC has been instrumental in driving growth and innovation, leveraging his expertise to develop and implement strategic initiatives that have yielded substantial results. His comprehensive understanding of the financial landscape and adeptness in navigating complex business environments have made him a trusted advisor and leader in his field.
                            </i>
                            <h6 className="leader-btn">
                              <i onClick={this.toggleContent2}>SHOW LESS</i>
                            </h6>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fulid" style={{ backgroundSize: "16%" }}>
            <div className="auto-container">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <div className="image-wrapper ">
                    <div className="image-one">
                      <img
                        srcset={require("../../assets/images/shape/sheeba_D'Mello.png")}
                        alt=""
                        className="zoom-mobile-leadership-chirman"
                        rel="preload"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="author-info">
                    <div className="wrapper-box text">
                      <h2 className="leader-heading">Sheeba D'Mello</h2>
                      <div className="designation leader-heading">
                        Director
                      </div>
                      <hr></hr>
                      <div className="text">
                        <i>
                          Sheeba leads investments in the unlisted space at the Pratithi Family Office, where she plays a pivotal role in identifying and nurturing high-potential opportunities. With over 20 years of experience in financial services, Sheeba has spent the last decade deeply immersed in the Indian venture capital and private equity industry. Her extensive background spans a broad spectrum of investment activities, from accelerators and seed-stage funding to growth-stage investments and LP investing in funds.
                        </i>
                        {showFullContent3 ? (
                          <h6 className="leader-btn">
                            <i onClick={this.toggleContent3}>SHOW MORE</i>
                          </h6>
                        ) : (
                          <div className="text">
                            <i>
                              Prior to her venture investing career, Sheeba worked with global leaders in credit research, retirement funds, and financial research. During this time, she was instrumental in establishing new functions and setting best practices, showcasing her ability to drive innovation and efficiency within organizations. Sheeba's passion lies in supporting visionary entrepreneurs who are dedicated to solving significant challenges.
                            </i>
                            <h6 className="leader-btn">
                              <i onClick={this.toggleContent3}>SHOW LESS</i>
                            </h6>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="container-fulid" style={{ backgroundSize: "16%" }}>
              <div className="auto-container marginTop50">
                <div className="row align-items-center">
                  <div className="col-lg-9">
                    <div className="author-info">
                      <div className="wrapper-box text">
                        <h2 className="leader-heading1">Swami D Swaminathan</h2>
                        <div className="designation leader-heading2">
                          Director & Co-Founder
                        </div>
                        <hr></hr>
                        <div className="text1">
                          <i>
                            Swaminathan Dandapani in his earlier role as
                            Executive Chairman of Manipal Health Enterprises,
                            provided direction and leadership to the Healthcare
                            business expanding the footprint of Manipal
                            Hospitals in new geographies both within & outside
                            India.
                          </i>
                          {showFullContent2 ? (
                            <h6 className="leader-btn">
                              <i onClick={this.toggleContent2}>SHOW MORE</i>
                            </h6>
                          ) : (
                            <div className="text1">
                              <i>
                                He also was the Chairman of Manipal Integrated
                                Services the integrated facility management
                                company in the Manipal group, Swami was also the
                                initiative lead for the “Bridging the Health
                                DivideLeveraging Digital India” sponsored by
                                NASSCOM ( Association of Software & Services
                                Companies in India) and NATHEALTH ( Association
                                of Healthcare Services & Products providers in
                                India) . Prior to joining Manipal Group, Swami
                                was the Managing Director & Chief Executive
                                Officer of Infosys BPO (a fully owned subsidiary
                                of Infosys Ltd). Swami led the firm building a
                                25000 + team worldwide, of over 65+ different
                                nationalities in their workforce, with global
                                operations in 6 centers in India and over 12
                                centers outside India in Americas, Europe,
                                China, LATAM and APAC. Swami, a Chartered
                                Accountant has over 40 years of experience in
                                General Management, Finance & Accounting, Sales
                                & Marketing, International Trading and Human
                                Resources Management. He has performed multiple
                                roles in Sales, Marketing and Operations,
                                leading global teams in the Automobiles,
                                Consumer Durables, Technology, Manufacturing and
                                Services industries. He has also been associated
                                with infrastructure projects in India and
                                overseas. Swami has successfully concluded &
                                executed several M&A projects across US, Europe,
                                APAC, Middle East & India in multiple business
                                verticals.
                              </i>
                              <h6 className="leader-btn">
                                <i onClick={this.toggleContent2}>SHOW LESS</i>
                              </h6>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="image-wrapper ">
                      <div className="image-one">
                        <img
                          srcset={require("../../assets/images/shape/directo.webp")}
                          alt=""
                          className="zoom-mobile-leadership-direc"
                          rel="preload"
                          style={{ borderRadius: "50%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        </section>

        {/*
        <!-- Gallery Section --> */}
        <section className="gallery-section teamleader">
          <div className="auto-container">
            <div className="auto-container">
              <div className="d-none d-md-block sec-title textalign martextleft40 ldr-hd-2">
                <h2>
                  LEADERSHIP
                  <br></br>
                  <span className="" style={{ color: 'black' }}>
                    TEAM
                  </span>
                </h2>
              </div>
              <div className="sec-title textalign martextleft40 ldr-hd-2 d-md-none mob-ldr-hdr-5">
                <h2>
                  LEADERSHIP
                  <span className="" style={{ color: 'black', marginLeft: '15px' }}>
                    TEAM
                  </span>
                </h2>
              </div>
            </div>
            <div className="team-grid-desk-d1">
              <div className="ldr-sx-w1">
              <div className="member-card">
                  <img
                    srcset={require("../../assets/images/gallery/bipul.jpg")}
                    alt=""
                    className="member-photo"
                    rel="preload"
                  />
                  <div className="ldr-overlay">
                    <h3 className="">Bipul Jha</h3>
                    <h4 className="">Operations</h4>
                  </div>
                </div>
                <div className="member-card">
                  <img
                    srcset={require("../../assets/images/gallery/tanvi.JPG")}
                    alt=""
                    className="member-photo"
                    rel="preload"
                  />
                  <div className="ldr-overlay">
                    <h3 className="">Tanvi K</h3>
                    <h4 className="">Product & Marketing</h4>
                  </div>
                </div>
              
              </div>
              <div className="ldr-sx-w2">
                <div className="member-card">
                  <img
                    srcset={require("../../assets/images/gallery/dhanya.JPG")}
                    alt=""
                    className="member-photo"
                    rel="preload"
                  />
                  <div className="ldr-overlay">
                    <h3 className="">Dr Dhanya R</h3>
                    <h4 className="">Clinical Services</h4>
                  </div>
                </div>
                <div className="member-card">
                  <img
                    srcset={require("../../assets/images/gallery/jahan_Copy.jpg")}
                    alt=""
                    className="member-photo"
                    rel="preload"
                  />
                  <div className="ldr-overlay">
                    <h3 className="">Jahan Aara</h3>
                    <h4 className="">CFO</h4>
                  </div>
                </div>
                <div className="member-card">
                  <img
                    srcset={require("../../assets/images/gallery/sunil.jpg")}
                    alt=""
                    className="member-photo"
                    rel="preload"
                  />
                  <div className="ldr-overlay">
                    <h3 className="">Dr. Sunil Bura</h3>
                    <h4 className="">Partnerships & Corp. Ops.</h4>
                  </div>
                </div>
              </div>
              <div className="ldr-sx-w3">
              <div className="member-card">
                  <img
                    srcset={require("../../assets/images/gallery/karan.jpg")}
                    alt=""
                    className="member-photo"
                    rel="preload"
                  />
                  <div className="ldr-overlay">
                    <h3 className="">Karan Verma</h3>
                    <h4 className="">CEO</h4>
                  </div>
                </div>
           
                <div className="member-card">
                  <img
                    srcset={require("../../assets/images/gallery/jayeeta.JPG")}
                    alt=""
                    className="member-photo"
                    rel="preload"
                  />
                  <div className="ldr-overlay">
                    <h3 className="">Jayeeta Swar</h3>
                    <h4 className="">IT/Technology</h4>
                  </div>
                </div>
                <div className="member-card">
                  <img
                    srcset={require("../../assets/images/gallery/ravi.JPG")}
                    alt=""
                    className="member-photo"
                    rel="preload"
                  />
                  <div className="ldr-overlay">
                    <h3 className="">Ravi Shankar</h3>
                    <h4 className="">Elder Care</h4>
                  </div>
                </div>
              </div>
              <div className="ldr-sx-w4">
                <div className="member-card">
                  <img
                    srcset={require("../../assets/images/gallery/sunu.JPG")}
                    alt=""
                    className="member-photo"
                    rel="preload"
                  />
                  <div className="ldr-overlay">
                    <h3 className="">Sunu Mathew</h3>
                    <h4 className="">Human Resource</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="team-grid-mob-m1 mob-ldr-tmp">
              <div className="member-card">
                <img
                  srcset={require("../../assets/images/gallery/karan.jpg")}
                  alt=""
                  className="member-photo"
                  rel="preload"
                />
                <div className="ldr-overlay">
                  <h3 className="">Karan Verma</h3>
                  <h4 className="">CEO</h4>
                </div>
              </div>
              <div className="member-card">
                <img
                  srcset={require("../../assets/images/gallery/bipul.jpg")}
                  alt=""
                  className="member-photo"
                  rel="preload"
                />
                <div className="ldr-overlay">
                  <h3 className="">Bipul Jha</h3>
                  <h4 className="">Operations</h4>
                </div>
              </div>
              <div className="member-card">
                <img
                  srcset={require("../../assets/images/gallery/dhanya.JPG")}
                  alt=""
                  className="member-photo"
                  rel="preload"
                />
                <div className="ldr-overlay">
                  <h3 className="">Dr Dhanya R</h3>
                  <h4 className="">Clinical Services</h4>
                </div>
              </div>
              <div className="member-card">
                <img
                  srcset={require("../../assets/images/gallery/jahan_Copy.jpg")}
                  alt=""
                  className="member-photo"
                  rel="preload"
                />
                <div className="ldr-overlay">
                  <h3 className="">Jahan Aara</h3>
                  <h4 className="">CFO</h4>
                </div>
              </div>
              <div className="member-card">
                <img
                  srcset={require("../../assets/images/gallery/jayeeta.JPG")}
                  alt=""
                  className="member-photo"
                  rel="preload"
                />
                <div className="ldr-overlay">
                  <h3 className="">Jayeeta Swar</h3>
                  <h4 className="">IT/Technology</h4>
                </div>
              </div>
              <div className="member-card">
                <img
                  srcset={require("../../assets/images/gallery/sunu.JPG")}
                  alt=""
                  className="member-photo"
                  rel="preload"
                />
                <div className="ldr-overlay">
                  <h3 className="">Sunu Mathew</h3>
                  <h4 className="">Human Resource</h4>
                </div>
              </div>
              <div className="member-card">
                <img
                  srcset={require("../../assets/images/gallery/sunil.jpg")}
                  alt=""
                  className="member-photo"
                  rel="preload"
                />
                <div className="ldr-overlay">
                  <h3 className="">Dr. Sunil Bura</h3>
                  <h4 className="">Partnerships & Corp. Ops.</h4>
                </div>
              </div>           
              <div className="member-card">
                <img
                  srcset={require("../../assets/images/gallery/ravi.JPG")}
                  alt=""
                  className="member-photo"
                  rel="preload"
                />
                <div className="ldr-overlay">
                  <h3 className="">Ravi Shankar</h3>
                  <h4 className="">Elder Care</h4>
                </div>
              </div>
              <div className="member-card">
                <img
                  srcset={require("../../assets/images/gallery/tanvi.JPG")}
                  alt=""
                  className="member-photo"
                  rel="preload"
                />
                <div className="ldr-overlay">
                  <h3 className="">Tanvi K</h3>
                  <h4 className="">Product & Marketing</h4>
                </div>
              </div>
           
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default Leadership;
