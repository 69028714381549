import React from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import InfluenzaTab from "./influenzaTab";
import InfluenzaTabMob from "./influenzaTabMob";
import TabInfluenza from "./TabInfluenza";

function influenza() {
  return (
    <>
      <Header />
      <Helmet>
        <title>
          Comprehensive Guide to Shingles: Causes, Symptoms, Treatment |
          BridgeHealth
        </title>
        <meta
          name="description"
          content="Vaccination for Shingles at Bridge Health: Herpes Zoster, caused by the varicella-zoster virus. Discover symptoms, Vaccines, treatments, & solutions for effective management."
        />
      </Helmet>
      <section className="team-section padding backimg3">
        {/* About Section Two */}
        <section className="container-fluid">
          <div className="row align-items-center auto-container reverseFlex">
            <div className="col-lg-6">
              <div className="desk-top">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50 wrap-font mt-5">
                      <span className="headeingcolorblue wrap-shin font55">
                        KEEP THE FLU AT BAY!{" "}
                      </span>
                    </h2>
                    <h3 className="textleft wrap-text1 font44">
                      Take the flu vaccine today!
                    </h3>
                  </div>
                  <div className="btn-box">
                    <ContactForm
                      buttonText="CONSULT / BOOK"
                      popupPosition="right"
                      className="btn-style-one"
                    />
                  </div>
                </div>
              </div>

              {/* Mobile Code  */}
              <div className="mob-mobile">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50 mt-5 uni-shingles">
                      <span className="headeingcolorblue">
                        KEEP THE FLU AT BAY!{" "}
                      </span>
                    </h2>
                    <h3 className="uni-covid">Take the flu vaccine today!</h3>
                  </div>
                  <div className="btn-box btn8">
                    <ContactForm
                      buttonText="CONSULT / BOOK"
                      popupPosition="right"
                      className="btn-style-one"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="imgright">
                <img
                  srcset={require("../../assets/images/shape/infulz.webp")}
                  alt=""
                  className="imgwidth wrap2-shi-img1 mobilehide"
                  rel="preload"
                />
                <img
                  srcset={require("../../assets/images/shape/infulzMobile.webp")}
                  alt=""
                  className="imgwidth wrap2-shi-img1 desktophide"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>
      </section>

      {/* WHAT IS influenza */}
      {/* Desktop code  */}
      <section className="covid-third-sec">
        <div className="container-fluid covidbackImg">
          <div className="auto-container">
            <div className="marginTop80">
              <h2 className="header1">
                <span className="headeingcolorblack wrap-header1 wrap-header2">
                  WHAT IS{" "}
                </span>
                <span className="headeingcolorblue wrap-header1 wrap-header2">
                  {" "}
                  Influenza?
                </span>
              </h2>
            </div>
            <div>
              <p className="wrap-covid">
                Influenza, commonly known as the flu, is a contagious
                respiratory illness. It can lead to symptoms like fever, cough,
                sore throat, body aches, and fatigue. The flu primarily spreads
                when an infected person coughs and sneezes, releasing droplets
                which contain the infection in close proximity to a healthy
                individual. It is mostly airborne, so getting vaccinated and
                practicing good hygiene can help prevent its spread. The flu can
                vary in severity and may lead to more serious complications for
                certain high-risk individuals, such as young children, the
                elderly, and those with underlying health conditions.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Mobile code  */}
      <section className="covid-mob-sec">
        <div className="container">
          <div className="marginTop80">
            <h2 className="header1-covid">
              <span className="headeingcolorblack wrapper-mob">What is </span>
              <span className="headeingcolorblue wrapper-mob"> Influenza?</span>
            </h2>
          </div>
          <div>
            <p className="wrap-covid-mob">
              Influenza, commonly known as the flu, is a contagious respiratory
              illness. It can lead to symptoms like fever, cough, sore throat,
              body aches, and fatigue. The flu primarily spreads when an
              infected person coughs and sneezes, releasing droplets which
              contain the infection in close proximity to a healthy individual.
              It is mostly airborne, so getting vaccinated and practicing good
              hygiene can help prevent its spread. The flu can vary in severity
              and may lead to more serious complications for certain high-risk
              individuals, such as young children, the elderly, and those with
              underlying health conditions.
            </p>
          </div>
        </div>
      </section>
      {/* Desktop code  */}
      <section className="inf-desk">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-3">
              <h2 className="header-covid">
                <span className="headeingcolorblue wrap-header-inf">
                  2 Million+
                </span>
              </h2>
              <p className="covid-par-inf">Cases of Influenza annually</p>
            </div>
            <div className="col-lg-3">
              <h2 className="header-covid">
                <span className="headeingcolorblue wrap-header-inf">
                  3-5 Million
                </span>
              </h2>
              <p className="covid-par-inf">
                People worldwide suffer from<br></br> severe flu infections
                annually
              </p>
            </div>
            <div className="col-lg-3">
              <h2 className="header-covid">
                <span className="headeingcolorblue wrap-header-inf">
                  50-70%
                </span>
              </h2>
              <p className="covid-par-inf">
                Increase in recorded <br></br>Influenza cases in 2023
              </p>
            </div>
            <div className="col-lg-3">
              <h2 className="header-covid">
                <span className="headeingcolorblue wrap-header-inf">
                  2,90,000 - 6,50,000
                </span>
              </h2>
              <p className="covid-par-inf">
                Deaths due to respiratory illnesses are<br></br> caused by
                seasonal Influenza annually
              </p>
            </div>
          </div>

          <div className="btn-box text-center">
            <ContactForm
              buttonText="CONSULT / BOOK"
              popupPosition="right"
              className="btn-style-one"
            />
          </div>
        </div>
      </section>
      {/* Mobile code  */}
      <section className="inf-mobile">
        <div className="auto-container">
          <div className="item-mob">
            <div className="row">
              <div className="col-6">
                <h2 className="header-covid marginBtmText">
                  <span className="headeingcolorblue wrap-header-inf-mob">
                    2 Million+
                  </span>
                </h2>
                <p className="covid-par-inf-mob">Cases of Influenza annually</p>
              </div>
              <div className="col-6">
                <h2 className="header-covid marginBtmText">
                  <span className="headeingcolorblue wrap-header-inf-mob">
                    3-5 Million
                  </span>
                </h2>
                <p className="covid-par-inf-mob">
                  People worldwide suffer from severe flu infections annually
                </p>
              </div>
              <div className="col-6 wrap-top">
                <h2 className="header-covid marginBtmText">
                  <span className="headeingcolorblue wrap-header-inf-mob">
                    50-70%
                  </span>
                </h2>
                <p className="covid-par-inf-mob">
                  Increase in recorded Influenza cases in 2023
                </p>
              </div>
              <div className="col-6 wrap-top">
                <h2 className="header-covid marginBtmText">
                  <span className="headeingcolorblue wrap-header-inf-mob">
                    2,90,000 - 6,50,000
                  </span>
                </h2>
                <p className="covid-par-inf-mob">
                  Deaths due to respiratory illnesses are caused by seasonal
                  Influenza annually
                </p>
              </div>
            </div>
          </div>
          <div
            className="btn-box text-center"
            style={{ marginBottom: 55 + "px" }}
          >
            <ContactForm
              buttonText="CONSULT / BOOK"
              popupPosition="right"
              className="btn-style-one"
            />
          </div>
        </div>
      </section>

      {/* SYMPTOMS OF influenza */}
      {/* Desktop code  */}
      <section className="des-covid">
        <div className="auto-container">
          <div className="top-covid">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/infulzdesk.webp")}
                  alt=""
                  className="imgwidth"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div>
                  <div className="card wrap-inter-covid">
                    <div className="card-body">
                      <h2 className="header1">
                        <span className="headeingcolorblack wrap-header2-item">
                          SYMPTOMS OF{" "}
                        </span>
                        <span className="headeingcolorblue wrap-header2-item">
                          {" "}
                          INFLUENZA
                        </span>
                      </h2>

                      <p className="card-text-partners1">
                        The symptoms typically include a sudden onset of fever,
                        often accompanied by chills and body aches. If you have
                        the flu, you will experience a persistent and dry cough,
                        along with a sore throat. Fatigue and weakness are
                        common, making simple and ordinary tasks feel exhausting
                        and overwhelming. Additionally, individuals may have
                        nasal congestion, runny nose, and occasional headaches.
                        In some cases, the flu can lead to gastrointestinal
                        symptoms like nausea, vomiting, and diarrhoea, though
                        these are more common in children than in adults.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Mobile code  */}
      <section className="wrapper-mobile">
        <div className="shi-wrap-mobile">
          <figure className="text-overlay-image">
            <img
              srcset={require("../../assets/images/shape/infulzMobile1.webp")}
              alt=""
              className="cov-item"
              rel="preload"
            />
          </figure>
          <div className="card wrap-inter-covid-mob">
            <div className="card-body">
              <h2 className="header18">
                <span className="headeingcolorblack wrap-header2-mob">
                  Symptoms of{" "}
                </span>
                <span className="headeingcolorblue wrap-header2-mob">
                  {" "}
                  Influenza?
                </span>
              </h2>

              <p className="card-text-par">
                The symptoms typically include a sudden onset of fever, often
                accompanied by chills and body aches. If you have the flu, you
                will experience a persistent and dry cough, along with a sore
                throat. Fatigue and weakness are common, making simple and
                ordinary tasks feel exhausting and overwhelming. Additionally,
                individuals may have nasal congestion, runny nose, and
                occasional headaches. In some cases, the flu can lead to
                gastrointestinal symptoms like nausea, vomiting, and diarrhoea,
                though these are more common in children than in adults.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="desk-item backGroundRight mobilehide">
        <div className="container-fulid backimg2">
          <div className="auto-container">
            <InfluenzaTab />
          </div>
        </div>
      </div>

      <div className="desk-item-covid backGroundRight d-lg-none">
        <div className="container-fulid backimg2">
          <div className="auto-container">
            <InfluenzaTabMob />
          </div>
        </div>
      </div>

      {/* MORE ABOUT SHINGLE */}
      <section>
        <div className="more-desktop">
          <div className="wrap-sites item-covid">
            <div className="wrap-img-sec7">
              <img
                srcset={require("../../assets/images/shape/infulzdesk2.webp")}
                alt=""
                className="imgwidth3"
                rel="preload"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Mobile code */}
      <section className="mt-3">
        <div className="more-mobile">
          <div className="wrap-sites">
            <div className="wrap-img-sec7">
              <img
                srcset={require("../../assets/images/shape/infulzMobile3.webp")}
                alt=""
                rel="preload"
                className="imgwidth3"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="backFaqsRight" id="section-3">
        <div className="container-fulid backFaqsLeft">
          <div className="auto-container">
            <TabInfluenza />
          </div>
        </div>
      </div>
      <section style={{ marginBottom: 4 + "rem" }}>
        <div className="auto-container">
          <div className="row">
            {/*<!-- News Block One --> */}
            <div
              className="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div className="inner-box">
                <div className="image">
                  <Link to={"/#"}>
                    {" "}
                    <img
                      srcset={require("../../assets/images/shape/blog.webp")}
                      alt=""
                      rel="preload"
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to={""}>What Does Your GI Score Indicate?</Link>
                  </h3>
                  <div className="text">
                    As a child, you were likely cautioned to limit your intake
                    of sweets. Remember the warning not to go
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- News Block One --> */}
            <div
              className="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div className="inner-box">
                <div className="image">
                  <Link to={"/#"}>
                    {" "}
                    <img
                      srcset={require("../../assets/images/shape/blog1.webp")}
                      alt=""
                      rel="preload"
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to={""}>Myths And Facts About GI Foods</Link>
                  </h3>
                  <div className="text">
                    Carbohydrates have been villainized in recent years as the
                    culprit for weight gain and even chronic diseases
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- News Block One --> */}
            <div
              className="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div className="inner-box">
                <div className="image">
                  <Link to={"/#"}>
                    {" "}
                    <img
                      srcset={require("../../assets/images/shape/blog3.webp")}
                      alt=""
                      rel="preload"
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to={""}>
                      Everything you need to know about Glycemic Index
                    </Link>
                  </h3>
                  <div className="text">
                    Even with the world becoming more health-conscious, diseases
                    seem to be on the rise. While you frantically
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default influenza;
