import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import FormButton from "../element/component/FormButton";

class fitnessAssessment extends Component {
  componentDidMount() {
    document.title = "Fitness Assessment | Bridge Health";
  }
  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Stay Fit With Best Gym in Bangalore |Call now- Bridge Health
          </title>
          <meta
            name="description"
            content="Best gym in Bangalore for workouts with incredible offers on gym memberships. Join Bridge health, one of the top gyms in Bangalore. Contact gym trainer online. "
          />
        </Helmet>
        <section className="team-section padding doctoeback image-mobile-terms">
          <section className="container-fluid">
            <div className="row align-items-center auto-container reverseFlex">
              <div className="col-lg-5">
                <div className="content-box marginTop40header fitnesback">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50">
                      <span className="headeingcolorblack">
                        {" "}
                        It’s time to get
                      </span>{" "}
                      <br></br>
                      <span className="headeingcolorblue fontitalic">
                        {" "}
                        MOVING{" "}
                      </span>
                    </h2>
                    <h3 className="textleft marginTop40">
                      Stay fit and active with our <br></br> pro-fit program
                    </h3>
                  </div>
                  <FormButton/>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="image-wrapper imgright">
                  <img
                    srcset={require("../../assets/images/shape/fitness.webp")}
                    alt=""
                    className="imgwidth"
                    rel="preload"
                  />
                </div>
              </div>
            </div>
          </section>
        </section>
        <section className="contact-section">
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/fitness4.webp")}
                  alt=""
                  className="widthimg"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div className="contact-form-area">
                  <div className="sec-title squrebox">
                    <p className="font22">
                      To stay healthy, regular exercise is a must! Regular
                      exercise and keeping our bodies active reduces blood
                      pressure, improves blood circulation, burns excess fat &
                      calories, and reduces the risk of heart ailments. Taking
                      it a step further, doctors and specialists have developed
                      workout plans based on clinical fact-based evidence with
                      the help of interdisciplinary teams. These programs are
                      proven to have incredible benefits on the overall health
                      of individuals.
                    </p>
                  </div>
                  <FormButton/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="fitnessbox">
          <div className="auto-container ">
            <div className="row align-items-center reverseFlex">
              <div className="col-lg-6">
                <div className="sec-title squrebox">
                  <p className="font22">
                    By combining their expertise, our team at Bridge Health
                    takes into account various aspects of your health and
                    well-being. Doctors offer medical insights, nutritionists
                    provide guidance on proper dietary choices, and exercise
                    specialists design tailored workout programs. This
                    collaborative approach ensures that all facets of your
                    fitness journey are addressed, leading to optimal results.
                  </p>
                </div>
                <FormButton/>
              </div>

              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/fit.webp")}
                  alt=""
                  className="width64"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="team-section">
          <div className="auto-container">
            <div className="row align-items-center reverseFlex">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/fitness2.webp")}
                  alt=""
                  className="widthimg"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <h2>
                  <span className="headeingcolorblack"> What We </span>
                  <span className="headeingcolorblue"> Offer</span>
                </h2>

                <ul className="listicon">
                  <li>Cardiorespiratory Training</li>
                  <li>Strength and Conditioning</li>
                  <li>Functional Training</li>
                  <li>Prehabilitation and Rehabilitation services</li>
                  <li>Customized program for health issues</li>

                  <li>Posture and functional corrective exercises</li>
                  <li>Vitality exercises</li>
                  <li>Ability Improvement exercises</li>

                  <li>Group Classes</li>
                  <li>Corporate Fitness Programs</li>
                  <li>Online Fitness Programs</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-section">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <h2 className="header1">
                  <span className="headeingcolorblack"> The</span>
                  <span className="headeingcolorblue"> Bridge Health</span>
                  <span className="headeingcolorblack"> Advantage </span>
                </h2>
                <ul className="listicon">
                  <li>Covid compliant atmosphere</li>

                  <li>Free trial session</li>

                  <li>Pay in easy installments</li>
                  <li>Flexible hours of workouts in a day</li>
                  <li>Medical providers & licensed therapist support</li>
                  <li>Supervision by an American Council-certified trainer</li>
                </ul>
              </div>
              <div className="col-lg-5">
                <img
                  srcset={require("../../assets/images/shape/Workout.webp")}
                  alt=""
                  className="widthimg imgFloatRight"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>{" "}
        <section>
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2 className="header1">
                <span className="headeingcolorblack">
                  {" "}
                  Get moving & Stay Active to{" "}
                </span>
                <span className="headeingcolorblue"> Stay Healthy! </span>
              </h2>
            </div>
            <div className="d-flex justify-content-center pb-4">
                <FormButton ButtonName={'CONSULT / BOOK'} />
              </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default fitnessAssessment;
