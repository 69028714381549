import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Helmet } from "react-helmet";

const aboutbg = require("./../../assets/images/background/image-11.jpg");

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogs: [],
            searchQuery: "",
            searchResults: [],
            categories: [],
            visibleBlogs: 3, // Initial number of blogs to show
            loadMoreClicked: false
        };
    }

    componentDidMount() {
        document.title = "Blog | Bridge Health";
        this.fetchBlogData();
    }

    fetchBlogData = () => {
        const siteUrl = `https://admin.bridgehealth.in`;
        fetch(`${siteUrl}/api/blog-listings?pagination[start]=0&pagination[limit]=999999&sort=id:desc&populate=*`)
            .then((response) => response.json())
            .then((data) => {
                const blogs = data.data.map((item) => ({
                    id: item?.id,
                    blog_slug: item?.attributes?.Slug,
                    blog_heading: item?.attributes?.blog_reading,
                    blog_title: item?.attributes?.blog_title,
                    blog_paragraph: item?.attributes?.blog_paragraph,
                    image: item?.attributes?.image?.data?.attributes?.url,
                    blog_categories: item?.attributes?.category?.data?.attributes?.Slug,
                }));
                this.setState({ blogs });
                // console.log(blogs)
            })
            .catch((error) => console.error("Error fetching data:", error));

        fetch(`${siteUrl}/api/categories`)
            .then((response) => response.json())
            .then((data) => {
                const categories = data.data.map((item) => ({
                    Name: item.attributes.Title,
                    Slug: item.attributes.Slug,
                }));
                this.setState({ categories });
            })
            .catch((error) => console.error("Error fetching data:", error));
    };

    handleSearchInputChange = (event) => {
        const searchQuery = event.target.value;
        this.setState({ searchQuery }, () => this.handleSearch());
    };

    handleSearch = () => {
        const { searchQuery, blogs } = this.state;
        let filteredItems;
        if (searchQuery) {
            filteredItems = blogs.filter((item) =>
                item.blog_title.toLowerCase().includes(searchQuery.toLowerCase())
            );
            this.setState({ searchResults: filteredItems });
        } else {
            this.setState({ searchResults: '' });
        }
    };

    shouldApplyMarginTop = (index) => {
        const patternIndices = [3, 5, 6, 8, 9, 11, 12, 14, 15, 17, 18];
        return patternIndices.includes(index);
    };


    handleClearSearch = () => {
        this.setState({ searchQuery: "", searchResults: "" });
    };

    loadMoreFn = () => {
        this.setState(prevState => ({
            visibleBlogs: -1, // Increase the number of visible blogs by 3
            loadMoreClicked: true
        }));
    };

    render() {
        const siteUrl = `https://admin.bridgehealth.in`;
        const { searchResults, blogs, searchQuery, categories, visibleBlogs, loadMoreClicked } = this.state;

        return (
            <>
                <Header />
                <Helmet>
                    <title>
                        Latest Health Blogs on preventive care, Corporate wellness | Bridge
                        Health
                    </title>
                    <meta
                        name="Blog"
                        content="Explore our informative blog for the latest articles & insights on health, wellness, & medical advancements. Stay informed & empowered with Bridge Health's blog."
                    />
                </Helmet>
                {/* Page Banner Section */}
                <section className="page-bann" style={{ backgroundImage: `url(${aboutbg})` }}>
                    <h1 className="colorwhite outline">BLOGS</h1>
                </section>

                {/* News Section */}
                <section className="blogs-n1">
                    <img className="blog-crcl crcl-1" src="/blog/crcl_01.png" alt="crcle_1" />
                    <img className="blog-crcl crcl-2" src="/blog/crcl_02.png" alt="crcle_2" />
                    <div>
                        <div className="container blogs-container-n1">
                            <div className="row search-bar-wrap">
                                <div className="col-lg-8 mx-auto">
                                    <div className="position-relative">
                                        {/* Search */}
                                        <div className="search-box-n1">
                                            <form className="">
                                                <div className="d-flex">
                                                    <input
                                                        type="text"
                                                        value={searchQuery}
                                                        onChange={this.handleSearchInputChange}
                                                        placeholder="Search Topic"
                                                        className="flex-1"
                                                    />
                                                    {searchQuery && (
                                                        <button
                                                            type="button"
                                                            className="clear-button"
                                                            onClick={this.handleClearSearch}
                                                        >
                                                            Clear
                                                        </button>
                                                    )}
                                                </div>

                                            </form>
                                        </div>
                                        {searchResults.length > 0 &&
                                            <div className="search-result-box">
                                                <div className="result-wrap-n1">
                                                    {searchResults.map((item) => (
                                                        <Link className="search-blog-item" key={item.id} to={`/blogs/${item.blog_slug}`}>
                                                            <img src={`${siteUrl}${item.image}`} alt={item.blog_title} rel="preload" />
                                                            <article>
                                                                <h4>{item.blog_title}</h4>
                                                                <p>{item.blog_paragraph}</p>
                                                            </article>
                                                        </Link>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="blog-cat-list">
                            <div className="container">
                                <div className="">
                                    <h2 className="blog-cat-title">Trending Blogs</h2>
                                    <div className="row g-4 blog-list-row">
                                        {blogs && blogs.slice(0, 3).map(blog => (
                                            <Link className="blog-item d-block col-lg-4" key={blog.id} to={"/blogs/" + blog.blog_slug}>
                                                <div className="image-wrap overflow-hidden">
                                                    <img className="object-fit-cover w-100" src={"https://admin.bridgehealth.in" + blog.image} alt={blog.blog_title} rel="preload" />
                                                </div>
                                                <article>
                                                    <div className="btn-wrap text-center">
                                                        <button>{blog.blog_heading}</button>
                                                    </div>
                                                    <h4>{blog.blog_title}</h4>
                                                    <div className="content">
                                                        {blog.blog_paragraph}
                                                    </div>
                                                    <div className="d-flex justify-content-center">
                                                        <button className="def-btn-n1">Read More</button>
                                                    </div>
                                                </article>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                                {/* {!loadMoreClicked && filteredBlogs.length > visibleBlogs && (
                                    <div className="loadmore-btn-n1">
                                        <button className="def-btn-n1 btn-1" onClick={this.loadMoreFn}>Load More</button>
                                    </div>
                                )} */}
                                <div className="cat-list-row">
                                    {categories && categories.map((category, index) => {
                                        let filteredBlogs = blogs.filter(blog_item => blog_item.blog_categories === category.Slug);
                                        let displayedBlogs = loadMoreClicked ? filteredBlogs : filteredBlogs.slice(0, visibleBlogs);

                                        return (
                                            displayedBlogs.length > 0 && (
                                                <div className="blog-cat-item" key={index}>
                                                    <h2 className="blog-cat-title">{category.Name}</h2>
                                                    <div className="row g-4 blog-list-row">
                                                        {displayedBlogs.map(blog => (
                                                            <Link className="blog-item d-block col-lg-4" key={blog.id} to={"/blogs/" + blog.blog_slug}>
                                                                <div className="image-wrap overflow-hidden">
                                                                    <img className="object-fit-cover w-100" src={"https://admin.bridgehealth.in" + blog.image} alt={blog.blog_title} rel="preload" />
                                                                </div>
                                                                <article>
                                                                    <div className="btn-wrap text-center">
                                                                        <button>{blog.blog_heading}</button>
                                                                    </div>
                                                                    <h4>{blog.blog_title}</h4>
                                                                    <div className="content">
                                                                        {blog.blog_paragraph}
                                                                    </div>
                                                                    <div className="d-flex justify-content-center">
                                                                        <button className="def-btn-n1">Read More</button>
                                                                    </div>
                                                                </article>
                                                            </Link>
                                                        ))}
                                                    </div>

                                                    {!loadMoreClicked && filteredBlogs.length > visibleBlogs && (
                                                        <div className="loadmore-btn-n1">
                                                            <button className="def-btn-n1 btn-1" onClick={this.loadMoreFn}>Load More</button>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        );
                                    })}
                                </div>


                            </div>
                        </div>
                    </div>
                </section>


                <Footer />
            </>
        );
    }
}

export default Blog;
