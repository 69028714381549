
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

const validationSchema = Yup.object({
  username: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email format").required("Email is required"),
});

const SubscribeForm = () => {
  const [thank, setThank] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const SubscribeData = {
        Name: values.username,
        Email: values.email,
      };

      const res = await axios.post(`https://admin.bridgehealth.in/api/footer-forms`, { data: SubscribeData });
      if (res.status === 200) {
        console.log("Email sent:", values);
        setThank(true)
        resetForm();
      }

    } catch (error) {
      console.log("Error sending email:", error);
    }
  };

  return (
    <div className="">
      <Formik
        initialValues={{ username: "", email: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="def-form subscribe-form">
            <div className="form-group">
              <Field name="username" type="text" className="" placeholder="Name" />
              <ErrorMessage name="username" component="div" className="error" />
            </div>
            <div className="form-group">
              <Field name="email" type="email" className="" placeholder="Email ID" />
              <ErrorMessage name="email" component="div" className="error"/>
            </div>
            <div className="btn-box footer-submit">
              <button type="submit" className="def-btn" disabled={isSubmitting}>
                SUBMIT
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {thank && "Thanks"}
    </div>
  );
};

export default SubscribeForm;
