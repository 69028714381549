import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import FormButton from "../element/component/FormButton";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
class HRA extends Component {
  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Comprehensive Guide to Shingles: Causes, Symptoms, Treatment |
            BridgeHealth
          </title>
          <meta
            name="description"
            content="Vaccination for Shingles at Bridge Health: Herpes Zoster, caused by the varicella-zoster virus. Discover symptoms, Vaccines, treatments, & solutions for effective management."
          />
        </Helmet>
        <section className="team-section padding mobilehide">
          {/* About Section Two */}
          <section className="container-fluid">
            <div className="row align-items-center auto-container reverseFlex">
              <div className="col-lg-6">
                <div className="desktop-hra">
                  <div className="content-box marginTop40header image-container-hra">
                    <div className=" text-center textleft">
                      <h2 className="textleft  wrap-hra">
                        <span className="headeingcolorblue">
                          Understand Your Health<br></br> With Our Health Risk{" "}
                          <br></br>Assessments
                        </span>
                      </h2>
                      <h3 className="textleft">
                        Get a comprehensive overview of your <br></br>health
                        with our simple & accurate HRAs
                      </h3>
                    </div>
                    <FormButton ButtonName={"get now"} />
                    <div className="hra-image">
                      <img
                        srcset={require("../../assets/images/shape/hra2.webp")}
                        alt=""
                        rel="preload"
                      />
                    </div>

                    <div className="hra-image1">
                      <img
                        srcset={require("../../assets/images/shape/hra3.webp")}
                        alt=""
                        className="wra-hra"
                        rel="preload"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <img
                    srcset={require("../../assets/images/shape/hra1.webp")}
                    alt=""
                    className="imgwidth1 mobilehide"
                    rel="preload"
                  />
                </div>
              </div>
            </div>
          </section>
        </section>
        <section className="team-section padding doctoeback desktophide ">
          {/* About Section Two */}
          <section className="container-fluid">
            <div className="row align-items-center auto-container reverseFlex">
              <div className="col-lg-6">
                <div className="content-box marginTop40header">
                  <h2 className="hraTitle hraTitle-hr">
                    <span className="headeingcolorblue">
                      {" "}
                      Understand your health<br></br> with our health risk
                      <br></br>
                      assessments
                    </span>
                  </h2>
                  <h3 className="hrasubtitle">
                    Get a comprehensive overview of your health with our simple
                    & accurate HRAs{" "}
                  </h3>
                  <div className="position-relative bann-form-a5">
                    <FormButton ButtonName={"try now"} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className=" imgright margin75px">
                  <img
                    srcset={require("../../assets/images/shape/mob1.webp")}
                    alt=""
                    className="imgwidth"
                    rel="preload"
                  />
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className="sectio-4">
          <div className="container-fluid paddingContainer">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <img
                  srcset={require("../../assets/images/shape/hra51.webp")}
                  alt=""
                  className="hra-img-hra"
                  rel="preload"
                />
              </div>
              <div className="col-lg-8">
                <div>
                  <div className="info-container">
                    <p className="hra-par">
                      Our HRA is built to gather insights that allow us to
                      understand your health profile easily, quickly, and
                      efficiently. This enables us to provide you with
                      personalized plans to manage your health well. Our HRA is:
                    </p>
                    <ul className="listStyle">
                      <li>Highly accurate</li>
                      <li>Simple and easy to understand</li>
                      <li>Beautifully designed</li>
                    </ul>
                    <FormButton ButtonName={"try now"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sectio-6 marginTop50">
          <div className="auto-container">
            <div className="row reverseFlex">
              <div className="col-lg-6">
                <div className="info-container1">
                  <h2 className="title-hra3">What is your Health Score?</h2>
                  <p className="hra-par2">
                    Your health score is a comprehensive score that assesses
                    your overall health by analyzing physical, mental, and
                    lifestyle factors. Our HRA calculates your health score
                    which helps you prioritize your well-being and motivates you
                    to adopt healthier habits.{" "}
                  </p>

                  <FormButton ButtonName={"try now"} />
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/hra7.webp")}
                  alt=""
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="sectio-5">
          <div className="auto-container">
            <div className="row">
              <img
                srcset={require("../../assets/images/1st.webp")}
                type="video/mp4"
                alt=""
                rel="preload"
                className="mobilehide"
              />
              <img
                srcset={require("../../assets/images/shape/heartHealthMobile.webp")}
                alt=""
                rel="preload"
                className="desktophide heartImg"
              />
            </div>
          </div>
        </section>

        <section className="sectio-7">
          <div className="auto-container">
            <h2 className="item-head">
              Why you should opt for{" "}
              <span className="headeingcolorblue">HRA?</span>{" "}
            </h2>
            <div className="row marginTop50">
              <div className="col-lg-6">
                <div className="hra-thord">
                  <img
                    srcset={require("../../assets/images/shape/hra91.webp")}
                    alt=""
                    className="img-hr1"
                    rel="preload"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="info-container info-container-mob">
                  <ul className="listStyle">
                    <li>
                      HRAs help to detect potential health risks and conditions,
                      enabling you to take preventive measures and seek timely
                      medical attention..{" "}
                    </li>
                    <li>
                      HRAs provide personalized insights on your health status,
                      empowering you to make informed decisions about your
                      well-being and adopt tailored lifestyle changes for better
                      health.
                    </li>
                    <li>
                      HRAs can help you avoid costly medical treatments and
                      hospitalizations that may arise from unmanaged health
                      conditions through preventive measures and early
                      interventions.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mobilehide">
          <div className="container marginTop5px">
            <h2 className="item-head">
              Benefits of <span className="headeingcolorblue">HRA</span>{" "}
            </h2>

            <h3 className="item-head2">
              Find out how our HRA helps you identify, understand, <br></br>and
              prevent chronic health conditions
            </h3>
            <div className="row">
              <div className="search-box">
                <div className="cardBox">
                  <img
                    srcset={require("../../assets/images/shape/hraCard1.webp")}
                    alt=""
                    rel="preload"
                  ></img>
                  <div className="top-left">
                    <h3>Tracking Symptoms</h3>
                    <p className="textAlignLeft">
                      Consistently monitor your symptoms to notice any shifts or
                      recurring themes in your health profile.
                    </p>
                    <button className="hraBtn" type="submit">
                      Track Now
                    </button>
                  </div>
                </div>
                <div className="cardBox margin5px">
                  <img
                    srcset={require("../../assets/images/shape/hraCard2.webp")}
                    alt=""
                    rel="preload"
                  ></img>
                  <div className="bottom-left">
                    <h3>
                      Feel better, <br></br>faster
                    </h3>
                    <p className="textAlignLeft">
                      Identify potential health risks and take appropriate
                      action regarding lifestyle changes and doctor
                      appointments.
                    </p>
                  </div>
                </div>
                <div className="cardBox margin5px">
                  <img
                    srcset={require("../../assets/images/shape/hraCard3.webp")}
                    alt=""
                    rel="preload"
                  ></img>
                  <div className="top-left">
                    <h3>
                      Comprehensive <br></br> Health Overview
                    </h3>
                    <p className="textAlignLeft">
                      Assess your health parameters across 7 crucial parameters,
                      namely heart health, diabetes, obesity, nutrition, cancer,
                      mental health, and fitness.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="desktophide">
          <div className="container marginTop60">
            <h2 className="item-head">
              Benefits of <span className="headeingcolorblue">HRA</span>{" "}
            </h2>

            <h3 className="item-head2">
              Find out how our HRA helps you identify, understand, and prevent
              chronic health conditions
            </h3>
            <div className="row">
              <div>
                <div className="cardBox mobileMarginSet">
                  <img
                    srcset={require("../../assets/images/shape/mobilehra1.webp")}
                    alt=""
                    rel="preload"
                  ></img>
                  <div className="top-left">
                    <h3>
                      Tracking <br></br> Symptoms
                    </h3>
                    <p className="textAlignLeft">
                      Consistently monitor your symptoms to notice any shifts or
                      recurring themes in your health profile.
                    </p>
                    <button className="hraBtn" type="submit">
                      Track Now
                    </button>
                  </div>
                </div>
                <div className="cardBox mobileMarginSet">
                  <img
                    srcset={require("../../assets/images/shape/mobilehra2.webp")}
                    alt=""
                    rel="preload"
                  ></img>
                  <div className="bottom-left">
                    <h3>
                      Feel better,<br></br> faster
                    </h3>
                    <p className="textAlignLeft">
                      Identify potential health risks and take appropriate
                      action regarding lifestyle changes and doctor
                      appointments.
                    </p>
                  </div>
                </div>
                <div className="cardBox mobileMarginSet">
                  <img
                    srcset={require("../../assets/images/shape/mobilehra123.webp")}
                    alt=""
                    rel="preload"
                  ></img>
                  <div className="top-left">
                    <h3>
                      Comprehensive <br></br> Health Overview
                    </h3>
                    <p className="textAlignLeft">
                      Assess your health parameters across 7 crucial parameters,
                      namely heart health, diabetes, obesity, nutrition, cancer,
                      mental health, and fitness.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="hra-second">
              <img
                srcset={require("../../assets/images/shape/tabDesign.webp")}
                alt=""
                rel="preload"
              />
            </div>
          </div>
        </section>
        <section className="sectio-7">
          <div className="auto-container hra-wrap-item">
            <div className="hra1-item">
              <h2 className="hra-heading">Ask the Experts</h2>
              <p className="hra-pagr">
                Bridge health is the longest-serving and most experienced
                company in <br></br>the health risk assessment industry.
              </p>
              <div className="d-flex justify-content-center pb-4">
                <FormButton ButtonName={'CONSULT / BOOK'} />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default HRA;
