import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import FormButton from "../element/component/FormButton";

class elderlyCare extends Component {
  componentDidMount() {
    document.title = "Elderly Care | Bridge Health";
  }

  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Best Elder care / Geriatric care Services in Bangalore | Bridge
            Health
          </title>
          <meta
            name="description"
            content="Comprehensive senior care services. Best Doctor for Geriatric care in Bangalore Discover our elderly care services at Bridge Health. "
          />
        </Helmet>
        <section className="team-section padding doctoeback image-mobile-terms">
          {/* About Section Two */}
          <section className="container-fluid">
            <div className="row align-items-center auto-container reverseFlex">
              <div className="col-lg-5">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50">
                      <span className="headeingcolorblack">
                        {" "}
                        Age is not just{" "}
                      </span>
                      <br></br>
                      <span className="headeingcolorblack"> a </span>
                      <span className="headeingcolorblue"> Number</span>
                    </h2>
                    <h3 className="textleft marginTop40">
                      Professional elderly care at <br></br> your doorstep
                    </h3>
                  </div>
                  <FormButton/>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="imgright">
                  <img
                    srcset={require("../../assets/images/shape/care1.webp")}
                    alt=""
                    className="width100"
                    rel="preload"
                  />
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className="contact-section heath-margin">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/care2.webp")}
                  alt=""
                  className="width100"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div className="contact-form-area">
                  <div className="sec-title squrebox">
                    <p className="font22">
                      Although we may stay young at heart, our body starts
                      ageing. As we grow older, our physical abilities decline
                      and we might find ourselves slower and more dependent on
                      others for physical assistance.
                    </p>
                  </div>
                  <FormButton/>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="auto-container">
          <div className="row reverseFlex">
            <div className="col-lg-6">
              <div className="sec-title squrebox">
                <p className="font22">
                  <span className="headeingcolorblue">
                    At Bridge Health, we understand the <br></br> importance of
                    elderly care.
                  </span>
                  <br></br>
                  Bridge Health's One Care Program revolutionizes geriatric care
                  in India by providing specialized plans to improve the
                  lifespan and health span of the elderly population. It starts
                  with a Comprehensive Elder Care Assessment (CECA) to evaluate
                  functional abilities, physical and cognitive health, and
                  mental well-being. Our clinical experts then create
                  personalized prevention and treatment plans based on the
                  assessments.
                </p>
              </div>
            </div>

            <div className="col-lg-5">
              <img
                srcset={require("../../assets/images/shape/care3.webp")}
                alt=""
                className="width100 imgFloatRight"
                rel="preload"
              />
            </div>
          </div>
        </div>

        <section className="contact-section">
          <div className="auto-container">
            <div className="row reverseFlex">
              <div className="col-lg-6">
                <h2 className="header1">
                  <span className="headeingcolorblack"> The</span>
                  <span className="headeingcolorblue"> Bridge Health</span>
                  <span className="headeingcolorblack"> Advantage </span>
                </h2>
                <ul className="listicon">
                  <li>Home care</li>

                  <li>Consults with experts</li>

                  <li>Assistive care</li>

                  <li>Diet and exercise plans for improved health</li>

                  <li>Nursing at home</li>

                  <li>Dialysis at home</li>

                  <li>Physiotherapy at home</li>

                  <li>Medicine delivered directly to your home</li>

                  <li>X-ray and ECG at home</li>

                  <li>Blood and urine sample collection at home</li>
                </ul>
              </div>

              <div className="col-lg-5">
                <img
                  srcset={require("../../assets/images/shape/care4.webp")}
                  alt=""
                  className="width100 marginTop40reverse"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2 className="header1">
                <span className="headeingcolorblack">
                  Giving the Elderly the Care{" "}
                </span>
                <span className="headeingcolorblue"> They Deserve </span>
              </h2>
            </div>
            <div className="d-flex justify-content-center pb-4">
                <FormButton ButtonName={'CONSULT / BOOK'} />
              </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default elderlyCare;
