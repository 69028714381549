import React, { useState, useEffect } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const aboutbg = require("./../../assets/images/shape/car1.webp");

const JobDescription = () => {
  const [selectedJob, setSelectedJob] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const siteUrl = `https://admin.bridgehealth.in`;
    fetch(`${siteUrl}/api/create-jobs`)
      .then((response) => response.json())
      .then((data) => {
        const foundJob = data.data.find((job) => job.id === parseInt(id));
        setSelectedJob(foundJob);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job descriptions:", error);
        setLoading(false);
      });
    document.title = "Job Description | Bridge Health";
  }, [id]);

  return (
    <>
      <Header />
      {/*
        <!-- Page Banner Section --> */}
      <Helmet>
        <title>JOb Description</title>
        <meta
          name="description"
          content="Vaccination for Shingles at Bridge Health: Herpes Zoster, caused by the varicella-zoster virus. Discover symptoms, Vaccines, treatments, & solutions for effective management."
        />
      </Helmet>
      <section className="page-banner">
        <div
          className="page-banner-bg background-size-cover"
          style={{ backgroundImage: "url(" + aboutbg + ")" }}
        ></div>
        <div className="bottom-rotten-curve alternate"></div>
      </section>
      
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="container">
          <h2 className=" fontSize text-center">
            <span className="headeingcolorblue"> Job </span>
            <span className="headeingcolorblack"> Description </span>
          </h2>

          {selectedJob ? (
            <div className="row">
              <div className="col-lg-8">
                <h3>{selectedJob.attributes.designation}</h3>
                <p className="text-muted">Bridge Health</p>
                <hr />
                <h4>Job Description</h4>
                <h5>1. ROLES AND RESPONSIBILITIES OF THE ROLE </h5>
                <div>
                  <ul className="job-desc">
                    {selectedJob.attributes.roles_and_responsibilities
                      .split("\n")
                      .map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                  </ul>
                </div>
                <h5>2. Desired Expertise & Experience:</h5>
                <div>
                  <ul className="job-desc">
                    {selectedJob.attributes.expertise_and_experience
                      .split("\n")
                      .map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                  </ul>
                </div>
                <h5>3. DESIRED EDUCATIONAL QUALIFICATIONS DESIRED</h5>
                <div>
                  <ul className="job-desc">
                    {selectedJob.attributes.qualifications
                      .split("\n")
                      .map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                  </ul>
                </div>
                <h5>4. PREFERRED SKILLS</h5>
                <div>
                  <ul className="job-desc">
                    {selectedJob.attributes.skills
                      .split("\n")
                      .map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                  </ul>
                </div>
                <div>
                  <h5>ADDITIONAL NOTES IF ANY</h5>
                  <p>{selectedJob.attributes.additional_notes}</p>
                </div>
              </div>
              <div className="col-lg-4">
                <h4>Job Details</h4>
                <ul className="list-unstyled">
                  <li>
                    <strong>Location:</strong> {selectedJob.attributes.location}
                  </li>
                  <li>
                    <strong>Contract Type:</strong>{" "}
                    {selectedJob.attributes.contract_type}
                  </li>
                  <li>
                    <strong>Posted:</strong>{" "}
                    {selectedJob.attributes.posted_date}
                  </li>
                </ul>
                <a href="/career-form">
                  {" "}
                  <button
                    className=" submitcontact"
                    type="submit"
                    name="submit-form"
                    title="Apply Now"
                  >
                    Apply Now
                  </button>
                </a>
              </div>
            </div>
          ) : (
            <p>No job data found.</p>
          )}
        </div>
      )}

      <Footer />
    </>
  );
};

export default JobDescription;
