import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

class HpvAccordionMob extends Component {
  render() {
    return (
      <>
        {/* <!-- Faq Section -->  */}
        <section className="faq-section backGroundRighthpv">
          <div className="auto-container">
            <div className="row">
              <div className="shi-mob">
                <div className="sec-title text-center textleft">
                  <h2 className="textleft fontSize50 shi1-mob1">
                    <span className="headeingcolorblack">
                      Possible Complications of
                    </span>
                    <span className="headeingcolorblue"> HPV</span>
                  </h2>
                </div>
              </div>
              <Accordion preExpanded={[0]} allowZeroExpanded>
                <AccordionItem allowZeroExpanded={true}>
                  <AccordionItemHeading>
                    <AccordionItemButton>Cervical Cancer</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        Persistent infection with high-risk HPV strains,
                        particularly types 16 and 18, can lead to changes in
                        cervical cells that may progress to cervical cancer over
                        time.
                      </p>

                      <img
                        srcset={require("../../assets/images/shape/Cervical Cancer.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Genital Warts</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        Some HPV strains can cause visible genital warts, which
                        can be uncomfortable and emotionally distressing.
                      </p>
                      <img
                        srcset={require("../../assets/images/shape/Genital Warts.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Respiratory Papillomatosis
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        In a few cases, an HPV infection can lead to growths in
                        the airway, causing breathing difficulties.
                      </p>
                      <img
                        srcset={require("../../assets/images/shape/Respiratory Papillomatosis.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default HpvAccordionMob;
