import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const siteUrl = `https://admin.bridgehealth.in`;

class blog_shingles extends Component {

  constructor(props) {
    super(props);
    this.state = {
      blog: [],
      Rel_Blogs: [],
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  shareOnSocialMedia = (platform) => {
    const shareURL = "https://bridgehealth.in/";

    switch (platform) {
      case "instagram":
        // Open Instagram app with pre-filled caption
        window.open(
          `instagram://library?AssetPath=${encodeURIComponent(shareURL)}`
        );
        break;
      case "linkedin":
        // Open LinkedIn share dialog with pre-filled URL
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            shareURL
          )}`
        );
        break;
      case "facebook":
        // Open Facebook share dialog with pre-filled URL
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            shareURL
          )}`
        );
        break;
      case "twitter":
        // Open Twitter share dialog with pre-filled tweet
        const tweetText = "Check out this link: " + shareURL;
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            tweetText
          )}`
        );
        break;
      default:
        break;
    }
  }


  fetchData = () => {
    const { match } = this.props;
    const blogSlug = match.params.Slug;


    fetch(`${siteUrl}/api/blog-listings?filters[Slug][$eq]=${blogSlug}&populate=*`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          blog: data.data[0],
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        this.setState({
          blog: [],
          loading: false,
          error: error.message,
        });
      });


    fetch(`${siteUrl}/api/blog-listings?filters[Slug][$ne]=${blogSlug}&sort=id:desc&pagination[start]=0&pagination[limit]=3&populate=*`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          Rel_Blogs: data.data,
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        this.setState({
          Rel_Blogs: [],
          loading: false,
          error: error.message,
        });
      });
  };


  render() {
    const { blog, Rel_Blogs, loading, error } = this.state;

    console.log("rb", Rel_Blogs)


    return (
      <>
        <Header />
        <Helmet>
          <title>
            Personalized Care Continuum -Medical & Digital Health Services
            |Bridge Health
          </title>
          <meta
            name="Blog Shingles"
            content="Bridge Health is one stop health solution for all preventive & holistic healthcare with personalized annual health plans. & There is a plan to fit the specific health needs of everybody - your spouse, parents, kids & YOU. Know more about Bridge Health, JP Nagar 2nd phase Bangalore."
          />
        </Helmet>

        <div className="pt-5"></div>
        <div className="pt-5"></div>
        <section className="s1-wrap-a1">
          <div className="banner-image">
            <img className="w-100" src={siteUrl + blog?.attributes?.image_1?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
          </div>
          <div className="p-5 ">
            <h2 className="text-start pb-2">{blog?.attributes?.blog_title}</h2>
            <div className="row g-0">
              <div className="col-lg-9 pr-5">
                <h3>Introduction</h3>
                <p className="mb-4">{blog?.attributes?.paragraph_1}</p>
                <div className="sec1-image mb-4">
                  <img className="w-100" src={siteUrl + blog?.attributes?.image?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
                </div>
                <p className="mb-4">{blog?.attributes?.paragraph_2}</p>
                <div className="sec1-image mb-4">
                  <img className="w-100" src={siteUrl + blog?.attributes?.image_2?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
                </div>
                <p className="mb-4">{blog?.attributes?.paragraph_3}</p>
                <div className="sec1-image mb-4">
                  <img className="w-100" src={siteUrl + blog?.attributes?.image_3?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
                </div>
                <p className="mb-4">{blog?.attributes?.paragraph_4}</p>
                <div className="sec1-image mb-4">
                  <img className="w-100" src={siteUrl + blog?.attributes?.image_4?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
                </div>
                <p className="mb-4">{blog?.attributes?.paragraph_5}</p>
                <div className="sec1-image mb-4">
                  <img className="w-100" src={siteUrl + blog?.attributes?.image_5?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
                </div>
                <p className="mb-4">{blog?.attributes?.paragraph_6}</p>
              </div>
              <div className="col-lg-3">
                <div className="bg-head1 mb-5">
                  <div className="sec-title-blog1">
                    <div className="text-blog">
                      <h5 className="side-hed">Share:</h5>
                      <img
                        srcSet={require("../../assets/images/shape/icon1.webp")}
                        alt="instagram"
                        className="testthumb1"
                        onClick={() => this.shareOnSocialMedia("instagram")}
                        rel="preload"
                      />
                      <img
                        srcSet={require("../../assets/images/shape/icon2.webp")}
                        alt="linkedin"
                        className="testthumb1"
                        onClick={() => this.shareOnSocialMedia("linkedin")}
                        rel="preload"
                      />
                      <img
                        srcSet={require("../../assets/images/shape/icon3.webp")}
                        alt="facebook"
                        className="testthumb1"
                        onClick={() => this.shareOnSocialMedia("facebook")}
                        rel="preload"
                      />
                      <img
                        srcSet={require("../../assets/images/shape/icon4.webp")}
                        alt="twitter"
                        className="testthumb1"
                        onClick={() => this.shareOnSocialMedia("twitter")}
                        rel="preload"
                      />
                    </div>
                    <div className="text-align-start">
                      <Link to='/blogs'>
                        Back to the Blogs
                      </Link>
                    </div>
                  </div>
                </div>
                <h3 className="mb-5">Suggested Blogs</h3>
                <div className="">
                  {Rel_Blogs.map((item, index) => {
                    return (
                      <a key={index} className="rel-blog-uu2 mb-5" href={`/blogs/${item.attributes?.Slug}`}>
                        <div className="blog-image">
                          <img className="w-100" src={siteUrl + item.attributes?.image?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
                        </div>
                        <div className="auth-ff2">
                          <div className="auth-image-b4">
                            <img className="" src={siteUrl + item.attributes?.image?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
                          </div>
                          <p className="">{item.attributes?.author_name}</p>
                        </div>
                        <h4>{item.attributes?.blog_title}</h4>
                      </a>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default blog_shingles;
