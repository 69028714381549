import React, { useEffect, useState } from 'react'


const Popup_Main = ({ children, isOpen, onClose }) => {

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShowModal(isOpen);
    }, [isOpen]);

    return (
        <div className={`popup-main ${showModal ? 'active' : ''}`}>
            <div className='popup-container'>
                <div className='popup-wrap'>
                    <button className='close-popup p-2' onClick={onClose}>
                        <img src='/images/cross-button.png' alt='cross' />
                    </button>
                    <div className='popup-box'>
                        {children}
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Popup_Main