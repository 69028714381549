import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContactForm from "../contact-form";
import FormButton from "./FormButton";
import Popup_Main from "./Popup_Main";
import ReachToUsForm from "../ReachToUsForm";


const Banner_slider = () => {

  const [isOpen, setIsOpen] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <div className="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i>
    </div>,
    nextArrow: <div className="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i>
    </div>,
  };

  return (
    <>
      <div className="banner-slider-home">
        <Slider {...settings} className="def-slider theme_carousel">

          <div className="d-flex flex-column justify-content-center align-items-center position-relative bann-form-a5">
            <img className="desk" src="/images/new_banners/b4.jpg" alt="" />
            <img className="mob" src="/images/new_banners/b4_mob.jpg" alt="" />
            <button className='commonBtnforAll' onClick={()=>setIsOpen(true)}>Request a Call back </button>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center position-relative bann-form-a5">
            <img className="desk" src="/images/new_banners/b5.jpg" alt="" />
            <img className="mob" src="/images/new_banners/b5_mob.jpg" alt="" />
            <button className='commonBtnforAll' onClick={()=>setIsOpen(true)}>Request a Call back </button>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center position-relative bann-form-a5">
            <img className="desk" src="/images/new_banners/b3.jpg" alt="" />
            <img className="mob" src="/images/new_banners/b3_mob.jpg" alt="" />
            <button className='commonBtnforAll' onClick={()=>setIsOpen(true)}>Request a Call back </button>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center position-relative bann-form-a5">
            <img className="desk" src="/images/new_banners/b2.jpg" alt="" />
            <img className="mob" src="/images/new_banners/b2_mob.jpg" alt="" />
            <button className='commonBtnforAll' onClick={()=>setIsOpen(true)}>Request a Call back </button>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center position-relative bann-form-a5">
            <img className="desk" src="/images/new_banners/b1.jpg" alt="" />
            <img className="mob" src="/images/new_banners/b1_mob.jpg" alt="" />
            <button className='commonBtnforAll' onClick={()=>setIsOpen(true)}>Request a Call back </button>
          </div>
        </Slider>

      </div>

      <Popup_Main isOpen={isOpen} onClose={() => setIsOpen(false)} >
        <ReachToUsForm submitted={() => setIsOpen(false)} />
      </Popup_Main>
    </>
  );
}
export default Banner_slider;