import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Helmet } from "react-helmet";

class Team extends Component {
  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>Trusted Preventive Health Specialists | Bridge Health</title>
          <meta
            name="description"
            content="Get to know the talented individuals who make up Bridge health's exceptional team. Experienced & dedicated in delivering high-quality services & driving our mission forward.
          "
          />
        </Helmet>
        <div className="marginTopTeam image-mobile-terms"></div>
        <div className="sec-title text-center px-3">
          <h2 style={{ fontSize: 50 + "px" }}>
            <span className="headeingcolorblack mt-5"> Meet The </span>{" "}
            <span className="headeingcolorblue">Bridgers</span>
          </h2>
          <h5 className="heading3">
            Build a Healthy Future With the Help of Our Specialists
          </h5>
        </div>
        {/* <!-- About Section Two --> */}
        <section className="about-section-two px-3">
          <div className="auto-container">
            <div className="row ">
              <div className="col-lg-4">
                <div className="image-one">
                  <img
                  style={{objectPosition:`50% 20%`}}
                    srcset={require("../../assets/images/shape/team.webp")}
                    alt=""
                    rel="preload"
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="author-info">
                  <div className="wrapper-box text">
                    <h3>Dr. Dhanya Prabhu Ramdas</h3>
                    <h4>Head – Clinical Services</h4>
                    <div className="text font18">
                      Dr. Dhanya Prabhu Ramadas is a highly motivated,
                      team-oriented, and compassionate family physician. She
                      believes in providing evidence-based holistic care to all
                      her patients. Her fields of interest are geriatrics,
                      neurology, women & child health and lifestyle medicine.
                      <br></br>
                      She has completed her DNB from Bangalore Baptist Hospital
                      and thereafter, completed MRCGP [Int]. She is a member of
                      The Academy of Family Physicians of India (AFPI) and is
                      certified by the Karnataka Medical Council.<br></br>
                      When not with her patients, she is busy travelling,
                      spending time with family, listening to old English
                      country music or reading. She keeps herself fit by
                      practicing yoga, jogging and dancing.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row  marginTop50">
              <div className="col-lg-4">
                <div className="image-one">
                  <img
                  style={{objectPosition:`50% 30%`}}
                    srcset={require("../../assets/images/shape/team1.webp")}
                    alt=""
                    rel="preload"
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="author-info">
                  <div className="wrapper-box text">
                    <h3>Dr. Vedavathi. N</h3>
                    <h4>Family Physician</h4>
                    <div className="text font18">
                      Dr. Vedavathi. N is an empathetic, competent and
                      passionate doctor. She has over 10 years of experience in
                      identifying and screening individuals of all age groups
                      for risk factors to prevent diseases and their
                      progression. She has a special interest in preventive
                      medicine and anatomy.<br></br>
                      She has completed her P.G Diploma in Family Medicine from
                      Apollo Hospitals, Bangalore, through the Royal College of
                      General Practitioners - United Kingdom. She is a certified
                      NABH Assessor and ISO Assessor. Dr. Veda has also been a
                      faculty member of the paramedical college at Dayanand
                      Sagar University.<br></br>
                      At a personal level, she loves learning rangoli designs
                      and also enjoys dancing.
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="row  marginTop50">
              <div className="col-lg-4">
                <div className="image-one">
                  <img
                  style={{objectPosition:`50% 30%`}}
                    srcset={require("../../assets/images/shape/team2.webp")}
                    alt=""
                    rel="preload"
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="author-info">
                  <div className="wrapper-box text">
                    <h3>Dr. Jyotsna Kurien Mathai</h3>
                    <h4>Consultant Physician</h4>
                    <div className="text font18">
                      Dr. Jyotsna Mathai is a Consultant Physician with a
                      special interest in Women's Health. She has completed her
                      MBBS from Jawaharlal Nehru Medical College-Dharwad
                      University and DGO from College of Physician and Surgeons-
                      Mumbai. She was previously associated with Activ Doctors
                      Clinic and NationWide Primary Health Clinic. She is known
                      for her knowledge of Obstetrics and Gynaecology.<br></br>
                      Dr Jyotsna believes in integrity, respect and
                      responsibility, which have led her to have a successful
                      practice and career.Her core beliefs have helped her reach
                      out to all kinds of people, from every walk of life. She
                      also loves reading about spirituality, esoteric
                      philosophy, and human behaviour analysis.<br></br>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row  marginTop50">
              <div className="col-lg-4">
                <div className="image-one">
                  <img
                  style={{objectPosition:`50% 30%`}}
                    src={require("../../assets/images/team/Dr Jisha B.webp")}
                    alt=""
                    rel="preload"
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="author-info">
                  <div className="wrapper-box text">
                    <h3>Dr Jisha B</h3>
                    <h4>Consultant Physician</h4>
                    <div className="text font18">
                      Dr Jisha B has an MBBS degree from Pondicherry University.
                      With several years of experience in the healthcare
                      industry, Dr. Benansia has worked in various prestigious
                      hospitals, including MFine in Bangalore, PRS Hospital in
                      Trivandrum. <br></br>
                      Dr. Benansia has a keen interest in research and has
                      conducted an observational study on the association
                      between Vitamin D levels and serum lipid parameters among
                      Indian adults working in the IT sector. Her findings have
                      contributed to the growing body of research in this area.
                      In her free time, she enjoys reading books, listening to
                      songs, and practicing kathak dancing.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row  marginTop50">
              <div className="col-lg-4">
                <div className="image-one">
                  <img
                  style={{objectPosition:`50% 20%`}}
                    srcset={require("../../assets/images/team/Krithika.webp")}
                    alt=""
                    rel="preload"
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="author-info">
                  <div className="wrapper-box text">
                    <h3>Dr. Krithika Prasad </h3>
                    <h4>Head – Pathologist</h4>
                    <div className="text font18">
                      Dr. Krithika Prasad is the Head Pathologist at Bridge
                      Health, where she brings over 7+ years of experience. With
                      an MBBS degree from Yenepoya Medical College and Hospital,
                      Mangalore, she went on to specialize in MD Pathology and
                      has worked with some of the leading hospitals and
                      diagnostic centres in the country.<br></br>
                      Dr. Prasad has been associated with organizations such as
                      The Madras Medical Mission in Chennai, SRL Diagnostics,
                      Marigold Clinics and Diagnostics, in Bangalore. She has
                      presented research papers at various conferences and
                      forums in Karnataka and won awards for her poster
                      presentations.<br></br>
                      Dr. Krithika’s core belief is that there is no substitute
                      for hard work. Apart from her work, she has a keen
                      interest in skating, dancing, table tennis, and swimming,
                      which she pursues in her free time.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row  marginTop50">
              <div className="col-lg-4">
                <div className="image-one">
                  <img
                  style={{objectPosition:`50% 35%`}}
                    srcset={require("../../assets/images/shrinivas.webp")}
                    alt=""
                    rel="preload"
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="author-info">
                  <div className="wrapper-box text">
                    <h3>Srinivasa Murthy K </h3>
                    <h4>Senior Physiotherapist</h4>
                    <div className="text font18">
                      Srinivasa Murthy has completed his Bachelor of
                      Physiotherapy from NTR University in Andhra Pradesh and
                      thereafter, MPT in Rehabilitation from
                      Pondicherry University. He is a member of the Indian
                      Association of Physiotherapists (IAP).<br></br>
                      The aim of his practice is to provide high-quality
                      rehabilitation care to individuals with orthopaedic
                      conditions, stroke rehabilitation, neuro rehabilitation,
                      and spinal cord injuries, using the latest evidence-based
                      clinical tools.<br></br>
                      While not with his patients, he loves to watch movies,
                      travel, cook and play cricket.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row  marginTop50">
              <div className="col-lg-4">
                <div className="image-one">
                  <img
                  style={{objectPosition:`50% 30%`}}
                    srcset={require("../../assets/images/arpita.webp")}
                    alt=""
                    rel="preload"
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="author-info">
                  <div className="wrapper-box text">
                    <h3>Arpitha Mirchandani </h3>
                    <h4>Psychologist</h4>
                    <div className="text font18">
                      Arpitha Mirchandani holds a Master’s degree in Psychology
                      and has 17+ years of work experience. She has been
                      associated with organizations and schools like Jindal
                      Nature Cure Institute, K12 Techno Services, RIMS
                      International School, Lenovo, etc. <br></br>
                      Arpitha strongly believes that mental health plays a
                      significant role in our overall well-being. Her
                      counselling approach is grounded in a holistic and
                      humanistic perspective, which involves providing guidance
                      on diet, sleep, and stress management. Additionally,
                      Arpitha has contributed articles to newspapers and created
                      content for YouTube.<br></br>
                      Alongside her professional pursuits, she derives pleasure
                      from cooking, travelling, and reading.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row  marginTop50">
              <div className="col-lg-4">
                <div className="image-one">
                  <img
                  style={{objectPosition:`50% 30%`}}
                    srcset={require("../../assets/images/team/anup.webp")}
                    alt=""
                    rel="preload"
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="author-info">
                  <div className="wrapper-box text">
                    <h3>Anoop M </h3>
                    <h4>Optometrist</h4>
                    <div className="text font18">
                      Anoop M a certified clinical optometrist from the
                      University of Kerela and has about 7 years of work
                      experience. He has also completed a Diploma in Ophthalmic
                      Assistance. He is passionate about helping others by
                      solving vision problems and improving their quality of
                      life with the right type of vision correction.<br></br>
                      Anoop has mastered optometry technology tools and
                      solutions to derive accurate diagnosis and determine ideal
                      prescriptions for patients. His other areas of interest
                      include community ophthalmic care, squint assessment,
                      objective and subjective refraction, participating in eye
                      check-up camps, etc. <br></br>
                      During his time away from work, Anoop plays football,
                      reads books and watches movies.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row  marginTop50">
              <div className="col-lg-4">
                <div className="image-one">
                  <img
                  style={{objectPosition:`50% 15%`}}
                    srcset={require("../../assets/images/team/nayak.webp")}
                    alt=""
                    rel="preload"
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="author-info">
                  <div className="wrapper-box text">
                    <h3>Shivakumara Nayaka </h3>
                    <h4>Radiographer</h4>
                    <div className="text font18">
                      Shivakumara Nayaka is a highly skilled Radiographer with
                      over 5 years of experience in the field. He holds a
                      Diploma in Medical Imaging Technology and has expertise in
                      X-ray, CT, PET CT, and other imaging technologies.
                      <br></br>
                      He has worked with some of the leading healthcare
                      institutions in India, including Jana Priya Hospital,
                      Wellspring Health Care PVT Ltd (Health spring), and Cure
                      Fit. Throughout his career, he has demonstrated a
                      customer-centric approach. He is specialized in
                      troubleshooting and is highly cooperative, making him an
                      invaluable member of any team.<br></br>
                      In addition to his professional accomplishments,
                      Shivakumara Nayaka is passionate about travelling, hiking,
                      and listening to music.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row  marginTop50">
              <div className="col-lg-4">
                <div className="image-one">
                  <img
                  style={{objectPosition:`50% 60%`}}
                    srcset={require("../../assets/images/team/Maitreyee.webp")}
                    alt=""
                    rel="preload"
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="author-info">
                  <div className="wrapper-box text">
                    <h3>Maitreyee De Sarkar </h3>
                    <h4>Senior Clinical Nutritionist </h4>
                    <div className="text font18">
                      Maitreyee De Sarkar with about 11 years of experience,
                      Maitreyee’s goal is to simplify existing health issues by
                      providing practical and enjoyable lifestyle management
                      strategies without restricting favourite foods, suggesting
                      extreme diets or fad diets. <br></br> She has done her
                      Master’s in Nutrition & Dietetic Management and is a
                      certified Diabetic Educator from Dr Mohan’s Diabetes
                      Hospital, Chennai. Her areas of specialization include
                      weight management, diabetes, women’s health, thyroid and
                      eating disorders. She has previously worked with reputed
                      institutions such as Apollo Hospitals, Narayana Health, to
                      name a few. Maitreyee is an active member of the Indian
                      Dietetic Association (IDA) and Indian Association for
                      Parenteral and Enteral Nutrition (IAPEN). <br></br> In her
                      leisure time, she enjoys glass painting, swimming,
                      listening to music and cooking.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row  marginTop50">
              <div className="col-lg-4">
                <div className="image-one">
                  <img
                  style={{objectPosition:`50% 20%`}}
                    srcset={require("../../assets/images/team/roshan.webp")}
                    alt=""
                    rel="preload"
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="author-info">
                  <div className="wrapper-box text">
                    <h3>Roshan Madhusudhan Suvarna </h3>
                    <h4>Fitness Coach </h4>
                    <div className="text font18">
                      Roshan Madhusudhan Suvarna a certified exercise specialist
                      with over 7 years of experience in the field of fitness
                      and exercise science. With a passion for helping
                      individuals achieve their health and fitness goals, he is
                      adept at curating and implementing personalized exercise
                      programs. <br></br>
                      In addition to being internationally recognized by the
                      American Council on Exercise, he has a Diploma in Sports
                      and Exercise Science and is a specialist in Posture and
                      Functional Corrective Exercise. He has worked with a wide
                      spectrum of customers, including those who have conditions
                      like diabetes, obesity, and cardiovascular disease.{" "}
                      <br></br>
                      Roshan is committed to staying up-to-date on the latest
                      research and trends in exercise science and nutrition. In
                      his free time, he prefers staying active through running,
                      weightlifting, and yoga. He also enjoys spending time
                      outdoors and hiking.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default Team;
