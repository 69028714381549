import React, { useState, useRef, useEffect } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EventItem from "../element/eventsInfo";
import EventMobileItem from "../element/mobileInfo";
import MediaItem from "../element/mediaItem";
import MediaMobileItem from "../element/mediamobileItem";

function MediaAndPR() {
  const siteUrl = `https://admin.bridgehealth.in`;

  const [showAllEvents, setShowAllEvents] = useState(false);
  const [showMoreMedia, setShowMoreMedia] = useState(false);
  const [eventsData, setEventsData] = useState([]);
  const initialMediaToShow = 6;

  const sliderRef = useRef(null);

  const previousSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const toggleEvents = () => {
    setShowAllEvents(!showAllEvents);
  };

  const toggleShowMoreMedia = () => {
    setShowMoreMedia(!showMoreMedia);
  };

  useEffect(() => {
    const siteUrl = `https://admin.bridgehealth.in`;
    fetch(
      `${siteUrl}/api/medias?pagination[start]=0&pagination[limit]=999999&sort=id:desc&populate=*`
    )
      .then((response) => response.json())
      .then((data) => {
        setEventsData(data.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
  };

  const mediaCoverage = [
    {
      id: 1,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3267.png",
    },
    {
      id: 2,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3268.png",
    },
    {
      id: 3,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3269.png",
    },
    {
      id: 4,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3270.png",
    },
    {
      id: 5,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3267.png",
    },
    {
      id: 6,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3268.png",
    },
    {
      id: 7,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3267.png",
    },
    {
      id: 8,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3268.png",
    },
  ];

  return (
    <>
      <Header />
      <Helmet>
        <title>
          Media & PR | Latest News and Press Releases | Bridge Health
        </title>
        <meta
          name="description"
          content="Stay updated with the latest news and press releases from Bridge Health. Explore our media and PR page for the most recent updates and exciting developments."
        />
      </Helmet>

      {/* <!-- Page Banner Section --> */}
      <section className="team-section padding doctoeback image-mobile-terms">
        <section className="container-fluid">
          <div className="row align-items-center auto-container reverseFlex">
            <div className="col-lg-6">
              <div className="desk-top">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft ">
                      <span className="headeingcolorblack">
                        WHERE CARE TAKES <br />
                        THE{" "}
                      </span>

                      <span className="headeingcolorblue">CENTER STAGE</span>
                    </h2>
                    <h3 className="textleft ">
                      Discover the journey of Bridge Health, <br />
                      crafting a diverse array of exceptional care{" "}
                    </h3>
                  </div>
                </div>
              </div>
              {/* Mobile Code */}
              <div className="mob-mobile">
                <div className="content-box marginTop40header">
                  <div className="text-center textleft">
                    <h2 className="textleft fontSize50 mt-5 uni-affi">
                      <span className="headeingcolorblack">
                        WHERE CARE TAKES THE{" "}
                      </span>{" "}
                      <br />
                      sit
                      <span className="headeingcolorblue"> CENTER STAGE</span>
                    </h2>
                    <h3 className="uni-media">
                      Discover the journey of Bridge Health, crafting a diverse
                      array of exceptional care{" "}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="imgright">
                <img
                  srcSet={require("../../assets/images/shape/mediapr1.webp")}
                  // src={siteUrl + eventsData?.attributes?.Banner_Image?.data?.attributes?.url}
                  alt=""
                  className="imgwidth wrap2-shi-img1 mobilehide"
                  rel="preload"
                />
                <img
                  //  src={siteUrl + eventsData?.attributes?.Banner_Image?.data?.attributes?.url}
                  srcSet={require("../../assets/images/shape/media-mob.webp")}
                  alt=""
                  className="imgwidth wrap2-shi-img1 desktophide"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>
      </section>

      {/* Desktop code */}
      <section className="media-events-bg desk-afflicate marginMedia pb-4">
        <div className="container-event-media">
          <h2 className="media-pr">EVENTS</h2>
          <section>
            <div className="row">
              {eventsData
                .slice(0, showAllEvents ? eventsData.length : 4)
                .map((event) => (
                  <div className="col-xl-3" key={event.id}>
                    <EventItem
                      key={event.id}
                      title={event?.attributes?.Title}
                      eventLink={event?.attributes?.Media_Link}
                      imageSrc={siteUrl + event?.attributes?.Thumbnail_image?.data?.attributes?.url}
                    />
                  </div>
                ))}
            </div>
            <div className="media-see-btn">
              <div className="btn-style-media">
                {eventsData.length > 3 ? (
                  <button onClick={toggleEvents}>SEE MORE</button>
                ) : null}
              </div>
            </div>
          </section>
        </div>
      </section>

      {/* Mobile code */}
      <section className="media-events-bg-mob mob-afflicate mt-5">
        <div className="container">
          <h2 className="media-pr-mob">Events</h2>
          <section>
            <div className="row">
              {eventsData
                .slice(0, showAllEvents ? eventsData.length : 4)
                .map((event) => (
                  <div className="" key={event.id}>
                    <EventItem
                      key={event.id}
                      title={event?.attributes?.Title}
                      eventLink={event?.attributes?.Media_Link}
                      imageSrc={siteUrl + event?.attributes?.Thumbnail_image?.data?.attributes?.url}
                    />
                  </div>
                ))}
            </div>

            <div className="media-see-btn">
              <div className="btn-style-media">
                {eventsData.length > 3 ? (
                  <button onClick={toggleEvents}>SEE MORE</button>
                ) : null}
              </div>


            </div>
          </section>
        </div>
      </section>

      {/* Desktop code */}
      {/* <section className="desk-afflicate marginMedia">
        <div className="container-blog">
          <h2 className="media-titlw">MEDIA COVERAGE</h2>
          <section className="media-lower">
            <div className="row">
              <div className="col-xl-12">
                <Slider
                  ref={sliderRef}
                  {...settings}
                  className="media-lower-container"
                >
                  {mediaCoverage
                    .slice(0, showMoreMedia ? mediaCoverage.length : initialMediaToShow)
                    .map((item, index) => (
                      <div key={item.id}>
                        <MediaItem
                          title={item.title}
                          description={item.description}
                          imageSrc={item.imageSrc}
                        />
                      </div>
                    ))}
                </Slider>
                <div className="pagination-button-container">
                  <button className="btn-previous" onClick={previousSlide}>
                    Previous
                  </button>
                  <button className="btn-next" onClick={nextSlide}>
                    Next
                  </button>
                </div>
                <div className="btn-style-media" style={{ paddingTop: 40 }}>
                  {showMoreMedia ? (
                    <button onClick={toggleShowMoreMedia}>SEE LESS</button>
                  ) : (
                    <button onClick={toggleShowMoreMedia}>SEE MORE</button>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section> */}

      {/* Mobile code */}
      {/* <section className="mob-afflicate marginMedia">
        <div className="container-blog">
          <h2 className="media-titlw">MEDIA COVERAGE</h2>
          <section className="media-lower">
            <div className="row">
              <div className="col-xl-12">
                <Slider
                  ref={sliderRef}
                  {...settings}
                  className="media-lower-container"
                >
                  {mediaCoverage
                    .slice(0, showMoreMedia ? mediaCoverage.length : initialMediaToShow)
                    .map((item, index) => (
                      <div key={item.id}>
                        <MediaMobileItem
                          title={item.title}
                          description={item.description}
                          imageSrc={item.imageSrc}
                        />
                      </div>
                    ))}
                </Slider>
                <div className="pagination-button-container">
                  <button className="btn-previous" onClick={previousSlide}>
                    Previous
                  </button>
                  <button className="btn-next" onClick={nextSlide}>
                    Next
                  </button>
                </div>
                <div className="btn-style-media" style={{ paddingTop: 40 }}>
                  {showMoreMedia ? (
                    <button onClick={toggleShowMoreMedia}>SEE LESS</button>
                  ) : (
                    <button onClick={toggleShowMoreMedia}>SEE MORE</button>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section> */}

      <Footer />
    </>
  );
}

export default MediaAndPR;
