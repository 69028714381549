import React from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import TabCovid from "./TabCovid";
import HepTab from "./HepTab";
import HepTabMobile from "./HepTabMobile";

function hepatitis() {
  return (
    <>
      <Header />
      <Helmet>
        <title>
          Comprehensive Guide to Shingles: Causes, Symptoms, Treatment |
          BridgeHealth
        </title>
        <meta
          name="description"
          content="Vaccination for Shingles at Bridge Health: Herpes Zoster, caused by the varicella-zoster virus. Discover symptoms, Vaccines, treatments, & solutions for effective management."
        />
      </Helmet>
      <section className="team-section padding backimg3">
        {/* About Section Two */}
        <section className="container-fluid">
          <div className="row align-items-center auto-container reverseFlex">
            <div className="col-lg-6">
              <div className="desk-top">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50 wrap-font mt-5">
                      <span className="headeingcolorblue wrap-shin font55">
                        Stay Hepatitis-Free!
                      </span>
                    </h2>
                    <h3 className="textleft wrap-text1 font44">
                      Get your Vaccine Today
                    </h3>
                  </div>
                  <div className="btn-box">
                    <ContactForm
                      buttonText="CONSULT / BOOK"
                      popupPosition="right"
                      className="btn-style-one"
                    />
                  </div>
                </div>
              </div>

              {/* Mobile Code  */}
              <div className="mob-mobile">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50 mt-5 uni-shingles">
                      <span className="headeingcolorblue">
                        Stay Hepatitis-Free!{" "}
                      </span>
                    </h2>
                    <h3 className="uni-covid">
                      Take your COVID Vaccine and stay protected.
                    </h3>
                  </div>
                  <div className="btn-box btn8">
                    <ContactForm
                      buttonText="CONSULT / BOOK"
                      popupPosition="right"
                      className="btn-style-one"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="imgright">
                <img
                  srcset={require("../../assets/images/shape/hepatitis.webp")}
                  alt=""
                  className="imgwidth wrap2-shi-img1 mobilehide"
                  rel="preload"
                />
                <img
                  srcset={require("../../assets/images/shape/hepmob.webp")}
                  alt=""
                  className="imgwidth wrap2-shi-img1 desktophide"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>
      </section>

      {/* WHAT IS COVID? */}
      {/* Desktop code  */}
      <section className="covid-third-sec">
        <div className="container-fluid covidbackImg">
          <div className="auto-container">
            <div className="marginTop80">
              <h2 className="header1">
                <span className="headeingcolorblack wrap-header1 wrap-header2">
                  WHAT IS{" "}
                </span>
                <span className="headeingcolorblue wrap-header1 wrap-header2">
                  {" "}
                  Hepatitis?
                </span>
              </h2>
            </div>
            <div>
              <p className="wrap-covid">
                Hepatitis refers to inflammation of the liver, often caused by
                viral infections. There are various types of hepatitis viruses,
                including A, B, C, D, and E. These viruses are spread through
                contaminated food or water (Hepatitis A and E), contact with
                infected blood or bodily fluids (Hepatitis B, C, and D), or
                through sexual contact. Symptoms of hepatitis can differ, but
                common symptoms include fatigue, jaundice (yellowing of the skin
                and eyes), abdominal pain, nausea, and vomiting. While some
                people recover from the virus on their own, certain types of
                hepatitis can become chronic and lead to severe liver damage and
                cancer.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Mobile code  */}
      <section className="covid-mob-sec">
        <div className="container">
          <div className="marginTop80">
            <h2 className="header1-covid">
              <span className="headeingcolorblack wrapper-mob">What is </span>
              <span className="headeingcolorblue wrapper-mob"> Hepatitis?</span>
            </h2>
          </div>
          <div>
            <p className="wrap-covid-mob">
              Hepatitis refers to inflammation of the liver, often caused by
              viral infections. There are various types of hepatitis viruses,
              including A, B, C, D, and E. These viruses are spread through
              contaminated food or water (Hepatitis A and E), contact with
              infected blood or bodily fluids (Hepatitis B, C, and D), or
              through sexual contact. Symptoms of hepatitis can differ, but
              common symptoms include fatigue, jaundice (yellowing of the skin
              and eyes), abdominal pain, nausea, and vomiting. While some people
              recover from the virus on their own, certain types of hepatitis
              can become chronic and lead to severe liver damage and cancer.
            </p>
          </div>
        </div>
      </section>
      {/* Desktop page code */}
      <section className="covid-four-sec">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-4">
              <h2 className="header-covid">
                <span className="headeingcolorblue wrap-header-covid">
                  350 Million+
                </span>
              </h2>
              <p className="covid-par">
                People are infected with either<br></br> hepatitis B or C
              </p>
            </div>
            <div className="col-lg-4">
              <h2 className="header-covid">
                <span className="headeingcolorblue wrap-header-covid">
                  300 Million +
                </span>
              </h2>
              <p className="covid-par">
                People suffer from chronic <br></br>hepatitis
              </p>
            </div>
            <div className="col-lg-4">
              <h2 className="header-covid">
                <span className="headeingcolorblue wrap-header-covid">
                  1.5 Million +
                </span>
              </h2>
              <p className="covid-par">
                New cases of hepatitis are <br></br>reported annually
              </p>
            </div>
          </div>

          <div className="btn-box text-center">
            <ContactForm
              buttonText="CONSULT / BOOK"
              popupPosition="right"
              className="btn-style-one"
            />
          </div>
        </div>
      </section>

      {/* Mobile code */}
      <section className="mobi-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="header-covid marginBtmText">
                <span className="headeingcolorblue wrap-header-covid-mob">
                  350 Million+
                </span>
              </h2>
              <p className="covid-par-mob">
                People are infected with either<br></br> hepatitis B or C
              </p>
            </div>
          </div>
          <div className="item-mob">
            <div className="row">
              <div className="col-6">
                <h2 className="header-covid marginBtmText">
                  <span className="headeingcolorblue wrap-header-covid-mob">
                    300 Million +
                  </span>
                </h2>
                <p className="covid-par-mob">
                  People suffer from chronic hepatitis
                </p>
              </div>
              <div className="col-6">
                <h2 className="header-covid marginBtmText">
                  <span className="headeingcolorblue wrap-header-covid-mob">
                    1.5 Million+
                  </span>
                </h2>
                <p className="covid-par-mob">
                  New cases of hepatitis are reported annually
                </p>
              </div>
            </div>
          </div>
          <div
            className="btn-box text-center"
            style={{ marginBottom: 55 + "px" }}
          >
            <ContactForm
              buttonText="CONSULT / BOOK"
              popupPosition="right"
              className="btn-style-one"
            />
          </div>
        </div>
      </section>

      {/* SYMPTOMS OF COVID */}
      {/* Desktop code  */}
      <section className="des-covid">
        <div className="auto-container">
          <div className="top-covid">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/hepatitis1.webp")}
                  alt=""
                  rel="preload"
                  className="imgwidth"
                />
              </div>
              <div className="col-lg-6">
                <div>
                  <div className="card wrap-inter-hep">
                    <div className="card-body">
                      <h2 className="header1">
                        <span className="headeingcolorblack wrap-header2-item">
                          SYMPTOMS OF
                        </span>
                        <span className="headeingcolorblue wrap-header2-item">
                          {" "}
                          Hepatitis
                        </span>
                      </h2>

                      <p className="card-text-partners1">
                        Early stages of hepatitis do not have noticeable
                        symptoms. Over time, symptoms like fatigue, jaundice
                        (yellowing of the skin and eyes), dark urine, pale
                        stools, abdominal pain or discomfort, nausea, vomiting,
                        and loss of appetite will arise. Some individuals might
                        also experience fever, muscle aches, and joint pain.
                        Abdominal swelling and general weakness might be
                        present, along with cognitive impairment in severe
                        cases.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Mobile code  */}
      <section className="wrapper-mobile">
        <div className="shi-wrap-mobile">
          <figure className="text-overlay-image">
            <img
              srcset={require("../../assets/images/shape/hepmob1.webp")}
              alt=""
              className="cov-item"
              rel="preload"
            />
          </figure>
          <div className="card wrap-inter-hep-mob">
            <div className="card-body">
              <h2 className="header18">
                <span className="headeingcolorblack wrap-header2-mob">
                  Symptoms of{" "}
                </span>
                <span className="headeingcolorblue wrap-header2-mob">
                  Hepatitis
                </span>
              </h2>

              <p className="card-text-par">
                Early stages of hepatitis do not have noticeable symptoms. Over
                time, symptoms like fatigue, jaundice (yellowing of the skin and
                eyes), dark urine, pale stools, abdominal pain or discomfort,
                nausea, vomiting, and loss of appetite will arise. Some
                individuals might also experience fever, muscle aches, and joint
                pain. Abdominal swelling and general weakness might be present,
                along with cognitive impairment in severe cases.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="desk-item backGroundRight mobilehide">
        <div className="container-fulid backimg2">
          <div className="container">
            <HepTab />
          </div>
        </div>
      </div>

      <div className="desk-item-covid backGroundRight d-lg-none">
        <div className="container-fulid backimg2">
          <div className="container">
            <HepTabMobile />
          </div>
        </div>
      </div>

      {/* MORE ABOUT SHINGLE */}
      <section>
        <div className="more-desktop">
          <div className="wrap-sites item-covid">
            <div className="wrap-img-sec7">
              <img
                srcset={require("../../assets/images/shape/hepatitis3.webp")}
                alt=""
                className="imgwidth3"
                rel="preload"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Mobile code */}
      <section className="mt-3">
        <div className="more-mobile">
          <div className="wrap-sites">
            <div className="wrap-img-sec7">
              <img
                srcset={require("../../assets/images/shape/hepmob3.webp")}
                alt=""
                className="imgwidth3"
                rel="preload"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="backFaqsRight" id="section-3">
        <div className="container-fulid backFaqsLeft">
          <div className="auto-container">
            <TabCovid />
          </div>
        </div>
      </div>
      <section style={{ marginBottom: 4 + "rem" }}>
        <div className="auto-container">
          <div className="row">
            {/*
                <!-- News Block One --> */}
            <div
              className="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div className="inner-box">
                <div className="image">
                  <Link to={"/#"}>
                    {" "}
                    <img
                      srcset={require("../../assets/images/shape/blog.webp")}
                      alt=""
                      rel="preload"
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to={""}>What Does Your GI Score Indicate?</Link>
                  </h3>
                  <div className="text">
                    As a child, you were likely cautioned to limit your intake
                    of sweets. Remember the warning not to go
                  </div>
                </div>
              </div>
            </div>
            {/*
                <!-- News Block One --> */}
            <div
              className="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div className="inner-box">
                <div className="image">
                  <Link to={"/#"}>
                    {" "}
                    <img
                      srcset={require("../../assets/images/shape/blog1.webp")}
                      alt=""
                      rel="preload"
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to={""}>Myths And Facts About GI Foods</Link>
                  </h3>
                  <div className="text">
                    Carbohydrates have been villainized in recent years as the
                    culprit for weight gain and even chronic diseases
                  </div>
                </div>
              </div>
            </div>
            {/*
                <!-- News Block One --> */}
            <div
              className="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div className="inner-box">
                <div className="image">
                  <Link to={"/#"}>
                    {" "}
                    <img
                      srcset={require("../../assets/images/shape/blog3.webp")}
                      alt=""
                      rel="preload"
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to={""}>
                      Everything you need to know about Glycemic Index
                    </Link>
                  </h3>
                  <div className="text">
                    Even with the world becoming more health-conscious, diseases
                    seem to be on the rise. While you frantically
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default hepatitis;
