import React from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import FormButton from "../element/component/FormButton";
function hpv_vaccines() {
  return (
    <>
      <Header />
      <Helmet>
        <title>
          Comprehensive Guide to Shingles: Causes, Symptoms, Treatment |
          BridgeHealth
        </title>
        <meta
          name="description"
          content="Vaccination for Shingles at Bridge Health: Herpes Zoster, caused by the varicella-zoster virus. Discover symptoms, Vaccines, treatments, & solutions for effective management."
        />
      </Helmet>
      <section className="team-section padding backimg3 image-mobile-terms">
        {/* About Section Two */}
        <section className="container-fluid">
          <div className="row align-items-center auto-container reverseFlex">
            <div className="col-lg-6">
              <div className="desk-top">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50 wrap-hpv">
                      <span className="headeingcolorblue wrap-shin font38">
                        Protect yourself from Cervical Cancer{" "}
                      </span>
                    </h2>
                    <h3 className="textleft wrap-text1 font41">
                      Get HPV Vaccinated at our JP Nagar Clinic or Opt for
                      At-Home Service Across Bangalore
                    </h3>
                  </div>
                  <FormButton ButtonName={'speak to our team'} />
                </div>
              </div>

              {/* Mobile Code */}
              <div className="mob-mobile">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50 mt-5 uni-shingles">
                      <span className="headeingcolorblue">
                        STAY AWAY FROM HPV{" "}
                      </span>
                    </h2>
                    <h3 className="uni-covid">
                      Getting vaccinated is the key!
                    </h3>
                  </div>                         
                      <FormButton ButtonName={'CONSULT / BOOK'} />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="imgright">
                <img
                  src={require("../../assets/images/shape/hpv.webp")}
                  alt=""
                  className="imgwidth wrap2-shi-img1 mobilehide"
                  rel="preload"
                />
                <img
                  src={require("../../assets/images/shape/hpv11.webp")}
                  alt=""
                  className="imgwidth wrap2-shi-img1 desktophide"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>
      </section>

      {/* Desktop Code */}

      <section className="hpv-section mt-5">
        <div className="container-fluid bgsection1">
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-6">
                <div className="imgright-second">
                  <img
                    srcSet={require("../../assets/images/shape/hpv6.webp")}
                    alt=""
                    rel="preload"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="wrapper-hpv">
                  <h2 className="header1">
                    <span className="headeingcolorblue wrap-header1 wrap-header3">
                      {" "}
                      HPV{" "}
                    </span>
                    <span className="headeingcolorblack wrap-header1 wrap-header3">
                      Vaccines
                    </span>
                  </h2>
                  <p className="wrap-pagr-hpv">
                    Available HPV vaccines protect against either two, four, or
                    nine types of HPV. All HPV vaccines protect against at least
                    HPV types 16 and 18, which cause the greatest risk of
                    cervical cancer. It is estimated that HPV vaccines may
                    prevent 70% of cervical cancer, 80% of anal cancer, 60% of
                    vaginal cancer, 40% of vulvar cancer, and show more than 90%
                    efficacy in preventing HPV-positive oropharyngeal cancers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Mobile Code */}
      <section className="hpv-mobile-sec mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div>
                <img
                  srcSet={require("../../assets/images/shape/hpv12.webp")}
                  alt=""
                  className="mt-4"
                  rel="preload"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="wrapper-hpv-mob">
                <h2 className="header1-mob">
                  <span className="headeingcolorblue wrap-header1 wrap-header3-mob">
                    {" "}
                    HPV{" "}
                  </span>
                  <span className="headeingcolorblack wrap-header1 wrap-header3-mob">
                    Vaccines
                  </span>
                </h2>
                <p className="wrap-pagr-hpv-mob">
                  Available HPV vaccines protect against either two, four, or
                  nine types of HPV. All HPV vaccines protect against at least
                  HPV types 16 and 18, which cause the greatest risk of cervical
                  cancer. It is estimated that HPV vaccines may prevent 70% of
                  cervical cancer, 80% of anal cancer, 60% of vaginal cancer,
                  40% of vulvar cancer, and show more than 90% efficacy in
                  preventing HPV-positive oropharyngeal cancers.{" "}
                </p>
              </div>
            </div>
          </div>        
        </div>
      </section>

      {/* WHAT IS COVID? */}

      {/* Desktop page code */}
      <section className="covid-four-sec margin8top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <h2 className="header-covid">
                <span className="headeingcolorblue-des-mob wrap-header-hpv">
                  99.7%
                </span>
              </h2>
              <p className="hpv-par">
                of cervical cancer cases are caused by persistent genital
                high-risk human papillomavirus (HPV) infection.
              </p>
            </div>
            <div className="col-lg-4">
              <h2 className="header-covid">
                <span className="headeingcolorblue-des-mob wrap-header-hpv">
                  80-90%
                </span>
              </h2>
              <p className="hpv-par">
                of sexually active women <br></br>will get an HPV infection in{" "}
                <br></br>their lifetime
              </p>
            </div>
            <div className="col-lg-4">
              <h2 className="header-covid">
                <span className="headeingcolorblue-des-mob wrap-header-hpv">
                  95%
                </span>
              </h2>
              <p className="hpv-par">
                of cervical cancer cases<br></br> are caused by HPV
              </p>
            </div>
          </div>

          <div className="btn-box text-center">
          <FormButton ButtonName={'CONSULT / BOOK'} />
          </div>
        </div>
      </section>
      <section className="contact-section">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              {/* <h2 className='header1'>
                        <span className='headeingcolorblue'> Stay Informed, Stay Shielded</span>
                    </h2> */}
              <ul className="listiconhpv">
                <li className="header1 hpv-marleft">
                  <span className="headeingcolorblue">
                    {" "}
                    Stay Informed, Stay Shielded
                  </span>
                </li>
                <li>
                  High-risk HPV types persist for years, increasing the risk of
                  cervical, anal, and throat cancers.{" "}
                </li>

                <li>
                  HPV infections often have no symptoms but may cause visible
                  genital warts in the form of small growths or clusters on the
                  genital or anal areas.
                </li>

                <li>
                  Mitigate HPV risks through regular medical check-ups,
                  practicing safe sex, and getting vaccinated.{" "}
                </li>
              </ul>
            </div>
            <div className="col-lg-5">
              <img
                srcSet={require("../../assets/images/shape/hpvthumb.webp")}
                alt=""
                className="widthimg imgFloatRight borderRadius"
                rel="preload"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Mobile code */}
      <section className="mobi-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="header-covid-mob">
                <span className="headeingcolorblue-des-mob wrap-header-hpv-mob">
                  99.7%
                </span>
              </h2>
              <p className="hpv-par-mob">
                of cervical cancer cases are caused by persistent genital
                high-risk human papillomavirus (HPV) infection.
              </p>
            </div>
          </div>
          <div className="item-mob">
            <div className="row">
              <div className="col-md-12">
                <h2 className="header-covid">
                  <span className="headeingcolorblue-des-mob wrap-header-hpv-mob">
                    80-90%
                  </span>
                </h2>
                <p className="hpv-par-mob">
                  of sexually active women will get an HPV infection in their
                  lifetime
                </p>
              </div>
              <div className="col-md-12">
                <h2 className="header-covid">
                  <span className="headeingcolorblue-des-mob wrap-header-hpv-mob">
                    95%
                  </span>
                </h2>
                <p className="hpv-par-mob">
                  of cervical cancer cases are caused by HPV
                </p>
              </div>
            </div>
          </div>
          <div
            className="btn-box text-center"
            style={{ marginBottom: 55 + "px" }}
          >
            <FormButton ButtonName={'CONSULT / BOOK'} />
          </div>
        </div>
      </section>

      {/* SYMPTOMS OF COVID */}

      {/* <div className='backGroundRight'>
        <div className="desk-item backimg2 mobilehide">
            <div className='container-fulid'>
                <div className='container'>
                    <HpvTab />
                </div>
            </div>
        </div>
    </div> */}
      {/* About Section Two */}
      <section>
        <div className="auto-container">
          <div className="row marginbutton30">
            {/*
                <!-- Team Block One --> */}
            <div className="col-lg-3 team-block-one">
              <div className="expanded-text grow bozhover">
                <p className="text">
                  <div>
                    <img
                      src={require("../../assets/images/shape/image 92.webp")}
                      alt=""
                      rel="preload"
                    />
                  </div>
                  <div className="short-name expertise">Cervical Cancer</div>
                  <div className="longer-name">
                    <h1 className="boxtitle">Cervical Cancer</h1>
                  </div>
                </p>
              </div>
            </div>
            {/*
                <!-- Team Block One --> */}
            <div className="col-lg-3 team-block-one">
              <div className="expanded-text grow bozhover">
                <div className="text">
                  <img
                    src={require("../../assets/images/shape/image 94.webp")}
                    alt=""
                    rel="preload"
                  />

                  <div className="short-name expertise">Anal cancers </div>
                  <div className="longer-name">
                    <h1 className="boxtitle">Anal cancers </h1>
                  </div>
                </div>
              </div>
            </div>
            {/*
                <!-- Team Block One --> */}
            <div className="col-lg-3 team-block-one">
              <div className="expanded-text grow bozhover">
                <div className="text">
                  <img
                    src={require("../../assets/images/shape/image 93.webp")}
                    alt=""
                    rel="preload"
                  />

                  <div className="short-name expertise">Vulvar cancers </div>
                  <div className="longer-name">
                    <h1 className="boxtitle">Vulvar cancers </h1>
                  </div>
                </div>
              </div>
            </div>
            {/*
                <!-- Team Block One --> */}
            <div className="col-lg-3 team-block-one">
              <div className="expanded-text grow bozhover">
                <div className="text">
                  <img
                    src={require("../../assets/images/shape/image 95.webp")}
                    alt=""
                    rel="preload"
                  />

                  <div className="short-name expertise">Vaginal cancers</div>
                  <div className="longer-name">
                    <h1 className="boxtitle">Vaginal cancers</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row marginbutton30">
            <div className="col-lg-1"></div>
            <div className="col-lg-3 team-block-one">
              <div className="expanded-text grow bozhover">
                <div className="text">
                  <img
                    src={require("../../assets/images/shape/image 96.webp")}
                    alt=""
                    rel="preload"
                  />

                  <div className="short-name expertise">
                    Head and neck cancers{" "}
                  </div>
                  <div className="longer-name">
                    <h1 className="boxtitle">Head and neck cancers </h1>
                  </div>
                </div>
              </div>
            </div>
            {/*
                <!-- Team Block One --> */}
            <div className="col-lg-3 team-block-one">
              <div className="expanded-text grow bozhover">
                <div className="text">
                  <img
                    src={require("../../assets/images/shape/image 97.webp")}
                    alt=""
                    rel="preload"
                  />

                  <div className="short-name expertise">Genital warts</div>
                  <div className="longer-name">
                    <h1 className="boxtitle">Genital warts</h1>
                  </div>
                </div>
              </div>
            </div>
            {/*
                <!-- Team Block One --> */}
            <div className="col-lg-3 team-block-one">
              <div className="expanded-text grow bozhover">
                <div className="text">
                  <img
                    src={require("../../assets/images/shape/image 98.webp")}
                    alt=""
                    rel="preload"
                  />

                  <div className="short-name expertise">
                    Respiratory papillomatosis
                  </div>
                  <div className="longer-name">
                    <h1 className="boxtitle"> Respiratory papillomatosis</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1.5"></div>
          </div>
        </div>
      </section>

      {/* MORE ABOUT SHINGLE */}
      <section>
        <div className="more-desktop">
          <div className="wrap-sites item-covid">
            <div className="wrap-img-sec7">
              <img
                src={require("../../assets/images/shape/hpv3.webp")}
                alt=""
                className="imgwidth3"
                rel="preload"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Mobile code */}
      <section className="mt-3">
        <div className="more-mobile">
          <div className="wrap-sites">
            <div className="wrap-img-sec7">
              <img
                src={require("../../assets/images/shape/hpv3.webp")}
                alt=""
                className="imgwidth3"
                rel="preload"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="backFaqsRight" id="section-3">
        <div className="container-fulid backFaqsLeft">
          <div className="auto-container">{/* <TabInfluenza /> */}</div>
        </div>
      </div>
      <section style={{ marginBottom: 4 + "rem" }}>
        <div className="auto-container">
          <div className="row">
            {/*
                <!-- News Block One --> */}
            <div
              className="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div className="inner-box">
                <div className="image">
                  <Link to={"/#"}>
                    {" "}
                    <img
                      src={require("../../assets/images/shape/blog.webp")}
                      alt=""
                      rel="preload"
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  {/* <div className="category">Blog heading</div> */}

                  <h3>
                    <Link to={""}>What Does Your GI Score Indicate?</Link>
                  </h3>
                  <div className="text">
                    As a child, you were likely cautioned to limit your intake
                    of sweets. Remember the warning not to go
                  </div>
                </div>
              </div>
            </div>
            {/*
                <!-- News Block One --> */}
            <div
              className="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div className="inner-box">
                <div className="image">
                  <Link to={"/#"}>
                    {" "}
                    <img
                      src={require("../../assets/images/shape/blog1.webp")}
                      alt=""
                      rel="preload"
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  {/* <div className="category">Blog heading</div> */}

                  <h3>
                    <Link to={""}>Myths And Facts About GI Foods</Link>
                  </h3>
                  <div className="text">
                    Carbohydrates have been villainized in recent years as the
                    culprit for weight gain and even chronic diseases
                  </div>
                </div>
              </div>
              {/* <button className="theme-btn btn-style-one btnblog" type="submit" name="submit-form"><span
                            className="btn-title btnblog">READ ALL BLOGS</span></button> */}
            </div>
            {/*
                <!-- News Block One --> */}
            <div
              className="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div className="inner-box">
                <div className="image">
                  <Link to={"/#"}>
                    {" "}
                    <img
                      src={require("../../assets/images/shape/blog3.webp")}
                      alt=""
                      rel="preload"
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to={""}>
                      Everything you need to know about Glycemic Index
                    </Link>
                  </h3>
                  <div className="text">
                    Even with the world becoming more health-conscious, diseases
                    seem to be on the rise. While you frantically
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default hpv_vaccines;
