import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import CaseStudy from "../element/case-study";
import { Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";

class ourservice extends Component {
  state = {
    isOpen: false,
  };
  state1 = {
    isOpen1: false,
  };
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {
    document.title = "Our Services | Bridge Health";
  }

  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Best preventive healthcare services in Bangalore | Call for details
            -Bridge Health
          </title>
          <meta
            name="description"
            content="Explore Bridge health comprehensive range of services designed to meet your specific needs. From Doctor consultation, Lab test, health checks, chronic care programs, Personal annual health plans to home care & more.
          "
          />
        </Helmet>
        <section className="team-section padding d-lg-none">
          {/* About Section Two */}
          <section className="auto-container ourservice serviceMarginTop">
            <div className="row align-items-center ">
              <div className="col-lg-6">
                <div className="content-box boxsha marginleft8">
                  <div className=" text-center textleft">
                    <h2 className="textleft">
                      <span className="headeingcolorblue">Our Services</span>
                    </h2>
                  </div>
                  <div>
                    <div className="text">
                      With our suite of personalised preventive health plans,
                      you and your loved ones can enjoy the benefits of a
                      healthier tomorrow. It’s time to #BridgeTheGap between you
                      and your health.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      srcset={require("../../assets/images/shape/service222.webp")}
                      alt=""
                      className="imgwidth margin-left97"
                      rel="preload"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section className="team-section padding mobilehide">
          {/* About Section Two */}
          <section className="auto-container ourservice serviceMarginTop">
            <div className="row align-items-center ">
              <div className="col-lg-6 banner-image">
                <div className="content-box boxsha marginleft8">
                  <div className=" text-center textleft">
                    <h2 className="textleft">
                      <span className="headeingcolorblue">Our Services</span>
                    </h2>
                  </div>
                  <div>
                    <div className="text">
                      With our suite of personalised preventive health plans,
                      you and your loved ones can enjoy the benefits of a
                      healthier tomorrow. It’s time to #BridgeTheGap between you
                      and your health.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      srcset={require("../../assets/images/shape/service222.webp")}
                      alt=""
                      className="imgwidth margin-left97"
                      rel="preload"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        {/* <!-- Gallery Section --> */}
        <section className="gallery-section ourservice1">
          <section>
            <div className="auto-container">
              <div className="row">
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3 team-block-one wow fadeInUp margintop100">
                  <div onClick={this.openModal}>
                    <div className="expanded-text grow bozhover">
                      <p className="text">
                        <img
                          srcset={require("../../assets/images/shape/doctor.webp")}
                          alt=""
                          rel="preload"
                        />
                        <div className="short-name expertise">
                          Annual Preventive<br></br> Health Plans
                        </div>
                        <div className="longer-name">
                          <h1 className="boxtitle">
                            Annual Preventive<br></br> Health Plans
                          </h1>
                          No matter what you need, there’s always a plan for
                          you. Choose from a selection of Annual Preventive
                          Health plans and sign up for better health.
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 team-block-one wow fadeInUp">
                  <div>
                    <CaseStudy />
                  </div>
                </div>

                <div className="col-lg-3 colz team-block-one wow fadeInUp margontop50">
                  <div className="expanded-text grow bozhover">
                    <p className="text">
                      <a
                        href={"/comprehensive-health-checks"}
                        title="Comprehensive health Checks"
                      >
                        <img
                          srcset={require("../../assets/images/shape/s2.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                      <div className="short-name expertise">
                        Comprehensive<br></br> Health Checks
                      </div>
                      <div className="longer-name">
                        {" "}
                        <h1 className="boxtitle">
                          Comprehensive <br></br>Health Checks
                        </h1>
                        Prevention is better than cure. With advanced
                        technology, we are able to understand your entire health
                        profile and help you prevent illnesses. Book your
                        Comprehensive Health Check today.
                      </div>
                    </p>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3 colz team-block-one wow fadeInUp margin100">
                  <div className="expanded-text grow bozhover">
                    <p className="text">
                      <a href={"/doctor-consults"} title="Doctor Consults">
                        <img
                          srcset={require("../../assets/images/shape/k3.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                      <div className="short-name expertise">
                        Doctor <br></br>Consults
                      </div>
                      <div className="longer-name">
                        <h1 className="boxtitle">
                          Doctor <br></br>Consults
                        </h1>
                        Getting an appointment with your doctor at your nearest
                        clinic can be burdensome. We simplify the process and
                        allow you to consult with our experts whenever,
                        wherever.
                      </div>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3 team-block-one wow fadeInUp margintop100">
                  <div className="expanded-text grow bozhover">
                    <p className="text">
                      <a
                        href={"/psychological-health"}
                        title="Psychological healths"
                      >
                        <img
                          srcset={require("../../assets/images/shape/k2.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                      <div className="short-name expertise">
                        Psychologist Consults
                      </div>
                      <div className="longer-name">
                        <h1 className="boxtitle">Psychologist Consults</h1>
                        Mental health is extremely important in the current
                        times due to increased work and social pressures. Talk
                        to our expert clinical psychologists.
                      </div>
                    </p>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3 team-block-one wow fadeInUp">
                  <div className="expanded-text grow bozhover">
                    <p className="text">
                      <a href={"/dental-consults"} title="Dental Consults">
                        <img
                          srcset={require("../../assets/images/shape/s5.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                      <div className="short-name expertise">
                        Dental Consults
                      </div>
                      <div className="longer-name">
                        <h1 className="boxtitle">Dental Consults</h1>
                        Most people neglect their oral health which can be
                        disastrous for their overall health. Book your dental
                        consultation with us today and smile with confidence.
                      </div>
                    </p>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3 coly team-block-one wow fadeInUp margontop50">
                  <div className="expanded-text grow bozhover">
                    <p className="text">
                      <a href={"/dermatology"} title="Dermatology">
                        <img
                          srcset={require("../../assets/images/shape/s7.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                      <div className="short-name expertise">Dermatology</div>
                      <div className="longer-name">
                        {" "}
                        <h1 className="boxtitle">Dermatology</h1>
                        Contrary to popular belief, don’t just cater to the
                        needs of fashion models. Our hair, skin, and nails
                        deserve to be treated well. Talk to our dermatologists
                        today.
                      </div>
                    </p>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3 coly team-block-one wow fadeInUp margin100">
                  <div className="expanded-text grow bozhover">
                    <p className="text">
                      <a href={"/ophthalmologist"} title="Ophthalmologist">
                        <img
                          srcset={require("../../assets/images/shape/s8.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                      <div className="short-name expertise">Ophthalmology</div>
                      <div className="longer-name">
                        <h1 className="boxtitle">Ophthalmology</h1>
                        Our eyesight is a precious gift which allows us to see
                        the beauty of the world. We need to take care of our
                        eyes to prevent any eye-related illnesses. Meet our
                        ophthalmologists today.
                      </div>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3 team-block-one wow fadeInUp margintop100">
                  <div className="expanded-text grow bozhover">
                    <p className="text">
                      <a href={"/physio-therapy"} title="Physio Therapy">
                        <img
                          srcset={require("../../assets/images/shape/s9.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                      <div className="short-name expertise">Physiotherapy</div>
                      <div className="longer-name">
                        <h1 className="boxtitle">Physiotherapy</h1>
                        In life, we take our ability to move around freely for
                        granted. Due to injuries or certain illnesses, our
                        movement gets affected. Schedule a physiotherapy session
                        with us and move with ease.
                      </div>
                    </p>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3 team-block-one wow fadeInUp">
                  <div className="expanded-text grow bozhover">
                    <p className="text">
                      <a href={"/elderly-care"} title="Elderly Care">
                        <img
                          srcset={require("../../assets/images/shape/s10.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                      <div className="short-name expertise">Elderly Care</div>
                      <div className="longer-name">
                        <h1 className="boxtitle">Elderly Care</h1>
                        Growing old is a natural part of our lives. As we age,
                        our physical abilities gradually get compromised and we
                        need assistance. Discover our elderly care services.
                      </div>
                    </p>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3 colx team-block-one wow fadeInUp margontop50">
                  <div className="expanded-text grow bozhover">
                    <p className="text">
                      <a href={"/lab-tests"} title="Lab Tests">
                        <img
                          srcset={require("../../assets/images/shape/s11.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                      <div className="short-name expertise">Lab Tests</div>
                      <div className="longer-name">
                        {" "}
                        <h1 className="boxtitle">Lab Tests</h1>
                        The key to an effective diagnosis is effective testing.
                        Advancements in lab testing have allowed us to
                        accurately determine illnesses and provide treatment
                        plans. Discover our state-of-the-art lab facilities.
                      </div>
                    </p>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3 colx team-block-one wow fadeInUp margin100">
                  <div className="expanded-text grow bozhover">
                    <p className="text">
                      <a
                        href={"/diet-and-nutrition"}
                        title="Diet And Nutrition"
                      >
                        <img
                          srcset={require("../../assets/images/shape/s12.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                      <div className="short-name expertise">
                        Diet and Nutrition
                      </div>
                      <div className="longer-name">
                        {" "}
                        <h1 className="boxtitle">Diet and Nutrition</h1>A
                        healthy diet is the basis for good health. After all,
                        you are what you eat. Find out how our expert clinical
                        nutritionists help you eat healthy to stay healthy.
                      </div>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3 team-block-one wow fadeInUp margintop100">
                  <div className="expanded-text grow bozhover">
                    <p className="text">
                      <a href={"/yoga-assessment"} title="Yoga Assesments">
                        <img
                          srcset={require("../../assets/images/shape/s13.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                      <div className="short-name expertise">
                        Yoga Assessment
                      </div>
                      <div className="longer-name">
                        <h1 className="boxtitle">Yoga Assessment</h1>
                        Yoga has been a part of our culture for centuries. The
                        focus on harmony of mind, body, and spirit has been
                        scientifically proven to have positive effects on
                        health. Talk to our yoga therapists.
                      </div>
                    </p>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3 team-block-one wow fadeInUp">
                  <div className="expanded-text grow bozhover">
                    <p className="text">
                      <a href={"/fitness-assessment"} title="Fitness Assesment">
                        <img
                          srcset={require("../../assets/images/shape/s14.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                      <div className="short-name expertise">
                        Fitness Assessment
                      </div>
                      <div className="longer-name">
                        <h1 className="boxtitle">Fitness Assessment</h1>
                        For good health, exercise is important. We’ve taken this
                        to another level and have adopted a clinical fact-based
                        approach with our unique fitness program. Discover our
                        pro-fit program.
                      </div>
                    </p>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3 team-block-one wow fadeInUp margontop50">
                  <div className="expanded-text grow bozhover">
                    <p className="text">
                      <a href={"/pharmacy"} title="Pharmacy">
                        <img
                          srcset={require("../../assets/images/shape/s15.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                      <div className="short-name expertise">Pharmacy</div>
                      <div className="longer-name">
                        <h1 className="boxtitle">Pharmacy</h1>
                        It’s important to get the medicines you need at the
                        right time. At Bridge Health, we have a fully-stocked
                        pharmacy which is built to meet your needs. Discover our
                        pharmacy today.
                      </div>
                    </p>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3 team-block-one wow fadeInUp margin100">
                  <div className="expanded-text grow bozhover">
                    <p className="text">
                      <a href={"/nursing-at-home"} title="Nursing At Home">
                        <img
                          srcset={require("../../assets/images/shape/s16.webp")}
                          alt=""
                        />
                      </a>
                      <div className="short-name expertise">
                        Nursing At Home
                      </div>
                      <div className="longer-name">
                        <h1 className="boxtitle">Nursing At Home</h1>
                        With nursing services provided directly to you and your
                        loved ones at the comfort of your own home, get the
                        timely care and assistance you need at all times.
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section>
          <div className="image-eight imgpa">
            <img
              srcset={require("../../assets/images/shape/scan.webp")}
              alt=""
              rel="preload"
              style={{
                marginLeft: 6 + "%",
                width: 126 + "px",
                height: 127 + "px",
                marginBottom: 3 + "rem",
              }}
            />
          </div>
        </section>

        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <a
              href={"/personal-membership-program"}
              title="Personel Membership Program"
            >
              <span>
                <img
                  className="width50"
                  srcset={require("../../assets/images/shape/popup1.webp")}
                  alt=""
                  rel="preload"
                />
                <p className="width50p">Personal Membership Programme</p>
              </span>
            </a>
            <a
              href={"/corporate-membership-program"}
              title="Corporate membership Program"
            >
              <span>
                <img
                  className="width50"
                  srcset={require("../../assets/images/shape/popup2.webp")}
                  alt=""
                  rel="preload"
                />
                <p className="width50p">Corporate Membership Programme</p>
              </span>
            </a>
          </Modal.Header>
        </Modal>
        <Footer />
      </>
    );
  }
}
export default ourservice;
