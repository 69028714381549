import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

class InfluenzaTab extends Component {
  render() {
    return (
      <>
        <section className="container">
          <div className="shi-desk">
            <div className="sec-title text-center textleft">
              <h2 className="textleft fontSize50 wrap-sites-poss">
                <span className="headeingcolorblack">RISK FACTORS </span>
                <span className="headeingcolorblue"> OF INFLUENZA</span>
              </h2>
            </div>
          </div>
          {/* Mobile Code  */}
          <div className="shi-mob">
            <div className="sec-title text-center textleft">
              <h2 className="textleft fontSize50 wrap-sites-poss shi1-mob1">
                <span className="headeingcolorblack">RISK FACTORS </span>
                <span className="headeingcolorblue"> OF INFLUENZA</span>
              </h2>
            </div>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={6}>
                <Nav variant="pills" className="flex-column text-center">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Pnuemonia</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Bronchitis</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">Sinus Infections</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="four">Ear Infections</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="five">
                      Exacerbation of Chronic Health Conditions
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="six">
                      Myocarditis and Pericarditis
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={6}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div id="image_para_section">
                      <img
                        srcset={require("../../assets/images/shape/Pnuemonia.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                      <p className="paragraph_after_image mt-2">
                        Influenza can weaken the respiratory system, making it
                        easier for bacteria to cause a secondary infection in
                        the lungs which leads to pneumonia. This is especially
                        serious in older adults and people with weakened immune
                        systems.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div id="image_para_section">
                      <img
                        srcset={require("../../assets/images/shape/Bronchitis.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                      <p className="paragraph_after_image mt-2">
                        Inflammation of the bronchial tubes, often caused by viral
                        infections like the flu, can lead to persistent
                        coughing and difficulty breathing.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <div id="image_para_section">
                      <img
                        srcset={require("../../assets/images/shape/Sinus.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                      <p className="paragraph_after_image mt-2">
                        Influenza can cause inflammation in the sinuses,
                        leading to sinusitis. This leads to facial pain due to
                        pressure on the sinus areas (especially the head and the eyes),
                        nasal congestion, and a thick nasal discharge.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="four">
                    <div id="image_para_section">
                      <img
                        srcset={require("../../assets/images/shape/Ear infection.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                      <p className="paragraph_after_image mt-2">
                        Particularly common in young children, the flu can lead to ear infections (otitis media) due
                        to the close connection between the respiratory and ear passages.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="five">
                    <div id="image_para_section">
                      <img
                        srcset={require("../../assets/images/shape/Exacerbation.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                      <p className="paragraph_after_image mt-2">
                        Influenza can worsen existing chronic conditions such as asthma,
                        diabetes, or heart disease, increasing the risk of complications and hospitalization.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="six">
                    <div id="image_para_section">
                      <img
                        srcset={require("../../assets/images/shape/Myocarditis.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                      <p className="paragraph_after_image mt-2">
                        These are inflammations of the heart muscle or the lining around the heart,
                        which can occur as rare but severe complications of influenza, particularly in younger.
                      </p>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </section>
      </>
    );
  }
}
export default InfluenzaTab;
