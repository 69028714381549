import React, { useState } from 'react';
import IndiaStatesMap from 'react-india-states-map';


const IndianMapWithPointers = () => {
  const [hoveredCapital, setHoveredCapital] = useState(null);

  const stateCapitals = [
    { state: 'Andhra Pradesh', capital: 'Amaravati', coordinates: [515, 575] },
    { state: 'Arunachal Pradesh', capital: 'Itanagar', coordinates: [730, 350] },
    { state: 'Assam', capital: 'Dispur', coordinates: [650, 425] },
    { state: 'Bihar', capital: 'Patna', coordinates: [510, 370] },
    { state: 'Chhattisgarh', capital: 'Raipur', coordinates: [440, 430] },
    { state: 'Goa', capital: 'Panaji', coordinates: [300, 635] },
    { state: 'Gujarat', capital: 'Gandhinagar', coordinates: [280, 460] },
    { state: 'Haryana', capital: 'Chandigarh', coordinates: [270, 330] },
    { state: 'Himachal Pradesh', capital: 'Shimla', coordinates: [285, 245] },
    { state: 'Jharkhand', capital: 'Ranchi', coordinates: [480, 440] },
    { state: 'Karnataka', capital: 'Bangalore', coordinates: [400, 650] },
    { state: 'Kerala', capital: 'Thiruvananthapuram', coordinates: [320, 760] },
    { state: 'Madhya Pradesh', capital: 'Bhopal', coordinates: [410, 375] },
    { state: 'Maharashtra', capital: 'Mumbai', coordinates: [250, 570] },
    { state: 'Manipur', capital: 'Imphal', coordinates: [635, 360] },
    { state: 'Meghalaya', capital: 'Shillong', coordinates: [630, 280] },
    { state: 'Mizoram', capital: 'Aizawl', coordinates: [735, 280] },
    { state: 'Nagaland', capital: 'Kohima', coordinates: [720, 330] },
    { state: 'Odisha', capital: 'Bhubaneswar', coordinates: [550, 480] },
    { state: 'Punjab', capital: 'Chandigarh', coordinates: [280, 300] },
    { state: 'Rajasthan', capital: 'Jaipur', coordinates: [340, 520] },
    { state: 'Sikkim', capital: 'Gangtok', coordinates: [490, 320] },
    { state: 'Tamil Nadu', capital: 'Chennai', coordinates: [465, 620] },
    { state: 'Telangana', capital: 'Hyderabad', coordinates: [475, 570] },
    { state: 'Tripura', capital: 'Agartala', coordinates: [655, 400] },
    { state: 'Uttar Pradesh', capital: 'Lucknow', coordinates: [440, 340] },
    { state: 'Uttarakhand', capital: 'Dehradun', coordinates: [350, 290] },
    { state: 'West Bengal', capital: 'Kolkata', coordinates: [585, 550] },
    // Union Territories
    { state: 'Andaman and Nicobar Islands', capital: 'Port Blair', coordinates: [600, 650] },
    { state: 'Chandigarh', capital: 'Chandigarh', coordinates: [285, 335] },
    { state: 'Dadra and Nagar Haveli and Daman and Diu', capital: 'Daman', coordinates: [260, 520] },
    { state: 'Lakshadweep', capital: 'Kavaratti', coordinates: [700, 750] },
    { state: 'Delhi', capital: 'New Delhi', coordinates: [415, 380] },
    { state: 'Puducherry', capital: 'Puducherry', coordinates: [525, 620] },
  ];


  const handleHover = (capital) => {
    setHoveredCapital(capital);
  };

  const handleLeave = () => {
    setHoveredCapital(null);
  };


  return (
    <div className="container-blog">
      <h2 className="aff-titlw">Bridgehealth Partners</h2>
      <section>
        <div className="indian-map-container">
          <IndiaStatesMap customElements={stateCapitals.map((capital) =>
            <div
              key={capital.state}
              style={{ top: `${capital.coordinates[1]}px`, left: `${capital.coordinates[0]}px` }}
              onMouseEnter={() => handleHover(capital.capital)}
              onMouseLeave={handleLeave}
            />
          )} />
          {hoveredCapital && (
            <div className="capital-name">{hoveredCapital}</div>
          )}
        </div>
      </section>
    </div>
  );
};

export default IndianMapWithPointers;
