import React from "react";

const EventMobileItem = ({ title, description, imageSrc }) => {
  return (
    <>
      <div>
        <div>
          <div className="event-item1">
            <img
              srcSet={imageSrc}
              alt={title}
              className="med-image"
              rel="preload"
            />
            <h3 className="med-heading">{title}</h3>
            <p className="med-pagr">{description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventMobileItem;
