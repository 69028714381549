import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import SubscribeForm from "../element/subscribe-form";

class Footer extends Component {
  state = {
    isOpen: false,
    submitted: false,
  };
  state1 = {
    isOpen1: false,
  };
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      phone: "",
      errors: {
        username: "",
        email: "",
        phone: "",
      },
      submitted: false,
    };
  }

  closePopup = () => {
    this.setState({ submitted: false, isOpen: false });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const errors = { ...this.state.errors };
    errors[name] = "";

    this.setState({
      [name]: value,
      errors,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { username, phone } = this.state;
    const errors = {};

    // Validate username
    if (username.trim() === "") {
      errors.username = "Name is required";
    }

    // Validate email
    // if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
    //     errors.email = 'Invalid email format';
    // }

    // Validate phone
    if (!phone.match(/^\d{10}$/)) {
      errors.phone = "Phone number must be 10 digits";
    }

    // Update the state with the errors
    this.setState({ errors });

    // If there are no errors, submit the form
    if (Object.keys(errors).length === 0) {
      // Perform the form submission logic here
      // e.g., call an API endpoint, update the database, etc.

      // Reset the form
      this.setState({
        username: "",
        email: "",
        phone: "",
        message: "",
        errors: {
          username: "",
          email: "",
          phone: "",
          message: "",
        },
      });

      // Show the "Thank you" message
      this.setState({ submitted: true });

      // Call the sendEmail function
      await this.sendEmail();
    }
  };

  sendEmail = async (e) => {
    // e.preventDefault();

    const { email, username, phone, message } = this.state;

    const res = await fetch("https://mailbridge.bridgehealth.in/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        username,
        phone,
        message,
      }),
    });

    const data = await res.json();

    if (data.status === 401 || !data) {
      console.log("error");
    } else {
      this.setState({
        show: true,
        email: "",
        username: "",
        phone: "",
        message: "",
      });
      console.log("Email sent");
    }
  };

  render() {


    return (
      <>
        <footer className="main-footer desktop-footer d-none d-md-block">
          <div className="ontainer-fluid">
            <div className="row mb-5">
              <div className="col-md-2 bh-logo-s3">
                <div className="footer-widget">
                  <div className="">
                    <div className="">
                      <Link to="/">
                        <img
                          srcSet={require("../../assets/images/bhlogo.webp")}
                          alt="footer"
                          className="footer-logo-style"
                          rel="preload"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="footer-widget links-widget d-flex flex-column justify-content-end h-100">
                  <div className="widget-content">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="dropdown-menu-footer">
                          <button className="dropbtn-footer">
                            Our Services
                          </button>
                          <div className="dropdown-content-footer">
                            <a
                              href="/personal-membership-program"
                              title="Personal Memberships"
                            >
                              Personal Memberships
                            </a>
                            <a
                              href="/corporate-membership-program"
                              title="Corporate Memberships"
                            >
                              Corporate Memberships
                            </a>
                            <a
                              href="/personal-membership-program"
                              title="Health Plan"
                            >
                              Health Plan
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dropdown-menu-footer">
                          <button className="dropbtn-footer">About Us</button>
                          <div className="dropdown-content-footer">
                            <a href="/team" title="Clinical Team">
                              Clinical Team
                            </a>
                            <a href="/leadership" title="Leadership Team">
                              Leadership Team
                            </a>
                            <a href="/shingles" title="Shingles Vaccination">
                              Shingles Vaccination
                            </a>

                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="dropdown-menu-footer">
                          <button className="dropbtn-footer">Policies</button>
                          <div className="dropdown-content-footer">
                            <a
                              href="/terms-of-use"
                              title="Terms and Conditions"
                            >
                              Terms and Conditions
                            </a>
                            <a href="/privacy-policy" title="Privacy Policy">
                              Privacy Policy
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="dropdown-menu-footer">
                          <a className="d-inline-block pe-5 ps-5 text-center text-white dropbtn-footer" href="/blogs" title="Blogs">Blogs</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 d-flex align-items-center px-3">
                    <img src="/images/iso.png" className="iso-logo1" alt="iso" />
                    <ul className="social-links clearfix text-center d-flex flex-1 justify-content-end ">
                      <li className="socialp">Follow Us On</li>
                      <li>
                        <a
                          href="https://www.facebook.com/bridgehealth.fb"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Facebook"
                        >
                          <span className="fab fa-facebook-f"></span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://threads.net/@bridgehealth.in"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Threads"
                        >
                          <img src="/threads.png" style={{ filter: `invert(1)` }}></img>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/@bridgehealth.youtube"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Youtube"
                        >
                          <span className="fab fa-youtube"></span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://in.pinterest.com/Bridgehealthin"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Pinterest"
                        >
                          <span className="fab fa-pinterest"></span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/bridgehealth.insta"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Instagram"
                        >
                          <span className="fab fa-instagram"></span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/Bridgehealthin"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Twitter"
                        >
                          <span className="fab fa-twitter"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-widget instagram-widget">
                  <div className="contact-form-area">
                    {/* <!-- Contact Form--> */}
                    <SubscribeForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="auto-container copy footer-bottom-style">
            <div className="footer-bottom">
              <div className="copyright">
                Copyright © {new Date().getFullYear()} Bridge Health, All Rights
                Reserved
              </div>
            </div>
          </div>
        </footer>
        <footer className="main-footer mobile-footer d-md-none">
          <div className="ontainer-fluid">
            <div className="row clearfix">
              <div className="col-lg-2">
                <div className="footer-widget logo-widget">
                  <div className="widget-content">
                    <div className="footer-logo">
                      <a href="https://bridgehealth.in">
                        <img
                          srcSet={require("../../assets/images/bhlogo.webp")}
                          alt="footer"
                          className="footer-logo-style"
                          title="Logo"
                          rel="preload"
                        />
                      </a>
                      <img src="/images/iso.png" className="iso-logo1" alt="iso" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4">
                <div className="d-flex footer-style-content">
                  <div className="dropdown-menu-footer">
                    <button className="dropbtn-footer">Our Services</button>
                    <div className="dropdown-content-footer">
                      <a
                        href="/personal-membership-program"
                        title="Personal Memberships"
                      >
                        Personal Memberships
                      </a>
                      <a
                        href="/corporate-membership-program"
                        title="Corporate Memberships"
                      >
                        Corporate Memberships
                      </a>
                      <a
                        href="/personal-membership-program"
                        title="Health Plan"
                      >
                        Health Plan
                      </a>

                      <a href={"/shingles"} title="Shingles Vaccination">
                        Shingles Vaccination
                      </a>
                    </div>
                  </div>
                  <div className="dropdown-menu-footer">
                    <button className="dropbtn-footer">About Us</button>
                    <div className="dropdown-content-footer">
                      <a href="/team" title="Clinical Team">
                        Clinical Team
                      </a>
                      <a href="/leadership" title="Leadership Team">
                        Leadership Team
                      </a>
                      <a href="/blogs" title="Blogs">
                        Blogs
                      </a>
                    </div>
                  </div>

                  <div className="dropdown-menu-footer">
                    <button className="dropbtn-footer">Policies</button>
                    <div className="dropdown-content-footer">
                      <a href="/terms-of-use" title="Terms and Conditions">
                        Terms and Conditions
                      </a>
                      <a href="/privacy-policy" title="Privacy Policy">
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                  <div className="dropdown-menu-footer">
                    <a href="/blogs" title="Blogs"><button className="dropbtn-footer">Blogs</button></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="footer-widget instagram-widget ">
                  <div className="contact-form-area d-flex align-items-center text-align-center mt-4 px-2" style={{gap:'12px'}} >
                    <span className="text-secondary" style={{fontSize:'12px'}}>Follow Us On</span>
                    <ul className="social-links clearfix d-flex">
                      <li>
                        <a
                          href="https://www.facebook.com/bridgehealth.fb"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Facebook"
                        >
                          <span className="fab fa-facebook-f"></span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://threads.net/@bridgehealth.in"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Threads"
                        ><img src="/threads.png" style={{ filter: `invert(1)` }}></img>
                          <span className="fab fa-threads"></span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/@bridgehealth.youtube"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Youtube"
                        >
                          <span className="fab fa-youtube"></span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://in.pinterest.com/Bridgehealthin"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Pinterest"
                        >
                          <span className="fab fa-pinterest"></span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/bridgehealth.insta"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Instagram"
                        >
                          <span className="fab fa-instagram"></span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/Bridgehealthin"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Twitter"
                        >
                          <span className="fab fa-twitter"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="auto-container copy">
            <div className="footer-bottom">
              <div className="copyright">
                Copyright © {new Date().getFullYear()} Bridge Health, All Rights
                Reserved
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default withRouter(Footer);
