import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

class HpvTab extends Component {
    render() {
        return (
            <>
                <section className="container">
                    <div className="shi-desk">
                        <div className="sec-title text-center textleft">
                            <h2 className='textleft fontSize50 wrap-sites-hpv'>
                                <span className='headeingcolorblack'>POSSIBLE COMPLICATIONS  </span>
                                <span className='headeingcolorblue'> OF HPV</span>
                            </h2>
                        </div>
                    </div>
                    {/* Mobile Code  */}
                    <div className="shi-mob">
                        <div className="sec-title text-center textleft">
                            <h2 className='textleft fontSize50 wrap-sites-poss shi1-mob1'>
                                <span className='headeingcolorblack'>POSSIBLE COMPLICATIONS </span>
                                <span className='headeingcolorblue'> OF HPV</span>
                            </h2>
                        </div>
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col sm={5}>
                                <Nav variant="pills" className="flex-column text-center">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Cervical Cancer</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Genital Warts</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">Respiratory Papillomatosis</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={7}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div id="image_para_section">
                                            <img srcset={require('../../assets/images/shape/Cervical Cancer.webp')} rel="preload"
                                                alt="" className='image_shingles' />
                                            <p className="paragraph_after_image mt-2">
                                                Persistent infection with high-risk HPV strains, particularly types 16 and 18, can lead to
                                                changes in cervical cells that may progress to cervical cancer over time.
                                            </p>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div id="image_para_section">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/shape/Genital Warts.webp')} alt="" className='image_shingles' />
                                            <p className="paragraph_after_image mt-2">
                                                Some HPV strains can cause visible genital warts,
                                                which can be uncomfortable and emotionally distressing.
                                            </p>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <div id="image_para_section">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/shape/Respiratory Papillomatosis.webp')} alt="" className='image_shingles' />
                                            <p className="paragraph_after_image mt-2">
                                                In a few cases, an HPV infection can lead to
                                                growths in the airway, causing breathing difficulties.
                                            </p>
                                        </div>
                                    </Tab.Pane>

                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </section>

            </>
        )
    }
}
export default HpvTab;