import React, { useEffect, useState } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import FormButton from "../element/component/FormButton";
import { Helmet } from "react-helmet";
import Popup_Main from "../element/component/Popup_Main";
import ReachToUsForm from "../element/ReachToUsForm";

const B2CMembership = () => {

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.title = "B2C Membership | Bridge Health";
  }, []);

  return (
    <>
      <Header />
      <Helmet>
        <title>
          Annual preventive health & wellness plan for all | Bridge health
        </title>
        <meta
          name="description"
          content="Discover our exclusive personal membership program designed to predict prevent & delay your health issues. Join Bridge Health for exceptional benefits & services."
        />
      </Helmet>
      <section className="team-section padding personal-health image-mobile-terms">
          <section className="container-fluid">
            <div className="row align-items-center auto-container reverseFlex">
              <div className="col-lg-6">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft personal">
                    <h2 className="textleft fontSize50">
                      <span className="headeingcolorblack"> Your </span>
                      <span className="headeingcolorblue">
                        Good Health{" "}
                      </span>{" "}
                      <br></br>
                      <span className="headeingcolorblack">is Our Plan</span>
                    </h2>
                    <h3 className="textleft marginTop40 b2c-heading">
                      Choose from a selection of<br></br> Annual Preventive
                      Health plans
                    </h3>
                    <button className='commonBtnforAll' onClick={()=>setIsOpen(true)}>Book Now</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="imgright">
                  <img
                    srcset={require("../../assets/images/shape/image2.webp")}
                    alt=""
                    rel="preload"
                    className="imgwidth"
                  />
                </div>
              </div>
            </div>
          </section>
        </section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 b2cimgcenter">
              <h1>
                The <span className="headeingcolorblue">Gift</span> of
                Preventive Healthcare Made Easy.
              </h1>
              <p>
                Our Annual Health Plans are membership-based integrated
                healthcare programs covering everything from clinical
                assessments to consultations, lab tests to nutrition sessions,
                lifestyle management to emotional wellness, and more
              </p>
              <h3>Preventive Health Plans</h3>
              <div className="image-one">
                <img
                  srcset={require("../../assets/images/shape/imageb2c.webp")}
                  alt=""
                  className="imgwidth"
                  rel="preload"
                />
              </div>
              <button className='commonBtnforAll' onClick={()=>setIsOpen(true)}>say yes</button>
              {/* <div className="btn-box text-center ">
                <ContactForm
                  buttonText="say yes"
                  className="submitcontact extra-mar"
                />
              </div> */}
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
        <section>
          <div className="container-fluid annualback">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-form-area marleft">
                  <div className="sec-title persona3">
                    <h2>
                      <span className="headeingcolorblack">Why </span>
                      <span className="headeingcolorblue"> Annual </span>
                      <br></br>
                      <span className="headeingcolorblack">Health Plans?</span>
                    </h2>
                    <h4 className="textleft marginTop40">
                      A host of benefits await you as soon as you say{" "}
                      <span className="headeingcolorblue"> YES</span>
                    </h4>
                  </div>
                  <div className="content-box">
                    <ul className="listicon">
                      <li className="listper">
                        <img
                          className="listicon6"
                          srcset={require("../../assets/images/shape/b21.webp")}
                          alt=""
                          rel="preload"
                        />
                        Reduce healthcare expenditure
                      </li>
                      <li className="listper">
                        <img
                          className="listicon6"
                          srcset={require("../../assets/images/shape/b13.webp")}
                          alt=""
                          rel="preload"
                        />
                        Discover any hidden health conditions
                      </li>
                      <li className="listper">
                        <img
                          className="listicon6"
                          srcset={require("../../assets/images/shape/b12.webp")}
                          alt=""
                          rel="preload"
                        />
                        Know your health score
                      </li>
                      <li className="listper">
                        <img
                          className="listicon6"
                          srcset={require("../../assets/images/shape/b14.webp")}
                          alt=""
                          rel="preload"
                        />
                        Implement lifestyle changes for better quality of life
                      </li>
                    </ul>
                  </div>
                  <button className='commonBtnforAll' onClick={()=>setIsOpen(true)}>Say yes</button>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one"></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="blogdesignerd1">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-7"></div>
              <div className="col-lg-5">
                <div className="contact-form-area margintop145">
                  <div className="sec-title marginto90">
                    <h2 className="texttile1 heathCareTitle">
                      <span className="headeingcolorblack">Why </span>
                      <span className="headeingcolorblue"> Preventive </span>
                      <br></br>
                      <span className="headeingcolorblack ">Health Care?</span>
                    </h2>
                    <p className="font20 marginRigh">
                      Preventive healthcare helps in predicting & preventing
                      serious health conditions while prolonging your life. It
                      also helps you in making proactive choices regarding your
                      mental health and lifestyle to stay fit at all times
                    </p>
                  </div>
                  <button className='commonBtnforAll' onClick={()=>setIsOpen(true)}>say yes</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="health-plan pt-5 d-lg-block">
          <div
            className="container-fluid aos-init aos-animate"
            data-aos="fade-up"
          >
            <div className="section-header">
              <h2>
                <span className="headeingcolorblack">
                  Annual Preventive Health
                </span>
                <span className="headeingcolorblue">Plans</span>
              </h2>
              <p>
                Say<span className="headeingcolorblue"> YES </span>to a plan
                just for you!
              </p>
            </div>
            <div className="desktop-plan-table">
              <table>
                <thead>
                  <tr>
                    <td rowspan="15" className="tablebox1">
                      CLINICAL ASSESSMENTS
                    </td>
                  </tr>
                  <tr>
                    <th className="pers-serv">SERVICE OFFERING</th>
                    <th className="pers-blue">BH BLUE</th>
                    <th>BH SILVER</th>
                    <th className="pers-col">BH GOLD</th>
                    <th className="pers-plat">BH PLATINUM</th>
                    <th className="pers-plat">BH PLATINUM +</th>
                  </tr>
                  <tr>
                    <th></th>
                    <td className="pers-bh">101 Tests + ECG</td>
                    <td className="pers-bh1">118 Tests + ECG + USG</td>
                    <td className="pers-gold">122 Tests + ECG + USG</td>
                    <td className="pers-plat">138 Tests + ECG + USG</td>
                    <td className="pers-plus">222 Tests + ECG + USG</td>
                  </tr>
                  <tr>
                    <th>Initial Health Assessment</th>
                    <td className="pers-bh">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-bh1">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-gold">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plat">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plus">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                  </tr>
                  <tr>
                    <th>Blood Health</th>
                    <td className="pers-bh">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-bh1">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-gold">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plat">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plus">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                  </tr>
                  <tr>
                    <th>Diabetic Profile</th>
                    <td className="pers-bh">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-bh1">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-gold">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plat">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plus">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                  </tr>
                  <tr>
                    <th>Kidney Health</th>
                    <td className="pers-bh">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-bh1">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-gold">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plat">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plus">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                  </tr>
                  <tr>
                    <th>Cardiac Health</th>
                    <td className="pers-bh">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-bh1">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-gold">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plat">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plus">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                  </tr>
                  <tr>
                    <th>Liver Health</th>
                    <td className="pers-bh"></td>
                    <td className="pers-bh1">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-gold">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plat">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plus">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                  </tr>
                  <tr>
                    <th>Nutrition Health</th>
                    <td className="pers-bh"></td>
                    <td className="pers-bh1">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-gold">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plat">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plus">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                  </tr>
                  <tr>
                    <th>Bone Health</th>
                    <td className="pers-bh"></td>
                    <td className="pers-bh1"></td>
                    <td className="pers-gold">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plat">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plus">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                  </tr>
                  <tr>
                    <th>Thyroid Profile</th>
                    <td className="pers-bh"></td>
                    <td className="pers-bh1"></td>
                    <td className="pers-gold">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plat">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plus">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                  </tr>
                  <tr>
                    <th>Oncology Screening</th>
                    <td className="pers-bh"></td>
                    <td className="pers-bh1"></td>
                    <td className="pers-gold"></td>
                    <td className="pers-plat">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plus">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                  </tr>
                  <tr>
                    <th>Respiratory Health</th>
                    <td className="pers-bh"></td>
                    <td className="pers-bh1"></td>
                    <td className="pers-gold"></td>
                    <td className="pers-plat">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plus">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                  </tr>
                  <tr>
                    <th>Cardiac Markers</th>
                    <td className="pers-bh"></td>
                    <td className="pers-bh1"></td>
                    <td className="pers-gold"></td>
                    <td className="pers-plat">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plus">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <th>Pancreatic Profile</th>
                    <td className="pers-bh"></td>
                    <td className="pers-bh1"></td>
                    <td className="pers-gold"></td>
                    <td className="pers-plat">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plus">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                  </tr>

                  <tr>
                    <th></th>
                    <th>Radiology Screening</th>
                    <td className="pers-bh">ECG</td>
                    <td className="pers-bh1">ECG, USG (ABD. & PELVIS)</td>
                    <td className="pers-gold">ECG, USG (ABD. & PELVIS),ECHO</td>
                    <td className="pers-plat">
                      X-ray, ECG, USG (ABD. & PELVIS), ECHO
                    </td>
                    <td className="pers-plus">
                      X-ray, ECG, USG (ABD. & PELVIS), ECHO
                    </td>
                  </tr>

                  <tr>
                    <td rowspan="8" className="tablebox2">
                      CONSULTATIONS
                    </td>
                  </tr>
                  <tr>
                    <th>Physician Consults</th>
                    <td className="pers-bh">2</td>
                    <td className="pers-bh1">2</td>
                    <td className="pers-gold">2</td>
                    <td className="pers-plat">2</td>
                    <td className="pers-plus">4</td>
                  </tr>
                  <tr>
                    <th>Emotional Health Consults</th>
                    <td className="pers-bh">1</td>
                    <td className="pers-bh1">1</td>
                    <td className="pers-gold">2</td>
                    <td className="pers-plat">2</td>
                    <td className="pers-plus">4</td>
                  </tr>
                  <tr>
                    <th>Webinars and Group Sessions</th>
                    <td className="pers-bh">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-bh1">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-gold">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plat">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plus">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                  </tr>

                  <tr>
                    <th>Dental, Eye and Derma Screening</th>
                    <td className="pers-bh"></td>
                    <td className="pers-bh1"></td>
                    <td className="pers-gold"></td>
                    <td className="pers-plat"></td>
                    <td className="pers-plus">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                  </tr>
                  <tr>
                    <th>Nutrition Assessment and Consults</th>
                    <td className="pers-bh">1</td>
                    <td className="pers-bh1">1</td>
                    <td className="pers-gold">4</td>
                    <td className="pers-plat">4</td>
                    <td className="pers-plus">4</td>
                  </tr>
                  <tr>
                    <th>Yoga/Fitness Assessments and Sessions</th>
                    <td className="pers-bh"></td>
                    <td className="pers-bh1"></td>
                    <td className="pers-gold">5</td>
                    <td className="pers-plat">15</td>
                    <td className="pers-plus">15</td>
                  </tr>
                  <tr>
                    <th>App Access</th>
                    <td className="pers-bh">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-bh1">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-gold">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plat">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                    <td className="pers-plus">
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="6" className="tablebox3">
                      ADDITIONAL DISCOUNTS (UP TO)
                    </td>
                  </tr>
                  <tr>
                    <th>Medicines/Pharmacy (At-Home Delivery)</th>
                    <td className="pers-bh">10%</td>
                    <td className="pers-bh1">10%</td>
                    <td className="pers-gold">15%</td>
                    <td className="pers-plat">20%</td>
                    <td className="pers-plus">20%</td>
                  </tr>
                  <tr>
                    <th>Lab tests/ Annual Plans for Family</th>
                    <td className="pers-bh">10%</td>
                    <td className="pers-bh1">10%</td>
                    <td className="pers-gold">15%</td>
                    <td className="pers-plat">15%</td>
                    <td className="pers-plus">15%</td>
                  </tr>
                  <tr>
                    <th>Physiotherapy for Self & family</th>
                    <td className="pers-bh">10%</td>
                    <td className="pers-bh1">10%</td>
                    <td className="pers-gold">10%^</td>
                    <td className="pers-plat">1 session + 10%</td>
                    <td className="pers-plus">1 session + 10%</td>
                  </tr>
                  <tr>
                    <th>Home Sample Collection</th>
                    <td className="pers-bh"></td>
                    <td className="pers-bh1"></td>
                    <td className="pers-gold">FREE</td>
                    <td className="pers-plat">FREE</td>
                    <td className="pers-plus">FREE</td>
                  </tr>
                  <tr>
                    <th>Price</th>
                    <td className="pers-bh">
                      <p className="buy-btn border-blue BhBlue">₹3,999</p>
                    </td>
                    <td className="pers-bh1">
                      <p className="buy-btn border-blue BhBlue">₹7,999</p>
                    </td>
                    <td className="pers-gold">
                      <p className="buy-btn border-blue BhBlue">₹16,999</p>
                    </td>
                    <td className="pers-plat">
                      <p className="buy-btn border-blue BhBlue">₹24,999</p>
                    </td>
                    <td className="pers-plus">
                      <p className="buy-btn border-blue BhBlue">₹29,999</p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td>
                      {" "}
                      <a
                        href="https://razorpay.com/payment-link/inv_M2vyRWdM09nxcw"
                        className="getStarted"
                        title="Get Started"
                      >
                        Get Started
                      </a>
                    </td>
                    <td>
                      {" "}
                      <a
                        href="https://razorpay.com/payment-link/inv_M2vyRWdM09nxcw"
                        className="getStarted"
                        title="Get Started"
                      >
                        Get Started
                      </a>
                    </td>
                    <td>
                      {" "}
                      <a
                        href="https://razorpay.com/payment-link/inv_M2w1L4i4SwsI7p"
                        className="getStarted"
                        title="Get Started"
                      >
                        Get Started
                      </a>
                    </td>
                    <td>
                      {" "}
                      <a
                        href="https://razorpay.com/payment-link/inv_M2w30g1YwaCMwd"
                        className="getStarted"
                        title="Get Started"
                      >
                        Get Started
                      </a>
                    </td>
                    <td>
                      {" "}
                      <a
                        href="https://razorpay.com/payment-link/inv_M2wMTGZG5XdOa0"
                        className="getStarted"
                        title="Get Started"
                      >
                        Get Started
                      </a>
                    </td>
                  </tr>
                </thead>
              </table>

              <div className="row mt-3 ">
                <div className="col-lg-4 col-md-6"></div>
                <div className="col-lg-8 col-md-6">
                  <div className="d-flex">
                    <button
                     
                      title="Know More"
                      className="knowMore toggle2"
                    >
                      KNOW MORE
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Mobile code */}
            <div className="table-container d-block d-lg-none">
              <div className="table-horizontal-container">
                <table className="unfixed-table">
                  <thead>
                    <tr>
                      <th className="pers-serv priceTextLeft">
                        SERVICE OFFERING
                      </th>
                      <th className="pers-blue">BH BLUE</th>
                      <th>BH SILVER</th>
                      <th className="pers-col">BH GOLD</th>
                      <th className="pers-plat">BH PLATINUM</th>
                      <th className="pers-plat">BH PLATINUM +</th>
                    </tr>
                  </thead>
                  <tbody className="per-price">
                    <tr>
                      <th></th>
                      <td className="pers-bh">101 Tests + ECG</td>
                      <td className="pers-bh1">118 Tests + ECG + USG</td>
                      <td className="pers-gold">122 Tests + ECG + USG</td>
                      <td className="pers-plat">138 Tests + ECG + USG</td>
                      <td className="pers-plus">222 Tests + ECG + USG</td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">
                        Initial Health Assessment
                      </th>
                      <td className="pers-bh">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-bh1">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-gold">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plat">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plus">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">Blood Health</th>
                      <td className="pers-bh">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-bh1">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-gold">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plat">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plus">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">Diabetic Profile</th>
                      <td className="pers-bh">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-bh1">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-gold">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plat">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plus">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">Kidney Health</th>
                      <td className="pers-bh">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-bh1">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-gold">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plat">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plus">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">Cardiac Health</th>
                      <td className="pers-bh">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-bh1">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-gold">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plat">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plus">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">Liver Health</th>
                      <td className="pers-bh"></td>
                      <td className="pers-bh1">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-gold">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plat">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plus">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">Nutrition Health</th>
                      <td className="pers-bh"></td>
                      <td className="pers-bh1">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-gold">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plat">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plus">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">Bone Health</th>
                      <td className="pers-bh"></td>
                      <td className="pers-bh1"></td>
                      <td className="pers-gold">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plat">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plus">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">Thyroid Profile</th>
                      <td className="pers-bh"></td>
                      <td className="pers-bh1"></td>
                      <td className="pers-gold">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plat">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plus">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">Oncology Screening</th>
                      <td className="pers-bh"></td>
                      <td className="pers-bh1"></td>
                      <td className="pers-gold"></td>
                      <td className="pers-plat">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plus">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">Respiratory Health</th>
                      <td className="pers-bh"></td>
                      <td className="pers-bh1"></td>
                      <td className="pers-gold"></td>
                      <td className="pers-plat">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plus">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                    </tr>

                    <tr>
                      <th>Cardiac Markers</th>
                      <td className="pers-bh"></td>
                      <td className="pers-bh1"></td>
                      <td className="pers-gold"></td>
                      <td className="pers-plat">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plus">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <th></th>
                      <th>Pancreatic Profile</th>
                      <td className="pers-bh"></td>
                      <td className="pers-bh1"></td>
                      <td className="pers-gold"></td>
                      <td className="pers-plat">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plus">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <th className="priceTextLeft">Radiology Screening</th>
                      <td className="pers-bh">ECG</td>
                      <td className="pers-bh1">ECG, USG (ABD. & PELVIS)</td>
                      <td className="pers-gold">
                        ECG, USG (ABD. & PELVIS),ECHO
                      </td>
                      <td className="pers-plat">
                        X-ray, ECG, USG (ABD. & PELVIS), ECHO
                      </td>
                      <td className="pers-plus">
                        X-ray, ECG, USG (ABD. & PELVIS), ECHO
                      </td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">Physician Consults</th>
                      <td className="pers-bh">2</td>
                      <td className="pers-bh1">2</td>
                      <td className="pers-gold">2</td>
                      <td className="pers-plat">2</td>
                      <td className="pers-plus">4</td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">
                        Emotional Health Consults
                      </th>
                      <td className="pers-bh">1</td>
                      <td className="pers-bh1">1</td>
                      <td className="pers-gold">2</td>
                      <td className="pers-plat">2</td>
                      <td className="pers-plus">4</td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">
                        Webinars and Group Sessions
                      </th>
                      <td className="pers-bh">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-bh1">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-gold">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plat">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plus">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                    </tr>

                    <tr>
                      <th className="priceTextLeft">
                        Dental, Eye and Derma Screening
                      </th>
                      <td className="pers-bh"></td>
                      <td className="pers-bh1"></td>
                      <td className="pers-gold"></td>
                      <td className="pers-plat"></td>
                      <td className="pers-plus">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">
                        Nutrition Assessment and Consults
                      </th>
                      <td className="pers-bh">12</td>
                      <td className="pers-bh1">1</td>
                      <td className="pers-gold">4</td>
                      <td className="pers-plat">4</td>
                      <td className="pers-plus">4</td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">
                        Yoga/Fitness Assessments and Sessions
                      </th>
                      <td className="pers-bh"></td>
                      <td className="pers-bh1"></td>
                      <td className="pers-gold">5</td>
                      <td className="pers-plat">15</td>
                      <td className="pers-plus">15</td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">App Access</th>
                      <td className="pers-bh">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-bh1">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-gold">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plat">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                      <td className="pers-plus">
                        <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">
                        Medicines/Pharmacy (At-Home Delivery)
                      </th>
                      <td className="pers-bh">10%</td>
                      <td className="pers-bh1">10%</td>
                      <td className="pers-gold">15%</td>
                      <td className="pers-plat">20%</td>
                      <td className="pers-plus">20%</td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">
                        Lab tests/ Annual Plans for Family
                      </th>
                      <td className="pers-bh">10%</td>
                      <td className="pers-bh1">10%</td>
                      <td className="pers-gold">15%</td>
                      <td className="pers-plat">15%</td>
                      <td className="pers-plus">15%</td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">
                        Physiotherapy for Self & family
                      </th>
                      <td className="pers-bh">10%</td>
                      <td className="pers-bh1">10%</td>
                      <td className="pers-gold">10%^</td>
                      <td className="pers-plat">1 session + 10%</td>
                      <td className="pers-plus">1 session + 10%</td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">Home Sample Collection</th>
                      <td className="pers-bh"></td>
                      <td className="pers-bh1"></td>
                      <td className="pers-gold">FREE</td>
                      <td className="pers-plat">FREE</td>
                      <td className="pers-plus">FREE</td>
                    </tr>
                    <tr>
                      <th className="priceTextLeft">Price</th>
                      <td className="pers-bh">₹3,999</td>
                      <td className="pers-bh1">₹7,999</td>
                      <td className="pers-gold">₹16,999</td>
                      <td className="pers-plat">₹24,999</td>
                      <td className="pers-plus">₹29,999</td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <th></th>
                      <td>
                        <a
                          href="https://razorpay.com/payment-link/inv_M2vyRWdM09nxcw"
                          className="getStarted"
                          title="Get Started"
                        >
                          Get Started
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://razorpay.com/payment-link/inv_M2vyRWdM09nxcw"
                          className="getStarted"
                          title="Get Started"
                        >
                          Get Started
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://razorpay.com/payment-link/inv_M2w1L4i4SwsI7p"
                          className="getStarted"
                          title="Get Started"
                        >
                          Get Started
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://razorpay.com/payment-link/inv_M2w30g1YwaCMwd"
                          className="getStarted"
                          title="Get Started"
                        >
                          Get Started
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://razorpay.com/payment-link/inv_M2wMTGZG5XdOa0"
                          className="getStarted"
                          title="Get Started"
                        >
                          Get Started
                        </a>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </section>

        {/*
  <!-- Contact Section --> */}
        <section className="contact-section">
          <section>
            <div className="auto-container">
              <div className="sec-title text-center">
                <h2>
                  {" "}
                  <span className="headeingcolorblue">Contact Us</span>
                </h2>
              </div>
            </div>
          </section>
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-form-area">
                  {/*
            <!-- Contact Form--> */}
                  <div
                    className="contact-form"
                    style={{ marginTop: 5 + "rem" }}
                  >
                    <ReachToUsForm/>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      srcset={require("../../assets/images/shape/contactus.webp")}
                      alt=""
                      rel="preload"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Popup_Main isOpen={isOpen} onClose={() => setIsOpen(false)} >
        <ReachToUsForm submitted={() => setIsOpen(false)} />
      </Popup_Main>
      <Footer />
    </>
  );
};

export default B2CMembership;
