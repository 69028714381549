import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/eldercare.css"
import Banner_slider from "../element/component/Banner_New_Slider";
import TestimonialSlider from "../element/component/testimonial-slider";
import Footer from "../layout/footerUpdated";
import Header from "../layout/headerHome";
import ServiceSection from "../element/component/ServiceSectionAll";
import ReachToUsForm from "../element/ReachToUsForm";
import Popup_Main from "../element/component/Popup_Main";

const ElderCare = () => {

  const [isOpen, setIsOpen] = useState(false);
  const Counter = ({ start, end, duration }) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    let startTimestamp;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setCount(Math.floor(progress * (end - start) + start));
      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };
    requestAnimationFrame(step);
  }, [start, end, duration]);

  return <span>{count}+</span>;
};


  const [activeIndex, setActiveIndex] = useState(0);

  const testimonials = [
    {
      content:
        "Bridge health offers services at affordable cost. Akshata at the front desk is very cooperative and good at coordinating and follow-ups. The staff are well spoken and carried out all tests with patience.",
      name: "Akshata J Lokhande",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "I am Prof Sundararaj living at PRIMUS REFLECTION. The staff are very courteous with a smile on their faces, including all the doctors. Special thanks to Ms. Akshita!",
      name: "Sundar Raj",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Good , clean hygiene place ... Feels like your are at home with the services that provide... I have enrolled for gym here.. And the trainers are thorough professionals.. And the staff I have interacted with them during the seminars or presentations they are knowledgeable",
      name: "Shiva Kumar S",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "From sattva apartment Bridge healthy is useful for our patients.Mahendra the nursing staff is good and courteous.",
      name: "Susheela Sharan",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Laxman is an excellent nurse looking after the retired community. Gentle in taking blood. Very polite and efficient.",
      name: "Dipak Rao",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
  ];

  const faqs = [
    {
      question: "What senior care services does Bridge Health offer in Bangalore? ",
      answer:
        "Bridge Health offers a range of senior care services, including in-home caregiving, medical assistance, and wellness programs. ",
    },
    {
      question: "What are the costs associated with Bridge Health's senior care programs? ",
      answer: "The costs vary depending on the services required, ranging from basic caregiving to specialized medical care. Exact pricing is available upon consultation. ",
    },
    {
      question: "What types of in-home senior care services are available in Bangalore? ",
      answer: "In-home senior care services include personal caregiving, nursing care, physiotherapy, medication management, and companionship services. ",
    },
    {
      question: "Does Bridge Health provide specialised care for seniors with dementia or Alzheimer's? ",
      answer: "Yes, Bridge Health offers tailored mental wellness, and counselling programs for seniors with dementia or Alzheimer's. ",
    },
    {
      question: "What factors should I consider when choosing an in-home caregiver?  ",
      answer: "Consider the caregiver's qualifications, experience, reliability, communication skills, and compatibility with the senior's needs and personality. ",
    },
  ];

  const SeniorServices = [
    {
        name: "Specialised Clinics",
        desc: "Thoughtfully designed for seniors, our clinics focus on delivering expert primary, preventive & emergency care for every stage of ageing. ",
        image: "/images/new-website/elder-care/elder-clinics.webp",
      },
      {
        name: "Community Centric Approach",
        desc: "We create a warm, supportive environment where seniors can build meaningful connections and feel emotionally supported. ",
        image: "/images/new-website/elder-care/senior-care-clinics.webp",
      },
      {
        name: "Technology-Enabled Services",
        desc: "From hassle-free appointments to advanced health monitoring, our tech-driven services make life simpler, so seniors and their caregivers feel secure and empowered. ",
        image: "/images/new-website/elder-care/health-tech.webp",
      },

  ];

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

      // Slider settings defined here
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: (
      <div className="slick-prev">
        <i className="fa fa-angle-left" aria-hidden="true"></i>
      </div>
    ),
    nextArrow: (
      <div className="slick-next">
        <i className="fa fa-angle-right" aria-hidden="true"></i>
      </div>
    ),
  };

    // Custom slides for this page
    const slides = [
        {
          desktop: "/images/new-website/elder-care/Kutub-1.webp",
          mobile: "/images/new-website/elder-care/Kutub-1.webp",
        },
        {
          desktop: "/images/new-website/elder-care/Naiknavare-1.webp",
          mobile: "/images/new-website/elder-care/Naiknavare-1.webp",
        },
        {
          desktop: "/images/new-website/elder-care/primus-1-1.webp",
          mobile: "/images/new-website/elder-care/primus-1-1.webp",
        },
        {
          desktop: "/images/new-website/elder-care/primus-2.webp",
          mobile: "/images/new-website/elder-care/primus-2.webp",
        },
        {
          desktop: "/images/new-website/elder-care/Wadhwa-1.webp",
          mobile: "/images/new-website/elder-care/Wadhwa-1.webp",
        },
      ];
      

  const [isMobile, setIsMobile] = useState(false);
  const sectionRefs = useRef([]); // Refs for multiple sections/cards

  // Resize event listener to detect mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initialize on mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Scroll Animation Logic
  useEffect(() => {
    const handleScroll = () => {
      const updatedVisibility = sectionRefs.current.map((ref) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;

          // Element becomes visible when its top is within viewport
          return rect.top <= windowHeight - 50;
        }
        return false;
      });

    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger on mount to check initial visibility
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const cardsData = [
    { title: "Doctor Consultation", image: "/images/new-website/elder-care/doctor-consult.webp" },
    { title: "Diabetes Care", image: "/images/new-website/elder-care/diet.webp" },
    { title: "Yoga & Fitness", image: "/images/new-website/elder-care/yoga-elder.webp" },
    { title: "Physiotherapy", image: "/images/new-website/elder-care/physiotherapy (1).webp" },
    { title: "Hearing Care Services", image: "/images/new-website/elder-care/hearing.webp" },
    { title: "Specialized Health Checks", image: "/images/new-website/elder-care/health-screening.webp" },
    { title: "Medicine Delivery", image: "/images/new-website/elder-care/delivery-truck.webp" },
    { title: "Lab Tests", image: "/images/new-website/elder-care/test-elder.webp" }, ];

  const styles = {
    headingwoman1: {
        fontSize: "50px",
        color:"#0d0d0d",
        fontWeight: "600",
        lineHeight: "1.2",
        textAlign: "left",
        width: "100%"
      },
  };

  return (
    <div>
        <Header />
      <div className={`landingcontainer ${isMobile ? "mobile" : ""}`}>
        <div className={`leftContainer ${isMobile ? "mobile" : ""}`}>
          <h1>
            
            <span className="highlight1">Your Golden Years{" "}</span><br /> Our Golden Care
          </h1>
          <p className="paragraph-left">At Bridge Health’s senior care program, we walk alongside you in your golden years, offering compassionate elder care tailored to your unique needs.</p>
          <button className="buttonlandingelder" onClick={()=>setIsOpen(true)}>Connect With Us</button>
        </div>
        <div className="rightContainer">
          <img
            src="/images/new-website/elder-care/elder-care-landing-bg.webp"
            alt="elder-care-banner"
            className="imagelanding imageFadeInUp"
          />
        </div>
      </div>

      <section>
        <div className="yoga-types">
          <div className="rightstrongcontainer">
            <h2 className="heading1">
            What Makes Our
              <span className="highlight1"> Senior Care</span>
              <span className="line-break-heading"><br /></span> Services Unique?
            </h2>
          </div>
          <div className="yoga-type-card-section">
                {SeniorServices.map((yoga, index) => (
                    <div
                    key={index}
                    className="yoga-type-card"
                    >
                    <img src={yoga.image} alt={yoga.name} className="leadership-image" />
                    <h3 className="solutiontext"><span className="solutionbold">{yoga.name.split(" ")[0]}</span>{" "}
                    {yoga.name.split(" ").slice(1).join(" ")}</h3>
                    <p className="yoga-type-desc">{yoga.desc}</p>
                    </div>
                ))}
          </div>
        </div>
      </section>

      <section>
        <div className="sectiontwo">
          <h2 className="heading1">
            <span className="highlight1"> Senior Citizen Care:</span> Everything You Need, All in One Place
            <br />
          </h2>
        
        <ServiceSection
                        cardsData={cardsData}
                        isMobile={isMobile}
                        highlight="Diagnostic Services"
                        heading="Detect It Early: Explore Our"
                        customClass="WellnessSectionWomen"
                      />
        </div>
      </section>
      <section>
        <div className={`program-main-container ${isMobile ? "mobile" : ""}`}>
          <div className="program-container">
            <h2 className="program-heading">
            Bridge Health's
              <span className="highlight1"> Popular Elder <br />Care</span> Programs
            </h2>
            <div className="program-box-outer">
                <div className="program-box-inner-1">
                    <div className="fff-box">
                      <h3>Freedom from Fall</h3>
                      <p>Freedom from Fall empowers seniors with balance, strength, and safety to prevent falls confidently.</p>
                      {/* <button className="fff-button">Learn More</button> */}
                    </div>
                    <div className="diabetes-box">
                      <h3>Diabetes Care</h3>
                      <p>Comprehensive diabetes care program helping seniors manage health through lifestyle guidance.</p>
                      {/* <button className="diabetes-button">Learn More</button> */}
                    </div>
                </div>
                <div className="program-box-inner-1">
                    <div className="fff-box-hc" style={{backgroundImage: "url('/images/new-website/elder-care/homecare.webp')"}}>
                      <h3>Home Care Solution</h3>
                      <p>Comprehensive home care solutions ensuring comfort, safety, and independence for senior citizens.</p>
                      {/* <button className="fff-button">Learn More</button> */}
                    </div>
                    <div className="diabetes-box" style={{backgroundImage: "url('/images/new-website/elder-care/adult-vaccination.webp')"}}>
                      <h3>Adult Vaccination</h3>
                      <p>Adult vaccination program protecting health with essential immunizations for a safer, healthier future.</p>
                      {/* <button className="diabetes-button">Learn More</button> */}
                    </div>
                </div>
              </div>
            <button className="buttonlandingelder" onClick={()=>setIsOpen(true)}>Request a Call Back</button>
          </div>
        </div>
      </section>
      <section>
        <div className={`countercontainer ${isMobile ? "mobile" : ""}`}>
          <div className="leftcountercontainer">
            <h2 className="heading1-left">
            Enhance Your 
              <span className="highlight1"> Longevity With Us</span>
            </h2>
            <p className="paragraph-elder">
            At Bridge Health, we bring compassionate care to your doorstep, ensuring comfort and well-being for seniors and their families. 

            </p>
            <div className="statistics-container">
              <div className="stat-item">
                <h2>
                  <Counter start={0} end={20000} duration={2000} />
                </h2>
                <p>Seniors Cared</p>
              </div>
              <div className="stat-item">
                <h2>
                  <Counter start={0} end={200} duration={2000} />
                </h2>
                <p>Caregivers</p>
              </div>
              <div className="stat-item">
                <h2>
                  <Counter start={0} end={400} duration={2000} />
                </h2>
                <p>Vaccination</p>
              </div>
              <div className="stat-item">
                <h2>
                  <Counter start={0} end={4.8} duration={2000} />
                </h2>
                <p>Ratings</p>
              </div>
            </div>
          </div>
          <div className="middlecountercontainer">
            <img
              src="/images/new-website/elder-care/eldercare1.avif"
              alt="elder-care-1"
              className="imageai"
            />
          </div>
          <div className="rightcountercontainer">
            <div>
              <img
                src="/images/new-website/elder-care/elder-care-3.avif"
                alt="elder-care-3"
                className="imageai"
              />
            </div>
            <div>
              <div className= "counter-small-desc">
                <h3>We deliver comfort and compassion</h3>
                <p>Providing care with heartfelt compassion, ensuring comfort and dignity in every moment.</p>
              </div>
              <div>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="live-laugh">
        <div className="ll-section1">
        <img
              src="/images/new-website/elder-care/elder-care-1.webp"
              alt="elder-care-2"
              width="700"
            />
            <img
              src="/images/new-website/elder-care/elder-3.webp"
              alt="elder-care-1"
              width="300"
            />
        </div>
        <div className="ll-section2">
            <h2>
            Moments That Matter
              <span className="highlight1"> Activities at Partnered Communities</span>
            </h2>
            <p>Relive heartwarming moments from Bridge Health’s senior engagement programs, where every day is filled with joy, purpose, and connection. </p>
        </div>
        <div className="ll-section3">
        <img
              src="/images/new-website/elder-care/elder-2.webp"
              alt="elder-care-1"
              width="300"
            />
            <img
              src="/images/new-website/elder-care/elder-4.webp"
              alt="elder-care-1"
              width="500"
            />
        </div>
       
      </section>
      <section className="investsection">
        <div className={`whycontainer ${isMobile ? "mobile" : ""}`}>
            <h2 className="whyheading">
            Communities: Who Have 
              <span className="highlight1"> Trusted Us</span><br />
            </h2>
        </div>
      </section>
      <div className="custom-slider-wrapper slider-invest">
            <Banner_slider sliderSettings={sliderSettings} slides={slides} />
        </div>
      <div className="invest-button">
        <button className="buttonlandingelder" onClick={()=>setIsOpen(true)}>Want to partner with us? Get in Touch</button>
      </div>
      <section className="whysection">
        <div className={`whycontainer ${isMobile ? "mobile" : ""}`}>
            <h2 className="whyheading">
            Why Choose
              <span className="highlight1"> Bridge Health for <br />Elder Care </span> in india?
            </h2>
        </div>
        </section>
        <section className="whysectionelder">
           <div className="whycontainermain">
            <div className="whycontainerfirst">
                <div className="info-box">
                  <img
                    src="/images/new-website/elder-care/check.png"
                    alt="Check Icon"
                    className="check-icon"
                  />
                  <span>Superior advanced care tailored for seniors in Bangalore</span>
                </div>
                <div className="info-box" style={{backgroundColor: "#fff", color:"#0d0d0d"}}>
                  <img
                    src="/images/new-website/elder-care/check-blue.png"
                    alt="Check Icon"
                    className="check-icon"
                  />
                  <span>Compassionate and experienced staff providing empathetic Care</span>
                </div>
                <div className="info-box">
                  <img
                    src="/images/new-website/elder-care/check.png"
                    alt="Check Icon"
                    className="check-icon"
                  />
                  <span>Dedicated senior care managers with families for long-term support</span>
                </div>
            </div>
            <div className="whycontainersecond">
                
            </div>
            <div className="whycontainerthird">
            <div className="info-box">
                  <img
                    src="/images/new-website/elder-care/check.png"
                    alt="Check Icon"
                    className="check-icon"
                  />
                  <span>Holistic approach to care with collaboration across multiple disciplines</span>
                </div>
                <div className="info-box" style={{backgroundColor: "#fff", color:"#0d0d0d"}}>
                  <img
                    src="/images/new-website/elder-care/check-blue.png"
                    alt="Check Icon"
                    className="check-icon"
                  />
                  <span>Flexible Scheduling</span>
                </div>
                <div className="info-box">
                  <img
                    src="/images/new-website/elder-care/check.png"
                    alt="Check Icon"
                    className="check-icon"
                  />
                  <span>Easy & reliable at-home services</span>
                </div>
            </div>
           </div>

      </section>
    

    <section className="faq-updated">
      <div className="faq-container">
        {/* Left Section */}
        <div className="faq-left">
          <h2>
            Frequently <span className="highlight1">Asked</span> Questions
          </h2>
        </div>

        {/* Right Section */}
        <div className="faq-right">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${activeIndex === index ? "active" : ""}`}
            >
              {/* Title */}
              <div
                className="faq-title"
                onClick={() => handleAccordionClick(index)}
              >
                {faq.question}
                <span className="faq-icon">
                  {activeIndex === index ? "−" : "+"}
                </span>
              </div>

              {/* Content */}
              <div
                className="faq-content"
                style={{
                  height: activeIndex === index ? "auto" : "0",
                  overflow: "hidden",
                  transition: "height 0.3s ease",
                }}
              >
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>

    <section>
    <div className="testimonials-container">
      {/* Left Side */}
      <div className="left-section-testimonial">
        <h1>
        Trust Us Based on What Our <span>Patrons Say</span>
        </h1>
        <h3>Overall Ratings</h3>
        <div className="ratings">
          <img
            src="/images/new-website/women-care/google-logo-1.webp"
            alt="Google Logo"
          />
          <div>
            <p>
              4.9{" "}
              <span className="stars">
                ⭐⭐⭐⭐⭐
              </span>
            </p>
            <p>Customer Reviews</p>
          </div>
        </div>
        <a href="https://www.google.com/search?q=bridgehealth&sca_esv=f8c3d57e75161cda&source=hp&ei=k3mQZ5CSHe_G1e8Psu7IkA4&iflsig=AL9hbdgAAAAAZ5CHoz7_cRGSTpek_a5zgM_qrQEKb84T&ved=0ahUKEwjQp-qYxIiLAxVvY_UHHTI3EuIQ4dUDCBk&uact=5&oq=bridgehealth&gs_lp=Egdnd3Mtd2l6IgxicmlkZ2VoZWFsdGgyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIESNgdUABY_hpwAHgAkAEAmAHKAaABqg-qAQYwLjExLjG4AQPIAQD4AQGYAgygAuAPwgIIEAAYgAQYsQPCAgsQABiABBixAxiDAcICBRAuGIAEwgILEC4YgAQYsQMYgwHCAg4QLhiABBixAxiDARiKBcICDhAAGIAEGLEDGIMBGIoFwgIIEC4YgAQYsQPCAg4QLhiABBixAxjRAxjHAcICCxAAGIAEGLEDGIoFwgILEC4YgAQYsQMY1AKYAwCSBwYwLjExLjGgB86VAQ&sclient=gws-wiz#lrd=0x3bae1571e6b9bbaf:0xc485c6444a9e56b4,3,,,," target="_blank" rel="noopener noreferrer">
        <button className="buttonmaphome-review">Write a Review</button>
        </a>
      </div>

      {/* Right Side */}
      <div className="right-section-testimonial">
        <div className="testimonial-page">
      <TestimonialSlider testimonials={testimonials} />
    </div>
      </div>
    </div>
    </section>
    <Popup_Main isOpen={isOpen} onClose={() => setIsOpen(false)} >
        <ReachToUsForm submitted={() => setIsOpen(false)} />
        </Popup_Main>

<Footer />
    </div>
  );
};

export default ElderCare;
