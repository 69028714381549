import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import FaqQuestions from "./faq-questions";
import { Helmet } from "react-helmet";
import { FacebookIcon, FacebookShareButton } from "react-share";

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      selectedCategory: "Category",
      checkboxes: [
        { name: "About Proactive Health", checked: false },
        { name: "Annual Health Plans", checked: false },
        { name: "All Health Services", checked: false },
        { name: "Payments & Refunds", checked: false },
        { name: "More Common Questions", checked: false },
        { name: "Chronic Care", checked: false },
        { name: "Vaccines", checked: false },
      ],
    };
  }

  componentDidMount() {
    document.title = "FAQ | Bridge Health";
  }

  handleInputChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleCategoryChange = (event) => {
    this.setState({ category: event.target.value });
  };

  handleCheckboxChange = (index) => {
    const { checkboxes } = this.state;
    checkboxes[index].checked = !checkboxes[index].checked;

    // Update the selected checkboxes in state
    this.setState({ checkboxes });
  };

  handleCategoryChange = (event) => {
    this.setState({ selectedCategory: event.target.value });
  };

  render() {
    const { selectedCategory, checkboxes } = this.state;

    const selectedCategories = checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.name);

    return (
      <>
        <Header />
        <Helmet>
          <title>
            Frequently Asked Questions |FAQs about Preventive healthcare| Bridge
            Health
          </title>
          <meta
            name="description"
            content="Find answers to common questions about preventive healthcare, our services, & more. Our comprehensive FAQ page provides valuable information for your convenience."
          />
        </Helmet>
        {/* <!-- Page Banner Section --> */}
        <section className="team-section padding faqBackMobile">
          {/* About Section Two */}
          <section className="container-fluid">
            <div className="row align-items-center auto-container reverseFlex blogPagebox">
              <div className="col-lg-5 ">
                <div
                  className="content-box marginTop40header d-flex justify-content-between align-items-center"
                  style={{ gap: "10px" }}
                >
                  {/* <div className=" text-center">
                    <FacebookShareButton
                      url="https://bridgehealth.in"
                      hashtag="#code"
                      quote="please share this post"
                    >
                      <FacebookIcon></FacebookIcon>
                    </FacebookShareButton>
                  </div> */}
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize">
                      <span className="headeingcolorblack"> Got a </span>
                      <span className="headeingcolorblue"> query? </span>{" "}
                      <br></br>
                      <span className="headeingcolorblack">
                        {" "}
                        We are here to help.{" "}
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className=" imgright">
                  <img
                    srcset={require("./../../assets/images/shape/faqhead.webp")}
                    alt=""
                    className="faqImg"
                    rel="preload"
                  />
                </div>
              </div>
            </div>
          </section>
        </section>
        <section className="faqBack">
          <div className="auto-container">
            <FaqQuestions
              selectedCategory={selectedCategory}
              selectedCategories={selectedCategories}
            />
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

export default FAQ;
