import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
const aboutbg = require("./../../assets/images/shape/coming.webp");
class affiliatePartners extends Component {
  render() {
    return (
      <>
        <Header />
        <section
          className="internalpage internalimp internalpageresponsive style-two"
          style={{ backgroundImage: "url(" + aboutbg + ")" }}
        ></section>
        <section className="internalpage1 ">
          <div className="auto-container">
            <div className="content-box">
              <div className=" text-center textleft">
                <h2 className=" fontSize">
                  <span className="headeingcolorblue"> Coming Soon...</span>{" "}
                  <br></br>
                  <span className="headeingcolorblack">
                    {" "}
                    Affiliate Partners
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default affiliatePartners;
