import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import FormButton from "../element/component/FormButton";
const aboutbg = require("./../../assets/images/shape/psychological-health.webp");

class psychological_Health extends Component {
  componentDidMount() {
    document.title = "Psychological Health | Bridge Health";
  }

  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Best Psychological Health in Bangalore - Book Appointment | Bridge
            Health
          </title>
          <meta
            name="description"
            content="Bridge Health, the leading mental health provider in Bangalore. Mental health is extremely important in the current times due to increased work & social pressures. Talk to our expert clinical psychologists.
 "
          />
        </Helmet>
        <section
          className="internalpage internalpageresponsive style-two image-mobile-terms"
          style={{ backgroundImage: "url(" + aboutbg + ")" }}
        >
          <div className="auto-container">
            <div className="content-box">
              <div className=" text-center textleft">
                <h2 className="textleft fontSize">
                  <span className="headeingcolorblack">
                    {" "}
                    Good Health starts{" "}
                  </span>
                  <br></br>
                  <span className="headeingcolorblack"> with a </span>
                  <span className="headeingcolorblue"> Healthy Mind </span>
                </h2>
                <h3 className="textleft marginTop40 colorwhite">
                  Talk to our clinical psychologists today
                </h3>
              </div>
              <FormButton/>
            </div>
          </div>
        </section>

        <section className="contact-section">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/ps1.webp")}
                  alt=""
                  rel="preload"
                  className="imgFloatRight marginTop40reverse"
                />
              </div>
              <div className="col-lg-6">
                <div className="sec-title squrebox">
                  <p className="font22">
                    Unlike physical health issues,
                    <span className="headeingcolorblue">
                      {" "}
                      psychological issues are not as visible.{" "}
                    </span>
                    They are generally ignored and are not given as much
                    importance due to societal stigmas. However, if these are
                    ignored for too long, they can affect your intellectual
                    capabilities and overall behaviour.
                  </p>
                </div>
                <FormButton/>
              </div>
            </div>
          </div>
        </section>
        <section className="team-section">
          <div className="auto-container">
            <div className="row align-items-center reverseFlex">
              <div className="col-lg-6">
                <ul className="listicon">
                  <li className="listicon">
                    At{" "}
                    <span className="headeingcolorblue">Bridge Health, </span>
                    our psychologists are trained to understand your mental
                    state.
                  </li>
                  <li className="listicon">
                    They help you manage your stress or any mental health
                    difficulties that you are facing.
                  </li>
                  <li className="listicon">
                    In doing so, you will be able to improve your mental
                    well-being which has a positive effect on your overall
                    physical health.
                  </li>
                </ul>
                <FormButton/>
              </div>
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/ps3.webp")}
                  alt=""
                  className="imgFloatRight"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="contact-section">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/ps4.webp")}
                  alt=""
                  className="widthimg"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <h2 className="header1">
                  <span className="headeingcolorblack"> The</span>
                  <span className="headeingcolorblue"> Bridge Health</span>
                  <span className="headeingcolorblack"> Advantage </span>
                </h2>
                <ul className="listicon">
                  <li>Management of eating disorders</li>
                  <li>Pre and post-pregnancy mental well-being</li>
                  <li>CBT (Cognitive Behavioural Therapy)</li>
                  <li>Mindfulness/Meditation</li>
                  <li>Women’s supportive therapy</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2 className="header1">
                <span className="headeingcolorblack">A </span>
                <span className="headeingcolorblue"> Healthy </span>
                <span className="headeingcolorblack"> Mind leads to a </span>
                <span className="headeingcolorblue"> Happy </span>
                <span className="headeingcolorblack"> Body! </span>
              </h2>
            </div>
            <div className="d-flex justify-content-center pb-4">
                <FormButton ButtonName={'CONSULT / BOOK'} />
              </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default psychological_Health;
