import React from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import ShinglesPopup from "../element//shinglesPopup";
import ShinglessTab from "../element/ShinglessTab";
import TabAccordian from "../element/TabAccordian";
import RadioButtonComponent from "../element/shingles-popup";
import FaqAccordion from "../element/faq-accordion";
import { Helmet } from "react-helmet";
import FormButton from "../element/component/FormButton";

function shingles() {
  const handleClickScroll = () => {
    const element = document.getElementById("section-1");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll1 = () => {
    const element1 = document.getElementById("section-2");

    if (element1) {
      element1.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClickScroll10 = () => {
    const element1 = document.getElementById("section-mobile1");

    if (element1) {
      element1.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClickScroll2 = () => {
    const element2 = document.getElementById("section-3");

    if (element2) {
      element2.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScrollrisk = () => {
    const element2 = document.getElementById("wrap-shingles-img");

    if (element2) {
      element2.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Header />
      <Helmet>
        <title>
          Comprehensive Guide to Shingles: Causes, Symptoms, Treatment |
          BridgeHealth
        </title>
        <meta
          name="description"
          content="Vaccination for Shingles at Bridge Health: Herpes Zoster, caused by the varicella-zoster virus. Discover symptoms, Vaccines, treatments, & solutions for effective management."
        />
      </Helmet>

      <figcaption className="image-mobile-terms display-on-mobile">
        <h2 className="text-align-center">WHAT IS SHINGLES?</h2>
        <p className="card-text-partners12">
          Shingles, also known as herpes zoster, is a viral infection caused by
          the varicella-zoster virus—the same virus that causes chickenpox.
          After recovering from chickenpox, the virus can remain dormant in the
          body and reactivate later in life, leading to shingles.
        </p>
      </figcaption>
      <section className="team-section padding backimg3 image-mobile-terms">
        {/* About Section Two */}
        <section className="container-fluid">
          <div className="row align-items-center auto-container reverseFlex">
            <div className="col-lg-6">
              <div className="desk-top">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft  wrap-font mt-5">
                      <span className="headeingcolorblue wrap-shin">
                        Shield Yourself{" "}
                      </span>{" "}
                      <br></br>{" "}
                      <span className="headeingcolorblue wrap-shin">
                        {" "}
                        from Shingles{" "}
                      </span>
                    </h2>
                    <h3 className="textleft marginTop40 ">
                      Prioritize prevention and <br></br> enjoy a shingles-free
                      life
                    </h3>
                  </div>
                  <FormButton ButtonName={'CONSULT / BOOK'} />
                </div>
              </div>

              {/* Mobile Code  */}
              <div className="mob-mobile">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50 mt-5 uni-shingles">
                      <span className="headeingcolorblue wrap-shin">
                        Shield Yourself{" "}
                      </span>{" "}
                      <br></br>{" "}
                      <span className="headeingcolorblue wrap-shin">
                        {" "}
                        from Shingles{" "}
                      </span>
                    </h2>
                    <h3 className="textleft marginTop40 uni-pagrap">
                      Prioritize prevention and <br></br> enjoy a shingles-free
                      life
                    </h3>
                  </div>
                  <FormButton ButtonName={'CONSULT / BOOK'} />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              {/* <div className="imgright">
                <img
                  srcset={require("../../assets/images/shape/headerImage.webp")}
                  alt=""
                  className="imgwidth wrap2-shi-img1 mobilehide"
                />
              </div> */}

              <figcaption>
                <h2 className="text-align-center">WHAT IS SHINGLES?</h2>
                <p className="card-text-partners12">
                  Shingles, also known as herpes zoster, is a viral infection
                  caused by the varicella-zoster virus—the same virus that
                  causes chickenpox. After recovering from chickenpox, the virus
                  can remain dormant in the body and reactivate later in life,
                  leading to shingles.
                </p>
              </figcaption>
            </div>
          </div>
        </section>
      </section>

      <section className="container">
        <div className="wrap-shi">
          <div className="wrap-head mtop">
            <img
              srcset={require("../../assets/images/shape/shingles3.webp")}
              alt=""
              className="wrap-labwidth"
              rel="preload"
            />
          </div>
          <div className="wrap-tpo">
            <div className="row">
              <div className="col-lg-3 wrap-item-header">
                <div className="card wrap-shig bg1" onClick={handleClickScroll}>
                  <div className="card-body wrap-shig-text">
                    <p className="card-text-partners">
                      What is Shingles/Herpes?
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 wrap-item-header">
                <div
                  className="card wrap-shig bg2"
                  onClick={handleClickScroll1}
                >
                  <div className="card-body wrap-shig-text">
                    <p className="card-text-partners">
                      Symptoms and intensity of pain
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3 wrap-item-header">
                <div
                  className="card wrap-shig bg3"
                  onClick={handleClickScroll2}
                >
                  <div className="card-body wrap-shig-text">
                    <p className="card-text-partners">
                      About the <br></br>Vaccine
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 wrap-item-header">
                <div
                  className="card wrap-shig bg4"
                  onClick={handleClickScrollrisk}
                >
                  <div className="card-body wrap-shig-text">
                    <p className="card-text-partners">
                      Are you at risk <br></br>for Shingles?
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3 wrap-item-header">
                <div
                  className="card wrap-shig bg5"
                  onClick={handleClickScroll2}
                >
                  <div className="card-body wrap-shig-text">
                    <p className="card-text-partners">
                      Why take <br></br>the vaccine?
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 wrap-item-header">
                <button
                  title="Take The Vaccine Today!"
                  className="wrap-cont-col"
                >
                  <div className="card wrap-shig bg6">
                    <div className="card-body wrap-shig-text">
                      <p className="card-text-partners">
                        Take the <br></br>vaccine today!
                      </p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Mobile Code */}

      <section className="container">
        <div className="mobile-element">
          <div className="wrap-head mt-5">
            <img
              srcset={require("../../assets/images/shape/MobileImg.webp")}
              alt=""
              className="wrap-labwidth"
              rel="preload"
            />
          </div>
          <div className="wrap-tpo1">
            <div className="row">
              <div className="col-lg-6 col-md-6 wrap-item-header">
                <div
                  className="card wrap-shig1 bg11"
                  onClick={handleClickScroll}
                >
                  <div className="card-body wrap-shig-text">
                    <p className="card-text-partners1">
                      What is Shingles/Herpes?
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 wrap-item-header">
                <div
                  className="card wrap-shig1 bg21"
                  onClick={handleClickScroll10}
                >
                  <div className="card-body wrap-shig-text">
                    <p className="card-text-partners1">
                      Symptoms and intensity of pain
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 wrap-item-header">
                <div
                  className="card wrap-shig1 bg31"
                  onClick={handleClickScroll2}
                >
                  <div className="card-body wrap-shig-text">
                    <p className="card-text-partners1">
                      About <br></br>the Vaccine
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 wrap-item-header">
                <div
                  className="card wrap-shig1 bg41"
                  onClick={handleClickScrollrisk}
                >
                  <div className="card-body wrap-shig-text">
                    <p className="card-text-partners1">
                      Are you at risk for Shingles?
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 wrap-item-header">
                <div
                  className="card wrap-shig1 bg51"
                  onClick={handleClickScroll2}
                >
                  <div className="card-body wrap-shig-text">
                    <p className="card-text-partners1">Why take the vaccine?</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 wrap-item-header">
                <div className="card wrap-shig1 bg61">
                  <div className="card-body wrap-shig-text">
                    <p className="card-text-partners1">
                      Take the vaccine today!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="wrap-des1">
          <div className="auto-container marginTop80 wrap-header">
            <h2 className="header1 textaligncenter">
              <span className="headeingcolorblack wrap-header1 wrap-header2">
                Listen to what the doctor have to
                <br></br>say about
              </span>
              <span className="headeingcolorblue wrap-header1 wrap-header2">
                {" "}
                Shingles/Herpes
              </span>
            </h2>
          </div>
        </div>
      </section>

      {/* Mobile Code  */}
      <section>
        <div className="wrap-mob1">
          <div className="auto-container marginTop80 wrap-header">
            <h2 className="header1 textaligncenter textparagrap">
              <span className="headeingcolorblack">
                Listen to what the doctor have to say about
              </span>
              <span className="headeingcolorblue"> Shingles/Herpes</span>
            </h2>
          </div>
        </div>
      </section>
      <div>
        <ShinglesPopup />
      </div>
      <div className="d-flex justify-content-center">
        <FormButton ButtonName={'CONSULT / BOOK'} />
      </div>

      <section className="container wrap-shingles-img" id="wrap-shingles-img">
        <div className="row">
          <div className="col-lg-12">
            <div className="card wrap-inter">
              <div className="btn-box text-center">
                <RadioButtonComponent
                  buttonText="CHECK NOW"
                  popupPosition="right"
                  className="btn-style-one"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="desktop-element" id="section-2">
          <div className="wrap-image-item">
            <img
              srcset={require("../../assets/images/shape/symp.webp")}
              alt=""
              className="image-symp"
              rel="preload"
            />
          </div>
        </div>
      </section>

      {/* Mobile Code */}
      <section className="container">
        <div className="mobile-element-item" id="section-mobile1">
          <div className="wrap-image-item">
            <img
              srcset={require("../../assets/images/shape/Shingle21.webp")}
              alt=""
              className="image-symp"
              rel="preload"
            />
          </div>
        </div>
      </section>

      <section className="container mt-5">
        <div className="sites-desktop">
          <div className="wrap-sites">
            <div className="sec-title text-center textleft mb-5">
              <h2 className="wrap-sites-item">
                <span className="headeingcolorblue"> SITES</span>
                <span className="headeingcolorblack">
                  {" "}
                  WHERE SHINGLES OCCUR
                </span>
              </h2>
            </div>
            <div className="wrap-img-sec7 mt-4">
              <img
                srcset={require("../../assets/images/shape/sites.webp")}
                alt=""
                className="imgwidth3"
                rel="preload"
              />
            </div>
            <div className="parg1-item">
              <p>
                <i>*Characteristics of Herpes Zoster</i>
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <FormButton ButtonName={'CONSULT / BOOK'} />
            </div>
          </div>
        </div>
      </section>

      {/* Mobile Code */}
      <section className="container mt-5">
        <div className="sites-mobile">
          <div className="wrap-sites">
            <div className="sec-title text-center textleft">
              <h2 className="textleft fontSize50 wrap-sites-item12">
                <span className="headeingcolorblue">SITES </span>
                <span className="headeingcolorblack">
                  {" "}
                  WHERE SHINGLES OCCUR
                </span>
              </h2>
            </div>
            <div className="wrap-img-sec7">
              <img
                srcset={require("../../assets/images/shape/Mobile12345.webp")}
                alt=""
                className="imgwidth3"
                rel="preload"
              />
            </div>
            <div className="parg12-item mb-5">
              <p>
                <i>*Characteristics of Herpes Zoster</i>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="desk-item backGroundRight mobilehide">
        <div className="container-fulid backimg2">
          <div className="container">
            <ShinglessTab />
          </div>
        </div>
      </div>

      <div className="desk-item backGroundRight d-lg-none">
        <div className="container-fulid backimg2">
          <div className="container">
            <FaqAccordion />
          </div>
        </div>
      </div>

      {/* MORE ABOUT SHINGLE */}
      <section>
        <div className="more-desktop">
          <div className="wrap-sites">
            <div className="sec-title text-center textleft">
              <h2 className="wrap-sites-poss">
                <span className="headeingcolorblack">MORE ABOUT </span>
                <span className="headeingcolorblue"> SHINGLES</span>
              </h2>
            </div>
            <div className="wrap-img-sec7">
              <img
                srcset={require("../../assets/images/shape/Shingles8.webp")}
                alt=""
                className="imgwidth3"
                rel="preload"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Mobile code */}
      <section className="mt-3">
        <div className="more-mobile">
          <div className="wrap-sites">
            <div className="sec-title text-center textleft">
              <h2 className="wrap-sites-poss1">
                <span className="headeingcolorblack">MORE ABOUT </span>
                <span className="headeingcolorblue"> SHINGLES</span>
              </h2>
            </div>
            <div className="wrap-img-sec7">
              <img
                srcset={require("../../assets/images/shape/mob12.webp")}
                alt=""
                className="imgwidth3"
                rel="preload"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="backFaqsRight" id="section-3">
        <div className="container-fulid backFaqsLeft">
          <div className="auto-container">
            <TabAccordian/>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default shingles;
