/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import Header from "../layout/headerHome";
import Footer from "../layout/footerUpdated";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const siteUrl = `https://admin.bridgehealth.in`;
const publicUrl = `https://bridgehealth.in`;



class blog_shingles extends Component {

  constructor(props) {
    super(props);

    this.state = {
      blog: [],
      Rel_Blogs: [],
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }


  shareOnSocialMedia = (platform) => {
    const shareURL = "https://bridgehealth.in/";

    switch (platform) {
      case "instagram":
        // Open Instagram app with pre-filled caption
        window.open(
          `instagram://library?AssetPath=${encodeURIComponent(shareURL)}`
        );
        break;
      case "linkedin":
        // Open LinkedIn share dialog with pre-filled URL
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareURL)}`
        );
        break;
      case "facebook":
        // Open Facebook share dialog with pre-filled URL
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareURL)}`
        );
        break;
      case "twitter":
        // Open Twitter share dialog with pre-filled tweet
        const tweetText = "Check out this link: " + shareURL;
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`
        );
        break;
      default:
        break;
    }
  }


  fetchData = () => {
    const { match } = this.props;
    const blogSlug = match.params.Slug;


    fetch(`${siteUrl}/api/blog-listings?filters[Slug][$eq]=${blogSlug}&populate=*`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          blog: data.data[0],
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        this.setState({
          blog: [],
          loading: false,
          error: error.message,
        });
      });


    fetch(`${siteUrl}/api/blog-listings?filters[Slug][$ne]=${blogSlug}&sort=id:desc&pagination[start]=0&pagination[limit]=3&populate=*`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          Rel_Blogs: data.data,
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        this.setState({
          Rel_Blogs: [],
          loading: false,
          error: error.message,
        });
      });
  };




  render() {
    const { blog, Rel_Blogs, loading, error } = this.state;

    return (
      <>
        <Header />
        <Helmet>
          <title>
            Personalized Care Continuum -Medical & Digital Health Services
            |Bridge Health
          </title>
          <meta
            name="Blog Shingles"
            content="Bridge Health is one stop health solution for all preventive & holistic healthcare with personalized annual health plans. & There is a plan to fit the specific health needs of everybody - your spouse, parents, kids & YOU. Know more about Bridge Health, JP Nagar 2nd phase Bangalore."
          />
        </Helmet>

        <div></div>
        <div></div>
        <section className="s1-wrap-a1">
          {blog?.attributes?.image?.data &&
            <div className="banner-image">
              <img className="w-100" src={siteUrl + blog?.attributes?.image?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
            </div>
          }
          <div className="p-5 ">
            {/* <div class="d-flex justify-content-between pb-2">
              <h2 className="text-start">{blog?.attributes?.blog_title}</h2>
              <Link className="d-flex justify-content-between align-items-center" to={`https://wa.me?text=${publicUrl}/blogs/${blog.attributes?.Slug}`}>
                <p className="col-6">Share: Whatsapp </p>
                <img className="col-6" src="/whatsapp.webp" style={{ width: `30px` }}></img>
              </Link>
            </div> */}
            <div className="row g-8">
              <div className="col-lg-9 pr-5">
                <h1 className="">{blog?.attributes?.blog_title}</h1>
                <div className="def-richtext-wrap">{blog?.attributes?.blog_paragraph && parseMarkdown(blog.attributes.blog_paragraph)}</div>
                <div className="def-richtext-wrap">{blog?.attributes?.paragraph_1 && parseMarkdown(blog.attributes.paragraph_1)}</div>
                {blog?.attributes?.image_1?.data &&
                  (blog?.attributes?.image_1?.data?.attributes?.caption !== "" ?
                  <a href={blog?.attributes?.image_1?.data?.attributes?.caption} target="_blank" className="sec1-image mb-4">
                    <img className="w-100" src={siteUrl + blog?.attributes?.image_1?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
                  </a> : <img className="w-100" src={siteUrl + blog?.attributes?.image_1?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />)
                }
                <div className="def-richtext-wrap">{blog?.attributes?.paragraph_2 && parseMarkdown(blog.attributes.paragraph_2)}</div>
                {blog?.attributes?.image_2?.data &&
                  (blog?.attributes?.image_2?.data?.attributes?.caption !== "" ?
                  <a href={blog?.attributes?.image_2?.data?.attributes?.caption} target="_blank" className="sec1-image mb-4">
                    <img className="w-100" src={siteUrl + blog?.attributes?.image_2?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
                  </a> : <img className="w-100" src={siteUrl + blog?.attributes?.image_2?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />)
                }
                
                <div className="def-richtext-wrap">{blog?.attributes?.paragraph_3 && parseMarkdown(blog.attributes.paragraph_3)}</div>
                {blog?.attributes?.image_3?.data &&
                  (blog?.attributes?.image_3?.data?.attributes?.caption !== "" ?
                  <a href={blog?.attributes?.image_3?.data?.attributes?.caption} target="_blank" className="sec1-image mb-4">
                    <img className="w-100" src={siteUrl + blog?.attributes?.image_3?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
                  </a> : <img className="w-100" src={siteUrl + blog?.attributes?.image_3?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />)
                }
                <div className="def-richtext-wrap">{blog?.attributes?.paragraph_4 && parseMarkdown(blog.attributes.paragraph_4)}</div>
                {blog?.attributes?.image_4?.data &&
                  (blog?.attributes?.image_4?.data?.attributes?.caption !== "" ?
                    <a href={blog?.attributes?.image_4?.data?.attributes?.caption} target="_blank" className="sec1-image mb-4">
                      <img className="w-100" src={siteUrl + blog?.attributes?.image_4?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
                    </a> : <img className="w-100" src={siteUrl + blog?.attributes?.image_4?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
                  )
                }
                <div className="def-richtext-wrap">{blog?.attributes?.paragraph_5 && parseMarkdown(blog.attributes.paragraph_5)}</div>
                {blog?.attributes?.image_5?.data &&
                  (blog?.attributes?.image_5?.data?.attributes?.caption !== "" ?
                    <a href={blog?.attributes?.image_5?.data?.attributes?.caption} target="_blank" className="sec1-image mb-4">
                      <img className="w-100" src={siteUrl + blog?.attributes?.image_5?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
                    </a> : <img className="w-100" src={siteUrl + blog?.attributes?.image_5?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
                  )
                }
                <div className="def-richtext-wrap mb-4">{blog?.attributes?.paragraph_6 && parseMarkdown(blog.attributes.paragraph_6)}</div>

              </div>
              <div className="col-lg-3">
                <div className="bg-head1 mb-5">
                  <div className="sec-title-blog1">
                    <div className="text-blog">
                      <h5 className="side-hed">Share:</h5>

                      {/* <a href={`https://twitter.com/intent/tweet?url=${publicUrl}/blogs/${blog.attributes?.Slug}`} target="_blank">
                        <img
                          srcSet={require("../../assets/images/shape/icon1.webp")}
                          alt="instagram"
                          className="testthumb1"
                          rel="preload"
                        />
                      </a> */}
                      <div className="social-share">

                        <a href={`https://www.linkedin.com/shareArticle?url=${publicUrl}/blogs/${blog.attributes?.Slug}`} target="_blank">
                          <img
                            srcSet={require("../../assets/images/shape/icon2.webp")}
                            alt="linkedin"
                            className=""
                            onClick={() => this.shareOnSocialMedia("linkedin")}
                            rel="preload"
                          />
                        </a>
                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${publicUrl}/blogs/${blog.attributes?.Slug}`} target="_blank">
                          <img
                            srcSet={require("../../assets/images/shape/icon3.webp")}
                            alt="facebook"
                            className=""
                            onClick={() => this.shareOnSocialMedia("facebook")}
                            rel="preload"
                          />
                        </a>

                        <a href={`https://twitter.com/intent/tweet?url=${publicUrl}/blogs/${blog.attributes?.Slug}`} target="_blank">
                          <img
                            srcSet={require("../../assets/images/shape/icon4.webp")}
                            alt="twitter"
                            className=""
                            onClick={() => this.shareOnSocialMedia("twitter")}
                            rel="preload"
                          />
                        </a>

                        <a className="whatsapp-icon" href={`https://wa.me?text=${publicUrl}/blogs/${blog.attributes?.Slug}`} target="_blank">
                          <img src="/wa_icon.png" alt="whatsapp" />
                        </a>
                      </div>
                    </div>
                    <div className="text-align-start">
                      <Link to='/blogs'>
                        Back to the Blogs
                      </Link>
                    </div>
                  </div>
                </div>
                <h3 className="mb-5">Suggested Blogs</h3>
                <div className="">
                  {Rel_Blogs.map((item, index) => {
                    return (
                      <a key={index} className="rel-blog-uu2 mb-5" href={`/blogs/${item.attributes?.Slug}`}>
                        <div className="blog-image">
                          <img className="w-100" src={siteUrl + item.attributes?.image?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
                        </div>
                        <div className="auth-ff2">
                          <div className="auth-image-b4">
                            <img className="" src={siteUrl + item.attributes?.image?.data?.attributes?.url} alt={blog?.attributes?.blog_title} />
                          </div>
                          <p className="">{item.attributes?.author_name}</p>
                        </div>
                        <h4>{item.attributes?.blog_title}</h4>
                      </a>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default blog_shingles;





function parseMarkdown(markdown) {
  // Replace images
  markdown = markdown.replace(/!\[(.*?)\]\((.*?)\)/g, '<img src="$2" alt="$1">');

  // Replace links
  markdown = markdown.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>');

  // Replace blockquotes
  markdown = markdown.replace(/^\>(.*)$/gm, '<blockquote>$1</blockquote>');

  // Replace headings
  markdown = markdown.replace(/^###### (.*)$/gm, '<h6>$1</h6>');
  markdown = markdown.replace(/^##### (.*)$/gm, '<h5>$1</h5>');
  markdown = markdown.replace(/^#### (.*)$/gm, '<h4>$1</h4>');
  markdown = markdown.replace(/^### (.*)$/gm, '<h3>$1</h3>');
  markdown = markdown.replace(/^## (.*)$/gm, '<h2>$1</h2>');
  markdown = markdown.replace(/^# (.*)$/gm, '<h1>$1</h1>');

  // Replace bold text
  markdown = markdown.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

  // Replace italic text
  markdown = markdown.replace(/\_(.*?)\_/g, '<i>$1</i>');

  // Replace underline text
  markdown = markdown.replace(/\<u\>(.*?)\<\/u\>/g, '<u>$1</u>');

  // Replace strikethrough text
  markdown = markdown.replace(/\~\~(.*?)\~\~/g, '<del>$1</del>');

  // Replace unordered lists
  markdown = markdown.replace(/^\s*[\*\-\+]\s+(.*)$/gm, '<ul><li>$1</li></ul>');
  markdown = markdown.replace(/<\/ul>\n<ul>/g, ''); // Combine adjacent <ul> tags

  // Replace ordered lists
  markdown = markdown.replace(/^\s*\d+\.\s+(.*)$/gm, '<ol><li>$1</li></ol>');
  markdown = markdown.replace(/<\/ol>\n<ol>/g, ''); // Combine adjacent <ol> tags

  // Replace code blocks
  markdown = markdown.replace(/```([\s\S]*?)```/g, '<pre><code>$1</code></pre>');

  // Replace inline code
  markdown = markdown.replace(/`([^`]+)`/g, '<code>$1</code>');

  // Replace line breaks
  markdown = markdown.replace(/\n/g, '<br>');

  return <div dangerouslySetInnerHTML={{ __html: markdown.trim() }} />;
}