import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header';
import Footer from '../layout/footer';
import { Helmet } from 'react-helmet';

const Thankyou = () => {
    return (
        <>
            <Header />
            <Helmet>
                <title>Thank You Page</title>
                <meta
                    name="description"
                    content="Vaccination for Shingles at Bridge Health: Herpes Zoster, caused by the varicella-zoster virus. Discover symptoms, Vaccines, treatments, & solutions for effective management."
                />
            </Helmet>
            <section className="internalpage1 thankyou-bg">
                <div className="auto-container">
                    <div className="content-box">
                        <div className="text-center textleft">
                            <img srcSet={require('../../assets/images/shape/thankyouimg.webp')} alt="thankyou"
                                className='thankyou-main-img' rel="preload"

                            />
                            <h1 >

                                <span className='headeingcolorblack thank-mob-head'>Thank you for getting in touch with us!</span>
                                <p className='thank-line'>We will get back to you shortly.</p>
                            </h1>
                            <div>
                                <div className="btn-box text-center flex-thank-btn">
                                    <Link to="/" className="btn-thank">
                                        Take me to Homepage
                                    </Link>
                                    <Link to="/personal-membership-program" className="btn-two-thank">
                                        Personalised Health Plans
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Thankyou;
