import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
class CovidTab extends Component {
    render() {
        return (
            <>
                <section className="container">
                    <div className="shi-desk">
                        <div className="sec-title text-center textleft">
                            <h2 className='textleft fontSize50 wrap-sites-poss'>
                                <span className='headeingcolorblack'>RISK FACTORS </span>
                                <span className='headeingcolorblue'> OF COVID</span>
                            </h2>
                        </div>
                    </div>
                    {/* Mobile Code  */}
                    <div className="shi-mob">
                        <div className="sec-title text-center textleft">
                            <h2 className='textleft fontSize50 wrap-sites-poss shi1-mob1'>
                                <span className='headeingcolorblack'>RISK FACTORS </span>
                                <span className='headeingcolorblue'> OF COVID</span>
                            </h2>
                        </div>
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col sm={5}>
                                <Nav variant="pills" className="flex-column text-center">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Respiratory Distress</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Blood Clots</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">Heart Problems</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="four">Long COVID</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="five">Kidney Damage </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="six">Neurological Complications</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={7}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div id="image_para_section">
                                            <img srcset={require('../../assets/images/shape/Respiratory.webp')} rel="preload"
                                                alt="respiratory" className='image_shingles' />
                                            <p className="paragraph_after_image mt-2">
                                                COVID-19 often leads to severe respiratory complications, including pneumonia and acute respiratory distress syndrome (ARDS),
                                                where the lungs become severely inflamed and filled with fluid. This can result in difficulty breathing and the need for ventilator support.
                                            </p>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div id="image_para_section">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/shape/Blood Clots.webp')} alt="clots" className='image_shingles' />
                                            <p className="paragraph_after_image mt-2">
                                                COVID-19 has been linked to an increased risk of blood clots, which can lead to serious conditions such as deep vein thrombosis (DVT), pulmonary embolism (PE), or stroke.
                                            </p>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <div id="image_para_section">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/shape/Heart Problem.webp')} alt="problem" className='image_shingles' />
                                            <p className="paragraph_after_image mt-2">
                                                COVID-19 can cause heart inflammation (myocarditis) and lead to cardiac issues, including arrhythmias, heart attacks, and heart failure,
                                                especially in those with preexisting cardiovascular conditions.
                                            </p>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="four">
                                        <div id="image_para_section">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/shape/Long covid.webp')} alt="covid" className='image_shingles' />
                                            <p className="paragraph_after_image mt-2">
                                                Some individuals experience lingering symptoms long after the acute phase of the illness, a condition known as "long COVID" or "post-acute sequelae of SARS-CoV-2 infection" (PASC).
                                                These symptoms can include fatigue, brain fog, and a range of other persistent health issues.
                                            </p>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="five">
                                        <div id="image_para_section">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/shape/Kidney Damage.webp')} alt="kidney" className='image_shingles' />
                                            <p className="paragraph_after_image mt-2">
                                                COVID-19 can harm the kidneys, leading to acute kidney injury (AKI) or exacerbating preexisting kidney conditions.
                                                This can result in the need for dialysis or kidney transplantation.
                                            </p>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="six">
                                        <div id="image_para_section">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/shape/Neurological.webp')} alt="neurological" className='image_shingles' />
                                            <p className="paragraph_after_image mt-2">
                                                Some individuals experience neurological issues related to COVID-19, including loss of taste and smell, headaches, dizziness, and, in more severe cases,
                                                encephalitis or Guillain-Barré syndrome.
                                            </p>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </section>

            </>
        )
    }
}
export default CovidTab;