import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

const validationSchema = Yup.object({
    username: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    phone: Yup.string().matches(/^[0-9]{10}$/, "Phone number must be 10 digits").required("Phone number is required"),
    message: Yup.string().required("Message is required"),
    gender: Yup.string().required("Gender is required"),
    position: Yup.string().required("Position is required"),
    dob: Yup.date().required("Date of Birth is required"),
    resume: Yup.mixed().required("Resume is required")
        .test("fileSize", "File size is too large", value => value && value.size <= 3 * 1024 * 1024), // 3MB
    agree: Yup.boolean().oneOf([true], "You must accept the terms and conditions")
});

const ApplyNowxxForm = ({ submitted }) => {
    const [thank, setThank] = useState(false);

    const handleSubmit = async (values, { resetForm }) => {
        try {

            const formData = new FormData();
            formData.append('files', values.resume);

            const uploadResponse = await axios.post(`https://admin.bridgehealth.in/api/upload`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            const ResumeID = uploadResponse.data[0].id;

            const applyData = {
                Name: values.username,
                Email: values.email,
                Mobile: values.phone,
                Message: values.message,
                Gender: values.gender,
                Position: values.position,
                Date_of_Birth: values.dob,
                Resume: ResumeID
            };

            const res = await axios.post(`https://admin.bridgehealth.in/api/apply-forms`, { data: applyData });
            if (res.status === 200) {
                setThank(true);
                resetForm();
                setTimeout(() => {
                    setThank(false);
                    submitted();
                }, 3000);
            }

        } catch (error) {
            console.log("Error sending data:", error);
        }
    };

    return (
        <div className="px-1 py-1">
            <Formik
                initialValues={{ username: "", email: "", phone: "", message: "", gender: "", position: "", dob: "", resume: null, agree: false }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form className="def-form row" id="">
                        <div className="col-lg-12">
                            <Field name="username" type="text" className="form-control" placeholder="Name*" />
                            <ErrorMessage name="username" component="div" className="error" />
                        </div>
                        <div className="col-lg-6">
                            <Field name="email" type="email" className="form-control" placeholder="Email*" />
                            <ErrorMessage name="email" component="div" className="error" />
                        </div>
                        <div className="col-lg-6">
                            <Field name="phone" type="text" className="form-control" placeholder="Phone*" />
                            <ErrorMessage name="phone" component="div" className="error" />
                        </div>
                        <div className="col-lg-12">
                            <Field
                                name="message"
                                as="textarea"
                                className="form-control"
                                placeholder="Message*"
                                style={{ height: '400px' }} // Adjust the height here
                            />
                            <ErrorMessage name="message" component="div" className="error" />
                        </div>
                        <div className="col-lg-6">
                            <Field name="gender" as="select" className="form-control">
                                <option value="">Select Gender*</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                            </Field>
                            <ErrorMessage name="gender" component="div" className="error" />
                        </div>
                        <div className="col-lg-6">
                            <Field name="position" as="select" className="form-control">
                                <option value="">Select Position*</option>
                                <option value="developer">Developer</option>
                                <option value="designer">Designer</option>
                                <option value="manager">Manager</option>
                                <option value="other">Other</option>
                            </Field>
                            <ErrorMessage name="position" component="div" className="error" />
                        </div>
                        <div className="col-lg-6">
                            <Field name="dob" type="date" className="form-control" placeholder="Date of Birth*" />
                            <ErrorMessage name="dob" component="div" className="error" />
                        </div>
                        <div className="col-lg-6">
                            <input
                                name="resume"
                                type="file"
                                className="form-control"
                                onChange={(event) => setFieldValue("resume", event.currentTarget.files[0])}
                            />
                            <ErrorMessage name="resume" component="div" className="error" />
                        </div>
                        <div className="col-lg-12">
                            <div className="form-check-v3 d-flex">
                                <Field
                                    name="agree"
                                    type="checkbox"
                                    className="checkBoxNew"
                                    id="agreeCheckbox"
                                />
                                <label className=" ms-2 p-0 m-0" htmlFor="agreeCheckbox">
                                    I agree that Bridge Health may contact me at the email address or phone number above.
                                </label>
                            </div>
                            <ErrorMessage name="agree" component="div" className="error" />
                        </div>

                        <div className="d-flex justify-content-center align-items-center w-100">
                            <button type="submit" className="def-btn " disabled={isSubmitting}>
                                SUBMIT
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
            {thank && <div className="mt-4 text-center">Thanks for reaching out!</div>}
        </div>
    );
};

export default ApplyNowxxForm;
