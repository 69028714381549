import React from "react";
import JobListingForm from "./JobListingForm";
class Sidenav extends React.Component {
  render() {
    return (
      <>
        <div>
          <div className="row">
            <div className="col-lg-3">
              <nav id="sidebar">
                <div className="sidebar-header">
                  <img
                    className="sidebar_logo"
                    srcset={require("../../assets/images/shape/logo_bridgehealth.webp")}
                    alt="Bridge Health"
                    rel="preload"
                  />
                </div>

                <ul className="list-unstyled components">
                  <li>
                    <button
                      title="Dashboard"
                      className="on_hover_components"
                    >
                      Dashboard
                    </button>
                  </li>

                  <li>
                    <button
                      title=" Add New Job"
                      className="on_hover_components"
                    >
                      Add New Job
                    </button>
                  </li>
                  <li>
                    <button
                      title="Job listings"
                      className="on_hover_components"
                    >
                      Job listings
                    </button>
                  </li>
                  <li>
                    <button
                      title="Settings"
                      className="on_hover_components"
                    >
                      Settings
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-11">
                  <JobListingForm />
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="sidebarmargin d-none">
          <div className="auto-container">
            <div className="row">
              <div className="contact-form-area">
                <JobListingForm />
                {/* <!-- Contact Form--> */}
                <div className="contact-form">
                  <form method="post">
                    <div className="row clearfix">
                      <div className="col-md-12 form-group">
                        <input
                          type="text"
                          name="username"
                          placeholder="Name*"
                          // required
                        />
                      </div>

                      <div className="col-md-6 form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          // required
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          type="text"
                          name="phone"
                          placeholder="Phone*"
                          // required
                        />
                      </div>

                      <div className="col-md-12 form-group">
                        <textarea
                          name="message"
                          placeholder="Message"
                        ></textarea>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          value="good"
                          required
                          aria-label="checkbox"
                        />
                        <label className="form-check-label heading">
                          I agree that Bridge Health may contact me at the email
                          address or phone number above.
                        </label>
                      </div>
                      <div className="col-md-12 form-group">
                        <div className="btn-box text-center">
                          <button
                            className="submitcontact"
                            type="submit"
                            name="submit-form"
                          >
                            SUBMIT
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Sidenav;
