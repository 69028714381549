import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import FormButton from "../element/component/FormButton";

const aboutbg = require("./../../assets/images/shape/Dietandnutrition.webp");

class diet_and_nutrition extends Component {
  componentDidMount() {
    document.title = "Diet & Nutrition | Bridge Health";
  }

  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Best clinical Nutritionists In Bangalore - Book Appointment - Bridge
            Health
          </title>
          <meta
            name="description"
            content="Best Nutritionists in Bangalore. Personalized diet and nutrition services. Book  Dietitian Appointment now. Connect with our top nutritionists in Bangalore."
          />
        </Helmet>
        <section
          className="internalpage internalpageresponsive style-two image-mobile-terms"
          style={{ backgroundImage: "url(" + aboutbg + ")" }}
        >
          <div className="auto-container">
            <div className="content-box">
              <div className=" text-center textleft">
                <h2 className="textleft fontSize">
                  <span className="headeingcolorblack"> EAT RIGHT. </span>
                  <br></br>
                  <span className="headeingcolorblue"> LIVE RIGHT. </span>
                </h2>
                <h3 className="textleft marginTop40">
                  Talk to our clinical nutritionists<br></br> and start eating
                  healthy
                </h3>
              </div>
              <FormButton/>
            </div>
          </div>
        </section>

        <section className="contact-section">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/nu3.webp")}
                  alt=""
                  className="width100 marginTop40reverse"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div className="sec-title squrebox">
                  <h2>
                    {" "}
                    <span className="headeingcolorblue">
                      YOU ARE WHAT YOU EAT
                    </span>
                  </h2>
                  <p className="font22">
                    It’s no secret that eating a balanced diet is the key to a
                    longer and healthier life. Therefore, it’s important to
                    understand that making wise changes in your diet could
                    improve your health. Having paroper knowledge about which
                    foods to consume and limit or avoid is the first step in
                    eating healthy.
                  </p>
                </div>
                <FormButton/>
              </div>
            </div>
          </div>
        </section>

        <div className="auto-container">
          <img
            srcset={require("../../assets/images/shape/dieat.webp")}
            alt=""
            className="width100"
            rel="preload"
          />
        </div>
        <section className="team-section">
          {/* About Section Two */}
          <section>
            <div className="auto-container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div>
                    <h2 className="header1">
                      <span className="headeingcolorblack"> What We </span>
                      <span className="headeingcolorblue"> Offer</span>
                    </h2>
                  </div>
                  <div className="content-box">
                    <ul className="listicon">
                      <li>
                        Reduce the risk of diseases like diabetes, heart
                        disease, kidney disease, hypertension, PCOS, and thyroid
                        through diet modification plans
                      </li>
                      <li>Weight management for maintaining a healthy BMI</li>
                      <li>
                        Reverse prediabetes status with activity & specific diet
                        plan
                      </li>
                      <li>
                        Maintain a healthy lifestyle and reduce stress levels by
                        including the right nutrients in meals
                      </li>
                    </ul>
                    <FormButton/>
                  </div>
                </div>

                <div className="col-lg-6">
                  <img
                    srcset={require("../../assets/images/shape/nu5.webp")}
                    alt=""
                    rel="preload"
                  />
                </div>
              </div>
            </div>
          </section>
        </section>
        {/*
    <!-- Contact Section --> */}
        <section className="contact-section">
          <div className="auto-container">
            <div className="row align-items-center reverseFlex">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/nu10.webp")}
                  alt=""
                  rel="preload"
                />
              </div>

              <div className="col-lg-6">
                <h2 className="header1">
                  <span className="headeingcolorblack"> The</span>
                  <span className="headeingcolorblue"> Bridge Health</span>
                  <span className="headeingcolorblack"> Advantage </span>
                </h2>

                <ul className="listicon">
                  <li>
                    Inclusion of the right mix of macro & micronutrients based
                    on Bridge Health’s nutritional approach to lower the insulin
                    needed by the body
                  </li>
                  <li>
                    Inclusion of Biota nutrients (prebiotics and probiotics) to
                    strengthen your gut microbiomes
                  </li>
                  <li>
                    Inclusion of natural antioxidants, anti-inflammatory
                    nutrients, and adaptogens to reduce oxidative stress and
                    inflammation
                  </li>
                  <li>
                    Inclusion of organic, unprocessed & toxin-free ingredients
                    to ensure no harmful additives enter your system
                  </li>
                  <li>
                    Inclusion of the right amount of all essential nutrients to
                    ensure good health and satisfy the respective dietary
                    reference intakes.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2 className="header1">
                <span className="headeingcolorblack"> The Secret to a</span>
                <span className="headeingcolorblue"> Healthier Life</span>
                <span className="headeingcolorblack"> is a </span>
                <span className="headeingcolorblue"> Healthier Diet!</span>
              </h2>
            </div>
            <div className="d-flex justify-content-center pb-4">
                <FormButton ButtonName={'CONSULT / BOOK'} />
              </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default diet_and_nutrition;
