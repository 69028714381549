import React, { useState } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Helmet } from "react-helmet";
import ApplyNowxxForm from "../element/component/ApplyNowxxForm";
const aboutbg = require("./../../assets/images/shape/car1.webp");

const FormComponent = () => {
  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   mobile: "",
  //   gender: "",
  //   position: "",
  //   dob: "",
  //   resume: "",
  // });

  // const [errors, setErrors] = useState({});

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  // const validateForm = () => {
  //   let formErrors = {};
  //   if (!formData.name) {
  //     formErrors.name = "Name is required";
  //   }

  //   if (!formData.email) {
  //     formErrors.email = "Email is required";
  //   } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
  //     formErrors.email = "Invalid email format";
  //   }
  //   if (!formData.mobile) {
  //     formErrors.mobile = "Mobile is required";
  //   }

  //   if (!formData.gender) {
  //     formErrors.gender = "Gender is required";
  //   }

  //   if (!formData.position) {
  //     formErrors.position = "Position is required";
  //   }

  //   if (!formData.dob) {
  //     formErrors.dob = "Date of birth is required";
  //   }


  //   if (!formData.resume) {
  //     formErrors.resume = "Resume is required";
  //   }

  //   setErrors(formErrors);
  //   return true;
  // };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   const { name, type, value, files } = e.target;
  //   if (file && file.size > 3 * 1024 * 1024) {
  //     setErrors({ ...errors, resume: "File size exceeds 3 MB" });
  //   } else {
  //     setFormData({ ...formData, resume: file });
  //     setErrors({ ...errors, resume: "" });
  //   }

  //   if (!["submit", "file"].includes(type)) {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       [name]: value,
  //     }));
  //   } else if (type === "file") {

  //     setFormData((prevData) => ({
  //       ...prevData,
  //       [name]: files[0],
  //     }));
  //   }
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (validateForm()) {
  //     const jobUrl = `https://admin.bridgehealth.in`;
  //     const apiUrl = `${jobUrl}/api/applied-candidates`;

  //     const data = {
  //       full_name: formData.name,
  //       email_id: formData.email,
  //       contact_no: formData.mobile,
  //       dob: formData.dob,
  //       gender: formData.gender,
  //       applied_position: formData.position,
  //     };
  //     const formDataToSend = new FormData();
  //     formDataToSend.append("data", JSON.stringify(data));
  //     formDataToSend.append("files.uploded_resume", formData.resume);
  //     try {
  //       const response = await fetch(apiUrl, {
  //         method: "POST",
  //         body: formDataToSend,
  //       });

  //       if (response.ok) {
  //         const responseData = await response.json();

  //         if (responseData.data) {

  //           alert("Application submitted successfully!"); 

  //           setFormData({
  //             name: "",
  //             email: "",
  //             mobile: "",
  //             gender: "",
  //             position: "",
  //             dob: "",
  //             resume: null,
  //           });
  //         } else {
  //           console.error("Invalid response format");

  //         }
  //       } else {
  //         console.error("Error submitting form");

  //       }
  //     } catch (error) {
  //       console.error("Network error", error);
  //     }
  //   }
  // };

  return (
    <>
      <>
        <Header />
        {/* <!-- Page Banner Section --> */}
        <Helmet>
          <title>
            Personalized Care Continuum -Medical & Digital Health Services
            |Bridge Health
          </title>
          <meta
            name="Career form"
            content="Bridge Health is one stop health solution for all preventive & holistic healthcare with personalized annual health plans. & There is a plan to fit the specific health needs of everybody - your spouse, parents, kids & YOU. Know more about Bridge Health, JP Nagar 2nd phase Bangalore."
          />
        </Helmet>
        <section className="page-banner image-mobile-terms">
          <div
            className="page-banner-bg background-size-cover"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div className="bottom-rotten-curve alternate"></div>
        </section>

        <section className="team-section padding backimg3 py-4">
          <h2 className=" fontSize text-center">
            <span className="headeingcolorblue"> Apply </span>
            <span className="headeingcolorblack"> Now </span>
          </h2>
          <div className="col-lg-8 mx-auto py-4">
            <ApplyNowxxForm />
          </div>
          {/* <form
            onSubmit={handleSubmit}
            className="container"
            encType="multipart/form-data"
          >
            <div className="row align-items-center ">
              <div className="col-md-6 form-group">
                <label htmlFor="name" className="form-label" placeholder="Name">
                  Name:
                </label>
                <input
                  type="text"
                  name="name"
                  className={`form-control ${errors.name ? "is-invalid" : ""}`}
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
              </div>
              <div className="col-md-6 form-group">
                <label
                  htmlFor="email"
                  className="form-label"
                  placeholder="Email"
                >
                  Email:
                </label>
                <input
                  type="email"
                  name="email"
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>

              <div className="col-lg-6 form-group">
                <label
                  htmlFor="mobile"
                  className="form-label"
                  placeholder="Mobile"
                >
                  Mobile:
                </label>
                <input
                  type="text"
                  name="mobile"
                  className={`form-control ${
                    errors.mobile ? "is-invalid" : ""
                  }`}
                  value={formData.mobile}
                  onChange={handleChange}
                />
                {errors.mobile && (
                  <div className="invalid-feedback">{errors.mobile}</div>
                )}
              </div>
              <div className="col-lg-6 form-group">
                <label htmlFor="gender" className="form-label">
                  Gender:
                </label>
                <select
                  name="gender"
                  className="selectbox"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                {errors.gender && (
                  <div className="invalid-feedback">{errors.gender}</div>
                )}
              </div>

              <div className="col-lg-6 form-group">
                <label htmlFor="position" className="form-label">
                  Position Applying:
                </label>
                <select
                  name="position"
                  className="selectbox"
                  value={formData.position}
                  onChange={handleChange}
                >
                  <option value="">Select Position</option>
                  <option value="Clinical Services">Clinical Services</option>
                  <option value="Operations">Operations</option>
                  <option value="Corporate Operations">
                    Corporate Operations
                  </option>
                  <option value="Corporate Sales">Corporate Sales</option>
                  <option value="Marketing">Marketing</option>
                  <option value="Technology">Technology</option>
                  <option value="Pharmacy">Pharmacy</option>
                  <option value="Sales & Operations">Sales & Operations</option>
                  <option value="Finance">Finance</option>
                  <option value="HR">HR</option>
                  <option value="Facility & Admin">Facility & Admin</option>
                  <option value="Strategic Initiatives">
                    Strategic Initiatives
                  </option>
                  <option value="Affiliates & Alliances">
                    Affiliates & Alliances
                  </option>
                </select>
                {errors.position && (
                  <div className="invalid-feedback">{errors.position}</div>
                )}
              </div>

              <div className="col-lg-6 form-group">
                <label
                  htmlFor="dob"
                  className="form-label"
                  placeholder="Date of Birth"
                >
                  Date of Birth:
                </label>
                <input
                  type="date"
                  name="dob"
                  className={`form-control ${errors.dob ? "is-invalid" : ""}`}
                  value={formData.dob}
                  onChange={handleChange}
                />
                {errors.dob && (
                  <div className="invalid-feedback">{errors.dob}</div>
                )}
              </div>
              <div className="col-lg-6 form-group">
                <label htmlFor="resume" className="form-label">
                  Upload Resume:
                </label>
                <input
                  type="file"
                  name="resume"
                  accept=".pdf,.doc,.docx"
                  className={`form-control ${
                    errors.resume ? "is-invalid" : ""
                  }`}
                  onChange={handleFileChange}
                />
                <p className="file-size">
                  Upload a file with a maximum size of 3 MB.
                </p>
                {errors.resume && (
                  <div className="invalid-feedback">
                    {errors.resume}
                    <p className="file-size">
                      Please upload a file with a maximum size of 3 MB.
                    </p>
                  </div>
                )}
              </div>
              <div className="col-lg-12">
                <div className="btn-box text-center btn5">
                  <button
                    className=" submitcontact"
                    type="submit"
                    name="submit-form"
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </form> */}
        </section>
      </>
      <Footer />
    </>
  );
};

export default FormComponent;
