import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Helmet } from "react-helmet";

class privacyPolicies extends Component {
  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>Privacy Policies</title>
          <meta
            name="description"
            content="Vaccination for Shingles at  Bridge Health Medical and Digital Solution Private Limited: Herpes Zoster, caused by the varicella-zoster virus. Discover symptoms, Vaccines, treatments, & solutions for effective management."
          />
        </Helmet>
        <section>
          <section className="term-policy auto-container image-mobile-terms">
            <div className="terms-heading">
              <h2 className="privacy-heading">Privacy Policy</h2>
              <div className="mt-2 text-align-center d-flex justify-content-center">
                {/* <button
                  className="submitcontact disply-on-mobile "
                  type="submit"
                  name="submit-form"
                >
                  Share
                </button> */}
              </div>
              <p>
                <br></br>
                <br></br>
                This Privacy Policy describes Our policies and procedures on the
                collection, use and disclosure of Your information when You use
                the Service and tells You about Your privacy rights and how the
                law protects You. We use Your Personal data to provide and
                improve the Service. By using the Service, You agree to the
                collection and use of information in accordance with this
                Privacy Policy.
              </p>
            </div>
            <div>
              <h4>Preface</h4>
              <p>
                 Bridge Health Medical and Digital Solution Private Limited Medical & Digital Solutions Pvt., Ltd., a company
                incorporated under the laws of India having its registered
                office at, No.166-167, 21st Main Road, 100 Feet Ring Road, 2nd
                Phase, JP Nagar, Bangalore - 560078 (hereinafter referred to as{" "}
                <strong>" Bridge Health Medical and Digital Solution Private Limited"</strong> ) has devised, adopted,
                implemented and is operating the app and website (including but
                not limited to software applications, mobile applications,
                hereinafter referred to as Digital Platform) for providing
                catena of Services.
              </p>
            </div>
            <div>
              <h4>Privacy Policy</h4>

              <p>
                This Privacy Policy (<strong>"Policy"</strong>) is made, and
                hosted in accordance with the following Information Technology
                Laws,
                <br></br>
                <br></br>
                <div>
                  a.  Information Technology Act, 2000
                  <br></br>
                  <br></br>
                  b. Rule 3(1)(a) of the Information Technology (Intermediary
                  Guidelines and Digital Media Ethics Code) Rules, 2021;
                  <br></br>
                  <br></br>
                  c.  Rule 4 of the Information Technology (Reasonable Security
                  Practices and Procedures and Sensitive Personal Information)
                  Rules, 2011
                  <br></br>
                  <br></br>
                </div>
                This Policy document is an 'electronic record' within the
                meaning of section 2(t) of the Information Technology Act, 2000
                and the rules made thereunder. This Policy requires no signature
                as it is an electronic record.
              </p>
            </div>

            <div>
              <h4>Purpose of the Policy</h4>
              <p>
                This Policy conveys the mode, method and way in which Bridge
                Health collects, stores and disseminates the Personal
                Information/Data ("Information") from the User(s) (hereinafter
                referred to as "User(s)"). For the purpose of this Policy,
                User(s) shall mean such individuals who are competent to
                contract under the Indian Contract Act, 1872 and wish to
                subscribe to the Services (offered by  Bridge Health Medical and Digital Solution Private Limited by itself or
                through third party service providers) on this Digital Platform
                by visiting this Digital Platform.  Bridge Health Medical and Digital Solution Private Limited therefore
                requests the User(s) on this Digital Platform, to carefully and
                comprehensively read and understand the Policy before they
                consent to the terms herein and start using or accessing this
                Digital Platform or subscribing to any of the Services offered
                through this Digital Platform. In the event of any disagreement
                or in the case that User(s) do not accept the terms of this
                Policy or the Terms of Use of this Digital Platform herein they
                are advised to forthwith disengage and leave this Digital
                Platform.
              </p>

              <h4>User Information and Processing</h4>
              <p>
                'User(s) information' for the purpose of this Policy includes
                the User(s)'s personally identifiable information including but
                not limited to name, email address, residential address, phone
                number, photograph, identity and address proof and other
                information collected by  Bridge Health Medical and Digital Solution Private Limited to provide its
                Services/Products to the User(s) through their Digital Platform.
                User(s) have the option not to provide any information if they
                do not intend to avail any Service/Product. If any User(s) are
                providing information on behalf of any legal entity or third
                party, such User(s) should be authorized to provide the
                information.
              </p>
              <br></br>
              <p>
                 Bridge Health Medical and Digital Solution Private Limited does not disclose or share any information which
                it collects from User(s) with Third Party/Parties, save and
                except those mentioned in this Privacy Policy and, in accordance
                with its Privacy Policy or as per procedure prescribed by law,
                or in compliance with legal obligations.
              </p>

              <p>
                 Bridge Health Medical and Digital Solution Private Limited, broadly, collects the following information:
                <br></br>
                a. Identity Details; <br></br>
                b. Contact Details; <br></br>
                c. Personal Health Information (PHI); <br></br>
                d. Personal Data; <br></br>
                e. Sensitive Personal Data; <br></br>
                f. Technical Information (such as IP address etc); <br></br>
                g. Security Details (such as User(s) Name, Password); <br></br>
                h. Other Details (as may be required for providing the
                Services); <br></br>
                i. data from Google Fit, and Apple Health and other fitness and
                medical devices; <br></br>
              </p>

              <p>
                We do not share any Identifiable Personal Information with any
                individuals, organizations and other companies. User(s)
                information is securely stored and utmost confidentiality is
                maintained and may be accessed/used/disseminated by us as per
                the terms specified herein.
              </p>

              <p>
                 Bridge Health Medical and Digital Solution Private Limited may keep records of electronic communications and
                telephone calls received and made for making appointments or
                other purposes for the purpose of administration of Services,
                customer support, research and development.
              </p>
            </div>

            <div>
              <h4>Purpose for Information Collection</h4>

              <p>
                The Information provided by User(s) and collected by us on the
                Digital Platform may be accessed/used/disseminated for the
                following purposes and the User(s) explicitly consent to the
                same:
              </p>

              <div>
                <p>
                  a.  Communicating with the User(s) <br></br>
                  <br></br>
                  b.  Providing the Services contemplated under this Digital
                  Platform <br></br>
                  <br></br>
                  c.  Offering new Services <br></br>
                  <br></br>
                  d. Analytical purposes in anonymous form or as de-identified
                  data and for this purpose we may take assistance of our
                  affiliates and trusted business partners in India or Outside
                  India for research, development, studies, betterment of
                  services, understanding the User(s) Experience, serving the
                  individual needs of the User(s) and improving the overall
                  health delivery services/health outcome of the User(s). Bridge
                  Health may disclose aggregated information, but it will never
                  contain Information that will identify a single User(s).{" "}
                  <br></br>
                  <br></br>
                  e.   Obtaining feedback <br></br>
                  <br></br>
                  f.    For conducting Audit(s)/Review <br></br>
                  <br></br>
                  g. Record their telephonic, Video and chat conversations
                  and/or consultations during their interaction with clinical
                  experts and other support staff and  Bridge Health Medical and Digital Solution Private Limited can use it
                  for legal, training and quality purposes. <br></br>
                  <br></br>
                  h.  Customizing User(s) needs and send personalized
                  communications. <br></br>
                  <br></br>
                  i.   Link User(s) Account with third party(ies) health
                  account(s) including but not limited to Google Fit for
                  tracking their health activities and health vitals and such
                  other third party(ies) as may be required for providing the
                  Services available on Digital Platform. <br></br>
                  <br></br>
                  j.   To send messages including but not limited to emailers,
                  bulk emailers, SMS etc. intimating the User(s) about Bridge
                  Health's healthcare services, healthcare packages, offers and
                  other promotional content as well as for reminders and
                  notifications as logged on the app by the User(s) or their
                  healthcare provider. These messages may be sent using various
                  third-party service providers.
                </p>
              </div>

              <p>
                When User(s) interact with or respond to an email campaign
                shared by  Bridge Health Medical and Digital Solution Private Limited, User(s) may be redirected to a third
                party website(s). The third-party website may collect
                information about the User(s) device, browser, IP address,
                operating system, interaction with an email etc. The third-party
                websites may also use cookies or other tracking technologies to
                identify the behavioral pattern of the User(s).
              </p>

              <h4> Editing of Information</h4>
              <p>
                After User(s) create the account and if there is any change in
                the Information provided earlier, the User(s) can edit their
                profile and make necessary modifications. User(s) are solely
                responsible for the Information provided by them.
              </p>

              <h4>Teleconsultation Consent</h4>
              <p>
                By agreeing to the terms of this Policy, User(s) consent to
                avail consultation via telemedicine.  Bridge Health Medical and Digital Solution Private Limited shall
                consider the consent implied as and when the User(s) initiate
                the consultation via the Digital Platform.{" "}
              </p>

              <h4>Disclosure and Sharing of Information</h4>

              <div>
                <p>
                  a.  Information collected from the User(s), healthcare
                  provider(s) or other prescriptive authority on the Digital
                  Platform forms an essential part of business structure and may
                  be shared/used as described hereinbelow and User(s) hereby
                  consent to the same: <br></br>
                  <br></br>
                  <p>
                    (i) Third Party Service Provider(s) <br></br>
                    <br></br>
                    <p>
                      Information including but not limited to contact details,
                      email ID health information and such other information as
                      may be required will be shared with Third Party Service
                      Provider(s), retailers, logistics service providers,
                      E-mail Service Providers and other providers for providing
                      the Services for the User(s) pursuant to the User(s)
                      requests, especially while facilitating the Pharmacy and
                      Laboratory Services. Information is likely to be exchanged
                      while fulfilling orders, delivering medicines, laboratory
                      reports or for other commercial use and for sending
                      communications on the Digital Platform and through
                      e-mailers.
                    </p>
                    (ii) Audit/Review Purposes <br></br>
                    <br></br>
                    <p>
                      User(s) Information/data/consultation transcripts and/or
                      summary (including but not limited to telephonic, Video
                      and chat conversations) that might be generated during
                      their interaction with Healthcare Professionals (HCPs)
                      /transactions on the Platform may be used by  Bridge Health Medical and Digital Solution Private Limited
                      or shared with Third Party(ies) for the purpose of (1)
                      review/conducting Audits. with the intent of improving the
                      overall quality of services and care, training of
                      Healthcare Professionals, for providing enhanced
                      consultation experience to User(s), (2) handling
                      complaints, and (3) meeting the regulatory or statutory
                      requirements.  Bridge Health Medical and Digital Solution Private Limited assures that any information
                      exchanged for the said purpose shall be subject to
                      stringent confidentiality obligations imposed on Third
                      Party(ies).
                    </p>
                    (iii) Analytics Purpose(s) <br></br>
                    <br></br>
                    <p>
                      User(s) Information/data in (de)identified form may be
                      used by  Bridge Health Medical and Digital Solution Private Limited or shared with Third Party(ies)
                      either in India or outside India for Analytical Purposes
                      including for improving User(s) experience and User(s) use
                      of the Platform.
                    </p>
                    (iv) Other Associated Services <br></br>
                    <br></br>
                    <p>
                      User(s) Information/Data may also be used for providing
                      (a) assistance, (b) effective search results and links
                      (including listings and paid services) and (c) customer
                      experience/service.  Bridge Health Medical and Digital Solution Private Limited assures that any
                      information exchanged herein shall be in accordance with
                      its Privacy Policy.
                    </p>
                    (v) Information required by law <br></br>
                    <br></br>
                    <p>
                      I.   Bridge Health Medical and Digital Solution Private Limited may share the information given by the
                      User(s) if directed by any judicial or a statutory
                      authority or against receipt of judicial or statutory
                      order or to comply with applicable law. Please be assured
                      that no such information will be provided to any Third
                      Party for any commercial purpose in any such way which is
                      contrary to the commitments made under the Privacy Policy.
                      <br></br> <br></br>
                      II. Information required for investigation, <br></br>
                       Bridge Health Medical and Digital Solution Private Limited may share information in order to
                      investigate, prevent, or take action regarding illegal
                      activities, suspected fraud, situations involving
                      potential threats to the physical safety of any person,
                      violations of these Terms and/or privacy policy, or as
                      otherwise required law.
                    </p>
                  </p>
                  b.    In the event, if there is any information which has to
                  be provided outside the scope of this Privacy Policy, a prior
                  notification and request for consent /approval will be shared
                  with the User(s). <br></br>
                  <br></br>
                  c.  Destruction of Data/Information Supplied by the User(s){" "}
                  <br></br>
                  <br></br>
                  Any data/information supplied by the User(s) and collected by
                  us shall be securely stored for the duration required under
                  applicable law. In the event any User(s) are deleting their
                  account or a User(s) account becomes inactive, the concerned
                  User(s) shall have no access to the data/information supplied
                  and collected by us and the same shall be deleted from our
                  servers after the duration for which it is required to be
                  stored as per applicable laws.
                </p>
              </div>

              <div>
                <h4>Information Modification</h4>

                <p>
                  Notwithstanding anything contained herein or the Terms of Use,
                   Bridge Health Medical and Digital Solution Private Limited reserves its right to add, update, change, edit,
                  alter, amend, modify, review, revise, vary or substitute this
                  Policy with or without prior notice to the User(s) once an
                  initial agreement and consent is taken after a complete
                  understanding of the Policy, first displayed.
                </p>

                <p>
                  By using or accessing this Digital Platform, the User(s)
                  categorically agree to have read and understood this Policy
                  and acknowledge that they shall be bound by the terms of this
                  Policy including any addition, updation, change, amendment,
                  revision, modification and/or substitution.  Bridge Health Medical and Digital Solution Private Limited
                  reserves its right to terminate the services of any User(s)
                  who do not subscribe to or violate the terms of this Policy.
                </p>
              </div>

              <div>
                <h4>Financial Information</h4>
                <p>
                  For availing certain Services/Products the User(s) may be
                  required to subscribe/purchase such Services by entering
                  requisite payment details. The payment details are captured by
                  third party service providers and the User(s) are advised to
                  read the terms and conditions of such third-party service
                  providers before making any payment. For the purpose of this
                  section it is clarified that the Digital Platform does not
                  collect sensitive personal or security related information
                  like passwords etc., which are totally under encryption.
                  User(s) may note the Third-Party Service Providers are not
                  under the direction, supervision and control of  Bridge Health Medical and Digital Solution Private Limited
                  and  Bridge Health Medical and Digital Solution Private Limited expressly disclaims any and all liabilities
                  associated, therewith. User(s) are advised to note this while
                  providing their personal or security related information.
                </p>
              </div>

              <div>
                <h4>Cookie Policy</h4>
                <p>
                  Cookies are small pieces of text used to store information on
                  web browsers. Cookies are used to store and receive
                  identifiers and other information on computers, phones and
                  other devices. Other technologies, including data we store on
                  User(s)r web browser or device, identifiers associated with
                  User(s)r device, and other software, are used for similar
                  purposes.
                </p>

                <p>
                  This Website and Mobile Application uses cookies to store
                  certain data (that is not sensitive personal data or
                  information) that is used to provide customized information
                  when the User(s) or Unregistered User(s) returns to the
                  Website. "Cookies" are used to store User(s) or Unregistered
                  User(s) preferences and to track User(s) or Unregistered
                  User(s) trends, so as to enhance their interactive experience
                  and generally improve the website's services to them. While
                  serving advertisements or optimizing services to its User(s),
                   Bridge Health Medical and Digital Solution Private Limited may allow authorized third parties including but
                  not limited Google Inc, E-Mail Service Providers, AppsFlyer
                  Ltd. for analytical and statistical purposes and to place or
                  recognize a unique cookie on the User(s)'s or Unregistered
                  User(s)'s browser.
                </p>
              </div>

              <div>
                <h4>
                  Creation of User(s) on behalf of third party and Third Party
                  Links
                </h4>
                <p>
                  If any User(s) creates an account on this Digital Platform for
                  and on behalf of any other Party/ third party (other than for
                  his use) like their family member(s)/friend(s) such User(s)
                  acknowledge that they are duly authorized by the other
                  Party/third party to create an account on this Digital
                  Platform by furnishing such details as may be required for
                  availing the Services, In case any User(s) is/are creating
                  account on behalf of a minor, such User(s) acknowledge that
                  they are legally authorized to act on behalf of such minors.
                  User(s) acknowledge that  Bridge Health Medical and Digital Solution Private Limited shall in no case be
                  held responsible/liable for any third party claims/damages if
                  any arising from any action in this regard.  Bridge Health Medical and Digital Solution Private Limited
                  strongly encourages parents and guardians to supervise the
                  online activities of their minor children.
                </p>
                <p>
                   Bridge Health Medical and Digital Solution Private Limited may provide certain of the Services contemplated
                  under this Digital Platform directly by itself or through
                  third party service providers. This Digital Platform may
                  contain links of such third party service providers and their
                  Digital Platform.  Bridge Health Medical and Digital Solution Private Limited shall not in any way be
                  responsible for the content, Terms of Use, Privacy Policy, the
                  mode of operations of such third party service providers.
                </p>
                <p>
                  User(s) may note the Third Party Service Providers are not
                  under the direction, supervision and control of
                   Bridge Health Medical and Digital Solution Private Limited expressly disclaims any and all liabilities
                  associated, therewith, The User(s) are hereby advised to
                  carefully read the Terms of Use and the Privacy Policy and
                  other relevant documents of such third party service
                  providers/websites as accessing such third party Digital
                  Platform is at the sole risk of the User(s). User(s) may also
                  note that the third party digital platform may collect various
                  types of information of the User(s) and their behavioural
                  patterns.  Bridge Health Medical and Digital Solution Private Limited definitely conveys to the User(s) that
                  it does not monitor or have any control over the affairs of
                  such third party websites or the contents therein. User(s) are
                  categorically advised to get in touch with the third party
                  digital platform and seek information/clarification about
                  their operations and functioning.
                </p>
              </div>

              <div>
                <h4>Promotions and Survey</h4>
                <p>
                  With a view to provide better Services  Bridge Health Medical and Digital Solution Private Limited may send
                  various communications to the User(s) regarding its
                  Products/Services If the User(s) do not wish to receive such
                  communications they may unsubscribe to such communications as
                  per the procedure set out in those communications.
                </p>
                <p>
                  At times,  Bridge Health Medical and Digital Solution Private Limited conducts a User survey to collect
                  information about End-Users' preferences. These surveys are
                  optional and if End-Users choose to respond, their responses
                  will be kept anonymous. Similarly,  Bridge Health Medical and Digital Solution Private Limited may offer
                  contests to qualifying End-Users in which we ask for contact
                  and demographic information such as name, email address and
                  mailing address. The demographic information that Bridge
                  Health collects in the registration process and through
                  surveys is used to help  Bridge Health Medical and Digital Solution Private Limited improve its Services to
                  meet the needs and preferences of End-Users.
                </p>
              </div>

              <div>
                <h4>Access to the Digital Platform</h4>
                <p>
                  a.  Registered User(s) will have complete access to the
                  Digital Platform <br></br>
                  <br></br>
                  b.  Unregistered User(s) will have restricted access to the
                  Digital Platform. However, Unregistered User(s) shall also be
                  bound by the terms of this Policy
                </p>
              </div>

              <div>
                <h4>Security and Restrictions</h4>
                <p>
                  To protect User(s)r privacy and security,  Bridge Health Medical and Digital Solution Private Limited takes
                  steps to verify User(s)' identity before granting User(s)
                  access to accounts, or before User(s) can make corrections to
                  their information. User(s) are advised to maintain a strong
                  password to help ensure the security of User(s) accounts.
                </p>
                <p>
                   Bridge Health Medical and Digital Solution Private Limited implements high standard security measures to
                  protect User(s) Information from and against unauthorized
                  access. To this effect,  Bridge Health Medical and Digital Solution Private Limited uses certain, physical,
                  managerial, technical and operational safeguards as per
                  industry standards and established best practices to protect
                  information of User(s) which is collected by  Bridge Health Medical and Digital Solution Private Limited.
                </p>
                <p>
                  Notwithstanding the above, User(s) may note that no online
                  platform can fully eliminate the security risks.  Bridge Health Medical and Digital Solution Private Limited
                  shall take all possible measures to protect the date,
                  security, confidentiality and integrity of the User(s)
                  information from unauthorised access. User(s) may also note
                  that internet where the Digital Platform is hosted is not
                  completely a secure environment, therefore  Bridge Health Medical and Digital Solution Private Limited does
                  not warrant a 100% security of the User(s) Information/data
                  from being hacked/misused/copied/used unauthorizedly and in
                  such cases User(s) agree not to hold  Bridge Health Medical and Digital Solution Private Limited responsible
                  for any consequences arising therefrom.
                </p>
                <p>
                   Bridge Health Medical and Digital Solution Private Limited makes all User information accessible to its
                  employees, agents or partners and third parties only on a
                  need-to-know basis, and binds only its employees to strict
                  confidentiality obligations. All  Bridge Health Medical and Digital Solution Private Limited employees and
                  data processors, who have access to, and are associated with
                  the processing of sensitive personal data or information, are
                  obliged to respect the confidentiality of every End-Users’
                  Personal Information or Sensitive Personal Data and
                  Information.  Bridge Health Medical and Digital Solution Private Limited has put in place procedures and
                  technologies as per good industry practices and in accordance
                  with the applicable laws, to maintain security of all personal
                  data from the point of collection to the point of destruction.
                  Any third-party data processor to which  Bridge Health Medical and Digital Solution Private Limited
                  transfers Personal Data shall have to agree to comply with
                  those procedures and policies, or put in place adequate
                  measures on their own.
                </p>
                <p>
                  Notwithstanding anything contained in this Policy or
                  elsewhere,  Bridge Health Medical and Digital Solution Private Limited shall not be held responsible for any
                  loss, damage or misuse of the User(s) Information, if such
                  loss, damage or misuse is attributable to a Force Majeure
                  Event. A "Force Majeure Event" means any event that is beyond
                  the reasonable control of  Bridge Health Medical and Digital Solution Private Limited and includes, without
                  limitation, fire, flood, explosion, acts of God, civil
                  commotion, strikes, lock outs or industrial action of any
                  kind, riots, insurrection, war, acts of government, power
                  failure, sabotage, computer hacking, unauthorised access to
                  computer data and storage device, system failure, virus,
                  attacks, bugs, computer crashes, breach of security and
                  encryption.
                </p>
                <p>
                  Your Personal Information is maintained by  Bridge Health Medical and Digital Solution Private Limited in
                  electronic form on its equipment, and on the equipment of its
                  employees. Such information may also be converted to physical
                  form from time to time.  Bridge Health Medical and Digital Solution Private Limited takes all necessary
                  precautions to protect your personal information both online
                  and off-line, and implements reasonable security practices and
                  measures including certain managerial, technical, operational
                  and physical security control measures that are commensurate
                  with respect to the information being collected and the nature
                  of  Bridge Health Medical and Digital Solution Private Limited’s business.
                </p>
                <p>
                   Bridge Health Medical and Digital Solution Private Limited does not undertake any liability for any
                  unauthorized use of your account and password. If you suspect
                  any unauthorized use of your account, you must immediately
                  notify  Bridge Health Medical and Digital Solution Private Limited by sending an email to
                  info@bridgehealthgroup.com You shall be liable to indemnify
                   Bridge Health Medical and Digital Solution Private Limited due to any loss suffered by it due to such
                  unauthorized use of your account and password.
                </p>
                <p>
                  Part of the functionality of the  Bridge Health Medical and Digital Solution Private Limited is assisting
                  the patients to access information relating to them. Bridge
                  Health may, therefore, retain and submit all such records to
                  the relevant patients, or to their doctors.
                </p>
              </div>

              <div>
                <h4>Casual Visitor’s Note</h4>
                <p>
                  Privacy Policy are applicable to even casual visitors, and
                  such casual visitors are also required to read and understand
                  the privacy statements set out herein, failing which they are
                  required to leave this Application immediately. You are not a
                  casual visitor if you have willingly submitted any personal
                  data or information to  Bridge Health Medical and Digital Solution Private Limited through any means,
                  including email, post or through the registration process on
                  the Application. All such visitors will be deemed to be, and
                  will be treated as, Users for the purposes of this Privacy
                  Policy, and in which case, all the statements in this Privacy
                  Policy apply to such persons.
                </p>
              </div>

              <div>
                <h4>General terms</h4>
                <p>
                  This Policy is to be read in conjunction with and shall be
                  deemed to be part of the Digital Platform Terms of Use
                </p>
                <p>
                  The User(s) Information resides in  Bridge Health Medical and Digital Solution Private Limited's database
                  for a certain period of time even after the User(s) ceases to
                  use or access the Digital Platform and in such cases Bridge
                  Health shall not be liable for any usage of the User(s)
                  Information after the User(s) ceases to use or access the
                  Digital Platform.
                </p>
                <p>
                  The User(s) hereby agree, consent and acknowledge that User(s)
                  Information shared by them, collected or collated through the
                  Digital Platform could be stored, processed and handled by
                   Bridge Health Medical and Digital Solution Private Limited or through its trusted third party service
                  providers as set out in this Privacy Policy.
                </p>
                <p>
                   Bridge Health Medical and Digital Solution Private Limited may avail services of third party service
                  providers for sending e-mail communications to the User(s).
                  The third party email service provider(s) and its ESPs (EMAIL
                  SERVICE PROVIDERS) will have access to User(s) information and
                  they will be processing User(s) data and information on Bridge
                  Health's behalf. The third party email service provider(s)
                  service involves their data and placement of cookies and may
                  also contain tracking pixels embedded therein along with other
                  tracking technologies. The User(s) would have the option to
                  enable or disable them. The third party Email service
                  providers also track the open and click activity of the e-mail
                  communications by the User(s). The User(s) have the option to
                  unsubscribe from receiving such e-mail communications.
                </p>
                <p>
                  The User(s) agrees and acknowledges  Bridge Health Medical and Digital Solution Private Limited's right to
                  utilize the User(s)'s posts, contents, information and other
                  personal data for the purpose of providing services through
                  the Digital Platform. The contents on the Digital Platform may
                  contain comments, post and reviews posted by the User(s) which
                  may be public and searchable on the internet in which case the
                  User(s)'s comments, post reviews and information and personal
                  data provided to the Digital Platform may be viewed by other
                  User(s) and  Bridge Health Medical and Digital Solution Private Limited cannot guarantee that other User(s)
                  or third parties have not made copies of or used the ideas and
                  information that the User(s) has shared on the Digital
                  Platform.
                </p>
                <p>
                  If User(s) unsubscribe from the Services and again intends to
                  avail/subscribe to the Services, he/she is authorizing Bridge
                  Health to send such information as may be required, including
                  but not limited to SMS, emails for enabling the User(s) to
                  continue to avail the Services. User(s) hereby explicitly
                  consent and agree that any request pursuant to this clause
                  will override any opt-out/ override any opt-out unsubscribe
                  request given by them earlier.
                </p>
              </div>

              <div>
                <h4>Grievance and Complaints</h4>
                <p>
                  In case of any complaints or grievances, the User(s) may
                  contact  Bridge Health Medical and Digital Solution Private Limited at the below mentioned details:
                </p>
                <p>
                  Address: No.166-167, 21st Main Road, 100 Feet Ring Road, 2nd
                  Phase, JP Nagar, Bangalore - 560078 <br></br>
                  <p>
                    {" "}
                    Email ID: 
                    <a
                      href="mailto:info@bridgehealthgroup.com"
                      title="info@bridgehealthgroup.com"
                    >
                      {" "}
                      info@bridgehealthgroup.com
                    </a>{" "}
                  </p>
                </p>
              </div>
              <div>
                <h4>Governing Law and Jurisdiction</h4>
                <p>
                  The terms and conditions of this Policy shall be governed by
                  Indian Laws and the courts at Bangalore shall only have
                  exclusive jurisdiction to resolve any disputes.
                </p>
              </div>
            </div>
          </section>
        </section>
        <Footer />
      </>
    );
  }
}

export default privacyPolicies;
