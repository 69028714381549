import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Modal } from "react-bootstrap";

class Testimonial2 extends Component {
  state = {
    isOpen: false,
  };
  state1 = {
    isOpen1: false,
  };
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
    };

    return (
      <>
        <section className="testimonial-section  testimonialpadding expertiseback1">
          <div className="auto-container">
            <div className="sec-title light">
              <h2>
                <div className="sec-title text-center">
                  <h2>
                    <span className="headeingcolorblack"> Testimonials</span>
                  </h2>
                  <h5 className="heading3">
                    They{" "}
                    <span className="headeingcolorblue">#BridgeTheGap!</span>{" "}
                    Here’s what they said.
                  </h5>
                </div>
              </h2>
            </div>
            <div className="row marginTop50">
              <div className="col-xl-12">
                <Slider {...settings} className="theme_carousel">
                  <div className="slide-item">
                    <section>
                      <div className="auto-container">
                        <div className="row align-items-center">
                          <div className="col-lg-6">
                            <div className="image-wrapper">
                              <div
                                className="image-one"
                                onClick={this.openModal}
                              >
                                <img
                                  srcSet={require("../../assets/images/shape/testimonial.webp")}
                                  alt="testmotwo"
                                  className="imgwidth"
                                  rel="preload"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="content-box">
                              <div className="sec-title testtitle">
                                <div className="text mar25">
                                  <span>
                                    <img
                                      srcSet={require("../../assets/images/shape/thumb4.webp")}
                                      alt="testmotwo"
                                      className="testthumb"
                                      rel="preload"
                                    />
                                  </span>
                                  <span className="headeingcolorblack spenheading">
                                    Anil Kesavan
                                  </span>
                                  <p className="textpa">
                                    Empowering transformation in employee
                                    wellness
                                  </p>
                                </div>
                              </div>
                              <div className="text">
                                By incorporating preventive healthcare
                                strategies, individuals can identify potential
                                cancer risks, take proactive steps to minimize
                                those risks and detect cancer at early stages
                                when treatment options are often more
                                successful. These efforts improve overall cancer
                                outcomes and reduce the disease's burden.{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="slide-item">
                    <section>
                      <div className="auto-container">
                        <div className="row align-items-center">
                          <div className="col-lg-6">
                            <div className="image-wrapper">
                              <div className="image-one">
                                <img
                                  srcSet={require("../../assets/images/shape/testimonial.webp")}
                                  alt="testmotwo"
                                  className="imgwidth"
                                  rel="preload"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="content-box">
                              <div className="sec-title testtitle">
                                <div className="text mar25">
                                  <span>
                                    <img
                                      srcSet={require("../../assets/images/shape/thumb4.webp")}
                                      alt="testmotwo"
                                      className="testthumb"
                                      rel="preload"
                                    />
                                  </span>
                                  <span className="headeingcolorblack spenheading">
                                    Anil Kesavan
                                  </span>
                                  <p className="textpa">
                                    Empowering transformation in employee
                                    wellness
                                  </p>
                                </div>
                              </div>
                              <div className="text">
                                By incorporating preventive healthcare
                                strategies, individuals can identify potential
                                cancer risks, take proactive steps to minimize
                                those risks and detect cancer at early stages
                                when treatment options are often more
                                successful. These efforts improve overall cancer
                                outcomes and reduce the disease's burden.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <Modal
            show={this.state.isOpen}
            onHide={this.closeModal}
            className="widthdialog modalDialog"
          >
            <Modal.Header closeButton>
              <iframe
                className="widthVideo"
                src="https://www.youtube.com/embed/Faz-OTvBuEk"
                title="Empowering transformation in employee wellness - Bridge Health"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </Modal.Header>
          </Modal>
        </section>
      </>
    );
  }
}
export default Testimonial2;
