import React from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import HpvTab from "./HpvTab";
import HpvAccordionMob from "./hpv-accordion-mob";
import TabInfluenza from "./TabInfluenza";

function hpv() {
  return (
    <>
      <Header />
      <Helmet>
        <title>
          Comprehensive Guide to Shingles: Causes, Symptoms, Treatment |
          BridgeHealth
        </title>
        <meta
          name="description"
          content="Vaccination for Shingles at Bridge Health: Herpes Zoster, caused by the varicella-zoster virus. Discover symptoms, Vaccines, treatments, & solutions for effective management."
        />
      </Helmet>
      <section className="team-section padding backimg3 image-mobile-terms">
        {/* About Section Two */}
        <section className="container-fluid">
          <div className="row align-items-center auto-container reverseFlex">
            <div className="col-lg-6">
              <div className="desk-top">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50 wrap-hpv mt-5">
                      <span className="headeingcolorblue wrap-shin">
                        STAY AWAY FROM HPV!{" "}
                      </span>
                    </h2>
                    <h3 className="textleft wrap-text1">
                      Getting vaccinated <br></br> is the key!
                    </h3>
                  </div>
                  <div className="btn-box">
                    <ContactForm
                      buttonText="CONSULT / BOOK"
                      popupPosition="right"
                      className="btn-style-one"
                    />
                  </div>
                </div>
              </div>

              {/* Mobile Code  */}
              <div className="mob-mobile">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50 mt-5 uni-shingles">
                      <span className="headeingcolorblue">
                        STAY AWAY FROM HPV!{" "}
                      </span>
                    </h2>
                    <h3 className="uni-covid">
                      Getting vaccinated is the key!
                    </h3>
                  </div>
                  <div className="btn-box btn8">
                    <ContactForm
                      buttonText="CONSULT / BOOK"
                      popupPosition="right"
                      className="btn-style-one"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="imgright">
                <img
                  srcset={require("../../assets/images/shape/hpv.webp")}
                  alt=""
                  className="imgwidth wrap2-shi-img1 mobilehide"
                  rel="preload"
                />
                <img
                  srcset={require("../../assets/images/shape/covidMob.webp")}
                  alt=""
                  className="imgwidth wrap2-shi-img1 desktophide"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="hpv-section mt-5">
        <div className="container-fluid bgsection">
          <div className="container">
            <div className="row bgpading">
              <div className="col-lg-4">
                <div className="cardHpvWrap">
                  <div className="Hpv-wrap-card">
                    <img
                      srcset={require("../../assets/images/shape/hpv4.webp")}
                      alt=""
                      className="hpvImageItem"
                      rel="preload"
                      style={{ backgroundColor: "white" }}
                    />
                    <div className="wrap-hpv1 text-white">
                      <h4 className="text-white mb-1">HPV VACCINE</h4>
                      <p className="pCenter-hpv">
                        Dosage: 2 doses (6-14 yrs) or 3 doses (15-45 yrs)
                      </p>
                    </div>
                  </div>
                  <div className="hpv-button text-right">
                    <button
                      title="BOOK NOW"
                      className="btnhpvwrap text-right"
                    >
                      BOOK NOW
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="cardHpvWrap">
                  <div className="Hpv-wrap-card">
                    <img
                      srcset={require("../../assets/images/shape/hpv4.webp")}
                      alt=""
                      className="hpvImageItem"
                      rel="preload"
                      style={{ backgroundColor: "white" }}
                    />
                    <div className="wrap-hpv1 text-white">
                      <h4 className="text-white mb-1">HPV VACCINE</h4>
                      <p className="pCenter-hpv">
                        Dosage: 2 doses (6-14 yrs) or 3 doses (15-45 yrs)
                      </p>
                    </div>
                  </div>
                  <div className="hpv-button text-right">
                    <button
                      title="BOOK NOW"
                      className="btnhpvwrap text-right"
                    >
                      BOOK NOW
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="cardHpvWrap">
                  <div className="Hpv-wrap-card">
                    <img
                      srcset={require("../../assets/images/shape/hpv4.webp")}
                      alt=""
                      rel="preload"
                      className="hpvImageItem"
                      style={{ backgroundColor: "white" }}
                    />
                    <div className="wrap-hpv1 text-white">
                      <h4 className="text-white mb-1">HPV VACCINE</h4>
                      <p className="pCenter-hpv">
                        Dosage: 2 doses (6-14 yrs) or 3 doses (15-45 yrs)
                      </p>
                    </div>
                  </div>
                  <div className="hpv-button text-right">
                    <button
                      title="BOOK NOW"
                      className="btnhpvwrap text-right"
                    >
                      BOOK NOW
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* WHAT IS COVID? */}
      {/* Desktop code  */}
      <section className="covid-third-sec">
        <div className="container-fluid">
          <div className="container">
            <div className="marginTop80">
              <h2 className="header1">
                <span className="headeingcolorblue wrap-header1 wrap-header2">
                  {" "}
                  WHAT IS HPV{" "}
                </span>
                <span className="headeingcolorblack wrap-header1 wrap-header2">
                  (human papillomavirus)?
                </span>
              </h2>
            </div>
            <div>
              <p className="wrap-covid">
                HPV infections often go unnoticed as they normally don't cause
                any symptoms. However, particular strains can lead to visible
                genital warts, which appear as small growths or clusters on the
                genital or anal areas. Additionally, certain high-risk HPV types
                can persist in the body for years and significantly increase the
                risk of developing various cancers, such as cervical, anal, and
                throat cancers. Regular medical check-ups, practicing safe sex,
                and getting vaccinated can help manage the risks associated with
                HPV infections.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Mobile code  */}
      <section className="covid-mob-sec">
        <div className="container">
          <div className="marginTop80">
            <h2 className="header1-covid">
              <span className="headeingcolorblue wrapper-mob">
                {" "}
                WHAT IS HPV
              </span>
              <span className="headeingcolorblack wrapper-mob">
                (human papillomavirus)?{" "}
              </span>
            </h2>
          </div>
          <div>
            <p className="wrap-covid-mob">
              HPV infections often go unnoticed as they normally don't cause any
              symptoms. However, particular strains can lead to visible genital
              warts, which appear as small growths or clusters on the genital or
              anal areas. Additionally, certain high-risk HPV types can persist
              in the body for years and significantly increase the risk of
              developing various cancers, such as cervical, anal, and throat
              cancers. Regular medical check-ups, practicing safe sex, and
              getting vaccinated can help manage the risks associated with HPV
              infections.
            </p>
          </div>
        </div>
      </section>
      {/* Desktop page code */}
      <section className="covid-four-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <h2 className="header-covid">
                <span className="headeingcolorblue1 wrap-header-hpv">01</span>
              </h2>
              <p className="hpv-par">
                HPV is the most common sexually transmitted infection (STI) in
                the world
              </p>
            </div>
            <div className="col-lg-4">
              <h2 className="header-covid">
                <span className="headeingcolorblue1 wrap-header-hpv">02</span>
              </h2>
              <p className="hpv-par">
                80 - 90% Of sexually active women will get an HPV infection in
                their lifetime
              </p>
            </div>
            <div className="col-lg-4">
              <h2 className="header-covid">
                <span className="headeingcolorblue1 wrap-header-hpv">03</span>
              </h2>
              <p className="hpv-par">
                95%+Of cervical <br></br>cancer cases are <br></br>due to HPV
              </p>
            </div>
          </div>

          <div className="btn-box text-center">
            <ContactForm
              buttonText="CONSULT / BOOK"
              popupPosition="right"
              className="btn-style-one"
            />
          </div>
        </div>
      </section>

      {/* Mobile code */}
      <section className="mobi-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="header-covid-mob">
                <span className="headeingcolorblue wrap-header-covid-mob">
                  01
                </span>
              </h2>
              <p className="covid-par-mob">
                HPV is the most common sexually transmitted infection (STI) in
                the world
              </p>
            </div>
          </div>
          <div className="item-mob">
            <div className="row">
              <div className="col-6">
                <h2 className="header-covid">
                  <span className="headeingcolorblue wrap-header-covid-mob">
                    02
                  </span>
                </h2>
                <p className="covid-par-mob">
                  80 - 90% Of sexually active women will get an HPV infection in
                  their lifetime
                </p>
              </div>
              <div className="col-6">
                <h2 className="header-covid">
                  <span className="headeingcolorblue wrap-header-covid-mob">
                    03
                  </span>
                </h2>
                <p className="covid-par-mob">
                  95%+Of cervical <br></br>cancer cases are <br></br>due to HPV
                </p>
              </div>
            </div>
          </div>
          <div
            className="btn-box text-center"
            style={{ marginBottom: 55 + "px" }}
          >
            <ContactForm
              buttonText="CONSULT / BOOK"
              popupPosition="right"
              className="btn-style-one"
            />
          </div>
        </div>
      </section>

      {/* SYMPTOMS OF COVID */}
      {/* Desktop code  */}
      <section className="des-covid">
        <div className="container-fluid backimghpv2">
          <div className="container">
            <div className="top-covid">
              <div className="row">
                <div className="col-lg-12">
                  <img
                    srcset={require("../../assets/images/shape/hpv1.webp")}
                    alt=""
                    className="imgwidth"
                    rel="preload"
                  />
                </div>
                <div className="col-lg-12">
                  <div>
                    <div className="card wrap-inter-hpv">
                      <div className="card-body">
                        <p className="card-text-hpv1">
                          HPV infections often go unnoticed as they normally
                          don't cause any symptoms. However, particular strains
                          can lead to visible genital warts, which appear as
                          small growths or clusters on the genital or anal
                          areas. Additionally, certain high-risk HPV types can
                          persist in the body for years and significantly
                          increase the risk of developing various cancers, such
                          as cervical, anal, and throat cancers. Regular medical
                          check-ups, practicing safe sex, and getting vaccinated
                          can help manage the risks associated with HPV
                          infections.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Mobile code  */}
      <section className="wrapper-mobile">
        <div className="shi-wrap-mobile">
          <figure className="text-overlay-image">
            <img
              srcset={require("../../assets/images/shape/hpv1.webp")}
              alt=""
              rel="preload"
              className="cov-item"
            />
          </figure>
          <div className="card wrap-inter-covid-mob">
            <div className="card-body">
              <p className="card-text-hpv1">
                HPV infections often go unnoticed as they normally don't cause
                any symptoms. However, particular strains can lead to visible
                genital warts, which appear as small growths or clusters on the
                genital or anal areas. Additionally, certain high-risk HPV types
                can persist in the body for years and significantly increase the
                risk of developing various cancers, such as cervical, anal, and
                throat cancers. Regular medical check-ups, practicing safe sex,
                and getting vaccinated can help manage the risks associated with
                HPV infections.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="desk-item backGroundRight mobilehide">
        <div className="container-fulid">
          <div className="container">
            <HpvTab />
          </div>
        </div>
      </div>

      <div className="desk-item-covid backGroundRight d-lg-none">
        <div className="container-fulid backimg2">
          <div className="container">
            <HpvAccordionMob />
          </div>
        </div>
      </div>

      {/* MORE ABOUT SHINGLE */}
      <section>
        <div className="more-desktop">
          <div className="wrap-sites item-covid">
            <div className="wrap-img-sec7">
              <img
                srcset={require("../../assets/images/shape/hpv3.webp")}
                alt=""
                className="imgwidth3"
                rel="preload"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Mobile code */}
      <section className="mt-3">
        <div className="more-mobile">
          <div className="wrap-sites">
            <div className="wrap-img-sec7">
              <img
                srcset={require("../../assets/images/shape/hpv3.webp")}
                alt=""
                className="imgwidth3"
                rel="preload"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="backFaqsRight" id="section-3">
        <div className="container-fulid backFaqsLeft">
          <div className="auto-container">
            <TabInfluenza />
          </div>
        </div>
      </div>
      <section style={{ marginBottom: 4 + "rem" }}>
        <div className="auto-container">
          <div className="row">
            {/*
                <!-- News Block One --> */}
            <div
              className="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div className="inner-box">
                <div className="image">
                  <Link to={"/#"}>
                    {" "}
                    <img
                      srcset={require("../../assets/images/shape/blog.webp")}
                      alt=""
                      rel="preload"
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  {/* <div className="category">Blog heading</div> */}

                  <h3>
                    <Link to={""}>What Does Your GI Score Indicate?</Link>
                  </h3>
                  <div className="text">
                    As a child, you were likely cautioned to limit your intake
                    of sweets. Remember the warning not to go
                  </div>
                </div>
              </div>
            </div>
            {/*
                <!-- News Block One --> */}
            <div
              className="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div className="inner-box">
                <div className="image">
                  <Link to={"/#"}>
                    {" "}
                    <img
                      srcset={require("../../assets/images/shape/blog1.webp")}
                      alt=""
                      rel="preload"
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  {/* <div className="category">Blog heading</div> */}

                  <h3>
                    <Link to={""}>Myths And Facts About GI Foods</Link>
                  </h3>
                  <div className="text">
                    Carbohydrates have been villainized in recent years as the
                    culprit for weight gain and even chronic diseases
                  </div>
                </div>
              </div>
              {/* <button className="theme-btn btn-style-one btnblog" type="submit" name="submit-form"><span
                            className="btn-title btnblog">READ ALL BLOGS</span></button> */}
            </div>
            {/*
                <!-- News Block One --> */}
            <div
              className="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div className="inner-box">
                <div className="image">
                  <Link to={"/#"}>
                    {" "}
                    <img
                      srcset={require("../../assets/images/shape/blog3.webp")}
                      alt=""
                      rel="preload"
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  {/* <div className="category">Blog heading</div> */}

                  <h3>
                    <Link to={""}>
                      Everything you need to know about Glycemic Index
                    </Link>
                  </h3>
                  <div className="text">
                    Even with the world becoming more health-conscious, diseases
                    seem to be on the rise. While you frantically
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default hpv;
