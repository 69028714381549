import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import React from 'react';
import Prevention from './prevention';
import Vaccine from './vaccine';
import Questions from './questions';
import Diagnosis2 from './diagnosis2';

function TabInfluenza() {
  const titleWithLineBreak = 'HPV<br>Vaccine';
  return (
    <Tabs
      defaultActiveKey="home"
      id="justify-tab-example"
      className="mb-3 auto-container"
      justify
    >
      <Tab eventKey="home" title="Symptoms and Diagnosis" className='linksAll'>
      <Diagnosis2 />      </Tab>
      <Tab eventKey="profile" title="Treatment &  Prevention">
      <Prevention />      </Tab>
      <Tab eventKey="longer-tab" title={<span dangerouslySetInnerHTML={{ __html: titleWithLineBreak }} />}>
      <Vaccine />      </Tab>
      <Tab eventKey="contact" title="Other Common Questions">
      <Questions />      </Tab>
    </Tabs>
  );
}

export default TabInfluenza;