import React, { Component } from 'react';

class SearchComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      selectedCategory: 'All',
      searchResults: [],
      categories: ['All', 'Fruits', 'Vegetables', 'Dairy'],
      itemsByCategory: {
        Fruits: ['Apple', 'Banana', 'Orange', 'Mango', 'Pineapple'],
        Vegetables: ['Carrot', 'Broccoli', 'Tomato', 'Cucumber'],
        Dairy: ['Milk', 'Cheese', 'Yogurt', 'Butter'],
      },
    };
  }

  componentDidMount() {
    this.handleSearch();
  }

  handleSearchInputChange = (event) => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery }, () => {
      this.handleSearch();
    });
  };

  handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    this.setState({ selectedCategory }, () => {
      this.handleSearch();
    });
  };

  handleSearch = () => {
    const { searchQuery, selectedCategory, itemsByCategory } = this.state;
    let filteredItems = [];

    if (selectedCategory === 'All') {
      for (const categoryItems of Object.values(itemsByCategory)) {
        filteredItems.push(...categoryItems);
      }
    } else {
      filteredItems = itemsByCategory[selectedCategory];
    }

    filteredItems = filteredItems.filter(item =>
      item.toLowerCase().includes(searchQuery.toLowerCase())
    );

    this.setState({ searchResults: filteredItems });
  };

  render() {
    const { searchQuery, searchResults, categories, selectedCategory } = this.state;

    return (
      <div>
        <div>
          <input
            type="text"
            value={searchQuery}
            onChange={this.handleSearchInputChange}
            placeholder="Search..."
          />

          <select value={selectedCategory} onChange={this.handleCategoryChange}>
            {categories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>

        <ul>
          {searchResults.map((result, index) => (
            <li key={index}>{result}</li>
          ))}
        </ul>
      </div>
    );
  }
}

export default SearchComponent;
