import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

class HepTab extends Component {
  render() {
    return (
      <>
        <section className="container">
          <div className="shi-desk">
            <div className="sec-title text-center textleft">
              <h2 className="textleft fontSize50 wrap-sites-poss">
                <span className="headeingcolorblack">RISK FACTORS </span>
                <span className="headeingcolorblue"> HEPATITIS?</span>
              </h2>
            </div>
          </div>
          {/* Mobile Code  */}
          <div className="shi-mob">
            <div className="sec-title text-center textleft">
              <h2 className="textleft fontSize50 wrap-sites-poss shi1-mob1">
                <span className="headeingcolorblack">RISK FACTORS </span>
                <span className="headeingcolorblue"> Hepatitis?</span>
              </h2>
            </div>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={5}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Liver Failure</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Liver Cirrhosis</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">
                      Hepatocellular Carcinoma
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="four">Ascites</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="five">Esophageal Varices</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="six">Encephalopathy</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={7}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div id="image_para_section">
                      <img
                        srcset={require("../../assets/images/shape/Liver failure.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                      <p className="paragraph_after_image mt-2">
                        Severe and advanced cases of hepatitis can result in liver failure. This is a very serious condition where
                        the liver's ability to perform essential functions becomes severely compromised.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div id="image_para_section">
                      <img
                        srcset={require("../../assets/images/shape/Liver cirrhosis.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                      <p className="paragraph_after_image mt-2">
                        Prolonged inflammation can lead to scarring of the liver tissue,
                        potentially progressing to cirrhosis, a condition characterized by impaired liver function.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <div id="image_para_section">
                      <img
                        srcset={require("../../assets/images/shape/Hepatocellular.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                      <p className="paragraph_after_image mt-2">
                        Chronic inflammation and scarring increase the risk of developing
                        liver cancer, particularly in cases of chronic hepatitis B and C.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="four">
                    <div id="image_para_section">
                      <img
                        srcset={require("../../assets/images/shape/Ascites.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                      <p className="paragraph_after_image mt-2">
                        Liver damage can lead to fluid accumulation in the abdominal
                        cavity, causing discomfort and distension.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="five">
                    <div id="image_para_section">
                      <img
                        srcset={require("../../assets/images/shape/Esophageal Varcies.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                      <p className="paragraph_after_image mt-2">
                        Liver cirrhosis can cause blood vessels in the oesophagus to enlarge.
                        This increases the risk of bleeding and having a potentially life-threatening haemorrhage.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="six">
                    <div id="image_para_section">
                      <img
                        srcset={require("../../assets/images/shape/Enacephalopathy.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                      <p className="paragraph_after_image mt-2">
                        Advanced liver dysfunction can result in cognitive and neurological disturbances,
                        collectively known as hepatic encephalopathy.
                      </p>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </section>
      </>
    );
  }
}
export default HepTab;
