import React from 'react';
import MemberCard from './MemberCard';

const members = [
  { id: 1, imgSrc: '../assets/images/gallery/bipul.jpg', alt: 'Member 1' },
  { id: 2, imgSrc: '../assets/images/gallery/bipul.jpg', alt: 'Member 2' },
  { id: 3, imgSrc: '../assets/images/gallery/dhanya.JPG', alt: 'Member 3' },
  { id: 4, imgSrc: '../assets/images/gallery/jahan_Copy.jpg', alt: 'Member 4' },
  { id: 5, imgSrc: '../assets/images/gallery/sunil.jpg', alt: 'Member 5' },
  { id: 6, imgSrc: '../assets/images/gallery/tanvi.JPG', alt: 'Member 6' },
  { id: 7, imgSrc: '../assets/images/gallery/jayeeta.JPG', alt: 'Member 7' },
  { id: 8, imgSrc: '../assets/images/gallery/ravi.JPG', alt: 'Member 8' },
  { id: 9, imgSrc: '../assets/images/gallery/sunu.JPG', alt: 'Member 9' }
];

const LeadershipTeam  = () => {
  return (
    <div className="leadership-team">
      <h1>LEADERSHIP TEAM</h1>
      <div className="team-grid">
        {members.map(member => (
          <MemberCard key={member.id} imgSrc={member.imgSrc} alt={member.alt} />
        ))}
      </div>
    </div>
  );
}

export default LeadershipTeam ;
