import React, { Component } from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, } from
    'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

class HepTabMobile extends Component {

    render() {
        return (
            <>

                {/* <!-- Faq Section -->  */}
                <section className="faq-section">
                    <h2 className='textleft fontSize50 hep-mob1 shi1-mob1'>
                        <span className='headeingcolorblack'>Risk factors </span>
                        <span className='headeingcolorblue'> of HEPATITIS</span>
                    </h2>
                    <div className="auto-container">
                        <div className="row">

                            <Accordion preExpanded={[0]} allowZeroExpanded>
                                <AccordionItem allowZeroExpanded={true}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Liver Failure
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div id="image_para_section">
                                            <p className='mobileFaqPara'>
                                                Severe and advanced cases of hepatitis can result in liver failure. This is a very serious condition where
                                                the liver's ability to perform essential functions becomes severely compromised.
                                            </p>

                                            <img srcset={require('../../assets/images/shape/Liver failure.webp')} alt=""
                                                className='image_shingles' rel="preload"
                                            />

                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem >
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Liver Cirrhosis
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>

                                        <div id="image_para_section">
                                            <p className='mobileFaqPara'>
                                                Prolonged inflammation can lead to scarring of the liver tissue,
                                                potentially progressing to cirrhosis, a condition characterized by impaired liver function.
                                            </p>
                                            <img srcset={require('../../assets/images/shape/Liver cirrhosis.webp')} alt=""
                                                className='image_shingles' rel="preload"
                                            />

                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Hepatocellular Carcinoma
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div id="image_para_section">
                                            <p className='mobileFaqPara'>
                                                Chronic inflammation and scarring increase the risk of developing
                                                liver cancer, particularly in cases of chronic hepatitis B and C.
                                            </p>
                                            <img srcset={require('../../assets/images/shape/Hepatocellular.webp')} alt=""
                                                className='image_shingles' rel="preload"
                                            />

                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Ascites
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div id="image_para_section">
                                            <p className='mobileFaqPara'>
                                                Liver damage can lead to fluid accumulation in the abdominal
                                                cavity, causing discomfort and distension.
                                            </p>
                                            <img srcset={require('../../assets/images/shape/Ascites.webp')} alt=""
                                                className='image_shingles' rel="preload"
                                            />

                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Esophageal Varices
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div id="image_para_section">
                                            <p className='mobileFaqPara'>
                                                Liver cirrhosis can cause blood vessels in the oesophagus to enlarge.
                                                This increases the risk of bleeding and having a potentially life-threatening haemorrhage
                                            </p>
                                            <img srcset={require('../../assets/images/shape/Esophageal Varcies.webp')} alt=""
                                                className='image_shingles' rel="preload"
                                            />

                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Encephalopathy
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div id="image_para_section">
                                            <p className='mobileFaqPara'>
                                                Advanced liver dysfunction can result in cognitive and neurological disturbances,
                                                collectively known as hepatic encephalopathy.
                                            </p>
                                            <img srcset={require('../../assets/images/shape/Enacephalopathy.webp')} alt=""
                                                className='image_shingles' rel="preload"
                                            />
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}
export default HepTabMobile;