import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";

class dental extends Component {
  componentDidMount() {
    document.title = "Dental | Bridge Health";
  }

  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Best Dental Consultations in Bangalore |Book Online - Bridge Health
          </title>
          <meta
            name="Dental"
            content="Best dental consultations at Bridge Health with skilled dental professionals provide personalized care, expert advice, & a wide range of dental services in Bangalore.
"
          />
        </Helmet>
        <section className="team-section padding doctoeback">
          {/* About Section Two */}
          <section className="container-fluid">
            <div className="row align-items-center auto-container reverseFlex">
              <div className="col-lg-6">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50">
                      <span className="headeingcolorblack">
                        {" "}
                        Healthy teeth.
                      </span>{" "}
                      <br></br>
                      <span className="headeingcolorblue"> Lovely smile </span>
                    </h2>
                    <h3 className="textleft marginTop40">
                      Book your dental <br></br> consultation today!
                    </h3>
                  </div>
                  <div className="btn-box btn5">
                    <ContactForm buttonText="BOOK NOW" popupPosition="right" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className=" imgright">
                  <img
                    srcset={require("../../assets/images/shape/dental1.webp")}
                    alt="dental"
                    className="imgwidth"
                    rel="preload"
                  />
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className="contact-section">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      srcset={require("../../assets/images/shape/manteeth.webp")}
                      alt="manteeth"
                      className="widthimg"
                      rel="preload"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-form-area">
                  <div className="sec-title squrebox">
                    <p className="fontimp">
                      Our teeth are made of enamel which is the hardest
                      substance in the human body. Unhealthy food items like
                      sugary carbonated drinks and candies weaken our enamel
                      which causes a variety of problems including tooth decay.
                    </p>
                  </div>
                  <div className="btn-box text-center btn5">
                    <ContactForm buttonText="BOOK NOW" popupPosition="left" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="auto-container">
          <div className="row align-items-center reverseFlex">
            <div className="col-lg-6">
              <div className="contact-form-area">
                <div className="sec-title squrebox">
                  <p className="font22">
                    It’s time to give your teeth the care they deserve. At
                    Bridge Health, we provide dental consultations with experts
                    who understand what your teeth need to stay strong and
                    healthy. Our practitioners have several years of experience
                    and are trained to perform a variety of different dental
                    procedures so that all your dental needs are met.
                  </p>
                </div>
                <div className="btn-box text-center btn5">
                  <ContactForm buttonText="BOOK NOW" popupPosition="right" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image-wrapper">
                <div className="image-one">
                  <img
                    srcset={require("../../assets/images/shape/dent1.webp")}
                    alt="dental"
                    className=" imgFloatRight"
                    rel="preload"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="container marginTop50">
            <div className="row align-items-center">
              <div className="col-sm-4">
                <img
                  srcset={require("../../assets/images/gallery/dent2.webp")}
                  alt="dental"
                  className="avtar"
                  rel="preload"
                />
                <p className="treat">Braces And Teeth Alignment</p>
              </div>

              <div className="col-sm-4">
                <img
                  srcset={require("../../assets/images/gallery/dent3.webp")}
                  alt="dental3"
                  className="avtar"
                  rel="preload"
                />

                <p className="treat">Root Canal Procedure</p>
              </div>

              <div className="col-sm-4">
                <img
                  srcset={require("../../assets/images/gallery/dent4.webp")}
                  alt="dental3"
                  className="avtar"
                  rel="preload"
                />
                <p className="treat">Bridge And Crown Fixing</p>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-section">
          <div className="auto-container">
            <div className="sec-title text-center marbot">
              <h2 className="header1 ">
                <span className="headeingcolorblack"> The</span>
                <span className="headeingcolorblue"> Bridge Health</span>
                <span className="headeingcolorblack"> Advantage </span>
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/dental2.webp")}
                  alt="dental2"
                  className="widthimg"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div className="contact-form-area">
                  <ul className="listicon">
                    <li>Dental hygiene and routine</li>
                    <li>Expert advice on dental procedures</li>
                    <li>Tooth extraction</li>
                    <li>Cavity removal and fillings</li>
                    <li>Teeth whitening</li>
                    <li>
                      Preventive dentistry through fissure sealants and fluoride
                      application
                    </li>
                    <li>Implant fixing</li>
                    <li>Dentures</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2 className="header1">
                <span className="headeingcolorblack"> Show off your </span>
                <span className="headeingcolorblue">
                  {" "}
                  Instagram-Worthy Smile{" "}
                </span>
              </h2>
            </div>
            <div className="btn-box text-center btn5">
              <ContactForm buttonText="BOOK NOW" popupPosition="right" />
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default dental;
