import React, { Component } from "react";
import { Modal } from "react-bootstrap";

class ContactForm extends Component {
  state = {
    isOpen: false,
    submitted: false,
  };
  state1 = {
    isOpen1: false,
  };
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      phone: "",
      errors: {
        username: "",
        email: "",
        phone: "",
      },
      submitted: false,
    };
  }

  closePopup = () => {
    this.setState({ submitted: false, isOpen: false });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const errors = { ...this.state.errors };

    // Clear the error for the changed field
    errors[name] = "";

    this.setState({
      [name]: value,
      errors,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { username, email, phone } = this.state;
    const errors = {};

    // Validate username
    if (username.trim() === "") {
      errors.username = "Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(username)) {
      errors.username = "Name can only contain letters and spaces";
    }

    // Validate email
    if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      errors.email = "Invalid email format";
    }

    // Validate phone
    if (!phone.match(/^\d{10}$/)) {
      errors.phone = "Phone number must be 10 digits";
    }

    // Update the state with the errors
    this.setState({ errors });

    // If there are no errors, submit the form
    if (Object.keys(errors).length === 0) {
      // Perform the form submission logic here
      // e.g., call an API endpoint, update the database, etc.

      // Reset the form
      this.setState({
        username: "",
        email: "",
        phone: "",
        message: "",
        errors: {
          username: "",
          email: "",
          phone: "",
          message: "",
        },
      });

      // Show the "Thank you" message
      this.setState({ submitted: true });

      // Call the sendEmail function
      await this.sendEmail();
    }
  };

  sendEmail = async (e) => {
    // e.preventDefault();

    const { email, username, phone, message } = this.state;

    const res = await fetch("/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        username,
        phone,
        message,
      }),
    });

    const data = await res.json();

    if (data.status === 401 || !data) {
    } else {
      this.setState({
        show: true,
        email: "",
        username: "",
        phone: "",
        message: "",
      });
      console.log("Email sent");
    }
  };

  render() {
    const { username, email, phone, errors, submitted } = this.state;
    const { buttonText, className } = this.props;

    return (
      <>
        {this.props.titleType === "image" ? (
          <img
            srcSet={this.props.imageURL}
            alt="contact"
            onClick={this.openModal}
            rel="preload"
          />
        ) : (
          <button
            className={`commonBtnforAll  ${className}`}
            type="submit"
            name="submit-form"
            onClick={this.openModal}
          >
            <span>{buttonText}</span>
          </button>
        )}
        <Modal
          show={this.state.isOpen}
          onHide={this.closeModal}
          className="popupform"
        >
          <Modal.Header closeButton>
            <div className="form-body">
              <div className="row">
                <div className="form-holder">
                  <div className="form-content">
                    <div className="form-items">
                      <form
                        className="requires-validation"
                        method="post"
                        onSubmit={this.handleSubmit}
                      >
                        <div classN="col-md-12 mb-2">
                          <input
                            className="form-control widthinput"
                            type="text"
                            value={username}
                            onChange={this.handleChange}
                            name="username"
                            placeholder="Name*"
                            //   required
                          ></input>
                          {errors.username && (
                            <div className="error">{errors.username}</div>
                          )}
                        </div>
                        <div className="col-md-12 mb-2">
                          <input
                            className="form-control widthinput"
                            type="email"
                            value={email}
                            onChange={this.handleChange}
                            name="email"
                            placeholder="Email"
                          ></input>
                        </div>
                        <div className="col-md-12 mb-2">
                          <input
                            className="form-control widthinput"
                            type="text"
                            value={phone}
                            onChange={this.handleChange}
                            name="phone"
                            placeholder="Phone*"
                          ></input>
                          {errors.phone && (
                            <div className="error">{errors.phone}</div>
                          )}
                        </div>
                        <div className="col-md-12 mb-2">
                          <textarea
                            name="message"
                            value={this.state.message}
                            onChange={this.handleChange}
                            placeholder="Message here.."
                            className="widthinput textareaheight"
                          ></textarea>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            required
                          ></input>
                          <label className="form-check-label">
                            I agree that Bridge Health Medical and Digital Solution Private Limited may contact me at the
                            email address or phone number above.
                          </label>
                        </div>
                        <div className="form-button mt-3 text-center">
                          <input
                            type="submit"
                            name="submit"
                            value="Submit"
                            className="submitbutton"
                          ></input>
                        </div>
                      </form>
                      {submitted && (
                        <div
                          className="thankyou-popup"
                          onClick={this.closePopup}
                        >
                          <h2>Thank You!</h2>
                          <p>
                            Your details has been successfully submitted.
                            Thanks!
                          </p>
                          <button type="button">OK</button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Header>
        </Modal>
      </>
    );
  }
}

export default ContactForm;
