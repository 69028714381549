import React from "react";

const MediaItem = ({ title, description, imageSrc }) => {
  return (
    <div>
      <div className="col-xl-12">
        <div className="media-item">
          <img srcSet={imageSrc} alt={title} rel="preload" />
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default MediaItem;
