import React, { Component } from 'react';
import 'react-modal-video/css/modal-video.min.css';

class ShinglesPopup extends Component {
    state = {
        isOpen: false
    };
    state1 = {
        isOpen1: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return (
            <>

                <div className="image-wrapper">
                    <div className="image-one" onClick={this.openModal}>
                        <iframe width="100%" height="600" className='imgwidth mobilehide' src="https://www.youtube.com/embed/oDNJaipvtts?si=dwQoSfqlUVcFQtyi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
                        encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
                        </iframe>
                        <iframe width="100%" height="227" className='imgwidth desktophide' src="https://www.youtube.com/embed/oDNJaipvtts?si=dwQoSfqlUVcFQtyi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
                        encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
                        </iframe>
                    </div>

                    <div className='container'>
                        <img srcSet={require('../../assets/images/shape/statsweb.webp')} alt="shingles" rel="preload"
                            className='imgwidth' />

                    </div>
                    <div className='container desktophide mobilemargin'>
                        <img rel="preload"
                            srcSet={require('../../assets/images/shape/stastmob.webp')} alt="shingles" className='imgwidth' />

                    </div>

                </div>

            </>
        )
    }
}
export default ShinglesPopup;