import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

const validationSchema = Yup.object({
    username: Yup.string().required("Name is required"),
    company: Yup.string().required("Company Name is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    phone: Yup.string().matches(/^[0-9]{10}$/, "Phone number must be 10 digits").required("Phone number is required"),
    team: Yup.string().required("Team Size is required"),
    agree: Yup.boolean().oneOf([true], "You must accept the terms and conditions")
});

const HealthForm = ({ submitted }) => {
    const [thank, setThank] = useState(false);

    const handleSubmit = async (values, { resetForm }) => {
        try {
            const contactData = {
                Name: values.username,
                Email: values.email,
                Phone: values.phone,
                Company_Name: values.company,
                Team_Size: values.team,
            };

            const res = await axios.post(`https://admin.bridgehealth.in/api/health-forms`, { data: contactData });
            if (res.status === 200) {
                setThank(true);
                resetForm();
                setTimeout(() => {
                    setThank(false);
                    submitted();
                }, 3000);
            }

        } catch (error) {
            console.log("Error sending email:", error);
        }
    };

    return (
        <div className="px-1 py-1">
            <Formik
                initialValues={{ username: "", email: "", phone: "", company: '', team: "", agree: false }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form className="def-form row" id="">
                        <div className="col-lg-12">
                            <Field name="username" type="text" className="form-control" placeholder="Name*" />
                            <ErrorMessage name="username" component="div" className="error" />
                        </div>
                        <div className="col-lg-12">
                            <Field name="email" type="email" className="form-control" placeholder="Email*" />
                            <ErrorMessage name="email" component="div" className="error" />
                        </div>
                        <div className="col-lg-12">
                            <Field name="phone" type="text" className="form-control" placeholder="Phone*" />
                            <ErrorMessage name="phone" component="div" className="error" />
                        </div>
                        <div className="col-lg-12">
                            <Field name="company" type="text" className="form-control" placeholder="Company Name*" />
                            <ErrorMessage name="company" component="div" className="error" />
                        </div>
                        <div className="col-lg-12">
                            <Field name="team" as="select" className="form-control custom-select">
                                <option value="">Team Size*</option>
                                <option value="0-11">0-11</option>
                                <option value="12-50">12-50</option>
                                <option value="51-100">51-100</option>
                                <option value="101-500">101-500</option>
                                <option value="501-1000">501-1000</option>
                                <option value="1001-above">1001-above</option>
                            </Field>
                            <ErrorMessage name="team" component="div" className="error" />
                        </div>
                        <div className="col-lg-12">
                            <div className="form-check-v3 d-flex">
                                <Field
                                    name="agree"
                                    type="checkbox"
                                    className="checkBoxNew"
                                    id="agreeCheckbox"
                                />
                                <label className=" ms-2 p-0 m-0" htmlFor="agreeCheckbox">
                                    I agree that Bridge Health may contact me at the email address or phone number above.
                                </label>
                            </div>
                            <ErrorMessage name="agree" component="div" className="error" />
                        </div>

                        <div className="d-flex justify-content-center align-items-center w-100">
                            <button type="submit" className="def-btn " disabled={isSubmitting}>
                                {isSubmitting ? "Submitting..." : "SUBMIT"}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
            {thank && <div className="mt-4 text-center">Thanks for reaching out!</div>}
        </div>
    );
};

export default HealthForm;
