import React from "react";

const MediaMobileItem = ({ title, description, imageSrc }) => {
  return (
    <div>
      <div>
        <div className="media-item">
          <img
            srcSet={imageSrc}
            alt={title}
            className="med-img-mob"
            rel="preload"
          />
          <h3 className="med-head-mob mt-3">{title}</h3>
          <p className="med-pagr-mob">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default MediaMobileItem;
