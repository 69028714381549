import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";

class blog_shingles_prevention extends Component {
  shareOnSocialMedia = (platform) => {
    const shareURL = "https://bridgehealth.in/";

    switch (platform) {
      case "instagram":
        // Open Instagram app with pre-filled caption
        window.open(
          `instagram://library?AssetPath=${encodeURIComponent(shareURL)}`
        );
        break;
      case "linkedin":
        // Open LinkedIn share dialog with pre-filled URL
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            shareURL
          )}`
        );
        break;
      case "facebook":
        // Open Facebook share dialog with pre-filled URL
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            shareURL
          )}`
        );
        break;
      case "twitter":
        // Open Twitter share dialog with pre-filled tweet
        const tweetText = "Check out this link: " + shareURL;
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            tweetText
          )}`
        );
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Personalized Care Continuum -Medical & Digital Health Services
            |Bridge Health
          </title>
          <meta
            name="Blog Shingles Prevention"
            content="Bridge Health is one stop health solution for all preventive & holistic healthcare with personalized annual health plans. & There is a plan to fit the specific health needs of everybody - your spouse, parents, kids & YOU. Know more about Bridge Health, JP Nagar 2nd phase Bangalore."
          />
        </Helmet>
        <img
          srcSet={require("./../../assets/images/background/shing1.webp")}
          alt="blog"
          className="imgwidth100 mobilehide"
          rel="preload"
        />
        <img
          srcSet={require("./../../assets/images/background/shimobile.webp")}
          alt="blog"
          className="imgwidth100 desktophide image-mobile-terms"
          rel="preload"
        />
        <section className="section-second">
          <div className="container-blog  mobile-shingles-blog">
            <div>
              <h2 className="wrap-shi-item mobilehide">
                Shield Yourself from Shingles: Prevention is Possible with
                Vaccination
              </h2>
            </div>
          </div>
        </section>

        {/* Mobile code  */}
        <section className="sec-2nd-mob">
          <div className="container">
            <h1 className="wrap-blog-item-mob desktophide">
              Shield Yourself from Shingles: Prevention is Possible with
              Vaccination
            </h1>
          </div>
        </section>

        <section className="section-third">
          <div className="container-fluid">
            <div className="container-blog">
              <div className="row">
                <div className="col-lg-9">
                  <section>
                    <div className="blog-content-first">
                      <h3 className="wrap-shingle">Introduction</h3>
                      <p className="blog-pagr">
                        It is known that 1 in 3 people are at risk of developing
                        shingles in their lifetime. Shingles, caused by the
                        varicella-zoster virus (VZV), is a painful and
                        debilitating condition. Fortunately, with the
                        availability of a safe, approved and effective vaccine,
                        we now have the power to protect ourselves from shingles
                        and reduce the risk of its complications.
                      </p>
                    </div>
                    <div className="blog-image">
                      <img
                        srcSet={require("./../../assets/images/background/per1.webp")}
                        alt="blog"
                        className="img-item"
                        rel="preload"
                      />
                    </div>
                  </section>
                </div>

                <div className="col-lg-3">
                  <div className="single-blog-sidebar">
                    <div className="sidebar-item">
                      <div className="sidebar-title-blog">
                        <h4>Trending posts</h4>
                      </div>
                      <div className="sidebar-content-blog">
                        <div>
                          <div className="blog-image1">
                            <img
                              srcSet={require("./../../assets/images/background/blog13.webp")}
                              alt="blog"
                              className="img-item1"
                              rel="preload"
                            />
                          </div>

                          <div className="sec-title-blog testtitle-blog">
                            <div className="text-blog mar25">
                              <span>
                                <img
                                  srcSet={require("../../assets/images/shape/blog-thumb1.webp")}
                                  alt="blog"
                                  className="testthumb"
                                  rel="preload"
                                />
                              </span>
                              <span className="headeingcolorblack2 spenheading">
                                Jenny kiaa
                              </span>
                            </div>
                          </div>
                        </div>

                        <div>
                          <h4 className="blog-wrap">
                            Top 10 yoga poses for overall fitness
                          </h4>
                          <div className="blog-image1 wrapper-blog">
                            <img
                              srcSet={require("./../../assets/images/background/blog8.webp")}
                              alt="blog"
                              className="img-item1"
                              rel="preload"
                            />
                          </div>

                          <div className="sec-title-blog testtitle-blog">
                            <div className="text-blog mar25">
                              <span>
                                <img
                                  srcSet={require("../../assets/images/shape/blog-thumb1.webp")}
                                  alt="blog"
                                  className="testthumb"
                                  rel="preload"
                                />
                              </span>
                              <span className="headeingcolorblack2 spenheading">
                                Jenny kiaa
                              </span>
                            </div>
                          </div>
                        </div>

                        <div>
                          <h4 className="blog-wrap">
                            Top ten acai bowls to maintain that nice healthy
                            glow
                          </h4>
                          <div className="blog-image1 wrapper-blog">
                            <img
                              srcSet={require("./../../assets/images/background/blog9.webp")}
                              alt="blog"
                              className="img-item1"
                              rel="preload"
                            />
                          </div>

                          <div className="sec-title-blog testtitle-blog">
                            <div className="text-blog mar25">
                              <span>
                                <img
                                  srcSet={require("../../assets/images/shape/blog-thumb1.webp")}
                                  alt="blog"
                                  className="testthumb"
                                  rel="preload"
                                />
                              </span>
                              <span className="headeingcolorblack2 spenheading">
                                Jenny kiaa
                              </span>
                            </div>
                          </div>
                        </div>

                        <div>
                          <h4 className="blog-wrap">
                            Top ten acai bowls to maintain that nice healthy
                            glow
                          </h4>
                          <div className="blog-image1 wrapper-blog">
                            <img
                              srcSet={require("./../../assets/images/background/blog10.webp")}
                              alt="blog"
                              className="img-item1"
                              rel="preload"
                            />
                          </div>

                          <div className="sec-title-blog testtitle-blog">
                            <div className="text-blog mar25">
                              <span>
                                <img
                                  srcSet={require("../../assets/images/shape/blog-thumb1.webp")}
                                  alt="blog"
                                  className="testthumb"
                                  rel="preload"
                                />
                              </span>
                              <span className="headeingcolorblack2 spenheading">
                                Jenny kiaa
                              </span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h4 className="blog-wrap">
                            Top ten acai bowls to maintain that nice healthy
                            glow
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid blogback2">
            <div className="container-blog1">
              <div className="row">
                <div className="col-lg-9">
                  <section>
                    <div className="blog-content-second blog-content-second">
                      <h2 className="blog-head mg">
                        Understanding Shingles and Its Impact
                      </h2>
                      <p className="blog-pagr">
                        Shingles is a viral infection caused by the
                        varicella-zoster virus (VZV); the same virus responsible
                        for causing chickenpox. After a person recovers from
                        chickenpox, the virus can lie dormant in the nerve
                        tissue for years. As we age or experience weakened
                        immunity, the virus can reactivate, leading to shingles.
                        This condition is primarily characterized by a painful
                        skin rash with fluid-filled blisters, usually occurring
                        on one side of the body.
                      </p>
                      <p className="blog-pagr">
                        The impact of shingles goes beyond just physical
                        discomfort. It can lead to complications such as
                        postherpetic neuralgia (PHN), a condition in which nerve
                        pain persists even after the rash has healed. The risk
                        of PHN increases with age and can significantly affect
                        the quality of life of those affected. Besides PHN, one
                        can develop vision-related complications as well.
                      </p>
                      <p className="blog-pagr">
                        Getting vaccinated is the best way to prevent shingles
                        and to reduce its impact.
                      </p>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid blogback1">
            <div className="container-blog">
              <div className="row">
                <div className="col-lg-9">
                  <section>
                    <div className="blog-image">
                      <img
                        srcSet={require("./../../assets/images/background/per2.webp")}
                        alt="blog"
                        className="img-item"
                        rel="preload"
                      />
                    </div>
                    <div className="blog-content-third blog-content-second">
                      <h2 className="blog-head">
                        The Significance of Getting Vaccinated for Shingles
                      </h2>
                      <p className="blog-pagr">
                        Vaccination is a crucial preventive measure to reduce
                        the incidence and severity of many viral infections and
                        diseases. According to the Centers for Disease Control
                        and Prevention (CDC), two doses of the newly launched
                        shingles vaccine in India is over 90 percent effective
                        in preventing shingles and reducing its complications.
                        The shingles vaccine stimulates the immune system,
                        helping it to recognize and fight the reactivation of
                        the varicella-zoster virus.
                      </p>
                      <p className="blog-pagr">
                        The vaccine has also been shown to significantly
                        decrease the likelihood of developing PHN in those who
                        still experience an outbreak. Additionally, for those
                        who develop shingles despite being vaccinated, the
                        vaccine has been found to reduce the severity and
                        duration of the illness
                      </p>
                    </div>
                    <div className="blog-image">
                      <img
                        srcSet={require("./../../assets/images/background/per3.webp")}
                        alt="blog"
                        rel="preload"
                        className="img-item"
                      />
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="container-blog">
              <div className="row">
                <div className="col-lg-9">
                  <section>
                    <div className="blog-content-four blog-content-second">
                      <h2 className="blog-head">
                        More About the Shingles Vaccine
                      </h2>
                      <p className="blog-pagr">
                        The Centers for Disease Control and Prevention (CDC) and
                        other health authorities have specific recommendations
                        for shingles vaccination:
                      </p>
                      <p className="blog-pagr1">
                        <strong>A) Vaccine Type: </strong>The recombinant zoster
                        vaccine, Shingrix, is the recommended vaccine due to its
                        safety and effectiveness, even in older age groups.
                      </p>
                      <p className="blog-pagr1">
                        <strong>B) Who Should Get Vaccinated: </strong>The CDC
                        recommends Shingrix vaccination for healthy adults aged
                        50 years and older, regardless of whether they have had
                        chickenpox in the past, have not had chickenpox yet
                        received the shingles vaccination or have had a previous
                        shingles outbreak.
                      </p>
                      <p className="blog-pagr">
                        Besides the above, other groups of individuals who
                        should consider taking the vaccination include:
                      </p>
                      <p className="blog-pagr1">
                        1. Individuals with a weakened immune system: Those who
                        have a compromised immune system due to certain medical
                        conditions like diabetes, heart disease, cancers, COPD,
                        auto-immune disorders like Rheumatoid Arthritis (RA) &
                        Lupus, or chronic kidney disease, or due to medications
                        or undergoing treatments like that for cancer, getting
                        vaccinated can be even more crucial to prevent severe
                        shingles.
                      </p>
                      <p className="blog-pagr1">
                        2. Individuals with diabetes: Diabetes weakens the
                        immune system, making it more difficult for the body to
                        fight off infections and viruses, including the
                        varicella-zoster virus responsible for causing shingles.
                        Additionally, high blood sugar levels associated with
                        diabetes can further impair the immune response and
                        delay the healing process. Therefore, individuals with
                        diabetes should discuss vaccination options with their
                        healthcare provider.
                      </p>
                      <p className="blog-pagr">
                        <strong>C) Who Should Not Get Vaccinated: </strong>The
                        shingles vaccine should not be administered to:
                      </p>
                      <p className="blog-pagr1">
                        1. Anyone with a history of severe allergic reaction,
                        such as anaphylaxis, to any component of the shingles
                        vaccine.
                      </p>
                      <p className="blog-pagr1">
                        2. Anyone who is currently experiencing an acute episode
                        of herpes zoster. The shingles vaccine is not for
                        treatment of herpes zoster or postherpetic neuralgia
                        (PHN). The general guidance for any vaccine is to wait
                        until the acute stage of the illness is over and
                        symptoms abate.
                      </p>
                      <p className="blog-pagr1">3. Women who are pregnant</p>
                    </div>
                  </section>

                  <section>
                    <div className="blog-content-seven blog-content-second">
                      <h2 className="blog-head">Conclusion</h2>
                      <p className="blog-pagr1">
                        The shingles vaccine is generally safe and
                        well-tolerated. Like any other vaccine, it can have side
                        effects although they are typically mild to moderate and
                        transient in nature. Talk to your healthcare provider
                        today about getting vaccinated and stay ahead in
                        safeguarding your well-being against shingles. Remember,
                        prevention is always better than cure!
                      </p>
                    </div>
                  </section>

                  <section>
                    <div className="blog-content-seven mt-4">
                      <div className="sec-title-blog testtitle">
                        <div className="text-blog mar25">
                          <span>
                            <img
                              srcSet={require("../../assets/images/shape/icon5.webp")}
                              alt="blog"
                              className="testthumb2"
                              rel="preload"
                            />
                          </span>
                          <span className="headeingcolorblack3 spenheading">
                            23 likes
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section>
                    <div className="blog-content-eight mt-4">
                      <h2 className="blog-head1">Comments (10)</h2>

                      <div className="input-form-container1">
                        <input
                          type="text"
                          className="input-field1"
                          placeholder="Enter your  Name"
                        />
                        <input
                          type="text"
                          className="input-field2"
                          placeholder="Post your comment here"
                        />
                        <div className="btn-box">
                          <ContactForm
                            buttonText="Post comment"
                            popupPosition="right"
                            className="btn-style-one"
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Mobile code  */}

        <section className="sec-3rd-mob">
          <div className="bg-head1">
            <div className="sec-title-blog1">
              <div className="text-blog-mob text-align-center">
                <h5 className="side-hed-mob">Share:</h5>

                <img
                  srcSet={require("../../assets/images/shape/icon1.webp")}
                  alt="instagram"
                  className="testthumb1-mob"
                  onClick={() => this.shareOnSocialMedia("instagram")}
                  rel="preload"
                />
                <img
                  srcSet={require("../../assets/images/shape/icon2.webp")}
                  alt="linkedin"
                  className="testthumb1-mob"
                  onClick={() => this.shareOnSocialMedia("linkedin")}
                  rel="preload"
                />
                <img
                  srcSet={require("../../assets/images/shape/icon3.webp")}
                  alt="facebook"
                  className="testthumb1-mob"
                  onClick={() => this.shareOnSocialMedia("facebook")}
                  rel="preload"
                />
                <img
                  srcSet={require("../../assets/images/shape/icon4.webp")}
                  alt="twitter"
                  className="testthumb1-mob"
                  onClick={() => this.shareOnSocialMedia("twitter")}
                  rel="preload"
                />
              </div>
              <div className="text-align-center">
                <Link to="/shingles">Back to Shingles</Link>
              </div>
            </div>
          </div>

          <div className="container">
            <div>
              <h3 className="wrap-shingle-mob">Introduction</h3>
              <p className="blog-pagr-mob">
                It is known that 1 in 3 people are at risk of developing
                shingles in their lifetime. Shingles, caused by the
                varicella-zoster virus (VZV), is a painful and debilitating
                condition. Fortunately, with the availability of a safe,
                approved and effective vaccine, we now have the power to protect
                ourselves from shingles and reduce the risk of its
                complications.
              </p>
            </div>
          </div>

          <div className="blog-image-mob">
            <img
              srcSet={require("./../../assets/images/background/shimobile6.webp")}
              alt="blog"
              className="img-item"
              rel="preload"
            />
          </div>

          <section>
            <div className="container">
              <div className="blog-content-second">
                <h2 className="blog-head-mob">
                  Understanding Shingles and Its Impact
                </h2>
                <p className="blog-pagr-mob">
                  Shingles is a viral infection caused by the varicella-zoster
                  virus (VZV); the same virus responsible for causing
                  chickenpox. After a person recovers from chickenpox, the virus
                  can lie dormant in the nerve tissue for years. As we age or
                  experience weakened immunity, the virus can reactivate,
                  leading to shingles. This condition is primarily characterized
                  by a painful skin rash with fluid-filled blisters, usually
                  occurring on one side of the body.
                </p>
                <p className="blog-pagr-mob">
                  The impact of shingles goes beyond just physical discomfort.
                  It can lead to complications such as postherpetic neuralgia
                  (PHN), a condition in which nerve pain persists even after the
                  rash has healed. The risk of PHN increases with age and can
                  significantly affect the quality of life of those affected.
                  Besides PHN, one can develop vision-related complications as
                  well.
                </p>
                <p className="blog-pagr-mob">
                  Getting vaccinated is the best way to prevent shingles and to
                  reduce its impact.
                </p>
              </div>
            </div>
            <div className="blog-image">
              <img
                srcSet={require("./../../assets/images/background/shimobile7.webp")}
                alt="blog"
                className="img-item"
                rel="preload"
              />
            </div>
          </section>

          <section>
            <div className="container">
              <div className="blog-content-third blog-content-second">
                <h2 className="blog-head-mob">
                  The Significance of Getting Vaccinated for Shingles
                </h2>
                <p className="blog-pagr-mob">
                  Vaccination is a crucial preventive measure to reduce the
                  incidence and severity of many viral infections and diseases.
                  According to the Centers for Disease Control and Prevention
                  (CDC), two doses of the newly launched shingles vaccine in
                  India is over 90 percent effective in preventing shingles and
                  reducing its complications. The shingles vaccine stimulates
                  the immune system, helping it to recognize and fight the
                  reactivation of the varicella-zoster virus.
                </p>
                <p className="blog-pagr-mob">
                  The vaccine has also been shown to significantly decrease the
                  likelihood of developing PHN in those who still experience an
                  outbreak. Additionally, for those who develop shingles despite
                  being vaccinated, the vaccine has been found to reduce the
                  severity and duration of the illness
                </p>
              </div>
            </div>
            <div className="blog-image">
              <img
                srcSet={require("./../../assets/images/background/shimobile8.webp")}
                alt="blog"
                className="img-item"
                rel="preload"
              />
            </div>
          </section>

          <section>
            <div className="container">
              <div className="blog-content-four blog-content-second">
                <h2 className="blog-head-mob">
                  More About the Shingles Vaccine
                </h2>
                <p className="blog-pagr-mob">
                  The Centers for Disease Control and Prevention (CDC) and other
                  health authorities have specific recommendations for shingles
                  vaccination:
                </p>
                <p className="blog-pagr1-mob">
                  A) Vaccine Type: The recombinant zoster vaccine, Shingrix, is
                  the recommended vaccine due to its safety and effectiveness,
                  even in older age groups.
                </p>
                <p className="blog-pagr1-mob">
                  B) Who Should Get Vaccinated: The CDC recommends Shingrix
                  vaccination for healthy adults aged 50 years and older,
                  regardless of whether they have had chickenpox in the past,
                  have not had chickenpox yet received the shingles vaccination
                  or have had a previous shingles outbreak.
                </p>
                <p className="blog-pagr-mob">
                  Besides the above, other groups of individuals who should
                  consider taking the vaccination include:
                </p>
                <p className="blog-pagr1-mob">
                  1. Individuals with a weakened immune system: Those who have a
                  compromised immune system due to certain medical conditions
                  like diabetes, heart disease, cancers, COPD, auto-immune
                  disorders like Rheumatoid Arthritis (RA) & Lupus, or chronic
                  kidney disease, or due to medications or undergoing treatments
                  like that for cancer, getting vaccinated can be even more
                  crucial to prevent severe shingles.
                </p>
                <p className="blog-pagr1-mob">
                  2. Individuals with diabetes: Diabetes weakens the immune
                  system, making it more difficult for the body to fight off
                  infections and viruses, including the varicella-zoster virus
                  responsible for causing shingles. Additionally, high blood
                  sugar levels associated with diabetes can further impair the
                  immune response and delay the healing process. Therefore,
                  individuals with diabetes should discuss vaccination options
                  with their healthcare provider.
                </p>
                <p className="blog-pagr-mob">
                  C) Who Should Not Get Vaccinated: The shingles vaccine should
                  not be administered to:
                </p>
                <p className="blog-pagr1-mob">
                  1. Anyone with a history of severe allergic reaction, such as
                  anaphylaxis, to any component of the shingles vaccine.
                </p>
                <p className="blog-pagr1-mob">
                  2. Anyone who is currently experiencing an acute episode of
                  herpes zoster. The shingles vaccine is not for treatment of
                  herpes zoster or postherpetic neuralgia (PHN). The general
                  guidance for any vaccine is to wait until the acute stage of
                  the illness is over and symptoms abate.
                </p>
                <p className="blog-pagr1-mob">3. Women who are pregnant</p>
              </div>
            </div>
          </section>
          <section>
            <div className="container shi-wrapper">
              <div className="blog-content-five">
                <h2 className="blog-head-mob">Conclusion</h2>
                <p className="blog-pagr1-mob">
                  The shingles vaccine is generally safe and well-tolerated.
                  Like any other vaccine, it can have side effects although they
                  are typically mild to moderate and transient in nature. Talk
                  to your healthcare provider today about getting vaccinated and
                  stay ahead in safeguarding your well-being against shingles.
                  Remember, prevention is always better than cure!
                </p>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="blog-content-seven">
                <div className="sec-title-blog">
                  <div className="text-blog-shi">
                    <span>
                      <img
                        srcSet={require("../../assets/images/shape/icon5.webp")}
                        alt="blog"
                        className="testthumb2-mob"
                        rel="preload"
                      />
                    </span>
                    <span className="headeingcolorblack3-mob spenheading">
                      23 likes
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="blog-content-eight-mob mt-4">
                <h2 className="blog-head1-mob">Comments (10)</h2>

                <div className="contact-form">
                  <form method="post" id="contact-form">
                    <div className="row clearfix">
                      <div className="col-md-12 form-group">
                        <input
                          type="text"
                          name="username"
                          placeholder="Enter your Name*"
                          value=""
                        />
                      </div>
                      <div className="col-md-12 form-group">
                        <textarea
                          name="message"
                          placeholder="Post your comment here"
                        ></textarea>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="input-form-container1-mob">
                  <div className="btn-box">
                    <ContactForm
                      buttonText="Post comment"
                      popupPosition="right"
                      className="btn-style-one"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <Footer />
      </>
    );
  }
}
export default blog_shingles_prevention;
