import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import FormButton from "../element/component/FormButton";

const aboutbg = require("./../../assets/images/shape/phesio.webp");

class physiotherapy extends Component {
  componentDidMount() {
    document.title = "Physiotherapy | Bridge Health";
  }

  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Best Physiotherapy Services in Bangalore|Book Online|Bridge Health
          </title>
          <meta
            name="description"
            content="Book physiotherapy consultation with Bridge health's experienced & skilled professionals in Bangalore. Schedule a physiotherapy session with us and move with ease. Get in touch with our physiotherapists in Bangalore "
          />
        </Helmet>
        <section
          className="internalpage internalpageresponsive style-two image-mobile-terms"
          style={{ backgroundImage: "url(" + aboutbg + ")" }}
        >
          <div className="auto-container">
            <div className="content-box physiobox">
              <div className="textleft marginTop40">
                <h2 className="textleft fontSize">
                  <span className="headeingcolorblack">
                    Improve your
                    <span className="headeingcolorblue"> mobility </span>
                    <br></br>
                    one step at a time{" "}
                  </span>
                </h2>
                <h3 className="textleft">
                  Schedule a physiotherapy<br></br> session with us
                </h3>
              </div>
              <FormButton/>
            </div>
          </div>
        </section>

        <section className="contact-section">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6 doctor-image">
                <img
                  srcset={require("../../assets/images/shape/p1.webp")}
                  alt=""
                  className="width100 marginTop40reverse"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div className="contact-form-area">
                  <div className="sec-title squrebox">
                    <p className="font22">
                      Due to natural age progression, chronic illnesses, or
                      accidents that cause crippling injuries, your ability to
                      move may be compromised. Physiotherapy helps you regain
                      strength in your limbs gradually so that you can improve
                      your mobility.
                    </p>
                    <p className="font22">
                      At Bridge Health, we try to go a step further with our
                      physiotherapy treatments. Apart from helping you regain
                      mobility, our physiotherapists are trained to help you
                      improve the strength and function of your limbs, increase
                      your endurance, and minimize pain.
                    </p>
                  </div>
                  <FormButton/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="team-section">
          {/* About Section Two */}
          <div className="auto-container">
            <div className="row align-items-center reverseFlex">
              <div className="col-lg-6">
                <div className="content-box">
                  <h2>
                    <span className="headeingcolorblack"> What We </span>
                    <span className="headeingcolorblue"> Offer</span>
                  </h2>

                  <ul className="listicon">
                    <li>
                      Physiotherapy for Musculo-Skeletal & Ortho Conditions
                    </li>
                    <li>
                      Physiotherapy for Women Health which inludes Pelvic Floor
                      Weakness, Pre and Post Natal Cases
                    </li>
                    <li>Physiotherapy for Elderly Care/Geriatrics</li>
                    <li>Physiotherapy for Neurological Conditions</li>
                    <li>Physiotherapy for Post-Operative Rehabilitation</li>
                  </ul>

                  <FormButton/>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one image-two">
                    <img
                      srcset={require("../../assets/images/shape/p2.webp")}
                      alt=""
                      className="width100"
                      rel="preload"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-section physi">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/p3.webp")}
                  alt=""
                  className="width100"
                  rel="preload"
                />
              </div>

              <div className="col-lg-6">
                <h2 className="header1 fontrem">
                  <span className="headeingcolorblack"> The</span>
                  <span className="headeingcolorblue"> Bridge Health</span>
                  <span className="headeingcolorblack"> Advantage </span>
                </h2>
                <ul className="listicon">
                  <li>Goal-oriented programs</li>
                  <li>Regular progress monitoring</li>
                  <li>
                    Personalized programs and sessions with expert guidance from
                    physicians
                  </li>

                  <li>Home visits are available across Bangalore</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2 className="header1">
                <span className="headeingcolorblack">
                  Its time to start moving towards a{" "}
                </span>
                <span className="headeingcolorblue"> better life! </span>
              </h2>
            </div>
            <div className="d-flex justify-content-center pb-4">
                <FormButton ButtonName={'CONSULT / BOOK'} />
              </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default physiotherapy;
