import React, { useState } from 'react'
import ReachToUsForm from '../ReachToUsForm'
import Popup_Main from './Popup_Main'

const FormButton = ({ ButtonName = "Book Now", imageURL, className }) => {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button
                className={className ? className : 'commonBtnforAll'}
                onClick={() => setIsOpen(true)}
            >
                {imageURL ? <img src={imageURL} alt={ButtonName} className='buttonImage' /> : ButtonName}
            </button>
            <Popup_Main isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <ReachToUsForm submitted={() => setIsOpen(false)} />
            </Popup_Main>
        </>
    )
}

export default FormButton;
