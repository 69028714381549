import React, { useState } from 'react';

const AccordionHome = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item1">
      <div className="titlehome" onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div className="plusSymbol">{isActive ? '-' : '+'}</div>
      </div>
      {isActive && <div className="accordion-content-home">{content}</div>}
    </div>
  );
};

export default AccordionHome;