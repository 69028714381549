import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

class FaqAccordion extends Component {
  render() {
    return (
      <>
        {/* <!-- Faq Section -->  */}
        <section className="faq-section">
          <div className="auto-container">
            <div className="row">
              <div className="shi-mob">
                <div className="sec-title text-center textleft">
                  <h2 className="textleft fontSize50 shi1-mob1">
                    <span className="headeingcolorblack">
                      POSSIBLE COMPLICATIONS{" "}
                    </span>
                    <br></br>
                    <span className="headeingcolorblue"> OF SHINGLES</span>
                  </h2>
                  <p className="subheading_shingles sh1-par">
                    While most people recover from Shingles infection fully,
                    some might face health complications
                  </p>
                </div>
              </div>
              <Accordion preExpanded={[0]} allowZeroExpanded>
                <AccordionItem allowZeroExpanded={true}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Postherpetic neuralgia (PHN)
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        PHN is a health complication that affects up to 25% of
                        people with Shingles. One of the main symptoms of PHN is
                        nerve pain that continues for months or years after the
                        Shingles rash is healed. The pain usually is experienced
                        in the affected area.
                      </p>
                      <img
                        srcSet={require("../../assets/images/shape/possible.webp")}
                        alt="image_shingles"
                        className="image_shingles"
                        rel="preload"
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Vision Problems </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        Shingles affecting the eyes (known as ophthalmic
                        shingles) can lead to vision problems in around 10-25%
                        of cases, and without prompt treatment, it can result in
                        permanent vision loss.
                      </p>
                      <img
                        srcSet={require("../../assets/images/shape/possible1.webp")}
                        alt="image_shingles"
                        className="image_shingles"
                        rel="preload"
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Skin Infections</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        The risk of bacterial skin infections increases when
                        shingles blisters are scratched or become contaminated,
                        affecting around 2-5% of shingles cases.
                      </p>
                      <img
                        srcSet={require("../../assets/images/shape/possible2.webp")}
                        alt="image_shingles"
                        className="image_shingles"
                        rel="preload"
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Neurological Issues
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        Neurological complications from shingles, such as
                        encephalitis (inflammation of the brain) or myelitis
                        (inflammation of the spinal cord), are rare but can
                        occur in approximately 1 in 1,000 cases.
                      </p>
                      <img
                        srcSet={require("../../assets/images/shape/possible3.webp")}
                        alt="image_shingles"
                        className="image_shingles"
                        rel="preload"
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Disseminated Shingles
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        Disseminated shingles, where the rash spreads beyond the
                        initial area, is relatively rare, occurring in less than
                        1% of cases. It is more commonly seen in individuals
                        with weakened immune systems.
                      </p>
                      <img
                        srcSet={require("../../assets/images/shape/possible4.webp")}
                        alt="image_shingles"
                        className="image_shingles"
                        rel="preload"
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Nerve Damage</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        Shingles can cause nerve damage, known as post-shingles
                        neuralgia, which can affect up to 30% of individuals
                        aged 60 and older.
                      </p>

                      <img
                        srcSet={require("../../assets/images/shape/possible5.webp")}
                        alt="faq-accordiun"
                        className="image_shingles"
                        rel="preload"
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default FaqAccordion;
