import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import FormButton from "../element/component/FormButton";

const aboutbg = require("./../../assets/images/shape/Pharmacy.webp");
class pharmacy extends Component {
  componentDidMount() {
    document.title = "Pharmacy | Bridge Health";
  }

  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Best Pharmacy stores in Bangalore| Order Online - Bridge Health
          </title>
          <meta
            name="description"
            content="Best Pharmacy stores in Bangalore. Top Pharmacy stores near me. 24-Hour Pharmacy, Medical Shops with discounts in Bangalore. Get Bridge health pharmacy phone numbers & address."
          />
        </Helmet>
        <section
          className="internalpage internalpageresponsive style-two image-mobile-terms"
          style={{ backgroundImage: "url(" + aboutbg + ")" }}
        >
          <div className="auto-container">
            <div className="content-box">
              <div className=" text-center textleft">
                <h2 className="textleft fontSize">
                  <span className="headeingcolorblack">All your Pharmacy </span>
                  <br></br>
                  <span className="headeingcolorblue">
                    {" "}
                    Needs In One Place{" "}
                  </span>
                </h2>
                <h3 className="textleft marginTop40">
                  Order your medicines through<br></br> Bridge Health Pharmacy
                </h3>
              </div>
              <FormButton/>
            </div>
          </div>
        </section>

        <section className="contact-section">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/ph1.webp")}
                  alt=""
                  className="marginTop40reverse"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div className="sec-title squrebox">
                  <p className="font22">
                    It’s important to always keep an adequate supply of the
                    medicines you need. This is especially important if you
                    suffer from any chronic conditions. The availability of a
                    fully- stocked pharmacy near your residence is considered to
                    be very important in case you need medicines urgently.
                  </p>
                </div>
                <FormButton/>
              </div>
            </div>
          </div>
        </section>
        <section className=" container-fulid pharmacy">
          <div className="row align-items-center reverseFlex">
            <div className="col-lg-6" style={{ marginTop: 3 + "rem" }}>
              <div className="contact-form-area marginLeft12">
                <div className="sec-title">
                  <p className="font24">
                    Get everything you need at the Bridge Health pharmacy. Our
                    pharmacy is well-stocked with prescription medicines and
                    over-the-counter medicines at affordable prices. Bridge
                    Health operates its pharmacy through both online and offline
                    pharmacy formats.
                  </p>
                </div>
                <FormButton/>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                srcset={require("../../assets/images/shape/phar1.webp")}
                alt=""
                className="marginLeft12"
                rel="preload"
              />
            </div>
          </div>
        </section>

        <section className="contact-section margintop100 pharback">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/ph3.webp")}
                  alt=""
                  className="marginTop40reverse"
                  rel="preload"
                />
              </div>

              <div className="col-lg-6">
                <h2 className="header1">
                  <span className="headeingcolorblack"> The</span>
                  <span className="headeingcolorblue"> Bridge Health</span>
                  <span className="headeingcolorblack"> Advantage </span>
                </h2>
                <ul className="listicon">
                  <li>Easy Booking through our onsite personnel</li>
                  <li>Free home delivery of medicines*</li>
                  <li>
                    Easy order bookings through email, WhatsApp & direct call
                  </li>
                  <li>Emergency & institutional medical supplies available</li>
                  <li>All branded & generic medicines available</li>

                  <li>
                    Pharmacy opening and running support inside corporate and
                    resident welfare associations
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2 className="header1">
                <span className="headeingcolorblack">Get your medicine </span>
                <span className="headeingcolorblue"> anytime, anywhere! </span>
              </h2>
            </div>
            <div className="d-flex justify-content-center pb-4">
                <FormButton ButtonName={'CONSULT / BOOK'} />
              </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default pharmacy;
