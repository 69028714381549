import React from "react";
import FormButton from "../element/component/FormButton";
import Header from "../layout/header";
import { Helmet } from "react-helmet";
import ContactForm from "../element/contact-form";
function explore() {
  return (
    <div
      className="explore-page"
      style={{ overflow: "hidden", height: "600px" }}
    >
      <Header />
      <Helmet>
        <title>Explore More In Bridge Health</title>
        <meta
          name="Explore More"
          content="Book consultations today with the best dermatologists in Bangalore. Our hair, skin, and nails deserve to be treated well. Talk to our dermatologists today for expert care."
        />
      </Helmet>
      <section className="team-section expertiseback">
        <section>
          <div className="auto-container">
            <div className="row marginbutton30 mt-5">
              {/* <!-- Team Block One --> */}

              <div className="col-lg-4 team-block-one mt-5 ">
                <div className="bozhover grow">
                  <div className="text">
                    <div title="Radiology" style={{ cursor: 'pointer' }}>
                      <FormButton
                        className="image_button-wr2 "
                        imageURL={require("../../assets/images/Radiology.webp")}
                      />
                    </div>
                    <div className="short-name expertise">Radiology</div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 team-block-one mt-5">
                <div className="bozhover grow">
                  <div className="text">
                    <div className="wm-hlth-wrap3" title="Womens Health" style={{ cursor: 'pointer' }}>
                      <FormButton
                        className="image_button-wr2 "
                        imageURL={require("../../assets/images/women.webp")}
                      />
                    </div>
                    <div className="short-name expertise">Womens Health</div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 team-block-one mt-5">
                <div className="bozhover grow">
                  <p className="text">
                    <div title="AI assisted smart screenings" style={{ cursor: 'pointer' }}>
                      <FormButton
                        className="image_button-wr2 "
                        imageURL={require("../../assets/images/ai.webp")}
                      />
                    </div>
                    <div className="short-name expertise">
                      AI assisted smart screenings
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
export default explore;
