import React, { Component } from 'react'
import Header from '../layout/header';
import Footer from '../layout/footer';
import PhoneInput from 'react-phone-number-input';
import { Helmet } from 'react-helmet';




export default class leadGeneration extends Component {


    state = {
        isOpen: false,
        submitted: false
    };
    state1 = {
        isOpen1: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            email: '',
            phone: '',
            errors: {
                username: '',
                email: '',
                phone: ''
            },
            submitted: false,
        };
    }



    handleChange = (event) => {



        const { name, value } = event?.target;
        const errors = { ...this.state.errors };

        // Clear the error for the changed field
        errors[name] = '';

        this.setState({
            [name]: value,
            errors
        });
    };
    handleChangePhoneInput = (value) => {
        this.setState({ value });

    };
    handleSubmit = async (event) => {
        event.preventDefault();

        const { username, email, phone } = this.state;
        const errors = {};

        // Validate username
        if (username.trim() === '') {
            errors.username = 'Name is required';
        }

        // Validate email
        if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            errors.email = 'Invalid email format';
        }

        // Validate phone
        if (!phone.match(/^\d{10}$/)) {
            errors.phone = 'Phone number must be 10 digits';
        }

        // Update the state with the errors
        this.setState({ errors });

        // If there are no errors, submit the form
        if (Object.keys(errors).length === 0) {
            // Perform the form submission logic here
            // e.g., call an API endpoint, update the database, etc.

            // Reset the form
            this.setState({
                username: '',
                email: '',
                phone: '',
                message: '',
                errors: {
                    username: '',
                    email: '',
                    phone: '',
                    message: ''
                }
            });
            console.log('form submitted',)
            // Show the "Thank you" message
            this.setState({ submitted: true });

            // Call the sendEmail function
            await this.sendEmail();
        }
    };

    sendEmail = async (e) => {
        const { email, username, phone, message } = this.state;

        const res = await fetch('https://mailbridge.bridgehealth.in/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email, username, phone, message
            }),
        });

        const data = await res.json();
        if (data.status === 401 || !data) {
            console.log('error');
        } else {
            this.setState({ show: true, email: '', username: '', phone: '', message: '' });
            console.log('Email sent');
        }
    };


    closePopup = () => {
        this.setState({ submitted: false, isOpen: false });
    };

    render() {
        const { username, email, phone, errors } = this.state;

        return (

            <div>
                <Header />
                <Helmet>
                    <title>Lead Generation Form</title>
                    <meta
                        name="description"
                        content="Vaccination for Shingles at Bridge Health: Herpes Zoster, caused by the varicella-zoster virus. Discover symptoms, Vaccines, treatments, & solutions for effective management."
                    />
                </Helmet>
                <div className='image-mobile-terms desktop-lead-gen-form'>
                    <div className='text-align-center '>
                        <h3>Fill the Form Below</h3>
                    </div>
                    <div className="form-body p-5 mb-5" style={{ background: '#3949AB' }}>
                        <div className="row justify-content-center items-center text-center">
                            <div className="form-holder">
                                <div className="form-content">
                                    <div className="form-items">
                                        <form className="requires-validation" method="post" onSubmit={this.handleSubmit}>
                                            <div className="col-md-12 mb-2">
                                                <input className="form-control widthinput"
                                                    type="text"
                                                    value={username}
                                                    onChange={this.handleChange}
                                                    name="username"
                                                    id="name"
                                                    placeholder="Name*"
                                                ></input>
                                                {errors.username && <div className="error">{errors.username}</div>}
                                            </div>
                                            <div className="col-md-12 mb-2">
                                                <input className="form-control widthinput"
                                                    type="email"
                                                    value={email}
                                                    onChange={this.handleChange}
                                                    name="email"
                                                    id="email"
                                                    placeholder="Email*"
                                                ></input>
                                                {errors.email && <div className="error">{errors.email}</div>}

                                            </div>
                                            <div >
                                                <PhoneInput
                                                    placeholder="Phone Number*"
                                                    value={phone}
                                                    onChange={this.handleChangePhoneInput}
                                                    name="phone"


                                                />

                                            </div>

                                            <div className="form-check mt-3">
                                                <input className="form-check-input" type="checkbox" id="invalidCheck3" required></input>
                                                <label className="form-check-label">I agree that Bridge Health may contact me at the email address or phone number above.
                                                </label>
                                            </div>
                                            <div className="form-button mt-3 text-center">
                                                <input type="submit" name="submit" value="Submit" className='submitbutton'></input>
                                            </div>

                                        </form>
                                        {/* {submitted && (
                                        <div className="thankyou-popup" onClick={this.closePopup}>
                                            <h2>Thank You!</h2>
                                            <p>Your details has been successfully submitted. Thanks!</p>
                                            <button type='button' >OK</button>
                                        </div>
                                    )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </div>

        )
    }
}
