import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import FormButton from "../element/component/FormButton";

class nursingAtHome extends Component {
  componentDidMount() {
    document.title = "Nursing at Home | Bridge Health";
  }
  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Best Home Nursing Services in Bangalore |Nursing at home - Bridge
            Health
          </title>
          <meta
            name="description"
            content="Best nursing at home services in Bangalore. Find Patient Care, home nursing Services near me. Nursing For Senior Citizen. Get contact details, reviews at Bridge health"
          />
        </Helmet>
        <section className="team-section padding doctoeback image-mobile-terms">
          {/* About Section Two */}
          <section className="container-fluid ">
            <div className="row align-items-center auto-container reverseFlex">
              <div className="col-lg-6">
                <div className="content-box marginTop40header triangleback">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft">
                      <span className="headeingcolorblack">
                        The care you need in the
                      </span>
                      <span className="headeingcolorblue ">
                        {" "}
                        Comfort of your Home{" "}
                      </span>
                    </h2>
                    <h3 className="textleft marginTop40">
                      Nursing services provided directly at your home
                    </h3>
                  </div>
                  <FormButton/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image-wrapper imgright">
                  <div className="image-one">
                    <img
                      srcset={require("../../assets/images/shape/nursing.webp")}
                      alt=""
                      className="imgwidth"
                      rel="preload"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className="contact-section">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      srcset={require("../../assets/images/shape/nursing1.webp")}
                      alt=""
                      className="width64"
                      rel="preload"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-form-area">
                  <div className="sec-title squrebox">
                    <p className="font22">
                      Sometimes, especially for the elderly and for patients
                      suffering from chronic illnesses, it becomes a challenge
                      to travel to a hospital to get timely care. Furthermore,
                      bedridden patients need timely care and assistance at all
                      times to perform their daily routines.
                    </p>
                  </div>
                  <FormButton/>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="image-wrapper">
            <div className="image-one">
              <img
                srcset={require("../../assets/images/shape/nursing2.webp")}
                alt=""
                className="labwidth"
                rel="preload"
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="contact-form-area">
              <div className="sec-title cont-box">
                <p className="font22">
                  We provide nursing services directly in the comfort of your
                  home. Our nursing staff is professionally trained to assist
                  all kinds of patients and will see to all their needs.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-section">
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      srcset={require("../../assets/images/shape/anurse.webp")}
                      alt=""
                      rel="preload"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="contact-form-area">
                  <h2 className="header1">
                    <span className="headeingcolorblack"> The</span>
                    <span className="headeingcolorblue"> Bridge Health</span>
                    <span className="headeingcolorblack"> Advantage </span>
                  </h2>
                  <ul className="listicon">
                    <li>Assistance with daily routine</li>
                    <li>Administering medicines and treatments</li>
                    <li>Hygiene care</li>
                    <li>Movement assistance</li>
                    <li>Critical nursing care</li>
                    <li>Palliative care</li>
                    <li>Tracheostomy care</li>
                    <li>Spinal injury care</li>
                    <li>PEG feeding</li>
                    <li>Catheter, stoma, and bowel management</li>
                    <li>Domiciliary care</li>
                    <li>Ventilator care and management</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2 className="header1">
                <span className="headeingcolorblue">Nursing </span>
                <span className="headeingcolorblack">without the hassle! </span>
              </h2>
            </div>
            <div className="d-flex justify-content-center pb-4">
                <FormButton ButtonName={'CONSULT / BOOK'} />
              </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default nursingAtHome;
