import React from "react";

const DoctorDataCopy = ({
  imageSrc,
  name,
  description,
  experience,
  location,
}) => {
  return (
    <div className="card-aff">
      <img
        srcSet={imageSrc}
        alt={name}
        className="card-image-aff"
        rel="preload"
      />
      <div className="card-details-aff">
        <h5>
          <span className="headeingcolorblue headeingcolorblueSize card-name-aff">
            {name}
          </span>
        </h5>
        <p className="card-description-aff">
          {description}
          <br></br>
          {experience}
          <br></br>
          {location}
        </p>
        <div className="card-map"></div>
      </div>
    </div>
  );
};

export default DoctorDataCopy;
