import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Helmet } from "react-helmet";
import PhoneInput from "react-phone-number-input";
import ReachToUsForm from "../element/ReachToUsForm";

const aboutbg = require("./../../assets/images/background/contactback.webp");
class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      phone: "",
      errors: {
        username: "",
        email: "",
        phone: "",
      },
      submitted: false,
    };
  }

  closePopup = () => {
    this.setState({ submitted: false });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const errors = { ...this.state.errors };
    errors[name] = "";

    this.setState({
      [name]: value,
      errors,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { username, email, phone } = this.state;
    const errors = {};

    // Validate username
    if (username.trim() === "") {
      errors.username = "Name is required";
    }

    // Validate email
    if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      errors.email = "Invalid email format";
    }

    // Validate phone
    if (!phone.match(/^\d{10}$/)) {
      errors.phone = "Phone number must be 10 digits";
    }

    // Update the state with the errors
    this.setState({ errors });

    // If there are no errors, submit the form
    if (Object.keys(errors).length === 0) {
      // Perform the form submission logic here
      // e.g., call an API endpoint, update the database, etc.

      // Show the "Thank you" message
      this.setState({ submitted: true });

      // Reset the form
      this.setState({
        username: "",
        email: "",
        phone: "",
        message: "",
        errors: {
          username: "",
          email: "",
          phone: "",
          message: "",
        },
      });

      // Show the "Thank you" message
      this.setState({ submitted: true });
      console.log("form submitted", username, email);

      // Call the sendEmail function
      await this.sendEmail();
    }
  };

  sendEmail = async (e) => {
    const { email, username, phone, message } = this.state;
    const res = await fetch("https://mailbridge.bridgehealth.in/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        username,
        phone,
        message,
      }),
    });

    const data = await res.json();
    console.log(data);

    if (data.status === 401 || !data) {
      console.log("error");
    } else {
      this.setState({
        show: true,
        email: "",
        username: "",
        phone: "",
        message: "",
      });
      console.log("Email sent");
    }
  };

  handleChangePhoneInput = (value) => {
    this.setState({ value });
  };

  render() {
    const { username, email, phone, submitted, errors } = this.state;

    return (
      <>
        <Header />
        <Helmet>
          <title>
            Contact us |2nd Phase, JP Nagar, Bangalore 560078 | Bridge Health Medical and Digital Solution Private Limited
          </title>
          <meta
            name="Contact Us"
            content="Contact Bridge Health for inquiries, feedback, Book Appointment, or assistance. Our dedicated team is ready to provide support & address your healthcare-related queries"
          />
        </Helmet>

        {/* <!-- Contact Section Two --> */}
        <section
          className="contact-section-two "
          style={{ marginTop: "100px" }}
        >
          <div className="auto-container">
            <div className="row icon-box">
              <div className="info-column col-lg-8">
                <div className="contact-form-area boxpadding">
                  <h2>Lets Have a Talk</h2>

                  {/* <!-- Contact Form--> */}
                  {/* <div className="contact-form mt-5">
                    <form
                      method="post"
                      onSubmit={this.handleSubmit}
                      id="contact-form"
                    >
                      <div className="row clearfix">
                        <div className="col-md-6 form-group">
                          <label for="name" className="contactlebel">
                            Name
                          </label>
                          <input
                            className="inputcontact"
                            type="text"
                            value={username}
                            onChange={this.handleChange}
                            name="username"
                          />
                          <i className="fas fa-user"></i>
                          {errors.username && (
                            <div className="error">{errors.username}</div>
                          )}
                        </div>
                        <div className="col-md-6 form-group">
                          <label for="name" className="contactlebel">
                            Email
                          </label>
                          <input
                            className="inputcontact"
                            type="email"
                            value={email}
                            onChange={this.handleChange}
                            name="email"
                          />
                          <i className="fas fa-user"></i>
                        </div>

                        <div className="col-md-6">
                          <div className="d-flex">
                            <label for="email" className="contactlebel">
                              Phone no.
                            </label>
                          </div>
                          <div>
                            <PhoneInput
                              value={phone}
                              onChange={this.handleChangePhoneInput}
                              name="phone"
                              className="inputcontact"
                              type="tel"
                            />
                          </div>
                        </div>
                        <div className="col-md-12 form-group">
                          <label for="message" className="contactlebel mt-3">
                            Message
                          </label>
                          <textarea
                            className="textareacontact"
                            name="message"
                            value={this.state.message}
                            onChange={this.handleChange}
                            placeholder="I  would like to talk about............."
                          ></textarea>
                          <i className="fas fa-edit"></i>
                        </div>

                        <div className="col-md-12 form-group">
                          <div className="btn-box text-center btn5">
                            <button
                              className="submitcontact"
                              type="submit"
                              name="submit-form"
                            >
                              SUBMIT
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    {submitted && (
                      <div className="thankyou-popup" onClick={this.closePopup}>
                        <h2>Thank You!</h2>
                        <p>
                          Your details has been successfully submitted. Thanks!
                        </p>
                        <button type="button">OK</button>
                      </div>
                    )}
                  </div> */}
                    <ReachToUsForm/>
                </div>
              </div>
              <div className="info-column col-lg-4 boxcolor">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.018675485648!2d77.58522187454818!3d12.906520587402882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1571e6b9bbaf%3A0xc485c6444a9e56b4!2sBridge%20Health%20Medical%20%26%20Digital%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1686708683071!5m2!1sen!2sin"
                  allowfullscreen=""
                  className="mapwidth"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  title="contact us"
                ></iframe>
                <div>
                  <ul>
                    <li>
                      <img
                        srcSet={require("../../assets/images/icons/call.webp")}
                        alt="call"
                        rel="preload"
                      />
                      <Link to={"tell:+91 8047093866"}>+91 8047093866</Link>
                    </li>

                    <li>
                      <img
                        srcSet={require("../../assets/images/whatsapp.webp")}
                        alt="mail"
                        rel="preload"
                        style={{ width: "70px", height: "20px" }}
                      />
                      <Link to={"8123863522"}>8123863522</Link>
                    </li>
                    <li>
                      <img
                        srcSet={require("../../assets/images/icons/mail.webp")}
                        alt="mail"
                        rel="preload"
                      />
                      <Link to={"mailto:info@bridgehealthgroup.com"}>
                        info@bridgehealthgroup.com
                      </Link>
                    </li>
                    <li>
                      <img
                        srcSet={require("../../assets/images/icons/map.webp")}
                        alt="map"
                        rel="preload"
                      />
                      <span>
                        No.166-167, 21st Main Road,<br></br>{" "}
                        <span className="textcnt">
                          100 Feet Ring Road, 2nd Phase,
                        </span>
                        <br></br>{" "}
                        <span className="textcnt">
                          JP Nagar, Bangalore - 560078
                        </span>{" "}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Page Banner Section --> */}
        <section className="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div className="bottom-rotten-curve alternate"></div>
        </section>
        <Footer />
      </>
    );
  }
}
export default Contact;
