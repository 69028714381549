import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Modal } from "react-bootstrap";

class CaseStudy extends Component {
  state = {
    isOpen: false,
  };
  state1 = {
    isOpen1: false,
  };
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  render() {
    return (
      <>
        <div className="expanded-text grow bozhover" onClick={this.openModal}>
          <div >
            <img
              srcSet={require("../../assets/images/shape/s1.webp")}
              src={require("../../assets/images/shape/s1.webp")}
              
              alt="case study"
              rel="preload"
              className="w-100 h-100"
            />
            </div>
            <div className="short-name expertise">
              Chronic Care <br></br>Programs
            </div>
            <div className="longer-name">
              <h1 className="boxtitle">
                Chronic Care <br></br>Programs
              </h1>
              Long-term illnesses and chronic diseases can be very scary to
              think about. Discover our chronic care programs for effective
              chronic illness management.
            </div>
         
        </div>
        <Modal
          show={this.state.isOpen}
          onHide={this.closeModal}
          className="widthdialog modalDialog"
        >
          <Modal.Header closeButton>
            <a href={"/diabetes"} title="Diabetes Management">
              <span>
                <img
                  className="popup2"
                  srcSet={require("../../assets/images/shape/healthdevice.webp")}
                  alt="healthdevice"
                  rel="preload"
                />
                <p className="popup2p">Diabetes Management</p>
              </span>
            </a>
            <a href={"/hyper-tension"} title="Hypertension Management">
              <span>
                <img
                  className="popup2"
                  srcSet={require("../../assets/images/shape/blood.webp")}
                  alt="healthdevice"
                  rel="preload"
                />
                <p className="popup2p">Hypertension Management</p>
              </span>
            </a>

            <a href={"/manage-weight"} title="Weight Management">
              <span>
                <img
                  className="popup2"
                  srcSet={require("../../assets/images/shape/apple.webp")}
                  alt="healthdevice"
                  rel="preload"
                />
                <p className="popup2p">Weight Management</p>
              </span>
            </a>
          </Modal.Header>
        </Modal>
      </>
    );
  }
}
export default CaseStudy;
