import React from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CovidTab from "./CovidTab";
import TabCovid from "./TabCovid";
import FaqAccordionMob from "./faq-accordion-mob";

function covid() {
  return (
    <>
      <Header />
      <Helmet>
        <title>
          Comprehensive Guide to Shingles: Causes, Symptoms, Treatment |
          BridgeHealth
        </title>
        <meta
          name="Covid"
          content="Vaccination for Shingles at Bridge Health: Herpes Zoster, caused by the varicella-zoster virus. Discover symptoms, Vaccines, treatments, & solutions for effective management."
        />
      </Helmet>
      <section className="team-section padding backimg3">
        {/* About Section Two */}
        <section className="container-fluid">
          <div className="row align-items-center auto-container reverseFlex">
            <div className="col-lg-6">
              <div className="desk-top">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50 wrap-font font55 mt-5">
                      <span className="headeingcolorblue wrap-shin">
                        Let’s prevent{" "}
                      </span>{" "}
                      <br></br>
                      <span className="headeingcolorblue wrap-shin">
                        {" "}
                        another pandemic{" "}
                      </span>
                    </h2>
                    <h3 className="textleft wrap-text1 font44">
                      Take your COVID Vaccine<br></br> and stay protected.
                    </h3>
                  </div>
                  <div className="btn-box">
                    <ContactForm
                      buttonText="CONSULT / BOOK"
                      popupPosition="right"
                      className="btn-style-one"
                    />
                  </div>
                </div>
              </div>

              {/* Mobile Code  */}
              <div className="mob-mobile">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50 mt-5 uni-shingles">
                      <span className="headeingcolorblue">
                        Let’s prevent another{" "}
                      </span>{" "}
                      <br></br>{" "}
                      <span className="headeingcolorblue"> pandemic </span>
                    </h2>
                    <h3 className="uni-covid">
                      Take your COVID Vaccine and stay protected.
                    </h3>
                  </div>
                  <div className="btn-box btn8">
                    <ContactForm
                      buttonText="CONSULT / BOOK"
                      popupPosition="right"
                      className="btn-style-one"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="imgright">
                <img
                  srcset={require("../../assets/images/shape/covidDesk.webp")}
                  alt="covid"
                  className="imgwidth wrap2-shi-img1 mobilehide"
                  rel="preload"
                />
                <img
                  srcset={require("../../assets/images/shape/covidMob.webp")}
                  alt="covis-mobile"
                  className="imgwidth wrap2-shi-img1 desktophide"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="covid-third-sec">
        <div className="container-fluid covidbackImg">
          <div className="auto-container">
            <div className="marginTop80">
              <h2 className="header1">
                <span className="headeingcolorblack wrap-header1 wrap-header2">
                  WHAT IS
                </span>
                <span className="headeingcolorblue wrap-header1 wrap-header2">
                  {" "}
                  COVID?
                </span>
              </h2>
            </div>
            <div>
              <p className="wrap-covid">
                COVID-19, short for "Coronavirus Disease 2019," is a highly
                contagious respiratory illness caused by the novel coronavirus,
                SARS-CoV-2. It emerged in late 2019 and quickly spread globally,
                leading to the largest pandemic of the 21st century. COVID-19
                spreads through respiratory droplets when an infected person
                coughs, sneezes, or talks (similar to the flu). Symptoms range
                from mild to severe and can include fever, cough, shortness of
                breath, fatigue, loss of taste or smell, and muscle aches. In
                severe cases, the virus can lead to pneumonia and organ failure,
                particularly in older adults and those with underlying health
                conditions and compromised immune systems.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Mobile code  */}
      <section className="covid-mob-sec">
        <div className="container">
          <div className="marginTop80">
            <h2 className="header1-covid">
              <span className="headeingcolorblack wrapper-mob">What is </span>
              <span className="headeingcolorblue wrapper-mob"> Covid?</span>
            </h2>
          </div>
          <div>
            <p className="wrap-covid-mob">
              COVID-19, short for "Coronavirus Disease 2019," is a highly
              contagious respiratory illness caused by the novel coronavirus,
              SARS-CoV-2. It emerged in late 2019 and quickly spread globally,
              leading to the largest pandemic of the 21st century. COVID-19
              spreads through respiratory droplets when an infected person
              coughs, sneezes, or talks (similar to the flu). Symptoms range
              from mild to severe and can include fever, cough, shortness of
              breath, fatigue, loss of taste or smell, and muscle aches. In
              severe cases, the virus can lead to pneumonia and organ failure,
              particularly in older adults and those with underlying health
              conditions and compromised immune systems.
            </p>
          </div>
        </div>
      </section>
      {/* Desktop page code */}
      <section className="covid-four-sec">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-4">
              <h2 className="header-covid">
                <span className="headeingcolorblue wrap-header-covid">
                  750 Million+
                </span>
              </h2>
              <p className="covid-par">Total cases of COVID-19 worldwide</p>
            </div>
            <div className="col-lg-4">
              <h2 className="header-covid">
                <span className="headeingcolorblue wrap-header-covid">
                  6.9 Million+
                </span>
              </h2>
              <p className="covid-par">Deaths due to COVID-19</p>
            </div>
            <div className="col-lg-4">
              <h2 className="header-covid">
                <span className="headeingcolorblue wrap-header-covid">
                  1.5 Million+
                </span>
              </h2>
              <p className="covid-par">New cases as per last record</p>
            </div>
          </div>

          <div className="btn-box text-center">
            <ContactForm
              buttonText="CONSULT / BOOK"
              popupPosition="right"
              className="btn-style-one"
            />
          </div>
        </div>
      </section>

      {/* Mobile code */}
      <section className="mobi-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="header-covid-mob marginBtmText">
                <span className="headeingcolorblue wrap-header-covid-mob">
                  750 Million+
                </span>
              </h2>
              <p className="covid-par-mob">Total cases of COVID-19 worldwide</p>
            </div>
          </div>
          <div className="item-mob">
            <div className="row">
              <div className="col-6">
                <h2 className="header-covid marginBtmText">
                  <span className="headeingcolorblue wrap-header-covid-mob">
                    6.9 Million+
                  </span>
                </h2>
                <p className="covid-par-mob">Deaths due to COVID-19</p>
              </div>
              <div className="col-6">
                <h2 className="header-covid marginBtmText">
                  <span className="headeingcolorblue wrap-header-covid-mob">
                    1.5 Million+
                  </span>
                </h2>
                <p className="covid-par-mob">New cases as per last record</p>
              </div>
            </div>
          </div>
          <div
            className="btn-box text-center"
            style={{ marginBottom: 55 + "px" }}
          >
            <ContactForm
              buttonText="CONSULT / BOOK"
              popupPosition="right"
              className="btn-style-one"
            />
          </div>
        </div>
      </section>

      {/* SYMPTOMS OF COVID */}
      {/* Desktop code  */}
      <section className="des-covid">
        <div className="auto-container">
          <div className="top-covid">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/Mask-group.webp")}
                  alt="mask-group"
                  className="imgwidth"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div>
                  <div className="card wrap-inter-covid">
                    <div className="card-body">
                      <h2 className="header1">
                        <span className="headeingcolorblack wrap-header2-item">
                          SYMPTOMS OF
                        </span>
                        <span className="headeingcolorblue wrap-header2-item">
                          {" "}
                          COVID?
                        </span>
                      </h2>

                      <p className="card-text-partners1">
                        COVID-19 symptoms can vary widely, with some individuals
                        experiencing mild effects while others may become
                        seriously ill. Common symptoms include fever, persistent
                        cough, and shortness of breath. Additionally, loss of
                        taste or smell, fatigue, body aches, sore throat, and
                        congestion are frequently reported. Some individuals may
                        experience gastrointestinal symptoms such as nausea or
                        diarrhoea. It's important to note that symptoms can
                        develop 2-14 days after exposure to the virus. Severe
                        cases can lead to pneumonia, acute respiratory distress
                        syndrome, and organ failure, particularly in vulnerable
                        populations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper-mobile">
        <div className="shi-wrap-mobile">
          <figure className="text-overlay-image">
            <img
              srcset={require("../../assets/images/shape/covidMob1.webp")}
              alt=""
              className="cov-item"
              rel="preload"
            />
          </figure>
          <div className="card wrap-inter-covid-mob">
            <div className="card-body">
              <h2 className="header18">
                <span className="headeingcolorblack wrap-header2-mob">
                  Symptoms of{" "}
                </span>
                <span className="headeingcolorblue wrap-header2-mob">
                  {" "}
                  COVID
                </span>
              </h2>

              <p className="card-text-par">
                COVID-19 symptoms can vary widely, with some individuals
                experiencing mild effects while others may become seriously ill.
                Common symptoms include fever, persistent cough, and shortness
                of breath. Additionally, loss of taste or smell, fatigue, body
                aches, sore throat, and congestion are frequently reported. Some
                individuals may experience gastrointestinal symptoms such as
                nausea or diarrhoea. It's important to note that symptoms can
                develop 2-14 days after exposure to the virus. Severe cases can
                lead to pneumonia, acute respiratory distress syndrome, and
                organ failure, particularly in vulnerable populations.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="desk-item backGroundRight mobilehide">
        <div className="container-fulid backimg2">
          <div className="auto-container">
            <CovidTab />
          </div>
        </div>
      </div>

      <div className="desk-item-covid backGroundRight d-lg-none">
        <div className="container-fulid backimg2">
          <div className="auto-container">
            <FaqAccordionMob />
          </div>
        </div>
      </div>
      <section>
        <div className="more-desktop">
          <div className="wrap-sites item-covid">
            <div className="wrap-img-sec7">
              <img
                srcset={require("../../assets/images/shape/covid1.webp")}
                alt="covid"
                className="imgwidth3"
                rel="preload"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Mobile code */}
      <section className="mt-3">
        <div className="more-mobile">
          <div className="wrap-sites">
            <div className="wrap-img-sec7">
              <img
                srcset={require("../../assets/images/shape/mobcovid.webp")}
                alt="covid"
                className="imgwidth3"
                rel="preload"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="backFaqsRight" id="section-3">
        <div className="container-fulid backFaqsLeft">
          <div className="auto-container">
            <TabCovid />
          </div>
        </div>
      </div>
      <section style={{ marginBottom: 4 + "rem" }}>
        <div className="auto-container">
          <div className="row">
            {/*
                <!-- News Block One --> */}
            <div
              className="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div className="inner-box">
                <div className="image">
                  <Link to={"/#"}>
                    {" "}
                    <img
                      srcset={require("../../assets/images/shape/blog.webp")}
                      alt="blog"
                      rel="preload"
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to={""}>What Does Your GI Score Indicate?</Link>
                  </h3>
                  <div className="text">
                    As a child, you were likely cautioned to limit your intake
                    of sweets. Remember the warning not to go
                  </div>
                </div>
              </div>
            </div>
            {/*
                <!-- News Block One --> */}
            <div
              className="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div className="inner-box">
                <div className="image">
                  <Link to={"/#"}>
                    {" "}
                    <img
                      srcset={require("../../assets/images/shape/blog1.webp")}
                      alt="blog"
                      rel="preload"
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to={""}>Myths And Facts About GI Foods</Link>
                  </h3>
                  <div className="text">
                    Carbohydrates have been villainized in recent years as the
                    culprit for weight gain and even chronic diseases
                  </div>
                </div>
              </div>
            </div>
            {/*
                <!-- News Block One --> */}
            <div
              className="news-block-one col-lg-4 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div className="inner-box">
                <div className="image">
                  <Link to={"/#"}>
                    {" "}
                    <img
                      srcset={require("../../assets/images/shape/blog3.webp")}
                      alt="blog"
                      rel="preload"
                    />
                  </Link>
                </div>
                <div className="lower-content">
                  <h3>
                    <Link to={""}>
                      Everything you need to know about Glycemic Index
                    </Link>
                  </h3>
                  <div className="text">
                    Even with the world becoming more health-conscious, diseases
                    seem to be on the rise. While you frantically
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default covid;
