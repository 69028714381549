import React from "react";



const EventItem = ({ title, description, imageSrc, eventLink }) => {
  console.log("Image Source:", imageSrc);

  return (
    <>
       <a href={eventLink}>
        <div className="col-xl-12">
          <div className="event-item">
            <img className="w-100 h-100" src={imageSrc} alt={title} rel="preload"/>          
            <h3 className="pt-2">{title}</h3>
          </div>
        </div>
        </a>
    </>
  );
};

export default EventItem;
