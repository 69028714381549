import React, { Component } from "react";
import { Link } from "react-router-dom";
import Menu from "./menu";
import { Modal } from "react-bootstrap";

class Header extends Component {
  state = {
    scrolled: false,
  };

  componentDidMount() {
    var btn = document.querySelector(".mobile-nav-toggler");
    var closeBtn = document.querySelector(".close-btn");
    var body = document.getElementsByTagName("body")[0];

    function addFunc() {
      return body.classList.add("mobile-menu-visible");
    }
    function closeFunc() {
      return body.classList.remove("mobile-menu-visible");
    }

    btn.addEventListener("click", addFunc);
    closeBtn.addEventListener("click", closeFunc);
  }

  handleScroll = (event) => {
    if (window.scrollY > 100) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      phone: "",
      errors: {
        username: "",
        email: "",
        phone: "",
      },
      submitted: false,
      selectedOption: "",
      isOpen: false,
      toggle: false,
    };
  }

  closePopup = () => {
    this.setState({ submitted: false });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const errors = { ...this.state.errors };

    // Clear the error for the changed field
    errors[name] = "";

    this.setState({
      [name]: value,
      errors,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { username, email, phone } = this.state;
    const errors = {};

    // Validate username
    if (username.trim() === "") {
      errors.username = "Name is required";
    }

    // Validate email
    if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      errors.email = "Invalid email format";
    }

    // Validate phone
    if (!phone.match(/^\d{10}$/)) {
      errors.phone = "Phone number must be 10 digits";
    }

    // Update the state with the errors
    this.setState({ errors });

    // If there are no errors, submit the form
    if (Object.keys(errors).length === 0) {
      // Perform the form submission logic here
      // e.g., call an API endpoint, update the database, etc.

      // Reset the form
      this.setState({
        username: "",
        email: "",
        phone: "",
        message: "",
        errors: {
          username: "",
          email: "",
          phone: "",
          message: "",
        },
      });

      // Show the "Thank you" message
      this.setState({ submitted: true });

      // Call the sendEmail function
      await this.sendEmail();
    }
  };

  sendEmail = async (e) => {
    // e.preventDefault();

    const { email, username, phone, message } = this.state;

    const res = await fetch("https://mailbridge.bridgehealth.in/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        username,
        phone,
        message,
      }),
    });

    const data = await res.json();
    if (data.status === 401 || !data) {
      console.log("error");
    } else {
      this.setState({
        show: true,
        email: "",
        username: "",
        phone: "",
        message: "",
      });
      console.log("Email sent");
    }
  };

  state = {
    scrolled: false,
  };

  handleDropdownChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  render() {
    const { scrolled } = this.state;
    return (
      <>
        <Modal
          show={this.state.isOpen}
          onHide={this.closeModal}
          className="widthdialog modalDialog"
        >
          <Modal.Header closeButton>
            <a href={"/diabetes"} title="Diabetes Management">
              <span>
                <img
                  className="popup2"
                  srcSet={require("../../assets/images/shape/healthdevice.webp")}
                  alt="header"
                  rel="preload"
                />
                <p className="popup2p">Diabetes Management</p>
              </span>
            </a>
            <a href={"/hyper-tension"} title="Hypertension Management">
              <span>
                <img
                  className="popup2"
                  srcSet={require("../../assets/images/shape/blood.webp")}
                  alt="header"
                  rel="preload"
                />
                <p className="popup2p">Hypertension Management</p>
              </span>
            </a>

            <a href={"/manage-weight"} title="Weight Management">
              <span>
                <img
                  className="popup2"
                  srcSet={require("../../assets/images/shape/apple.webp")}
                  alt="header"
                  rel="preload"
                />
                <p className="popup2p">Weight Management</p>
              </span>
            </a>
          </Modal.Header>
        </Modal>
        <header
          className="main-header mobilehide"
        >
          <div className=" d-flex justify-content-center align-items-center header-style-content">
            <a href="https://bridgehealth.in" title="footer logo">
              <img
                srcSet={require("../../assets/images/bhlogo.webp")}
                alt="header"
                className="footer-logo-style"
                rel="preload"
              />
            </a>

            <div className="cm-e-menu">
              <ul className="d-flex">
                <li className="topmenu">
                  <div className="d-flex gap-10">
                    <div>
                      <a
                        className="text-dark"
                        href="/our-services"
                        title="Our Services"
                      >
                        Our Services
                      </a>
                    </div>
                    <div>
                      <img
                        className="width-20px"
                        srcSet={require("../../assets/images/dropdown.webp")}
                        alt="header"
                        rel="preload"
                      />
                    </div>
                  </div>
                  <ul className="submenu">
                    <li className="divider">
                      <a
                        href="/personal-membership-program"
                        title="Personal Health Plans"
                      >
                        Personal Health Plans
                      </a>
                    </li>
                    <li className="divider">
                      <a
                        href="/corporate-membership-program"
                        title="Corporate Wellness"
                      >
                        Corporate Wellness
                      </a>
                    </li>

                    <li className="divider">
                  
                      <div style={{cursor:'pointer'}}
                      
                        onClick={this.openModal}
                      >
                        Chronic Care Management
                      </div>
                    </li>

                    <li className="divider">
                      <div className="d-flex gap-10">
                        <div>
                          <a title="Vaccination" href="/vaccination">
                            Vaccination
                          </a>
                        </div>
                        <div>
                          <img
                            srcSet={require("../../assets/images/dropdown.webp")}
                            alt="header"
                            className="width-20px"
                            rel="preload"
                          />
                        </div>
                      </div>

                      <ul className="submenu">
                        <li className="divider">
                          {" "}
                          <a title="HPV" href="/hpv-vaccines">
                            HPV{" "}
                          </a>
                        </li>

                        <li className="divider">
                          <a title="Shingles" href={"/shingles"}>
                            Shingles
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li ref={this.scrollRef} className="divider">
                      {" "}
                      <a
                        title="Health Risk Assessment"
                        className="margin-top-80px"
                        href="/hra"
                      >
                        Health Risk Assessment
                      </a>
                    </li>

                    <li className="divider">
                      <a
                        title="Comprehensive Health Checks"
                        href="/comprehensive-health"
                      >
                        Comprehensive Health Checks
                      </a>
                    </li>
                    <li className="divider">
                      <div className="d-flex gap-10">
                        <div>
                          <a title="Consultation" href="/doctor-consults">
                            Consultation
                          </a>
                        </div>
                        <div>
                          <img
                            srcSet={require("../../assets/images/dropdown.webp")}
                            alt="header"
                            className="width-20px"
                            rel="preload"
                          />
                        </div>
                      </div>
                      <ul className="submenu">
                        <li className="divider">
                          <a title="Psychology" href={"/psychological-Health"}>
                            Psychology
                          </a>
                        </li>
                        <li className="divider">
                          <a title="Nutrition" href={"/diet-and-nutrition"}>
                            Nutrition
                          </a>
                        </li>
                        <li className="divider">
                          <a title="Yoga" href={"/yoga-Assessment"}>
                            Yoga
                          </a>
                        </li>
                        <li className="divider">
                          <a title="Fitness" href={"/fitness-assessment"}>
                            Fitness
                          </a>
                        </li>
                        <li className="divider">
                          <a title="Physiotherapy" href={"/physiotherapy"}>
                            Physiotherapy
                          </a>
                        </li>
                        <li className="divider">
                          <div title="Specialist Consults" style={{cursor:'pointer'}}>
                            Specialist Consults{" "}
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li className="divider">
                      {" "}
                      <a title="Screenings" href="/explore">
                        Screenings{" "}
                      </a>
                    </li>
                    <li className="divider">
                      {" "}
                      <a title="Nursing" href="/nursing-at-home">
                        Nursing
                      </a>
                    </li>
                    <li className="divider">
                      <a title="Pharmacy" href="/pharmacy">
                        Pharmacy
                      </a>
                    </li>
                    <li className="divider">
                      <a title="Elder Care" href="/elderly-care">
                        Elder Care
                      </a>
                    </li>
                    <li className="divider">
                      <a title="Women’s Care" href="/explore">
                        Women’s Care
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="topmenu">
                  <div className="d-flex gap-10">
                    <div>
                      <a
                        className="text-dark"
                        href="/about-us"
                        title="About Us"
                      >
                        About Us
                      </a>
                    </div>
                    <div>
                      <img
                        srcSet={require("../../assets/images/dropdown.webp")}
                        alt="header"
                        className="width-20px"
                        rel="preload"
                      />
                    </div>
                  </div>
                  <ul className="submenu">
                    <li className="subs divider">
                      <a title="Leadership Team" href="/leadership">
                        Leadership Team
                      </a>
                    </li>
                    <li className="subs divider">
                      <a title="Clinical Team" href="/team">
                        Clinical Team
                      </a>
                    </li>
                    <li className="subs divider">
                      <a title="Media" href="/media-and-pr">
                        Media
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="topmenu">
                  <a title="Career" className="text-dark" href="/career">
                    Career
                  </a>
                </li>
                <li>
                  <a title="Contact" className="text-dark" href="/contact-us">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* <div>
            <nav>
              <ul className="mcd-menu">
                <li>
                  <a href="#">
                    <img srcSet={require('../../assets/images/menu.png')} alt="" />
                  </a>
                  <ul>
                    <li><a href="#">Menu</a>
                      <ul>
                        <li><a href="/about-us">About Us</a>
                        </li>
                        <li>
                              <a href="/leadership">Leadership Team</a>
                            </li>
                            <li><a href="/team">Clinical Team</a></li>
                        <li>
                          <a href="/our-services">Our Services</a>
                        </li>
                        <li>
                          <a href="/vaccination">Vaccination </a>
                        </li>
                        <li>
                          <a href="/hpv-vaccines">HPV </a>
                        </li>
                        <li><a href={'/shingles'}>Shingles Vaccination</a></li>
                        <li><a href="/blog">Blogs</a></li>
                        <li><a href="/media-and-pr">Media</a></li>
                        <li><a href="/faq">FAQ</a></li>
                        <li><a href="/career">Career</a></li>
                        <li><a href="/contact-us">Contact</a></li>
                      </ul>
                    </li>
                    <li>
                      <a href="/personal-membership-program">Health Plan</a>

                    </li>
                    <li><a href="#">Search</a></li>
                  </ul>
                </li>


              </ul>
            </nav>
          </div> */}
        </header>
        <header

          className="desktophide main-header fixed-header header-style-two"
        >
          <div className="header-upper">
            <div className="auto-container">
              <div className="inner-container">
                <div className="nav-outer clearfix">
                  <div className="logo-box">
                    <div className="logo">
                      <Link to={""}>
                        <img
                          srcSet={require("../../assets/images/bhlogo.webp")}
                          alt="header"
                          className="footer-logo-style"
                          rel="preload"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="mobile-nav-toggler">
                    <span
                      onClick={() =>
                        this.setState({ toggle: !this.state.toggle })
                      }
                      className="icon fal fa-bars"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.toggle ? (
            <div className="mobile-menu">
              <div className="menu-backdrop"></div>

              <div
                className="close-btn"
                onClick={() => this.setState({ toggle: false })}
              >
                <span className="icon flaticon-cancel"></span>
              </div>

              <nav className="menu-box">
                <div className="menu-outer">
                  <Menu />
                </div>
              </nav>
            </div>
          ) : null}
        </header>
      </>
    );
  }
}
export default Header;
