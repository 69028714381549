import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import FormButton from "../element/component/FormButton";

class manageWeight extends Component {
  componentDidMount() {
    document.title = "Manage Weight | Bridge Health";
  }

  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Best Weight Loss centre |Weight management in Bangalore - Bridge
            health
          </title>
          <meta
            name="description"
            content="Best doctors for weight loss diet counseling in Bangalore. Get personalized diet plans & expert guidance. Book online appointment for weight loss treatment in Bangalore."
          />
        </Helmet>
        <section className="team-section padding image-mobile-terms">
          {/* About Section Two */}
          <section className="container-fluid">
            <div className="row align-items-center auto-container reverseFlex">
              <div className="col-lg-6">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50">
                      <span className="headeingcolorblack">Manage Weight,</span>
                      <br></br>
                      <span className="headeingcolorblue"> Feel Great</span>
                    </h2>
                    <h3 className="textleft marginTop40">
                      Maintain the right weight <br></br> with the help of our
                      experts
                    </h3>
                  </div>

                  <FormButton/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image-wrapper imgright">
                  <div className="image-one">
                    <img
                      srcset={require("../../assets/images/shape/weight1.webp")}
                      alt=""
                      rel="preload"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className="contact-section heath-margin">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/weight2.webp")}
                  alt=""
                  className="widthimg"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div className="sec-title squrebox">
                  <p className="font22">
                    Weight management is vital for maintaining good health.
                    Failing to manage one's weight can lead to a range of health
                    problems and complications, including an increased risk of
                    heart disease, stroke, and diabetes. Obesity is linked to a
                    higher risk of high blood pressure, high cholesterol, and
                    sleep apnea.
                  </p>
                </div>
                <FormButton/>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="row align-items-center reverseFlex">
              <div className="col-lg-6">
                <div className="sec-title squrebox">
                  <p className="font22">
                    Conversely, being underweight can lead to malnutrition, a
                    weakened immune system, and osteoporosis. In addition to
                    physical health issues, improper weight management can also
                    have an impact on mental health and wellbeing, including
                    depression, anxiety, and low self-esteem. It is important to
                    take weight management seriously and seek support from
                    healthcare professionals to help prevent these potential
                    health problems.
                  </p>
                </div>
                <FormButton />
              </div>
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/mweight.webp")}
                  alt=""
                  className="widthimg imgFloatRight"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="contact-section heath-margin">
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/weight4.webp")}
                  alt=""
                  className="widthimg"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div className="sec-title squrebox">
                  <p className="font22">
                    Diabetes is a chronic condition that affects millions of
                    people worldwide. If left untreated or improperly managed,
                    it can lead to a range of complications, including nerve
                    damage, blindness, kidney disease, and heart disease.
                    Therefore, proper diabetes care is essential for maintaining
                    good health and preventing these complications.
                  </p>
                </div>
                <FormButton />
              </div>
            </div>
          </div>
        </section>

        <section>
          <h2 className="header1 text-center">
            <span className="headeingcolorblack"> The</span>
            <span className="headeingcolorblue"> Bridge Health</span>
            <span className="headeingcolorblack"> Advantage </span>
          </h2>
          <div className="auto-container heath-margin">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-form-area">
                  <ul className="listicon">
                    <li>
                      Assess current health status, weight, BMI, and medical
                      conditions
                    </li>
                    <li>
                      Develop personalized weight management plan based on
                      goals, lifestyle, and medical history
                    </li>
                    <li>
                      Provide guidance on healthy eating habits and food choices
                    </li>
                    <li>
                      Recommend appropriate physical activity and exercise
                    </li>
                    <li>Refer to other healthcare professionals as needed</li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="contact-form-area">
                  <ul className="listicon">
                    <li>
                      Monitor progress and provide ongoing support and
                      encouragement
                    </li>
                    <li>
                      Offer resources such as weight management guides and
                      videos
                    </li>
                    <li>
                      Collaborate with other healthcare team members for
                      comprehensive care
                    </li>
                    <li>
                      Prescription of medications or other medical interventions
                    </li>

                    <li>
                      Identify and address emotional or behavioural issues that
                      may be underlying causes for weight issues
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2 className="header1">
                <span className="headeingcolorblack"> Fit is the new </span>
                <span className="headeingcolorblue"> Skinny! </span>
              </h2>
            </div>
            <div  className="d-flex justify-content-center pb-5">
            <FormButton />
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}
export default manageWeight;
