import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";

const abouteye = require("./../../assets/images/shape/eyetest.webp");

class ophthalmologist extends Component {
  componentDidMount() {
    document.title = "Ophthalmologist | Bridge Health";
  }
  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Best Ophthalmologist Services |Best Eye check up in Bangalore
          </title>
          <meta
            name="description"
            content="Book consultations with the best ophthalmologists in Bangalore. We need to take care of our eyes to prevent any eye-related illnesses. Meet our ophthalmologists today for expert eye checkup."
          />
        </Helmet>
        <section
          className="internalpage style-two internalpageresponsive"
          style={{ backgroundImage: "url(" + abouteye + ")" }}
        >
          <div className="auto-container">
            <div className="content-box">
              <div className=" text-center textleft">
                <h2 className="textleft fontSize">
                  <span className="headeingcolorblack">
                    {" "}
                    Experience the benefits{" "}
                  </span>
                  <br></br>
                  <span className="headeingcolorblack">of</span>
                  <span className="headeingcolorwhite">
                    {" "}
                    Crystal Clear Vision
                  </span>
                </h2>
                <h3 className="textleft marginTop40">
                  Talk to our OPHTHALMOLOGISTS today
                </h3>
              </div>
              <div className="btn-box btn5">
                <ContactForm buttonText="BOOK NOW" popupPosition="right" />
              </div>
            </div>
          </div>
        </section>
        <section className="contact-section">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/eye1.webp")}
                  alt=""
                  className="widthimg"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div className="contact-form-area">
                  <div className="sec-title squrebox">
                    <p className="font22">
                      Our eyes are the windows to the world. They allow us to
                      see every detail and colour, near and far. However,
                      because of our modern-day habits and work, we spend too
                      much time looking at screens which strains our eyes. We
                      also tend to blink less which leads to dry eyes. These
                      factors contribute to poor eyesight in children and
                      adults.
                    </p>
                  </div>
                  <div className="btn-box text-center btn5">
                    <ContactForm buttonText="BOOK NOW" popupPosition="left" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <img
            srcset={require("../../assets/images/shape/eye2.webp")}
            alt=""
            className="labwidth"
            rel="preload"
          />
        </section>
        <section className="contact-section">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="contact-form-area">
                  <div className="sec-title squrebox">
                    <p className="font22">
                      Your eyes deserve great care. At Bridge Health, our expert
                      ophthalmologists will help you through regular eye
                      testing, vision enhancement recommendations, and
                      treatments so that you’ll never have to worry about
                      squinting to read that sign board across the street ever
                      again!
                    </p>
                  </div>
                  <div className="btn-box text-center btn5">
                    <ContactForm buttonText="BOOK NOW" popupPosition="right" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/eye3.webp")}
                  alt=""
                  className="width84"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/eye5.webp")}
                  alt=""
                  className="widthimg"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <h2 className="header1">
                  <span className="headeingcolorblack"> The</span>
                  <span className="headeingcolorblue"> Bridge Health</span>
                  <span className="headeingcolorblack"> Advantage </span>
                </h2>
                <ul className="listicon">
                  <li>Eye care tips</li>
                  <li>Consult with expert ophthalmologists</li>
                  <li>Expert advice on eye surgeries and procedures</li>

                  <li>Glasses and contact lens prescriptions</li>
                  <li>Comprehensive eye tests and examinations</li>

                  <li>
                    Prescriptions and medicines for various eye conditions like
                    conjunctivitis
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2 className="header1">
                <span className="headeingcolorblack">
                  Experience the beauty of
                </span>
                <span className="headeingcolorblue"> Clear Vision! </span>
              </h2>
            </div>
            <div className="btn-box text-center btn5">
              <ContactForm buttonText="BOOK NOW" popupPosition="right" />
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default ophthalmologist;
