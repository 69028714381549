import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Helmet } from "react-helmet";

const aboutbg = require("./../../assets/images/shape/car1.webp");
class Career extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      selectedDepartment: "",
      jobs: [],
      filteredJobs: [],
    };
  }

  componentDidMount() {
    this.fetchJobs();
    document.title = "Career | Bridge Health";
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.filterJobs();
      }
    );
  };

  fetchJobs = () => {
    const siteUrl = `https://admin.bridgehealth.in`;

    fetch(`${siteUrl}/api/create-jobs`)
      .then((response) => response.json())
      .then((data) => {
        const jobs = data.data.map((job) => ({
          ...job.attributes,
          id: job.id,
        }));
        const sortedJobs = jobs.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        this.setState({
          jobs: sortedJobs,
          filteredJobs: sortedJobs,
        });
      })
      .catch((error) => {
        console.error("Error fetching jobs:", error);
      });
  };

  filterJobs = () => {
    const { jobs, searchTerm, selectedDepartment } = this.state;

    try {
      const filtered = jobs.filter((job) => {
        const jobFields = [
          job.designation.toLowerCase(),
          job.department.toLowerCase(),
          job.location.toLowerCase(),
          job.contract_type.toLowerCase(),
        ];

        const searchTermMatch = jobFields.some((field) =>
          field.includes(searchTerm.toLowerCase())
        );

        const departmentMatch =
          selectedDepartment === "" ||
          job.department.toLowerCase() === selectedDepartment.toLowerCase();

        return searchTermMatch && departmentMatch;
      });

      this.setState({
        filteredJobs: filtered,
      });
    } catch (error) {
      console.error("Error during filtering:", error);
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.filterJobs();
  };
  render() {
    const { filteredJobs, selectedDepartment } = this.state;
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Join Our Team | Career Opportunities | Jobs at Bridge Health
          </title>
          <meta
            name="description"
            content="Explore exciting career opportunities at Bridge Health. Join our dynamic team and contribute to improving lives through innovative healthcare solutions."
          />
        </Helmet>
        <section
          className="internalpage carrerpadding  internalpageresponsive style-two image-mobile-terms"
          style={{ backgroundImage: "url(" + aboutbg + ")" }}
        >
          <div className="auto-container">
            <div className="content-box careerbox">
              <div className=" text-center ">
                <h2 className="textleft-text fontSize">
                  <span className="headeingcolorblue"> Careers </span>
                  <span className="headeingcolorblack">
                    {" "}
                    at <br></br> Bridge Health{" "}
                  </span>
                  <br></br>
                </h2>
              </div>
            </div>
          </div>
        </section>

        <div className="text-align-center">
          <button className="commonBtnforAll">
            <a title="Apply Now" href="/career-form" className="textcolor">
              Apply Now
            </a>
          </button>
        </div>

        <section className="auto-container marginTop50 career-head1 marginTop40reverse">
          {filteredJobs.map((job, index) => (
            <div className="job-card" key={index}>
              <div className="job-card-header">
                <div>
                  <span className="job-title">{job.designation}</span>
                  <span className="job-department">
                    <span className="fontweight">Dept:</span> {job.department}
                  </span>
                </div>
                <div className="careerpadding">
                  <span className="job-location">
                    <img
                      srcSet={require("../../assets/images/shape/iconmap.webp")}
                      alt="mapicon"
                      className="padeight"
                      rel="preload"
                    />
                    {job.location}
                    <span>
                      {" "}
                      <img
                        srcSet={require("../../assets/images/shape/dash.webp")}
                        alt="dash"
                        className="dash"
                        rel="preload"
                      />
                    </span>
                  </span>
                  <span className="job-timing">
                    <img
                      srcSet={require("../../assets/images/shape/icontime.webp")}
                      alt="time"
                      className="padeight"
                      rel="preload"
                    />{" "}
                    {job.contract_type}
                  </span>
                  <span className="job-department">
                    <a
                      title="Apply Now"
                      href="/career-form"
                      className="view-position"
                    >
                      Apply Now
                    </a>
                  </span>
                </div>
                <div className="job-card-footer">
                  <a
                    href={`/job-description/${job.id}`}
                    className="view-position"
                    title="View Position"
                  >
                    View Position &#8594;
                  </a>
                </div>
              </div>
            </div>
          ))}
        </section>

        <section className="contact-section">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      srcSet={require("../../assets/images/shape/career2.webp")}
                      alt="career"
                      className="widthimg"
                      rel="preload"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-form-area">
                  <div className="sec-title squrebox">
                    <h2 className="header1">
                      <span className="headeingcolorblack"> Why work at</span>
                      <span className="headeingcolorblue"> Bridge Health</span>
                    </h2>
                    <p className="font22">
                      Join Bridge Health and be part of a transformative
                      healthcare team. With a dynamic work environment and
                      opportunities for growth, your contributions can truly
                      make a difference. We prioritize work-life balance and
                      offer a supportive atmosphere where you can advance your
                      career while positively impacting lives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="auto-container marginbtm">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="contact-form-area">
                <div className="sec-title squrebox">
                  <h2 className="header1">
                    <span className="headeingcolorblack"> Life at</span>
                    <span className="headeingcolorblue"> Bridge Health</span>
                  </h2>
                  <p className="font22">
                    Experience a fulfilling and inclusive life at Bridge Health.
                    Our collaborative culture fosters teamwork and a sense of
                    belonging. From engaging activities to professional
                    development opportunities, we prioritize your well-being and
                    growth. Join our community dedicated to work-life balance
                    and making a meaningful impact in the preventive healthcare
                    industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image-wrapper">
                <div className="image-one">
                  <img
                    srcSet={require("../../assets/images/shape/career2.webp")}
                    alt="career"
                    className="widthimg"
                    rel="preload"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="auto-container">
            <div className="text-center careertitle">
              <h1
                className="mobile-current-opening"
                style={{ fontWeight: "bold", fontSize: "45px" }}
              >
                Current opening in Bridge Health
              </h1>
            </div>
          </div>
        </section>

        <div className="container" style={{ marginBottom: "50px" }}>
          <div className="row" style={{ background: "white" }}>
            <div className="col-lg-8 mx-auto">
              <div className="bg-white">
                <div className="d-lg-none">
                  <form>
                    <div className="mobile-input">
                      <input
                        name="searchTerm"
                        value={this.state.searchTerm}
                        onChange={this.handleChange}
                        placeholder="Search Topic"
                        required
                      />
                      <button type="submit" className="submitsearch">
                        <span className="icon far fa-search searchblog"></span>
                      </button>
                    </div>
                  </form>
                  <div className="mobile-input">
                    <form action="#" className="language-switcher">
                      <select
                        name="selectedDepartment"
                        value={selectedDepartment}
                        onChange={this.handleChange}
                        className="blog-bgcolor"
                      >
                        <option value="">Filter</option>
                        <option value="Clinical Services">
                          Clinical Services
                        </option>
                        <option value="Operations">Operations</option>
                        <option value="Corporate Operations">
                          Corporate Operations
                        </option>
                        <option value="Corporate Sales">Corporate Sales</option>
                        <option value="Marketing">Marketing</option>
                        <option value="Technology">Technology</option>
                        <option value="Pharmacy">Pharmacy</option>
                        <option value="Sales & Operations">
                          Sales & Operations
                        </option>
                        <option value="Finance">Finance</option>
                        <option value="HR">HR</option>
                        <option value="Facility & Admin">
                          Facility & Admin
                        </option>
                        <option value="Strategic Initiatives">
                          Strategic Initiatives
                        </option>
                        <option value="Affiliates & Alliances">
                          Affiliates & Alliances
                        </option>
                      </select>
                    </form>
                  </div>
                </div>
                {/* <!-- Search --> */}
                <div className=" search-box mobilehide">
                  <form>
                    <div className="form-group1">
                      <input
                        name="searchTerm"
                        value={this.state.searchTerm}
                        onChange={this.handleChange}
                        placeholder="Search Topic"
                        required
                      />
                      <button type="submit" className="submitsearch">
                        <span className="icon far fa-search searchblog"></span>
                      </button>
                    </div>
                  </form>
                  <div className="filtter">
                    <form action="#" className="language-switcher">
                      <select
                        name="selectedDepartment"
                        value={selectedDepartment}
                        onChange={this.handleChange}
                        className="blog-bgcolor"
                      >
                        <option value="">Filter</option>
                        <option value="Clinical Services">
                          Clinical Services
                        </option>
                        <option value="Operations">Operations</option>
                        <option value="Corporate Operations">
                          Corporate Operations
                        </option>
                        <option value="Corporate Sales">Corporate Sales</option>
                        <option value="Marketing">Marketing</option>
                        <option value="Technology">Technology</option>
                        <option value="Pharmacy">Pharmacy</option>
                        <option value="Sales & Operations">
                          Sales & Operations
                        </option>
                        <option value="Finance">Finance</option>
                        <option value="HR">HR</option>
                        <option value="Facility & Admin">
                          Facility & Admin
                        </option>
                        <option value="Strategic Initiatives">
                          Strategic Initiatives
                        </option>
                        <option value="Affiliates & Alliances">
                          Affiliates & Alliances
                        </option>
                      </select>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Career;
