import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Modal } from "react-bootstrap";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";

const aboutfair = require("./../../assets/images/shape/dera.webp");

class dermatology extends Component {
  state = {
    isOpen: false,
    submitted: false,
  };
  state1 = {
    isOpen1: false,
  };
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {
    document.title = "Dermatology | Bridge Health";
  }

  render() {
    return (
      <>
        <>
          <Header />
          <Helmet>
            <title>
              Best Dermatology Services in Bangalore |Skin Care - Bridge Health
            </title>
            <meta
              name="Dermatology"
              content="Book consultations today with the best dermatologists in Bangalore. Our hair, skin, and nails deserve to be treated well. Talk to our dermatologists today for expert care."
            />
          </Helmet>
          <section
            className="internalpage dermatologyresponsive style-two darmanorepeat"
            style={{ backgroundImage: "url(" + aboutfair + ")" }}
          >
            <div className="auto-container">
              <div className="content-box" onClick={this.openModal}>
                <img
                  srcset={require("../../assets/images/shape/dermato.webp")}
                  alt="dermato"
                  className="hidemobile"
                  rel="preload"
                />
              </div>
            </div>
            <img
              srcset={require("../../assets/images/shape/dera.webp")}
              alt="dermato"
              className="d-lg-none"
              rel="preload"
            />
          </section>

          <section className="contact-section">
            <div className="auto-container">
              <div className="row reverseFlex">
                <div className="col-lg-6">
                  <div className="sec-title squrebox">
                    <p className="font22">
                      A good physical appearance is a reflection of youth and
                      confidence. Unfortunately, as we age, our physical
                      appearance deteriorates. This could be in the form of
                      pimples, hair loss, stretch marks, unintended weight gain
                      around the abdominal area, and scarring. Therefore, it’s
                      important to take care of the physical features of our
                      body so that we always remain confident about how we look.
                    </p>
                  </div>
                  <div className="btn-box text-center btn5">
                    <ContactForm buttonText="BOOK NOW" popupPosition="right" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <img
                    srcset={require("../../assets/images/shape/darma.webp")}
                    alt="darma"
                    className="widthimg imgFloatRight marginTop40reverse"
                    rel="preload"
                  />
                </div>
              </div>
            </div>
          </section>

          <section>
            <img
              srcset={require("../../assets/images/shape/dermatology.webp")}
              alt="derma"
              className="labwidth"
              rel="preload"
            />
            <div className="col-lg-12">
              <div className="sec-title cont-box martobutton">
                <p className="font22">
                  Our expert dermatologists at Bridge Health know how to give
                  your physical appearance a boost. They will give you expert
                  advice on skin and haircare routines, diet plans, and
                  treatments so that you always look your best!
                </p>
              </div>
            </div>
          </section>
          <section>
            <div className="container marginTop80">
              <div className="row">
                <div className="col-sm-4">
                  <img
                    srcset={require("../../assets/images/gallery/dermatology1.webp")}
                    alt="derma"
                    className="avtar"
                    rel="preload"
                  />
                  <p className="treat">Non-surgical fat removal</p>
                </div>

                <div className="col-sm-4">
                  <img
                    srcset={require("../../assets/images/gallery/dermatology2.webp")}
                    alt="derma"
                    className="avtar"
                    rel="preload"
                  />
                  <p className="treat">Laser Hair Removal</p>
                </div>

                <div className="col-sm-4">
                  <img
                    srcset={require("../../assets/images/gallery/dermatology3.webp")}
                    alt="derma"
                    className="avtar"
                    rel="preload"
                  />
                  <p className="treat">
                    Dandruff control and gray<br></br> hair treatment
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="contact-section dermaback">
            <div className="auto-container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <h2 className="header1">
                    <span className="headeingcolorblack"> The</span>
                    <span className="headeingcolorblue"> Bridge Health</span>
                    <span className="headeingcolorblack"> Advantage </span>
                  </h2>
                  <ul className="listicon">
                    <li>Hair transplant</li>
                    <li>Skincare routine assistance</li>
                    <li>Pigmentation treatment</li>
                    <li>Face resurfacing</li>
                    <li>Stretch mark removal</li>
                    <li>
                      Assistance in developing diet plans to maintain healthy
                      skin
                    </li>
                    <li>Consultations with expert dermatologists</li>
                  </ul>
                </div>

                <div className="col-lg-6">
                  <img
                    srcset={require("../../assets/images/shape/fair4.webp")}
                    alt="derma"
                    className="widthimg "
                    rel="preload"
                  />
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="auto-container">
              <div className="sec-title text-center">
                <h2 className="header1">
                  <span className="headeingcolorblue"> Radiant Skin? </span>
                  <span className="headeingcolorblack">
                    {" "}
                    We know where to begin!{" "}
                  </span>
                </h2>
              </div>
              <div className="btn-box text-center btn5">
                <ContactForm buttonText="BOOK NOW" popupPosition="right" />
              </div>
            </div>
          </section>
          <Modal
            show={this.state.isOpen}
            onHide={this.closeModal}
            className="popupform"
          >
            <Modal.Header closeButton>
              <div className="form-body">
                <div className="row">
                  <div className="form-holder">
                    <div className="form-content">
                      <div className="form-items">
                        <form className="requires-validation" method="post">
                          <div className="col-md-12 mb-2">
                            <input
                              className="form-control widthinput"
                              type="text"
                              name="username"
                              placeholder="Name*"
                            ></input>
                          </div>
                          <div className="col-md-12 mb-2">
                            <input
                              className="form-control widthinput"
                              type="email"
                              name="email"
                              placeholder="Email"
                            ></input>
                          </div>
                          <div className="col-md-12 mb-2">
                            <input
                              className="form-control widthinput"
                              type="text"
                              name="phone"
                              placeholder="Phone*"
                            ></input>
                          </div>
                          <div className="col-md-12 mb-2">
                            <textarea
                              name="message"
                              placeholder="Message here.."
                              className="widthinput textareaheight"
                            ></textarea>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              required
                            ></input>
                            <label className="form-check-label">
                              I agree that Bridge Health may contact me at the
                              email address or phone number above.
                            </label>
                          </div>
                          <div className="form-button mt-3 text-center">
                            <input
                              type="submit"
                              name="submit"
                              value="Submit"
                              className="submitbutton"
                            ></input>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Header>
          </Modal>
        </>
        <Footer />
      </>
    );
  }
}

export default dermatology;
