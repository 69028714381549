import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

class InfluenzaTabMob extends Component {
  render() {
    return (
      <>
        {/* <!-- Faq Section -->  */}
        <section className="faq-section">
          <div className="auto-container">
            <div className="row">
              <h2 className="fontSize50 shi1-mob1">
                <span className="headeingcolorblack">Risk factors </span>
                <span className="headeingcolorblue"> of Influenza</span>
              </h2>
              <Accordion preExpanded={[0]} allowZeroExpanded>
                <AccordionItem allowZeroExpanded={true}>
                  <AccordionItemHeading>
                    <AccordionItemButton>Pnuemonia</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        Influenza can weaken the respiratory system, making it
                        easier for bacteria to cause a secondary infection in
                        the lungs which leads to pneumonia. This is especially
                        serious in older adults and people with weakened immune
                        systems.
                      </p>

                      <img
                        srcset={require("../../assets/images/shape/Pnuemonia.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Bronchitis</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        Inflammation of the bronchial tubes, often caused by viral
                        infections like the flu, can lead to persistent
                        coughing and difficulty breathing.
                      </p>
                      <img
                        srcset={require("../../assets/images/shape/Bronchitis.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Sinus Infections</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        Influenza can cause inflammation in the sinuses,
                        leading to sinusitis. This leads to facial pain due to
                        pressure on the sinus areas (especially the head and the eyes),
                        nasal congestion, and a thick nasal discharge.
                      </p>
                      <img
                        srcset={require("../../assets/images/shape/Sinus.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Ear Infections</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        Particularly common in young children, the flu can lead to ear infections (otitis media) due
                        to the close connection between the respiratory and ear passages.
                      </p>
                      <img
                        srcset={require("../../assets/images/shape/Ear infection.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Exacerbation of Chronic Health Conditions
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        Influenza can worsen existing chronic conditions such as asthma,
                        diabetes, or heart disease, increasing the risk of complications and hospitalization.
                      </p>
                      <img
                        srcset={require("../../assets/images/shape/Exacerbation.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Myocarditis and Pericarditis
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        These are inflammations of the heart muscle or the lining around the heart,
                        which can occur as rare but severe complications of influenza, particularly in younger.
                      </p>
                      <img
                        srcset={require("../../assets/images/shape/Myocarditis.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"

                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default InfluenzaTabMob;
