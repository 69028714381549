import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import FormButton from "../element/component/FormButton";

class doctor_consults extends Component {
  componentDidMount() {
    document.title = "Doctor Consults | Bridge Health";
  }

  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Best Doctor Consultation in Bangalore| Book Appointments today -
            Bridge Health
          </title>
          <meta
            name="description"
            content="Book doctor consultation with Bridge health's experienced & skilled medical professionals. Receive Virtual prescription & personalized care for your health concerns & conditions.
"
          />
        </Helmet>
        <section className="team-section padding doctoeback image-mobile-terms">
          {/* About Section Two */}
          <section className="container-fluid">
            <div className="row align-items-center auto-container reverseFlex">
              <div className="col-lg-6">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50">
                      <span className="headeingcolorblack">
                        Your doctor is just
                      </span>{" "}
                      <br></br>{" "}
                      <span className="headeingcolorblue">
                        a phone call away{" "}
                      </span>
                    </h2>
                    <h3 className="textleft marginTop40">
                      Schedule a consultation with <br></br> one of our expert
                      doctors
                    </h3>
                  </div>
                  <FormButton />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="imgright">
                  <img
                    srcset={require("../../assets/images/shape/doct1.webp")}
                    alt=""
                    className="imgwidth"
                    rel="preload"
                  />
                </div>
              </div>
            </div>
          </section>
        </section>

        <section>
          <img
            srcset={require("../../assets/images/shape/doct2.webp")}
            alt=""
            className="labwidth"
            rel="preload"
          />
          <div className="col-lg-12">
            <div className="sec-title cont-box martobutton">
              <p className="font22">
                All of us get sick at some point in life. And whenever we get
                sick, we always go to the doctor to find out the reason and get
                the appropriate medicines so that we can better quickly. In the
                past, doctor consultations had to be done in the traditional
                way. You book an appointment, visit the doctor’s office, and get
                a prescription.
              </p>
            </div>
            <FormButton />
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/doct3.webp")}
                  alt=""
                  className="imgwidth"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div className="sec-title squrebox">
                  <p className="font22">
                    The expert doctors at Bridge Health have years of experience
                    as practicing professionals. You can directly book an
                    appointment with them and avail of virtual consultations
                    with them wherever you are. This simplifies the process and
                    eliminates the long queue times at the doctor’s clinic.
                  </p>
                </div>
                <FormButton />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container marginTop80">
            <h2 className="header1 textaligncenter">
              <span className="headeingcolorblack"> The</span>
              <span className="headeingcolorblue"> Bridge Health</span>
              <span className="headeingcolorblack"> Advantage </span>
            </h2>
            <div className="row">
              <div className="col-sm-4">
                <img
                  srcset={require("../../assets/images/gallery/fair1.webp")}
                  alt=""
                  className="avtar"
                  rel="preload"
                />
                <p className="treat">
                  Book your consultation<br></br> easily and quickly
                </p>
              </div>
              <div className="col-sm-4">
                <img
                  srcset={require("../../assets/images/gallery/fair2.webp")}
                  alt=""
                  className="avtar"
                  rel="preload"
                />

                <p className="treat">
                  Expert doctors with years <br></br>of experience
                </p>
              </div>

              <div className="col-sm-4">
                <img
                  srcset={require("../../assets/images/gallery/fair3.webp")}
                  alt=""
                  className="avtar"
                  rel="preload"
                />

                <p className="treat">Virtual prescription generation</p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container marginTop80">
            <div className="sec-title text-center">
              <h2 className="header1">
                <span className="headeingcolorblack"> It’s a </span>
                <span className="headeingcolorblue"> Doctor on call. </span>
                <span className="headeingcolorblack"> Literally! </span>
              </h2>
            </div>
            <div className="d-flex justify-content-center pb-4">
              <FormButton ButtonName={'CONSULT / BOOK'} />
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default doctor_consults;
