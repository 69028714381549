import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import FormButton from "../element/component/FormButton";

class comprehensiveHealth extends Component {
  componentDidMount() {
    document.title = "Comprehensive Health | Bridge Health";
  }
  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Best Comprehensive health check ups in bangalore | Bridge Health
          </title>
          <meta
            name="Comprehensive Health"
            content="Bridge health offers Comprehensive health checks in Bangalore with all the test under one roof. Our clinical experts have developed a unique health assessment tool which helps determine the right diagnostic tests for you based on your current health status, family history.
          "
          />
        </Helmet>
        <section className="team-section padding image-mobile-terms">
          <section className="container-fluid">
            <div className="row align-items-center auto-container reverseFlex">
              <div className="col-lg-6">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50">
                      <span className="headeingcolorblack">
                        {" "}
                        Don’t compromise{" "}
                      </span>
                      <br></br>
                      <span className="headeingcolorblue">
                        {" "}
                        on your health{" "}
                      </span>
                    </h2>
                    <h3 className="textleft marginTop40">
                      Take a comprehensive <br></br> health check today!
                    </h3>
                  </div>
                  <FormButton/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="imgright">
                  <img
                    srcSet={require("../../assets/images/shape/health1.webp")}
                    alt="health"
                    className="imgwidth"
                    rel="preload"
                  />
                </div>
              </div>
            </div>
          </section>
        </section>
        <section className="contact-section">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcSet={require("../../assets/images/shape/health2.webp")}
                  alt="health"
                  className="widthimg imgFloatRight"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div className="sec-title squrebox">
                  <p className="font22">
                    Health is defined as a state of complete physical, mental,
                    and social well-being. If even one aspect isn’t satisfied,
                    it means that you are not healthy. Most people today have
                    some sort of vitamin deficiency or suffer from mental health
                    issues like anxiety or depression. Technology in the form of
                    AI and analytics have been implemented into the healthcare
                    industry for seamless monitoring but are not being utilized
                    effectively by healthcare providers. Therefore, there is a
                    need to evaluate your entire health profile at all times
                    with the highest possible accuracy.
                  </p>
                </div>
                <FormButton/>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="auto-container">
            <div className="row align-items-center reverseFlex">
              <div className="col-lg-6">
                <div className="sec-title squrebox">
                  <p className="font22">
                    The comprehensive health checks at Bridge Health evaluate
                    all aspects of your health. Our in-house clinical experts
                    have developed a unique assessment tool - BH Smart Guide,
                    which is a smarter way to understand the right diagnostic
                    tests for you based on your current health status, family
                    history, and lifestyle choices.
                  </p>
                </div>
                <FormButton/>
              </div>
              <div className="col-lg-6">
                <img
                  srcSet={require("../../assets/images/shape/Healthcheck.webp")}
                  alt="health"
                  className="widthimg imgFloatRight"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="auto-container">
            <h2 className="header1 textaligncenter comp-margin">
              <span className="headeingcolorblack"> The</span>
              <span className="headeingcolorblue"> Bridge Health</span>
              <span className="headeingcolorblack"> Advantage </span>
            </h2>
            <div className="row">
              <div className="col-sm-4 healts">
                <img
                  srcSet={require("../../assets/images/gallery/health4.webp")}
                  alt="health"
                  className="avtar "
                  rel="preload"
                />
                <div className="treat1">
                  <img
                    srcSet={require("../../assets/images/Healthquiz.svg")}
                    alt="health"
                    className="avtar "
                    rel="preload"
                  />
                  <p>Health Quiz</p>
                </div>
              </div>
              <div className="col-sm-4 healts">
                <img
                  srcSet={require("../../assets/images/gallery/health4.webp")}
                  alt="health"
                  className="avtar "
                  rel="preload"
                />
                <div className="treat1">
                  <img
                    srcSet={require("../../assets/images/RegularCheckup.svg")}
                    alt="health"
                    className="avtar "
                    rel="preload"
                  />
                  <p>
                    Regular check-ups <br></br>with experts
                  </p>
                </div>
              </div>
              <div className="col-sm-4 healts">
                <img
                  srcSet={require("../../assets/images/gallery/health4.webp")}
                  alt="health"
                  className="avtar "
                  rel="preload"
                />
                <div className="treat1">
                  <img
                    srcSet={require("../../assets/images/weght.svg")}
                    alt="health"
                    className="avtar "
                    rel="preload"
                  />
                  <br></br>
                  <p>BMI Tracker</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 healts">
                <img
                  srcSet={require("../../assets/images/gallery/health4.webp")}
                  alt="health"
                  className="avtar "
                  rel="preload"
                />
                <div className="treat1">
                  <img
                    srcSet={require("../../assets/images/Thermometer.svg")}
                    alt="health"
                    className="avtar "
                    rel="preload"
                  />
                  <p>Thermographic HRA</p>
                </div>
              </div>
              <div className="col-sm-4 healts">
                <img
                  srcSet={require("../../assets/images/gallery/health4.webp")}
                  alt="health"
                  className="avtar "
                  rel="preload"
                />
                <div className="treat1">
                  <img
                    srcSet={require("../../assets/images/Musclemass.svg")}
                    alt="health"
                    className="avtar "
                    rel="preload"
                  />
                  <p>Muscle Mass Measurement</p>
                </div>
              </div>
              <div className="col-sm-4 healts">
                <img
                  srcSet={require("../../assets/images/gallery/health4.webp")}
                  alt="health"
                  className="avtar "
                  rel="preload"
                />
                <div className="treat1">
                  <img
                    srcSet={require("../../assets/images/Bodymeasurment.svg")}
                    alt="health"
                    className="avtar "
                    rel="preload"
                  />
                  <p>Body Fat Measurement</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-section">
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-6">
                <ul className="listicon">
                  <li>Automated blood pressure measurement</li>

                  <li>Lung function tests</li>

                  <li>Wearable devices or sensors</li>

                  <li>Imaging tests like CT scans and MRIs</li>

                  <li>Virtual consultations using telemedicine</li>

                  <li>Advanced infectious disease testing</li>

                  <li>
                    Blood tests for cholesterol, blood sugar, and other health
                    indicators
                  </li>
                </ul>
              </div>

              <div className="col-lg-6">
                <ul className="listicon">
                  <li>Cardiac tests like ECGs</li>

                  <li>Cognitive tests</li>

                  <li>Pharmacogenetic testing</li>

                  <li>AI-powered algorithms to analyze health data</li>

                  <li>Mental health screening using AI-powered tools</li>

                  <li>Point-of-care testing using handheld devices</li>

                  <li>
                    Body composition analysis using bioelectrical impedance or
                    similar technology
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2 className="header1">
                <span className="headeingcolorblack"> The real </span>
                <span className="headeingcolorblue"> wealth is Health! </span>
              </h2>
            </div>
            <div className="d-flex justify-content-center pb-4">
                <FormButton ButtonName={'CONSULT / BOOK'} />
              </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default comprehensiveHealth;
