import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import FormButton from "../element/component/FormButton";

const aboutbg = require("./../../assets/images/shape/YogaAssessmentandCoaching.webp");

class yoga_Assessment extends Component {
  componentDidMount() {
    document.title = "Yoga Assessment | Bridge Health";
  }

  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Best Yoga Centre in Bangalore| Certified Yoga Expert - Bridge Health
          </title>
          <meta
            name="description"
            content="Best yoga therapy at Bridge Health. Experience tailored yoga programs with award-winning yoga trainers in Bangalore. Book your sessions now."
          />
        </Helmet>
        <section
          className="yogaPage internalpageresponsive style-two"
          style={{ backgroundImage: "url(" + aboutbg + ")" }}
        >
          <div className="auto-container">
            <div className="content-box">
              <div className=" text-center textleft">
                <h2 className="textleft fontSize">
                  <span className="headeingcolorblack"> Unite your </span>{" "}
                  <span className="headeingcolorblue">Mind, Body & Soul</span>
                </h2>
                <h3 className="textleft marginTop40">
                  Connect with our yoga therapists
                </h3>
              </div>
              <FormButton/>
            </div>
          </div>
        </section>
        {/*
    <b2c_page /> */}

        <section className="contact-section">
          <div className="auto-container ">
            <div className="row">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/y1.webp")}
                  alt=""
                  className="marginTop40reverse"
                  rel="preload"
                />
              </div>
              <div className="col-lg-6">
                <div className="contact-form-area">
                  <div className="sec-title squrebox">
                    <p className="font22">
                      Practiced for thousands of years, yoga has several
                      benefits on your overall health. Through a series of
                      breathing exercises and poses, yoga improves blood flow
                      and helps you focus your mind. This helps you obtain inner
                      peace and happiness which leads to better health.
                    </p>
                    <p className="font22">
                      The yoga therapists at Bridge Health are trained in yogic
                      counseling, lifestyle and nutrition. They will assist you
                      in developing a yoga regimen which helps you focus on
                      improving the functionality of your mind, body, and
                      breath.
                    </p>
                  </div>
                  <FormButton/>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="team-section container-fulid whatbe">
          {/* About Section Two */}
          <section>
            <div className="auto-container">
              <div className="row align-items-center reverseFlex">
                <div className="col-lg-6">
                  <div className="content-box yogaltst">
                    <h2>
                      <span className="headeingcolorblack"> What We </span>
                      <span className="headeingcolorblue"> Offer</span>
                    </h2>
                    <ul className="listicon">
                      <li>Yoga for general Wellbeing</li>
                      <li>Yoga for Mental Illness</li>
                      <li>Yoga for Pregnancy</li>
                      <li>Therapeutic Yoga</li>
                      <li>Yoga for Cardiovascular Disorders</li>
                      <li>Yoga for Respiratory Disorders</li>
                      <li>Yoga for Gastrointestinal Disorders</li>
                      <li>Yoga for Joints and Muscles</li>
                      <li>Yoga for Urogenital Disorders</li>
                      <li>Yoga for Mental Disorders</li>
                    </ul>
                    <FormButton/>
                  </div>
                </div>

                <div className="col-lg-6">
                  <img
                    srcset={require("../../assets/images/shape/Yoga.webp")}
                    alt=""
                    className="width84"
                    rel="preload"
                  />
                </div>
              </div>
            </div>
          </section>
        </section>
        {/*
    <!-- Contact Section --> */}
        <section className="contact-section">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/y4.webp")}
                  alt=""
                  rel="preload"
                />
              </div>

              <div className="col-lg-6">
                <div className="contact-form-area">
                  <h2 className="header1">
                    <span className="headeingcolorblack"> The</span>
                    <span className="headeingcolorblue"> Bridge Health</span>
                    <span className="headeingcolorblack"> Advantage </span>
                  </h2>
                  <ul className="listicon">
                    <li>Experienced yoga therapists</li>
                    <li>Customized plans and routines</li>
                    <li>Regular progress updates</li>
                    <li>General wellbeing and mental fitness programs</li>
                    <li>Therapeutic yoga for effective disease management</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2 className="header1">
                <span className="headeingcolorblack">
                  {" "}
                  Live a more spiritually{" "}
                </span>
                <span className="headeingcolorblue"> enriching</span>
                <span className="headeingcolorblack"> life!</span>
              </h2>
            </div>
            <div className="d-flex justify-content-center pb-4">
                <FormButton ButtonName={'CONSULT / BOOK'} />
              </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default yoga_Assessment;
