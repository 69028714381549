import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Testimonial1 from "../element/testimonial1";
import Testimonial2 from "../element/testimonial2";
import "reactjs-popup/dist/index.css";
import { Modal } from "react-bootstrap";
import CaseStudy from "../element/case-study";
import ContactForm from "../element/contact-form";
import { Helmet } from "react-helmet";
import Banner_slider from "../element/component/Banner_slider";
import ReachToUsForm from "../element/ReachToUsForm";

class Index extends Component {
  state = {
    isOpen: false,
  };
  state1 = {
    isOpen1: false,
  };
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      phone: "",
      message: "",
      isSubmitDisabled: true,
      errors: {},
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.validateForm();
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  validateForm = () => {
    const { username, email, phone } = this.state;
    const errors = {};

    // Username validation
    if (username.trim() === "") {
      errors.username = "Please enter your name*";
    }

    // Email validation
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = "Please enter a valid email address*";
    }

    // Phone validation
    if (!/^\d{10}$/.test(phone)) {
      errors.phone = "Please enter a 10-digit phone number*";
    }

    this.setState({
      errors,
      isSubmitDisabled: Object.keys(errors).length > 0,
    });
  };

  saveData = async (e) => {
    e.preventDefault();

    const { email, username, phone, message } = this.state;

    const res = await fetch("http://localhost:8000/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        username,
        phone,
        message,
      }),
    });

    const data = await res.json();

    if (data.status === 401 || !data) {
      console.log("error");
    } else {
      this.setState({
        show: true,
        email: "",
        username: "",
        phone: "",
        message: "",
      });
      console.log("Data saved");
    }
  };

  sendEmail = async (e) => {
    const { email, username, phone, message } = this.state;

    const res = await fetch("https://mailbridge.bridgehealth.in/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        username,
        phone,
        message,
      }),
    });

    const data = await res.json();

    if (data.status === 401 || !data) {
      console.log("error");
    } else {
      this.setState({
        show: true,
        email: "",
        username: "",
        phone: "",
        message: "",
      });
      console.log("Email sent");
    }
  };

  state = {
    images: [
      "/images/new_banners/b1.jpg",
      "/images/new_banners/b2.jpg",
      "/images/new_banners/b3.jpg",
      "/images/new_banners/b4.jpg",
      "/images/new_banners/b5.jpg",
      "/images/new_banners/b6.jpg",
    ],
  };

  render() {
    const { images } = this.state;


    return (
      <>
        <Header />
        <Helmet>
          <title>
            Personalised & Holistic Preventive Health-Bridge Health{" "}
          </title>

          <meta
            name="description"
            content="Best preventive healthcare services in Bangalore. Complete range of healthcare services under one roof. Achieve your health goals with Bridge Health's personalized & holistic preventive health plans. Customized plans & innovative solutions for a healthier lifestyle."
          />
        </Helmet>

        {/* <!-- Mobile --> */}

        {/* <div className="container bg-overlay desktophide image-mobile-terms">
          <video
            muted="muted"
            loop="loop"
            className="videowidthMobile"
            kind="Bridghelth-video"
            controls="controls"
            preload="none"
            playsInline="playsInline"
            autoPlay="autoPlay"
          >
            <source
              src={require("../../assets/images/mobilevideo1.mp4")}
              type="video/mp4"
            />
          </video>
          <div className="video-background-content">
            <div className="text-center">
              <div className="text-white paddingtext">
                <h5 className="display-4">
                  <span className="display-3">Let’s help you </span>
                  <br></br>#BridgeTheGap
                </h5>
                <div className="lead mb-0">
                  <div>Personalised and holistic preventive</div>
                  <div>health plans for you and your loved ones</div>
                </div>
                <div className=" btnleft">
                  <ContactForm
                    buttonText="Request a Call Back"
                    popupPosition="right"
                    className="btn-style-one"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <!-- Desktop --> */}
        <section className="banner-slder-t1">
          <Banner_slider />

        </section>

        {/* <div className="video-background-holder mobilehide ">
          <div className="video-background-overlay"></div>
          <video
            muted="muted"
            loop="loop"
            className="videowidthMobile"
            controls="controls"
            preload="none"
            playsInline
            autoPlay
            kind="Bridge Health"
          >
            <source
              src={require("../../assets/images/backgroundvideo.mp4")}
              type="video/mp4"
            />
          </video>
          <div className="video-background-content h-100 auto-container">
            <div className="d-flex h-100 text-center align-items-center">
              <div className="w-100 text-white floatleft">
                <h5 className="display-4">
                  <span className="display-3">Let’s help you </span>
                  #BridgeTheGap
                </h5>
                <p className="lead mb-0">
                  Personalised and holistic preventive health plans for you and
                  your loved ones
                </p>
                <div className=" btnleft">
                  <ContactForm
                    buttonText="Request a Call Back"
                    popupPosition="right"
                    className="btn-style-one"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <section>
          <div className="image-seven imgpad">
            <img
              srcSet={require("../../assets/images/shape/scan.webp")}
              alt=""
              className="w-100 h-100"
            />
          </div>
        </section>

  

        {/* <!-- About --> */}

        <div className="team-section">
          <div className="auto-container">
            <div className="sec-title text-center marginbutton ">
              <h2 className="mobile-annual">
                {" "}
                <span className=" headeingcolorblue"> Annual Preventive </span>
                <span className="headeingcolorblack">Health Plans</span>
              </h2>
              <h5 className="heading-member mb-5">
                Membership-based integrated programs covering various aspects
              </h5>
            </div>
          </div>
          {/* About Section Two */}
          <section>
            <div className="auto-container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="content-box">
                    <div className="sec-title">
                      <div className="text textpadding1">
                        <div
                          className="d-flex  desktop-bridg"
                          style={{ gap: "10px" }}
                        >
                          <div className="headeingcolorblue">#BridgeTheGap</div>
                          <div>with our Annual Health</div>
                        </div>
                        <div>Plans that are membership-based</div>
                        <div>integrated programs covering everything</div>
                        <div>from clinical assessments to</div>
                        <div>consultations, lab tests to nutrition</div>
                        <div>sessions, lifestyle management to</div>
                        <div>emotional wellness and much more.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 clmbackground">
                  <div className="box1 d-flex">
                    <div>
                      <img
                        srcSet={require("../../assets/images/shape/thumb.webp")}
                        alt=""
                        rel="preload"
                      />
                    </div>
                    <div>
                      <a
                        href="/personal-membership-program"
                        title="Personal Membership Program"
                      >
                        <div className="textpara">
                          Personal Membership <br></br> Program
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="box2 d-flex">
                    <div>
                      <a href="/corporate-membership-program">
                        <span className="textpara">
                          Corporate Membership <br></br> Program
                        </span>
                      </a>
                    </div>
                    <div>
                      <img
                        srcSet={require("../../assets/images/shape/thumb1.webp")}
                        alt=""
                        className="flotrightimg"
                        rel="preload"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>


        <section className="team-section">
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2 className="header1 predict">
                <span className="headeingcolorblack">
                  <strong>Predict. </strong>
                </span>
                <span className="headeingcolorblue">
                  <strong>Prevent.</strong>
                </span>
                <span className="headeingcolorblack">
                  {" "}
                  <strong>Progress.</strong>
                </span>
              </h2>
            </div>
          </div>
          {/* About Section Two */}
          <section>
            <div className="auto-container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className=" image-two imgpad">
                    <img
                      srcSet={require("../../assets/images/shape/ipad.webp")}
                      alt=""
                      rel="preload"
                      className="w-100 h-100 mobile-padding"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="content-box">
                    <div className="sec-title">
                      <div className="text1 textpadding textwidth">
                        <p>
                          {" "}
                          Predict the onset of
                          <span className="headeingcolorblack">
                            {" "}
                            hidden illnesses
                          </span>
                          .<br></br>
                          Prevent them by undergoing{" "}
                          <span className="headeingcolorblack">tests. </span>
                          <br></br>
                          Progress towards{" "}
                          <span className="headeingcolorblack">
                            good health.
                          </span>
                        </p>
                      </div>
                    </div>
                    <ul className="listicon10">
                      <li>
                        <img
                          className="listicon1"
                          srcSet={require("../../assets/images/shape/favicon-logo1.webp")}
                          alt=""
                          rel="preload"
                        />
                        Get a detailed assessment report
                      </li>
                      <li>
                        <img
                          className="listicon1"
                          srcSet={require("../../assets/images/shape/favicon-logo1.webp")}
                          alt=""
                          rel="preload"
                        />
                        Health score built on 7 parameters
                      </li>
                      <li>
                        <img
                          className="listicon1"
                          srcSet={require("../../assets/images/shape/favicon-logo1.webp")}
                          alt=""
                          rel="preload"
                        />
                        Pre and Post score comparisons
                      </li>
                    </ul>

                    <div className="btn-box text-center btn5 btn6">
                      <a href="/hra" title="Know More">
                        <button className="btn-style-one">KNOW MORE</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className="team-section expertiseback">
          <div className="auto-container">
            <div className="sec-title text-center d-none d-sm-block">
              <h2>
                {" "}
                <span className="headeingcolorblack "> Explore Our </span>{" "}
                <span className="headeingcolorblue"> Expertise</span>
              </h2>
              <h5 className="heading5">
                From mental health and wellness to nutrition management, our
                services help{" "}
                <span className="headeingcolorblue"> #BridgeTheGap</span>{" "}
                between you and healthy living
              </h5>
            </div>
          </div>
          {/* About Section Two */}
          <section>
            <div className="auto-container">
              <div className="row marginbutton30">
                <div className="col-lg-3">
                  <div onClick={this.openModal}>
                    <div className="expanded-text grow bozhover">
                      <div>
                        <img
                          srcSet={require("../../assets/images/shape/doctor.webp")}
                          src={require("../../assets/images/shape/doctor.webp")}
                          alt=""
                          rel="preload"
                        />
                      </div>

                      <div className="short-name expertise">
                        <span>Annual Preventive</span>
                        <br></br> <span>Health Plans</span>
                      </div>
                      <div className="longer-name">
                        <h1 className="boxtitle">
                          Annual Preventive<br></br> Health Plans
                        </h1>
                        No matter what you need, there’s always a plan for you.
                        Choose from a selection of Annual Preventive Health
                        plans and sign up for better health.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div>
                    <CaseStudy />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="expanded-text grow bozhover">
                    <div>
                      <a
                        href="/comprehensive-health-checks"
                        title="Comprehensive Health Checks"
                      >
                        <img
                          srcSet={require("../../assets/images/shape/s2.webp")}
                          src={require("../../assets/images/shape/s2.webp")}
                          alt=""
                          rel="preload"
                          className="w-100 h-100"
                        />
                      </a>
                    </div>
                    <div className="short-name expertise">
                      Comprehensive<br></br> Health Checks
                    </div>
                    <div className="longer-name">
                      <h1 className="boxtitle">
                        Comprehensive <br></br>Health Checks
                      </h1>
                      Prevention is better than cure. With advanced technology,
                      we are able to understand your entire health profile and
                      help you prevent illnesses. Book your Comprehensive Health
                      Check today.
                    </div>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3">
                  <div className="expanded-text grow bozhover">
                    <div>
                      <a href="/doctor-consults" title="Doctor Consults">
                        <img
                          srcSet={require("../../assets/images/shape/k3.webp")}
                          src={require("../../assets/images/shape/k3.webp")}
                          alt=""
                          className="w-100 h-100"
                          rel="preload"
                        />
                      </a>
                    </div>
                    <div className="short-name expertise">
                      Doctor <br></br>Consults
                    </div>
                    <div className="longer-name">
                      <h1 className="boxtitle">
                        Doctor <br></br>Consults
                      </h1>
                      Getting an appointment with your doctor at your nearest
                      clinic can be burdensome. We simplify the process and
                      allow you to consult with our experts whenever, wherever.
                    </div>
                  </div>
                </div>
              </div>
              <div className="row marginbutton30">
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3">
                  <div className="expanded-text grow bozhover">
                    <div>
                      <a
                        href="/psychological-health"
                        title="Psychological Health"
                      >
                        <img
                          srcSet={require("../../assets/images/shape/k2.webp")}
                          src={require("../../assets/images/shape/k2.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                    </div>
                    <div className="short-name expertise">
                      Psychologist Consults
                    </div>
                    <div className="longer-name">
                      <h1 className="boxtitle">Psychologist Consults</h1>
                      Mental health is extremely important in the current times
                      due to increased work and social pressures. Talk to our
                      expert clinical psychologists.
                    </div>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3">
                  <div className="expanded-text grow bozhover">
                    <div>
                      <a href="/dental-consults" title="Dental Consults">
                        <img
                          srcSet={require("../../assets/images/shape/s5.webp")}
                          src={require("../../assets/images/shape/s5.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                    </div>
                    <div className="short-name expertise">Dental Consults</div>
                    <div className="longer-name">
                      <h1 className="boxtitle">Dental Consults</h1>
                      Most people neglect their oral health which can be
                      disastrous for their overall health. Book your dental
                      consultation with us today and smile with confidence.
                    </div>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3">
                  <div className="expanded-text grow bozhover">
                    <div>
                      <a href="/dermatology" title="Dermatology">
                        <img
                          srcSet={require("../../assets/images/shape/s7.webp")}
                          src={require("../../assets/images/shape/s7.webp")}
                          alt=""
                          rel="preload"
                          className="w-100 h-100"
                        />
                      </a>
                    </div>
                    <div className="short-name expertise">Dermatology</div>
                    <div className="longer-name">
                      <h1 className="boxtitle">Dermatology</h1>
                      Contrary to popular belief, don’t just cater to the needs
                      of fashion models. Our hair, skin, and nails deserve to be
                      treated well. Talk to our dermatologists today.
                    </div>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3">
                  <div className="expanded-text grow bozhover">
                    <div>
                      <a href="/ophthalmologist" title="Ophthalmologist">
                        <img
                          srcSet={require("../../assets/images/shape/s8.webp")}
                          src={require("../../assets/images/shape/s8.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                    </div>
                    <div className="short-name expertise">Ophthalmology</div>
                    <div className="longer-name">
                      <h1 className="boxtitle">Ophthalmology</h1>
                      Our eyesight is a precious gift which allows us to see the
                      beauty of the world. We need to take care of our eyes to
                      prevent any eye-related illnesses. Meet our
                      ophthalmologists today.
                    </div>
                  </div>
                </div>
              </div>
              <div className="row marginbutton30">
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3">
                  <div className="expanded-text grow bozhover">
                    <div>
                      <a href="/physio-therapy" title="Physio Therapy">
                        <img
                          srcSet={require("../../assets/images/shape/s9.webp")}
                          src={require("../../assets/images/shape/s9.webp")}
                          alt=""
                          rel="preload"
                        />
                      </a>
                    </div>
                    <div className="short-name expertise">Physiotherapy</div>
                    <div className="longer-name">
                      <h1 className="boxtitle">Physiotherapy</h1>
                      In life, we take our ability to move around freely for
                      granted. Due to injuries or certain illnesses, our
                      movement gets affected. Schedule a physiotherapy session
                      with us and move with ease.
                    </div>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3 ">
                  <div className="expanded-text grow bozhover">
                    <div>
                      <a href="/elderly-care" title="Elderly Care">
                        <img
                          srcSet={require("../../assets/images/shape/s10.webp")}
                          src={require("../../assets/images/shape/s10.webp")}
                          alt=""
                          rel="preload"
                          className="w-100 h-100"
                        />
                      </a>
                    </div>
                    <div className="short-name expertise">Elderly Care</div>
                    <div className="longer-name">
                      <h1 className="boxtitle">Elderly Care</h1>
                      Growing old is a natural part of our lives. As we age, our
                      physical abilities gradually get compromised and we need
                      assistance. Discover our elderly care services.
                    </div>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3">
                  <div className="expanded-text grow bozhover">
                    <div>
                      <a href="/lab-tests" title="Lab Teste">
                        <img
                          srcSet={require("../../assets/images/shape/s11.webp")}
                          src={require("../../assets/images/shape/s11.webp")}
                          alt=""
                          rel="preload"
                          className="w-100 h-100"
                        />
                      </a>
                    </div>
                    <div className="short-name expertise">Lab Tests</div>
                    <div className="longer-name">
                      <h1 className="boxtitle">Lab Tests</h1>
                      The key to an effective diagnosis is effective testing.
                      Advancements in lab testing have allowed us to accurately
                      determine illnesses and provide treatment plans. Discover
                      our state-of-the-art lab facilities.
                    </div>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3">
                  <div className="expanded-text grow bozhover">
                    <div>
                      <a href="/diet-and-nutrition" title="Diet And Nutrition">
                        <img
                          srcSet={require("../../assets/images/shape/s12.webp")}
                          src={require("../../assets/images/shape/s12.webp")}
                          alt=""
                          rel="preload"
                          className="w-100 h-100"
                        />
                      </a>
                    </div>
                    <div className="short-name expertise">
                      Diet and Nutrition
                    </div>
                    <div className="longer-name">
                      <h1 className="boxtitle">Diet and Nutrition</h1>A healthy
                      diet is the basis for good health. After all, you are what
                      you eat. Find out how our expert clinical nutritionists
                      help you eat healthy to stay healthy.
                    </div>
                  </div>
                </div>
              </div>
              <div className="row marginbutton30">
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3">
                  <div className="expanded-text grow bozhover">
                    <div className="text">
                      <a href="/yoga-assessment" title="Yoga Asseessment">
                        <img
                          srcSet={require("../../assets/images/shape/s13.webp")}
                          src={require("../../assets/images/shape/s13.webp")}
                          alt=""
                          rel="preload"
                          className="w-100 h-100"
                        />
                      </a>
                    </div>
                    <div className="short-name expertise">Yoga Assessment</div>
                    <div className="longer-name">
                      <h1 className="boxtitle">Yoga Assessment</h1>
                      <div>
                        Yoga has been a part of our culture for centuries. The
                        focus on harmony of mind, body, and spirit has been
                        scientifically proven to have positive effects on
                        health. Talk to our yoga therapists.
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3">
                  <div className="expanded-text grow bozhover">
                    <div>
                      <a href="/fitness-assessment" title="Fitness Assessment">
                        <img
                          srcSet={require("../../assets/images/shape/s14.webp")}
                          src={require("../../assets/images/shape/s14.webp")}
                          alt=""
                          rel="preload"
                          className="w-100 h-100"
                        />
                      </a>
                    </div>
                    <div className="short-name expertise">
                      Fitness Assessment
                    </div>
                    <div className="longer-name">
                      <h1 className="boxtitle">Fitness Assessment</h1>
                      For good health, exercise is important. We’ve taken this
                      to another level and have adopted a clinical fact-based
                      approach with our unique fitness program. Discover our
                      pro-fit program.
                    </div>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3">
                  <div className="expanded-text grow bozhover">
                    <div>
                      <a href="/pharmacy" title="pharmacy">
                        <img
                          srcSet={require("../../assets/images/shape/s15.webp")}
                          src={require("../../assets/images/shape/s15.webp")}
                          alt=""
                          rel="preload"
                          className="w-100 h-100"
                        />
                      </a>
                    </div>
                    <div className="short-name expertise">Pharmacy</div>
                    <div className="longer-name">
                      <h1 className="boxtitle">Pharmacy</h1>
                      It’s important to get the medicines you need at the right
                      time. At Bridge Health, we have a fully-stocked pharmacy
                      which is built to meet your needs. Discover our pharmacy
                      today.
                    </div>
                  </div>
                </div>
                {/* <!-- Team Block One --> */}
                <div className="col-lg-3">
                  <div className="expanded-text grow bozhover">
                    <div>
                      <a href="/nursing-at-home" title="Nursing At Home">
                        <img
                          srcSet={require("../../assets/images/shape/s16.webp")}
                          src={require("../../assets/images/shape/s16.webp")}
                          alt=""
                          rel="preload"
                          className="w-100 h-100"
                        />
                      </a>
                    </div>
                    <div className="short-name expertise">Nursing At Home</div>
                    <div className="longer-name">
                      <h1 className="boxtitle">Nursing At Home</h1>
                      With nursing services provided directly to you and your
                      loved ones at the comfort of your own home, get the timely
                      care and assistance you need at all times.
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mt-5 ">
                  <div className="bozhover grow">
                    <div>
                      <ContactForm
                        buttonText="KNOW MORE"
                        className="btn-style-one"
                        titleType={"image"}
                        imageURL={require("../../assets/images/Radiology.webp")}
                        rel="preload"
                      />
                    </div>
                    <div className="short-name expertise">Radiology</div>
                  </div>
                </div>
                <div className="col-lg-3 mt-5">
                  <div className="bozhover grow">
                    <div>
                      <ContactForm
                        buttonText="KNOW MORE"
                        className="btn-style-one "
                        titleType={"image"}
                        imageURL={require("../../assets/images/women.webp")}
                        rel="preload"
                      />
                    </div>
                    <div className="short-name expertise">Womens Health</div>
                  </div>
                </div>
                <div className="col-lg-3  mt-5">
                  <div className="bozhover grow">
                    <div>
                      <ContactForm
                        buttonText="KNOW MORE"
                        className="btn-style-one "
                        titleType={"image"}
                        imageURL={require("../../assets/images/ai.webp")}
                        rel="preload"
                      />
                    </div>
                    <div className="short-name expertise">
                      AI assisted smart screenings
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        {/* <!-- Testimonial Section --> */}
        <Testimonial2 />
        <Testimonial1 />
        <h6 className="leader-btn1">
          <a href="/team" title="SEE ALL" style={{ color: "black" }}>
            SEE ALL
          </a>
        </h6>

        {/* <!-- Contact Section --> */}
        <div className="contact-section">
          <section>
            <div className="auto-container">
              <div className="sec-title text-center">
                <h2 style={{ fontSize: 50 + "px" }}>
                  {" "}
                  <span className="headeingcolorblue">Reach </span>{" "}
                  <span className="headeingcolorblack">Out To Us</span>
                  <br></br>
                  <p style={{ fontSize: 22 + "px" }}>
                    There is a plan to fit the specific health needs of
                    everybody - your spouse, parents, kids & YOU! <br></br>{" "}
                    <span className="headeingcolorblue">
                      Talk to our health advisor today!!
                    </span>
                  </p>
                </h2>
              </div>
            </div>
          </section>
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      className="reachout w-100 h-100"
                      srcSet={require("../../assets/images/shape/contact.webp")}
                      alt=""
                      rel="preload"
                    />
                  </div>
                </div>
              </div>
              {/* <FormValidation /> */}
              <div className="col-lg-6">
                <ReachToUsForm />
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <a
              href="/personal-membership-program"
              title="Personal Membership Programme"
            >
              <span>
                <img
                  className="width50"
                  srcSet={require("../../assets/images/shape/celebration.webp")}
                  alt=""
                  rel="preload"
                />
                <p className="width50p">Personal Membership Programme</p>
              </span>
            </a>
            <a
              href="/corporate-membership-program"
              title="Corporate Membership Programme"
            >
              <span>
                <img
                  className="width50"
                  srcSet={require("../../assets/images/shape/four.webp")}
                  alt=""
                  rel="preload"
                />
                <p className="width50p">Corporate Membership Programme</p>
              </span>
            </a>
          </Modal.Header>
        </Modal>
        <Footer />
      </>
    );
  }
}
export default Index;
