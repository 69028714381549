import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Helmet } from "react-helmet";
const aboutbg = require("./../../assets/images/shape/comming.webp");
class deviceAndTechnology extends Component {
  render() {
    return (
      <>
        <Header />
        {/* <!-- Page Banner Section --> */}
        <Helmet>
          <title>Best Device and Technology in - Bridge Health</title>
          <meta
            name="Device and Technology"
            content="Book consultations today with the best dermatologists in Bangalore. Our hair, skin, and nails deserve to be treated well. Talk to our dermatologists today for expert care."
          />
        </Helmet>
        <section className="page-banner commimgsoon">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div className="bottom-rotten-curve alternate"></div>
        </section>
        <Footer />
      </>
    );
  }
}

export default deviceAndTechnology;
