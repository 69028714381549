import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../layout/footer';

class LeadClinical extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFullContent1: true,
            showFullContent2: true,


        };
    }

    toggleContent1 = () => {
        this.setState((prevState) => ({
            showFullContent1: !prevState.showFullContent1,
        }));
    };
    toggleContent2 = () => {
        this.setState((prevState) => ({
            showFullContent2: !prevState.showFullContent2,
        }));
    };
    render() {
        const { showFullContent1, showFullContent2 } = this.state;

        return (

            <div>
                <Helmet>
                    <title>Board of Directors: Inspiring Leaders at Bridge Health

                    </title>
                    <meta
                        name="description"
                        content="Meet the accomplished & experienced members of Bridge health's Board of Directors who provide strategic guidance and leadership to drive the success of our organization.          "
                    />
                </Helmet>
                <img rel="preload"
                    srcset={require('./../../assets/images/background/direct.webp')} alt="" className='imgwidth100 hidemobile' />


                <div className='d-lg-none'>
                    <section className="about-section-two marginTop0">
                        <div className="auto-container">
                            <div className="sec-title text-center">
                                <h2> <span className='headeingcolorblack'> BOARD OF</span> <span className='headeingcolorblue'>
                                    DIRECTORS</span></h2>
                            </div>

                        </div>
                        <div className='container-fulid teamback1'>
                            <div className="auto-container">
                                <div className="row align-items-center">

                                    <div className="col-lg-2">
                                        <div className="displayflex ">
                                            <span> <img rel="preload"
                                                srcset={require('../../assets/images/shape/team-mob1.webp')} alt=""
                                                className="teamimg zoom" /> </span>
                                            <span className='teamtext'><b>Kris Gopalakrishnan</b> <br></br> CHAIRMAN &
                                                CO-FOUNDER</span>
                                        </div>

                                    </div>
                                    <div className="col-lg-10">
                                        <div className="author-info">
                                            <div className="wrapper-box text">
                                                <div className="text">
                                                    <i>Senapathy “Kris” Gopalakrishnan served as the Vice Chairman of Infosys from
                                                        2011 to 2014
                                                        and the Chief Executive Officer and Managing Director of Infosys from 2007
                                                        to 2011. Kris is
                                                        one of the co-founders of Infosys. He also was selected to Thinkers 50, an
                                                        elite list of global
                                                        business thinkers, in 2009.
                                                    </i>
                                                    {showFullContent1 ? (
                                                        <h6 className="leader-btn">
                                                            <i onClick={this.toggleContent1}>SHOW MORE</i>
                                                        </h6>
                                                    ) : (
                                                        <div className="text">
                                                            <i>
                                                                He was elected president of India's apex industry chamber Confederation
                                                                of Indian Industry (CII) for 2013-14, and served as one of the co-chairs
                                                                of the World Economic Forum in Davos in January 2014.
                                                                He serves on the Board of Governors of Okinawa Institute for Science and
                                                                Technology (OIST) and is the Chairman, Board of Governors of IIIT,
                                                                Bangalore. He is the Chairman of the Vision Group on Information
                                                                Technology of Karnataka Government and the Chairman of CII Start up
                                                                Council. In January 2011, the Government of India awarded Kris the Padma
                                                                Bhushan, the country’s third-highest civilian honor. Kris holds Master’s
                                                                degrees in physics and computer science from the Indian Institute of
                                                                Technology, Madras. Kris is a Fellow of Indian National Academy of
                                                                Engineers (INAE) and an Honorary Fellow of Institution of Electronics
                                                                and Telecommunication Engineers (IETE) of India.
                                                            </i>
                                                            <h6 className="leader-btn">
                                                                <i onClick={this.toggleContent1}>SHOW LESS</i>
                                                            </h6>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container-fulid teamback2'>
                            <div className="auto-container marginTop50">
                                <div className="row align-items-center">
                                    <div className="col-lg-2">
                                        <div className="displayflex marginleftteam">
                                            <span className='teamtext'><b>Swami D Swaminathan</b> <br></br> Director & Co-Founder</span>
                                            <span> <img rel="preload"
                                                srcset={require("../../assets/images/shape/directo.webp")} style={{ borderRadius: "50%" }} alt=""
                                                className="teamimg zoom" /> </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="author-info">
                                            <div className="wrapper-box text">
                                                <div className="text1">
                                                    <i>Swaminathan Dandapani in his earlier role as Executive Chairman of Manipal
                                                        Health
                                                        Enterprises, provided direction and leadership to the Healthcare business
                                                        expanding the
                                                        footprint of Manipal Hospitals in new geographies both within & outside
                                                        India.
                                                    </i>
                                                    {showFullContent2 ? (
                                                        <h6 className="leader-btn">
                                                            <i onClick={this.toggleContent2}>SHOW MORE</i>
                                                        </h6>
                                                    ) : (
                                                        <div className="text1">
                                                            <i>
                                                                He also was the Chairman of Manipal Integrated Services the integrated
                                                                facility management company in the Manipal group, Swami was also the
                                                                initiative lead for the “Bridging the Health DivideLeveraging Digital
                                                                India” sponsored by NASSCOM ( Association of Software & Services
                                                                Companies in India) and NATHEALTH ( Association of Healthcare Services &
                                                                Products providers in India) . Prior to joining Manipal Group, Swami was
                                                                the Managing Director & Chief Executive Officer of Infosys BPO (a fully
                                                                owned subsidiary of Infosys Ltd). Swami led the firm building a 25000 +
                                                                team worldwide, of over 65+ different nationalities in their workforce,
                                                                with global operations in 6 centers in India and over 12 centers outside
                                                                India in Americas, Europe, China, LATAM and APAC. Swami, a Chartered
                                                                Accountant has over 40 years of experience in General Management,
                                                                Finance & Accounting, Sales & Marketing, International Trading and Human
                                                                Resources Management. He has performed multiple roles in Sales,
                                                                Marketing and Operations, leading global teams in the Automobiles,
                                                                Consumer Durables, Technology, Manufacturing and Services industries. He
                                                                has also been associated with infrastructure projects in India and
                                                                overseas.
                                                                Swami has successfully concluded & executed several M&A projects across
                                                                US, Europe, APAC, Middle East & India in multiple business verticals.
                                                            </i>
                                                            <h6 className="leader-btn">
                                                                <i onClick={this.toggleContent2}>SHOW LESS</i>
                                                            </h6>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                    {/*
        <!-- Gallery Section --> */}
                    <section className="gallery-section teamleader">
                        <div className="auto-container">
                            <div className="auto-container">
                                <div className="sec-title textalign martextleft40">
                                    <h2> <span className='headeingcolorblue'> LEADERSHIP</span><span
                                        className='headeingcolorblack marginleftspan'> TEAM</span></h2>
                                </div>

                            </div>
                            <div className="displayflex">
                                <div className="teamflex team-block-one lead1">
                                    <div className="column">
                                        <div className="leadership">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/gallery/L4.webp')} alt="" className="avtar" />
                                            <div className="overlay">
                                                <div className="texttitle">Karan Verma</div>
                                                <div className='designation1'>CEO</div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="teamflex team-block-one lead2">
                                    <div className="column">
                                        <div className="leadership">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/gallery/L1.webp')} alt="" className="avtar" />
                                            <div className="overlay">
                                                <div className="texttitle">Dr. Dhanya Prabhu Ramdas</div>
                                                <div className='designation1'>Head – Clinical Services</div>
                                                <div className='linkdinicon'>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="teamflex team-block-one lead1">
                                    <div className="column">
                                        <div className="leadership">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/gallery/L5.webp')} alt="" className="avtar" />
                                            <div className="overlay">
                                                <div className="texttitle">Bipul Jha</div>
                                                <div className='designation1'>Head of Operations</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="displayflex marginteam">
                            </div>
                            <div className="displayflex ">
                                <div className="teamflex team-block-one lead1">
                                    <div className="column">
                                        <div className="leadership">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/gallery/L6.webp')} alt="" className="avtar" />
                                            <div className="overlay">
                                                <div className="texttitle">Pavan Biddanda</div>
                                                <div className='designation1'>VP - Risk Management </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="teamflex team-block-one lead2">
                                    <div className="column">
                                        <div className="leadership div-heading">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/gallery/L7.webp')} alt="" className="avtar" />
                                            <div className="overlay">
                                                <div className="texttitle">Jayeeta Swar</div>
                                                <div className='designation1'>VP - Technology</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                {/*
    <!-- desktop --> */}
                <div className='hidemobile'>
                    <section className="about-section-two marginTop0">
                        <div className="auto-container">
                            <div className="sec-title text-center">
                                <h2> <span className='headeingcolorblack'> BOARD OF</span> <span className='headeingcolorblue'>
                                    DIRECTORS</span></h2>
                            </div>

                        </div>
                        <div className='container-fulid teamback1'>
                            <div className="auto-container">
                                <div className="row align-items-center">

                                    <div className="col-lg-2">
                                        <div className="image-wrapper ">
                                            <div className="image-one">
                                                <img rel="preload"
                                                    srcset={require('../../assets/images/shape/director.webp')} className='zoom-mobile-leadership-chirman' alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="author-info">
                                            <div className="wrapper-box text">
                                                <h2 className="leader-heading">Kris Gopalakrishnan</h2>
                                                <div className="designation leader-heading">Chairman & Co-Founder</div>
                                                <hr>
                                                </hr>
                                                <div className="text">
                                                    <i>Senapathy “Kris” Gopalakrishnan served as the Vice Chairman of Infosys from
                                                        2011 to 2014
                                                        and the Chief Executive Officer and Managing Director of Infosys from 2007
                                                        to 2011. Kris is
                                                        one of the co-founders of Infosys. He also was selected to Thinkers 50, an
                                                        elite list of global
                                                        business thinkers, in 2009.
                                                    </i>
                                                    {showFullContent1 ? (
                                                        <h6 className="leader-btn">
                                                            <i onClick={this.toggleContent1}>SHOW MORE</i>
                                                        </h6>
                                                    ) : (
                                                        <div className="text">
                                                            <i>
                                                                He was elected president of India's apex industry chamber Confederation
                                                                of Indian Industry (CII) for 2013-14, and served as one of the co-chairs
                                                                of the World Economic Forum in Davos in January 2014.
                                                                He serves on the Board of Governors of Okinawa Institute for Science and
                                                                Technology (OIST) and is the Chairman, Board of Governors of IIIT,
                                                                Bangalore. He is the Chairman of the Vision Group on Information
                                                                Technology of Karnataka Government and the Chairman of CII Start up
                                                                Council. In January 2011, the Government of India awarded Kris the Padma
                                                                Bhushan, the country’s third-highest civilian honor. Kris holds Master’s
                                                                degrees in physics and computer science from the Indian Institute of
                                                                Technology, Madras. Kris is a Fellow of Indian National Academy of
                                                                Engineers (INAE) and an Honorary Fellow of Institution of Electronics
                                                                and Telecommunication Engineers (IETE) of India.
                                                            </i>
                                                            <h6 className="leader-btn">
                                                                <i onClick={this.toggleContent1}>SHOW LESS</i>
                                                            </h6>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container-fulid teamback2'>

                            <div className="auto-container marginTop50">
                                <div className="row align-items-center">
                                    <div className="col-lg-10">
                                        <div className="author-info">
                                            <div className="wrapper-box text">
                                                <h2 className="leader-heading1">Swami D Swaminathan</h2>
                                                <div className="designation leader-heading2">Director & Co-Founder</div>
                                                <hr>
                                                </hr>
                                                <div className="text1">
                                                    <i>Swaminathan Dandapani in his earlier role as Executive Chairman of Manipal
                                                        Health
                                                        Enterprises, provided direction and leadership to the Healthcare business
                                                        expanding the
                                                        footprint of Manipal Hospitals in new geographies both within & outside
                                                        India.
                                                    </i>
                                                    {showFullContent2 ? (
                                                        <h6 className="leader-btn">
                                                            <i onClick={this.toggleContent2}>SHOW MORE</i>
                                                        </h6>
                                                    ) : (
                                                        <div className="text1">
                                                            <i>
                                                                He also was the Chairman of Manipal Integrated Services the integrated
                                                                facility management company in the Manipal group, Swami was also the
                                                                initiative lead for the “Bridging the Health DivideLeveraging Digital
                                                                India” sponsored by NASSCOM ( Association of Software & Services
                                                                Companies in India) and NATHEALTH ( Association of Healthcare Services &
                                                                Products providers in India) . Prior to joining Manipal Group, Swami was
                                                                the Managing Director & Chief Executive Officer of Infosys BPO (a fully
                                                                owned subsidiary of Infosys Ltd). Swami led the firm building a 25000 +
                                                                team worldwide, of over 65+ different nationalities in their workforce,
                                                                with global operations in 6 centers in India and over 12 centers outside
                                                                India in Americas, Europe, China, LATAM and APAC. Swami, a Chartered
                                                                Accountant has over 40 years of experience in General Management,
                                                                Finance & Accounting, Sales & Marketing, International Trading and Human
                                                                Resources Management. He has performed multiple roles in Sales,
                                                                Marketing and Operations, leading global teams in the Automobiles,
                                                                Consumer Durables, Technology, Manufacturing and Services industries. He
                                                                has also been associated with infrastructure projects in India and
                                                                overseas.
                                                                Swami has successfully concluded & executed several M&A projects across
                                                                US, Europe, APAC, Middle East & India in multiple business verticals.
                                                            </i>
                                                            <h6 className="leader-btn">
                                                                <i onClick={this.toggleContent2}>SHOW LESS</i>
                                                            </h6>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-2">
                                        <div className="image-wrapper ">
                                            <div className="image-one">
                                                <img rel="preload"
                                                    srcset={require("../../assets/images/shape/directo.webp")} style={{ borderRadius: "50%" }} className='zoom-mobile-leadership-direc' alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/*
        <!-- Gallery Section --> */}
                    <section className="gallery-section teamleader">
                        <div className="auto-container">
                            <div className="auto-container">
                                <div className="sec-title textalign martextleft40 leadership-title">
                                    <h2> <span className='headeingcolorblue leadership-title'> LEADERSHIP</span><br></br> <span
                                        className='headeingcolorblack marginleftspan leadership-title'> TEAM</span></h2>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-lg-3 team-block-one lead1">
                                    <div className="column">
                                        <div className="leadership">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/gallery/L4.webp')} alt="" className="avtar" />
                                            <div className="overlay">
                                                <div className="texttitle">Karan Verma</div>
                                                <div className='designation1'>CEO</div>
                                                <div className='linkdinicon'>
                                                    <a href={'https://www.linkedin.com/in/karanverma/'} title='Linkedin'> <div
                                                        className="fab fa-linkedin linkdinicon1"></div></a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 team-block-one lead2">
                                    <div className="column">
                                        <div className="leadership">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/gallery/L1.webp')} alt="" className="avtar" />
                                            <div className="overlay">
                                                <div className="texttitle">Dr. Dhanya Prabhu Ramdas</div>
                                                <div className='designation1'>Head – Clinical Services</div>
                                                <div className='linkdinicon'>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="leadership">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/gallery/L5.webp')} alt="" className="avtar" />
                                            <div className="overlay">
                                                <div className="texttitle">Bipul Jha</div>
                                                <div className='designation1'>Head of Operations</div>
                                                <div className='linkdinicon'>
                                                    <a href={'https://www.linkedin.com/in/bipul-jha-96286110/'} title='linkedin'> <div
                                                        className="fab fa-linkedin linkdinicon1"></div></a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 team-block-one ">
                                    <div className="column">
                                        <div className="leadership">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/gallery/L6.webp')} alt="" className="avtar" />
                                            <div className="overlay">
                                                <div className="texttitle">Pavan Biddanda</div>
                                                <div className='designation1'>VP - Risk Management </div>
                                                <div className='linkdinicon'>
                                                    <a href={'https://www.linkedin.com/in/pavanba/'} title='linkedin'> <div className="fab fa-linkedin linkdinicon1"></div>
                                                    </a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-3 team-block-one lead3">
                                    <div className="column">
                                        <div className="leadership div-heading">
                                            <img rel="preload"
                                                srcset={require('../../assets/images/gallery/L7.webp')} alt="" className="avtar" />
                                            <div className="overlay">
                                                <div className="texttitle">Jayeeta Swar</div>
                                                <div className='designation1'>VP - Technology</div>
                                                <div className='linkdinicon'>
                                                    <a href={'https://www.linkedin.com/in/jayeeta-swar-92b9bb65/'} title='linkedin'> <div
                                                        className="fab fa-linkedin linkdinicon1"></div></a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>


                <div className='marginTopTeam'>
                </div>
                <div className="sec-title text-center">
                    <h2 style={{ fontSize: 50 + 'px' }}>
                        <span className='headeingcolorblack mt-5'> Meet The </span> <span className='headeingcolorblue'>Bridgers</span>
                    </h2>
                    <h5 className='heading3'>Build a Healthy Future With the Help of Our Specialists</h5>
                </div>
                {/* <!-- About Section Two --> */}
                <section className="about-section-two">
                    <div className="auto-container">
                        <div className="row ">
                            <div className="col-lg-4">
                                <div className="image-one">
                                    <img rel="preload"
                                        srcset={require('../../assets/images/shape/team.webp')} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="author-info">
                                    <div className="wrapper-box text">
                                        <h3>Dr. Dhanya Prabhu Ramdas</h3>
                                        <h4>Head – Clinical Services</h4>
                                        <div className="text font18">Dr. Dhanya Prabhu Ramadas is a highly motivated, team-oriented, and compassionate family physician. She believes in providing evidence-based holistic care to all her patients. Her fields of interest are geriatrics, neurology, women & child health and lifestyle medicine.<br></br>
                                            She has completed her DNB from Bangalore Baptist Hospital and thereafter, completed MRCGP [Int]. She is a member of The Academy of Family Physicians of India (AFPI) and is certified by the Karnataka Medical Council.<br></br>
                                            When not with her patients, she is busy travelling, spending time with family, listening to old English country music or reading. She keeps herself fit by practicing yoga, jogging and dancing.</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row  marginTop50">

                            <div className="col-lg-4">
                                <div className="image-one">
                                    <img rel="preload"
                                        srcset={require('../../assets/images/shape/team1.webp')} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="author-info">
                                    <div className="wrapper-box text">
                                        <h3>Dr. Vedavathi. N</h3>
                                        <h4>Family Physician</h4>
                                        <div className="text font18">
                                            Dr. Vedavathi. N is an empathetic, competent and passionate doctor. She has over 10 years of experience in identifying and screening individuals of all age groups for risk factors to prevent diseases and their progression. She has a special interest in preventive medicine and anatomy.<br></br>
                                            She has completed her P.G Diploma in Family Medicine from Apollo Hospitals, Bangalore, through the Royal College of General Practitioners - United Kingdom. She is a certified NABH Assessor and ISO Assessor. Dr. Veda has also been a faculty member of the paramedical college at Dayanand Sagar University.<br></br>
                                            At a personal level, she loves learning rangoli designs and also enjoys dancing.
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="row  marginTop50">

                            <div className="col-lg-4">
                                <div className="image-one">
                                    <img rel="preload"
                                        srcset={require('../../assets/images/shape/team2.webp')} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="author-info">
                                    <div className="wrapper-box text">
                                        <h3>Dr. Jyotsna Kurien Mathai</h3>
                                        <h4>Consultant Physician</h4>
                                        <div className="text font18">
                                            Dr. Jyotsna Mathai is a Consultant Physician with a special interest in Women's Health. She has completed her MBBS from Jawaharlal Nehru Medical College-Dharwad University and DGO from College of Physician and Surgeons- Mumbai. She was previously associated with Activ Doctors Clinic and NationWide Primary Health Clinic. She is known for her knowledge of Obstetrics and Gynaecology.<br></br>
                                            Dr Jyotsna believes in integrity, respect and responsibility, which have led her to have a successful practice and career.Her core beliefs have helped her reach out to all kinds of people, from every walk of life. She also loves reading about spirituality, esoteric philosophy, and human behaviour analysis.<br></br>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="row  marginTop50">

                            <div className="col-lg-4">
                                <div className="image-one">
                                    <img rel="preload"
                                        src={require("../../assets/images/team/Dr Jisha B.webp")}
                                        alt="" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="author-info">
                                    <div className="wrapper-box text">
                                        <h3>Dr Jisha B</h3>
                                        <h4>Consultant Physician</h4>
                                        <div className="text font18">
                                            Dr Jisha B
                                            has an MBBS degree from Pondicherry University. With several years of experience in the healthcare industry, Dr. Benansia has worked in various prestigious hospitals, including MFine in Bangalore, PRS Hospital in Trivandrum. <br></br>
                                            Dr. Benansia has a keen interest in research and has conducted an observational study on the association between Vitamin D levels and serum lipid parameters among Indian adults working in the IT sector. Her findings have contributed to the growing body of research in this area. In her free time, she enjoys reading books, listening to songs, and practicing kathak dancing.
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="row  marginTop50">

                            <div className="col-lg-4">
                                <div className="image-one">
                                    <img rel="preload"
                                        srcset={require('../../assets/images/team/Krithika.webp')} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="author-info">
                                    <div className="wrapper-box text">
                                        <h3>Dr. Krithika Prasad </h3>
                                        <h4>Head – Pathologist</h4>
                                        <div className="text font18">
                                            Dr. Krithika Prasad
                                            is the Head Pathologist at Bridge Health, where she brings over 7+ years of experience. With an MBBS degree from Yenepoya Medical College and Hospital, Mangalore, she went on to specialize in MD Pathology and has worked with some of the leading hospitals and diagnostic centres in the country.<br></br>
                                            Dr. Prasad has been associated with organizations such as The Madras Medical Mission in Chennai, SRL Diagnostics, Marigold Clinics and Diagnostics, in Bangalore. She has presented research papers at various conferences and forums in Karnataka and won awards for her poster presentations.<br></br>
                                            Dr. Krithika’s core belief is that there is no substitute for hard work. Apart from her work, she has a keen interest in skating, dancing, table tennis, and swimming, which she pursues in her free time.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="row  marginTop50">
                            <div className="col-lg-4">
                                <div className="image-one">
                                    <img rel="preload"
                                        srcset={require('../../assets/images/shrinivas.webp')} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="author-info">
                                    <div className="wrapper-box text">
                                        <h3>Srinivasa Murthy K </h3>
                                        <h4>Senior Physiotherapist</h4>
                                        <div className="text font18">
                                            Srinivasa Murthy has completed his Bachelor of Physiotherapy from NTR University in Andhra Pradesh and thereafter, MPT in Rehabilitation from Pondicherry University. He is a member of the Indian Association of Physiotherapists (IAP).<br></br>
                                            The aim of his practice is to provide high-quality rehabilitation care to individuals with orthopaedic conditions, stroke rehabilitation, neuro rehabilitation, and spinal cord injuries, using the latest evidence-based clinical tools.<br></br>
                                            While not with his patients, he loves to watch movies, travel, cook and play cricket.             </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row  marginTop50">

                            <div className="col-lg-4">
                                <div className="image-one">
                                    <img rel="preload"
                                        srcset={require('../../assets/images/arpita.webp')} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="author-info">
                                    <div className="wrapper-box text">
                                        <h3>Arpitha Mirchandani </h3>
                                        <h4>Psychologist</h4>
                                        <div className="text font18">
                                            Arpitha Mirchandani holds a Master’s degree in Psychology and has 17+ years of work experience. She has been associated with organizations and schools like Jindal Nature Cure Institute, K12 Techno Services, RIMS International School, Lenovo, etc. <br></br>
                                            Arpitha strongly believes that mental health plays a significant role in our overall well-being. Her counselling approach is grounded in a holistic and humanistic perspective, which involves providing guidance on diet, sleep, and stress management. Additionally, Arpitha has contributed articles to newspapers and created content for YouTube.<br></br>
                                            Alongside her professional pursuits, she derives pleasure from cooking, travelling, and reading.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row  marginTop50">
                            <div className="col-lg-4">
                                <div className="image-one">
                                    <img rel="preload"
                                        srcset={require('../../assets/images/team/anup.webp')} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="author-info">
                                    <div className="wrapper-box text">
                                        <h3>Anoop M </h3>
                                        <h4>Optometrist</h4>
                                        <div className="text font18">
                                            Anoop M a certified clinical optometrist from the University of Kerela and has about 7 years of work experience. He has also completed a Diploma in Ophthalmic Assistance. He is passionate about helping others by solving vision problems and improving their quality of life with the right type of vision correction.<br></br>
                                            Anoop has mastered optometry technology tools and solutions to derive accurate diagnosis and determine ideal prescriptions for patients. His other areas of interest include community ophthalmic care, squint assessment, objective and subjective refraction, participating in eye check-up camps, etc. <br></br>
                                            During his time away from work, Anoop plays football, reads books and watches movies.
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row  marginTop50">
                            <div className="col-lg-4">
                                <div className="image-one">
                                    <img rel="preload"
                                        srcset={require('../../assets/images/team/nayak.webp')} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="author-info">
                                    <div className="wrapper-box text">
                                        <h3>Shivakumara Nayaka </h3>
                                        <h4>Radiographer</h4>
                                        <div className="text font18">
                                            Shivakumara Nayaka is a highly skilled Radiographer with over 5 years of experience in the field. He holds a Diploma in Medical Imaging Technology and has expertise in X-ray, CT, PET CT, and other imaging technologies.<br></br>
                                            He has worked with some of the leading healthcare institutions in India, including Jana Priya Hospital, Wellspring Health Care PVT Ltd (Health spring), and Cure Fit. Throughout his career, he has demonstrated a customer-centric approach. He is specialized in troubleshooting and is highly cooperative, making him an invaluable member of any team.<br></br>
                                            In addition to his professional accomplishments, Shivakumara Nayaka is passionate about travelling, hiking, and listening to music.

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row  marginTop50">
                            <div className="col-lg-4">
                                <div className="image-one">
                                    <img rel="preload"
                                        srcset={require('../../assets/images/team/Maitreyee.webp')} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="author-info">
                                    <div className="wrapper-box text">
                                        <h3>Maitreyee De Sarkar </h3>
                                        <h4>Senior Clinical Nutritionist </h4>
                                        <div className="text font18">
                                            Maitreyee De Sarkar
                                            with about 11 years of experience, Maitreyee’s goal is to simplify existing health issues by providing practical and enjoyable lifestyle management strategies without restricting favourite foods,
                                            suggesting extreme diets or fad diets. <br></br> She has done her Master’s in Nutrition & Dietetic Management and is a certified Diabetic Educator from Dr Mohan’s Diabetes Hospital, Chennai. Her areas of specialization include weight management, diabetes,
                                            women’s health, thyroid and eating disorders. She has previously worked with reputed institutions such as Apollo Hospitals, Narayana Health, to name a few. Maitreyee is an active member of the Indian Dietetic Association (IDA) and Indian Association for Parenteral and Enteral Nutrition (IAPEN). <br></br> In her leisure time, she enjoys glass painting, swimming, listening to music and cooking.
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>



                        <div className="row  marginTop50">

                            <div className="col-lg-4">
                                <div className="image-one">
                                    <img rel="preload"
                                        srcset={require('../../assets/images/team/roshan.webp')} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="author-info">
                                    <div className="wrapper-box text">
                                        <h3>Roshan Madhusudhan Suvarna  </h3>
                                        <h4>Fitness Coach </h4>
                                        <div className="text font18">
                                            Roshan Madhusudhan Suvarna a certified exercise specialist with over 7 years of experience in the field of fitness and exercise science. With a passion for helping individuals achieve their health and fitness goals, he is adept at curating and implementing personalized exercise programs. <br></br>
                                            In addition to being internationally recognized by the American Council on Exercise, he has a Diploma in Sports and Exercise Science and is a specialist in Posture and Functional Corrective Exercise. He has worked with a wide spectrum of customers, including those who have conditions like diabetes, obesity, and cardiovascular disease. <br></br>
                                            Roshan is committed to staying up-to-date on the latest research and trends in exercise science and nutrition. In his free time, he prefers staying active through running, weightlifting, and yoga. He also enjoys spending time outdoors and hiking.
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <Footer />

            </div>

        )
    }
}
export default LeadClinical