import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ContactForm from "../element/contact-form";
import FormValidation from "./formvalidation";
import { Helmet } from "react-helmet";
import FormButton from "../element/component/FormButton";
import ReachToUsForm from "../element/ReachToUsForm";
const aboutbg = require("./../../assets/images/shape/Vaccination.webp");

class vaccination extends Component {
  componentDidMount() {
    document.title = "Pharmacy | Bridge Health";
  }

  constructor(props) {
    super(props);
    this.state = {
      vaccineName: "",
      age: "",
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSearch = (event) => {
    event.preventDefault();
    alert(`Searching for ${this.state.vaccineName} at age ${this.state.age}`);
  };

  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>Vaccination</title>
          <meta
            name="Vaccination"
            content="Getting vaccinated is a crucial step in safeguarding your health and the well-being of those around you"
          />
        </Helmet>
        <section
          className="internalpage internalpageresponsive style-two mobilehide "
          style={{ backgroundImage: "url(" + aboutbg + ")" }}
        >
          <div className="auto-container margin90Top">
            <div className="content-box">
              <h1 className="textleft fontSize1">
                <span className="headeingcolorblack">
                  Protect yourselves with{" "}
                </span>
                <br></br>
                <span className="headeingcolorblue"> Vaccinations</span>
              </h1>
              <h2 className="textleft12 marginTop40">Get Vaccinated Today!</h2>
            </div>
            <div className="btn-box btn15">
            <FormButton/>
            </div>
          </div>
        </section>
        <section className="team-section padding doctoeback desktophide image-mobile-terms">
          <section className="container-fluid">
            <div className="row align-items-center auto-container reverseFlex">
              <div className="col-lg-6">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft fontSize50">
                      <span className="headeingcolorblack">
                        Protect<br></br> yourselves with{" "}
                      </span>
                      <br></br>
                      <span className="headeingcolorblue"> Vaccinations</span>
                    </h2>
                    <h3 className="textleft marginTop40">
                      Get Vaccinated Today!
                    </h3>
                  </div>
                  <div className="btn-box">
                    <ContactForm buttonText="BOOK NOW" popupPosition="right" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className=" imgright">
                  <img
                    srcset={require("../../assets/images/shape/Vaccinationmobile.webp")}
                    alt=""
                    className="imgwidth"
                    rel="preload"
                  />
                </div>
              </div>
            </div>
          </section>
        </section>
        <section className="desktophide">
          <div className="auto-container">
            <div className="searchBox">
              <div className="row form-group">
                <input
                  type="text"
                  id="vaccineName"
                  name="vaccineName"
                  value={this.state.vaccineName}
                  onChange={this.handleInputChange}
                  className="mobile-vacc-input"
                  placeholder="Vaccine Name"
                  required
                />
              </div>
              <div className="form-group row">
                <div className="displayflex">
                  <input
                    type="number"
                    id="age"
                    name="age"
                    value={this.state.age}
                    onChange={this.handleInputChange}
                    className="mobile-vacc-input"
                    placeholder="Age"
                    required
                  />
                  <button type="submit" className="mobile-btn-style-vacc">
                    Search{" "}
                    <img
                      srcset={require("../../assets/images/shape/Group 237788.webp")}
                      alt=""
                      className="search-vacc-img"
                      rel="preload"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="auto-container margin-vcc2">
            <div className="sec-title text-center">
              <h2 className="header1">
                <span className="headeingcolorblack"> WHY YOU SHOULD GET</span>
                <span className="headeingcolorblue"> VACCINATED</span>
              </h2>
            </div>

            <div className="row reverseFlex marginTop60">
              <div className="col-lg-6">
                <div className="marginleftvacination">
                  <p className="fontSize23">
                    Getting vaccinated is a crucial step in safeguarding your
                    health and the well-being of those around you. Vaccines work
                    by inducing weakened cells of the concerned virus or
                    bacteria into the bloodstream, triggering your immune system
                    to recognize and fight off the foreign bodies.By getting
                    vaccinated, you not only protect yourself from serious
                    illnesses but also contribute to community immunity,
                    reducing the overall risk of disease outbreaks. Vaccines are
                    a safe and effective way to ensure a healthier future for
                    you and everyone around you.
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <img
                  srcset={require("../../assets/images/shape/Vacc1.webp")}
                  alt=""
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container wrap-vcc4">
            <div className="row">
              <div className="col-sm-4">
                <div className="card-vacc1">
                  <div className="vaccHeadCard">
                    <img
                      srcset={require("../../assets/images/shape/VccCard.webp")}
                      alt=""
                      rel="preload"
                    />
                    <h4>
                      Vaccination<br></br>for Kids
                    </h4>
                  </div>
                  <h5>
                    <span className="headeingcolorblue headeingcolorblueSize">
                      (Birth - 9 Years)
                    </span>
                  </h5>
                  <p className="pCenter">
                    View the immunization<br></br>schedule for Children
                  </p>

                  <button>
                    <h5>
                      <span className="headeingcolorblue headeingcolorblueSize">
                        BOOK NOW{" "}
                        <img
                          srcset={require("../../assets/images/shape/Arrow 9.webp")}
                          alt=""
                          rel="preload"
                        />
                      </span>
                    </h5>
                  </button>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="card-vacc1">
                  <div className="vaccHeadCard">
                    <img
                      srcset={require("../../assets/images/shape/VccCard.webp")}
                      alt=""
                      rel="preload"
                    />
                    <h4>
                      Vaccination<br></br>for Adolescents
                    </h4>
                  </div>
                  <h5>
                    <span className="headeingcolorblue headeingcolorblueSize">
                      (10 - 18 years)
                    </span>
                  </h5>

                  <p className="pCenter">
                    View the immunization <br></br> schedule for adolescents
                  </p>
                  <button>
                    <h5>
                      <span className="headeingcolorblue headeingcolorblueSize">
                        BOOK NOW{" "}
                        <img
                          srcset={require("../../assets/images/shape/Arrow 9.webp")}
                          alt=""
                          rel="preload"
                        />
                      </span>
                    </h5>
                  </button>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="card-vacc1">
                  <div className="vaccHeadCard">
                    <img
                      srcset={require("../../assets/images/shape/VccCard.webp")}
                      alt=""
                      rel="preload"
                    />
                    <h4>
                      Vaccination<br></br>for Adults
                    </h4>
                  </div>
                  <h5>
                    <span className="headeingcolorblue headeingcolorblueSize">
                      (18-50 Years, All Genders)
                    </span>
                  </h5>
                  <p className="pCenter">
                    View the immunization <br></br>schedule for adults
                  </p>
                  <button>
                    <h5>
                      <span className="headeingcolorblue headeingcolorblueSize">
                        BOOK NOW{" "}
                        <img
                          srcset={require("../../assets/images/shape/Arrow 9.webp")}
                          alt=""
                          rel="preload"
                        />
                      </span>
                    </h5>
                  </button>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-sm-4">
                <div className="card-vacc1">
                  <div className="vaccHeadCard">
                    <img
                      srcset={require("../../assets/images/shape/VccCard.webp")}
                      alt=""
                      rel="preload"
                    />
                    <h4>
                      Vaccination for<br></br> Pregnant Women
                    </h4>
                  </div>
                  <p className="pCenter">
                    View the immunization <br></br>schedule for pregnant women
                  </p>
                  <button>
                    <h5>
                      <span className="headeingcolorblue headeingcolorblueSize">
                        BOOK NOW{" "}
                        <img
                          srcset={require("../../assets/images/shape/Arrow 9.webp")}
                          alt=""
                          rel="preload"
                        />
                      </span>
                    </h5>
                  </button>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="card-vacc1">
                  <div className="vaccHeadCard">
                    <img
                      srcset={require("../../assets/images/shape/VccCard.webp")}
                      alt=""
                      rel="preload"
                    />
                    <h4>
                      Vaccination<br></br>above 50 years
                    </h4>
                  </div>
                  <p className="pCenter">
                    View the immunization <br></br>schedule for elderly
                    individuals
                  </p>
                  <button>
                    <h5>
                      <span className="headeingcolorblue headeingcolorblueSize">
                        BOOK NOW{" "}
                        <img
                          srcset={require("../../assets/images/shape/Arrow 9.webp")}
                          alt=""
                          rel="preload"
                        />
                      </span>
                    </h5>
                  </button>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="card-vacc1">
                  <div className="vaccHeadCard">
                    <img
                      srcset={require("../../assets/images/shape/VccCard.webp")}
                      alt=""
                      rel="preload"
                    />
                    <h4>
                      Vaccination<br></br>for Travelers
                    </h4>
                  </div>
                  <p className="pCenter">
                    View the immunization <br></br>schedule for travelers
                  </p>
                  <button>
                    <h5>
                      <span className="headeingcolorblue headeingcolorblueSize">
                        BOOK NOW{" "}
                        <img
                          srcset={require("../../assets/images/shape/Arrow 9.webp")}
                          alt=""
                          rel="preload"
                        />
                      </span>
                    </h5>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="image-wrapper ">
          <div className="image-one">
            <img
              srcset={require("../../assets/images/shape/Vacc2.webp")}
              alt=""
              className="width100"
              rel="preload"
            />
          </div>
        </div>
        <div className="vccRight">
          <section className="vccLeft">
            <div className="auto-container">
              <div className="sec-title text-center margin-vcc3">
                <h2 className="header1">
                  <span className="headeingcolorblack">
                    {" "}
                    EXCLUSIVE VACCINESS BY
                  </span>
                  <span className="headeingcolorblue"> BRIDGE HEALTH</span>
                </h2>
              </div>

              <div className="row mt-5">
                <div className="col-sm-4">
                  <div className="card-vacc">
                    <div className="vaccHeadCard">
                      <img
                        srcset={require("../../assets/images/shape/syringe 1.webp")}
                        alt=""
                        className="syringeimg"
                        rel="preload"
                      />
                      <h3 className="vcch3">
                        <span className="VccHeadeingcolorblack">
                          Influenza Vaccine
                        </span>
                        <br></br>
                        <span className="VcccHeadingcolorblue">
                          Dosage: Yearly
                        </span>
                      </h3>
                    </div>

                    <p>
                      The Influenza vaccine, updated annually to adapt to the
                      virus’ evolving nature, protects you against seasonal
                      flus. It is very important for the elderly, young
                      children, immunocompromised individuals, ...
                    </p>
                    <div className="btn-box text-center">
                      <a href="/influenza" title="KNOW MORE">
                        <button className="commonBtnforAll">KNOW MORE</button>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="card-vacc">
                    <div className="vaccHeadCard">
                      <img
                        srcset={require("../../assets/images/shape/syringe 1.webp")}
                        alt=""
                        className="syringeimg"
                        rel="preload"
                      />
                      <h3 className="vcch3">
                        <span className="VccHeadeingcolorblack">
                          HPV Vaccine
                        </span>
                        <br></br>
                        <span className="VcccHeadingcolorblue">
                          Dosage: 2 doses (6-14 yrs) or 3 doses (15-45yrs)
                        </span>
                      </h3>
                    </div>

                    <p>
                      The HPV vaccine, designed to prevent human papillomavirus
                      infections, reduces the risk of various cancers and
                      genital warts associated with HPV; one of the most common
                      STIs. It is given in a series of t...
                    </p>
                    <div className="btn-box text-center">
                      <a href="/hpv" title="KNOW MORE">
                        <button className="commonBtnforAll">KNOW MORE</button>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="card-vacc">
                    <div className="vaccHeadCard">
                      <img
                        srcset={require("../../assets/images/shape/syringe 1.webp")}
                        alt=""
                        className="syringeimg"
                        rel="preload"
                      />
                      <h3 className="vcch3">
                        <span className="VccHeadeingcolorblack">
                          Shingles Vaccine
                        </span>
                        <br></br>
                        <span className="VcccHeadingcolorblue">
                          Dosage: 2 doses
                        </span>
                      </h3>
                    </div>

                    <p>
                      The Shingles vaccine helps prevent shingles; a painful
                      condition that can affect you if you’ve had chickenpox.
                      The vaccine is usually given in two doses and is crucial,
                      especially for individuals over ...
                    </p>
                    <div className="btn-box text-center">
                      <a href="/shingles" title="KNOW MORE">
                        <button className="commonBtnforAll">KNOW MORE</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cont-vaccination">
                <div className="row mt-4">
                  <div className="col-sm-2"></div>

                  <div className="col-sm-4">
                    <div className="card-vacc">
                      <div className="vaccHeadCard">
                        <img
                          srcset={require("../../assets/images/shape/syringe 1.webp")}
                          alt=""
                          className="syringeimg"
                          rel="preload"
                        />
                        <h3 className="vcch3">
                          <span className="VccHeadeingcolorblack">
                            Hepatitis Vaccine
                          </span>
                          <br></br>
                          <span className="VcccHeadingcolorblue">
                            Dosage: Multiple doses as per (A and B)
                          </span>
                        </h3>
                      </div>

                      <p>
                        Hepatitis vaccines protect against Hepatitis A and
                        Hepatitis B; viral infections that can cause long-term
                        liver problems. The number of vaccination doses varies
                        based on the type being vaccinated against.
                      </p>

                      <div className="btn-box text-center">
                        <a href="/hepatitis" title="KNOW MORE">
                          <button className="commonBtnforAll">KNOW MORE</button>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="card-vacc">
                      <div className="vaccHeadCard">
                        <img
                          srcset={require("../../assets/images/shape/syringe 1.webp")}
                          alt=""
                          className="syringeimg"
                          rel="preload"
                        />
                        <h3 className="vcch3">
                          <span className="VccHeadeingcolorblack">
                            Covid Vaccine
                          </span>
                          <br></br>
                          <span className="VcccHeadingcolorblue">
                            Dosage: 2 doses with a recommeded Booster dose
                          </span>
                        </h3>
                      </div>

                      <p>
                        The COVID-19 vaccine, which is administered in two doses
                        after a specified interval of 8-12 weeks, is very
                        critical in reducing the spread and severity of the
                        Coronavirus and its associated mutations.
                      </p>
                      <div className="btn-box text-center">
                        <a href="/covid" title="KNOW MORE">
                          <button className="commonBtnforAll">KNOW MORE</button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2"></div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section className="marginTop80">
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2>
                {" "}
                <span className="headeingcolorblue">REACH </span>{" "}
                <span className="headeingcolorblack">OUT TO US</span>
                <br></br>
                <p style={{ fontSize: 22 + "px" }}>
                  Our health advisor is ready to answer your queries about what
                  we do and how we
                  <br></br>{" "}
                  <span>
                    can help you take care of yourself and your loved ones
                    better.
                  </span>
                </p>
              </h2>
            </div>
          </div>
        </section>
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6">
              <div className="image-wrapper">
                <div className="image-one">
                  <img
                    className="reachout"
                    srcset={require("../../assets/images/shape/contact.webp")}
                    alt=""
                    rel="preload"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
            <ReachToUsForm/>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default vaccination;
