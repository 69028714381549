import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

class FaqAccordionMob extends Component {
  render() {
    return (
      <>
        {/* <!-- Faq Section -->  */}
        <section className="faq-section">
          <div className="auto-container">
            <div className="row">
              <h2 className="textleft fontSize50 hep-mob1 shi1-mob1">
                <span className="headeingcolorblack">RISK FACTORS </span>
                <span className="headeingcolorblue"> OF COVID</span>
              </h2>
              <Accordion preExpanded={[0]} allowZeroExpanded>
                <AccordionItem allowZeroExpanded={true}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Respiratory Distress
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        COVID-19 often leads to severe respiratory
                        complications, including pneumonia and acute respiratory
                        distress syndrome (ARDS), where the lungs become
                        severely inflamed and filled with fluid. This can result
                        in difficulty breathing and the need for ventilator
                        support.
                      </p>

                      <img
                        srcset={require("../../assets/images/shape/Respiratory.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Blood Clots</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        COVID-19 has been linked to an increased risk of blood
                        clots, which can lead to serious conditions such as deep
                        vein thrombosis (DVT), pulmonary embolism (PE), or
                        stroke
                      </p>
                      <img
                        srcset={require("../../assets/images/shape/Blood Clots.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Heart Problems</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        COVID-19 can cause heart inflammation (myocarditis) and
                        lead to cardiac issues, including arrhythmias, heart
                        attacks, and heart failure, especially in those with
                        preexisting cardiovascular conditions.
                      </p>
                      <img
                        srcset={require("../../assets/images/shape/Heart Problem.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Long COVID</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        Some individuals experience lingering symptoms long
                        after the acute phase of the illness, a condition known
                        as "long COVID" or "post-acute sequelae of SARS-CoV-2
                        infection" (PASC). These symptoms can include fatigue,
                        brain fog, and a range of other persistent health
                        issues.
                      </p>
                      <img
                        srcset={require("../../assets/images/shape/Long covid.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Kidney Damage</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        COVID-19 can harm the kidneys, leading to acute kidney
                        injury (AKI) or exacerbating preexisting kidney
                        conditions. This can result in the need for dialysis or
                        kidney transplantation.
                      </p>
                      <img
                        srcset={require("../../assets/images/shape/Kidney Damage.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Neurological Complications
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div id="image_para_section">
                      <p className="mobileFaqPara">
                        Some individuals experience neurological issues related
                        to COVID-19, including loss of taste and smell,
                        headaches, dizziness, and, in more severe cases,
                        encephalitis or Guillain-Barré syndrome.
                      </p>
                      <img
                        srcset={require("../../assets/images/shape/Neurological.webp")}
                        alt=""
                        className="image_shingles"
                        rel="preload"
                      />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default FaqAccordionMob;
