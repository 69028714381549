import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Helmet } from "react-helmet";


class blog_designs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [],
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    fetch("https://admin.bridgehealth.in/api/blogs?populate=*")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          blogs: data.data,
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        this.setState({
          blogs: [],
          loading: false,
          error: error.message,
        });
      });
  };

  shareOnSocialMedia = (platform) => {
    const shareURL = "https://bridgehealth.in/";

    switch (platform) {
      case "instagram":
        window.open(
          `instagram://library?AssetPath=${encodeURIComponent(shareURL)}`
        );
        break;
      case "linkedin":
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            shareURL
          )}`
        );
        break;
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            shareURL
          )}`
        );
        break;
      case "twitter":
        const tweetText = "Check out this link: " + shareURL;
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            tweetText
          )}`
        );
        break;
      default:
        break;
    }
  };



  render() {
    const { blogs, loading, error } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error: {error}</div>;
    }

    return (
      <>
        <Header />
        <Helmet>
          <title>
            Personalized Care Continuum -Medical & Digital Health Services |Bridge Health
          </title>
          <meta
            name="Blog design"
            content="Bridge Health is one stop health solution for all preventive & holistic healthcare with personalized annual health plans. & There is a plan to fit the specific health needs of everybody - your spouse, parents, kids & YOU. Know more about Bridge Health, JP Nagar 2nd phase Bangalore."
          />
        </Helmet>
        <img
          srcSet={require("./../../assets/images/background/blog1.webp")}
          alt="blog"
          className="imgwidth100 mobilehide "
          rel="preload"
        />
        <section className="section-second ">
          <div className="container-blog">
            <h1 className="wrap-blog-item mobilehide">
              The Influence of Sleep on Mental Health: Unlocking the Secrets of Quality Rest
            </h1>
          </div>
        </section>

        {/* <!-- Blog Section --> */}
        <section className="py-5">
          <div className="container-fluid px-lg-5">
            <div className="row g-4 blog-list-row">
              {blogs.map((blog) => (
                <Link className="blog-item d-block col-lg-4" key={blog.id} to={"/blog/" + blog.id}>
                  <div className="image-wrap overflow-hidden">
                    <img className="object-fit-cover w-100" style={{height: '18vw'}} src={"https://admin.bridgehealth.in" + blog.attributes.image_1.data.attributes.url} alt={blog.attributes.main_title} rel="preload" />
                  </div>
                  <article>
                    <div className="btn-wrap text-center">
                      <button>{blog.attributes.author_name}</button>
                    </div>
                    <h4>{blog.attributes.main_title}</h4>
                    <div className="content">
                      {blog.attributes.paragraph_1}
                    </div>
                    <button className="btn-style-one mt-0">Read More</button>
                  </article>
                </Link>
              ))}
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default blog_designs;
